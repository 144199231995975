<template>
  <div
    :class="{ auction: true, auction_mobile: isMobile }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="center">
      <div class="container">
        <!-- 面包屑 -->
        <div class="bread d-sm-none d-lg-block">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/tasting-online' }"
              >品鉴在线</el-breadcrumb-item
            >
            <el-breadcrumb-item>品鉴师</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="title row d-lg-none">
          <div class="col-sm-6">品鉴师</div>
        </div>
        <div class="row">
          <div
            class="col-md-4 col-sm-12"
            v-for="(item, index) in tastingList"
            :key="index"
            @click="toTastingDetail(item.id, item.name)"
          >
            <div class="center-item">
              <div class="big-img">
                <img class="img-fluid" :src="item.image" alt="" />
                <div class="label-status">
                  <span>TASTING</span>
                  <span>DIVISION</span>
                </div>
              </div>
              <div class="center-bottom">
                <span class="one">{{ item.name }}</span>
                <span class="two">{{ item.introduction }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-sm-none d-lg-block">
        <div class="discount-bottom">
          <el-pagination
            @current-change="handleCurrentChange"
            class="discount-bottom-pagation"
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
          >
          </el-pagination>
          <span class="page-span">共{{ total }}个</span>
        </div>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>

<script>
import { getArtistList } from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.getTastingList();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      tastingList: [],
      pageSize: 9,
      total: 0,
      currentPage: 1,
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
    };
  },

  methods: {
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getArtistList({
        type: 1,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.tastingList = res.items;
    },
    // 获得数据
    async getTastingList() {
      this.currentPage = 1;
      let res = await getArtistList({
        type: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.tastingList = this.tastingList.concat(res.items);
      console.log(res);
    },
    // 跳转
    async toTastingDetail(id, name) {
      this.$router.push({
        path: "/tasting-division-detail",
        query: {
          id: id,
          name: name,
        },
      });
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.tastingList.length < this.total) {
          this.loading = false;
          this.getTastingList();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

.row {
  margin: 0;
}

.bread {
  padding-top: 40px;
}

.auction_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

////////////////////////////////////////////
.center {
  background-color: #f5f5f5;
  padding-bottom: 80px;
  .center-total {
    display: flex;
    justify-content: space-around;
  }
  .center-item {
    width: 90%;
    margin-bottom: 100px;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
    padding: 0;
    transition: all 0.3s ease;
    position: relative;
    .big-img {
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
      }
      .label-status {
        color: #111;
        font-size: 30px;
        font-weight: 500;
        position: absolute;
        top: 0;
        left: -8%;
        span {
          display: block;
          height: 30px;
        }
      }
    }

    .center-bottom {
      position: absolute;
      bottom: -55px;
      left: -8%;
      width: 100%;
      background-color: #fff;
      padding: 20px 44px;
      .one {
        display: block;
        font-size: 24px;
        color: #111;
      }
      .two {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-top: 14px;
      }
    }
  }

  .center-item:hover {
    transform: translate(1px, -1px);
    box-shadow: 0px 12px 14px 0px rgba(153, 153, 153, 0.3);
  }

  @media (min-width: 768px) {
    .col-md-4 {
      max-width: 30%;
    }
  }
}

/////////////////////////////////////
.discount-bottom {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}
/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
}

/deep/ .el-tag .el-tag__close:hover {
  background-color: #111;
}

/deep/ .clear-tag {
  background-color: #eee;
  color: #111;
  border: 0;
  cursor: pointer;
}

/deep/ .el-pager li {
  background-color: #f5f5f5;
}

/deep/ .el-pagination .btn-next {
  background-color: #f5f5f5 !important;
}

/deep/ .el-pagination .btn-prev {
  background-color: #f5f5f5 !important;
}

.footer {
  margin: 0;
}

@media (min-width: 300px) and (max-width: 992px) {
  .title {
    font-size: 18px;
    color: #111;
    font-weight: 500;
    padding-top: 30px !important;
  }
  .no-padding-left {
    padding-left: 5px;
  }
  .no-padding-right {
    padding-right: 5px;
  }
  .auction {
    .center-item {
      width: 100% !important;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .label-status {
      top: 20px !important;
      left: 20px !important;
    }
    .center-bottom {
      padding: 0 !important;
      height: 64px !important;
      background: rgba(255, 255, 255, 0.8) !important;
      width: 90% !important;
      bottom: 5% !important;
      left: 5% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .two {
        margin-top: 5px !important;
        padding: 0 15px;
        -webkit-line-clamp: 1 !important;
      }
    }
  }
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

@media (min-width: 300px) and (max-width: 992px){
  .one{
    font-size: 18px !important;
  }

  .label-status{
    font-size: 24px !important;
  }
}
</style>