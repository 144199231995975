<template>
  <div
    :class="{ auction: true, auction_mobile: isMobile }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="container top">
      <div class="top-bread d-sm-none d-lg-block">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/tasting-online' }"
            >品鉴在线</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/auction' }"
            >拍卖会</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top-name">
        <span>{{ name }}</span>
      </div>
      <div class="top-sort row">
        <div
          class="top-item col-lg-2 col-sm-4 pointer"
          v-for="(item, index) in classificationList"
          :key="index"
          @click="toTwoItem(index)"
        >
          <img class="img-fluid" :src="item.image" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="status">
        <button
          :class="{ normal: true, active: index == statusChoose }"
          v-for="(item, index) in statusList"
          :key="index"
          @click="statusChange(index)"
        >
          {{ item.text }}
        </button>
      </div>
    </div>

    <div class="center">
      <div class="container">
        <div class="row center-total">
          <div
            class="col-lg-4 col-sm-12 pointer"
            v-for="(item, index) in shopList"
            :key="index"
            @click="toAuctionDetail(item.id)"
          >
            <div class="center-item">
              <div class="big-img">
                <img class="img-fluid item-big-img" :src="item.image" alt="" />
                <div class="label-status">
                  <img
                    src="../../assets/img/auction-pre.png"
                    alt=""
                    v-if="item.type == 0"
                  />
                  <img
                    src="../../assets/img/auction-loading.png"
                    alt=""
                    v-if="item.type == 1"
                  />
                  <img
                    src="../../assets/img/auction-end.png"
                    alt=""
                    v-if="item.type == 2"
                  />
                </div>
              </div>
              <div class="center-bottom">
                <span class="one">{{ item.name }}</span>
                <span class="two">拍品来源：{{ item.source }}</span>
                <span class="two">拍卖时间：{{ item.startTime }}</span>
                <span class="two">拍卖地点：{{ item.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-sm-none d-lg-block">
        <div class="discount-bottom">
          <el-pagination
            @current-change="handleCurrentChange"
            class="discount-bottom-pagation"
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
          >
          </el-pagination>
          <span class="page-span">共{{ total }}个</span>
        </div>
      </div>

      <div class="center-swiper container">
        <span class="center-name">历史成交</span>
        <div class="swiper-container row swiper-no-swiping" v-if="historyList.length>0">
          <div class="swiper-wrapper">
            <div
              :class="{
                'swiper-slide': true,
                'col-lg-3': true,
                'col-sm-4': true,
                pointer: true,
                'no-padding-left': true,
                'no-padding-right': true,
              }"
              v-for="(item, index) in historyList"
              :key="index"
            >
              <div class="swiper-item">
                <div class="swiper-item-total">
                  <img class="img-fluid" :src="item.image" alt="" />
                  <div class="swiper-item-bottom">
                    <span class="name">{{ item.name }}</span>
                    <span class="price">成交价：￥{{ item.finalPrice }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div v-else style="font-size:30px;font-weight:500;margin-left:50px">暂无</div>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>

<script>
import {
  getClassicClassification,
  getAuctionList,
  getHistoryList,
} from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.classificationId = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getList();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      statusList: [
        {
          text: "全部",
        },
        {
          text: "待拍卖",
        },
        {
          text: "拍卖中",
        },
        {
          text: "已结束",
        },
      ],
      classificationList: [],
      shopList: [],
      historyList: [],
      statusChoose: 0,
      type: -1,
      pageSize: 6,
      classificationId: 0,
      currentPage: 1,
      total: 0,
      name: "",
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
    };
  },

  methods: {
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getAuctionList({
        type: this.type,
        classificationId: this.classificationId,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
    },
    getList() {
      this.getAuctionClassification();
      this.getData();
      this.getHistory();
    },
    // 请求获得分类数据
    async getAuctionClassification() {
      let res = await getClassicClassification({
        type: 2,
        fid: this.classificationId,
        pageNo: 1,
        pageSize: 100,
      });
      res = res.data.data;
      this.classificationList = res.items;
      console.log(this.classificationList);
      console.log(res);
    },
    //获得主体数据
    async getData() {
      this.currentPage = 1;
      let res = await getAuctionList({
        type: this.type,
        classificationId: this.classificationId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.shopList = this.shopList.concat(res.items);
      console.log(res);
    },
    async getHistory() {
      let res = await getHistoryList({
        pageNo: 1,
        pageSize: 20,
      });
      res = res.data.data;
      this.historyList = res;
      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
    },
    // 状态选择
    statusChange(index) {
      this.statusChoose = index;
      this.type = index - 1;
      console.log(this.type);
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 去详情
    toAuctionDetail(id) {
      this.$router.push({
        path: "/auction-item-detail",
        query: {
          id: id,
        },
      });
    },
    getSwiper() {
      var mySwiper = new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: "auto",

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.shopList.length < this.total) {
          this.loading = false;
          this.getData();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
    toTwoItem(index){
      console.log(index);
      this.$router.push({
        path:'/auction-item-two',
        query:{
          id:this.classificationList[index].id,
          name:this.classificationList[index].name
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

.row {
  margin: 0;
}

.auction_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.top {
  .top-bread {
    margin-top: 50px;
  }
  .top-name {
    margin-top: 80px;
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
  .top-sort {
    margin-top: 20px !important;
    .top-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 160px;
      }
      span {
        display: block;
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }

  .status {
    margin-top: 40px;
    margin-bottom: 20px;
    .normal {
      border: 0;
      outline: none;
      background-color: #f5f5f5;
      border-radius: 20px;
      width: 100px;
      height: 40px;
      font-size: 12px;
      color: #111;
      margin-right: 10px;
      cursor: pointer;
    }
    .active {
      background-color: #111;
      color: #fff;
    }
  }
}

////////////////////////////////////////////
.center {
  background-color: #f5f5f5;
  .center-item {
    margin-top: 60px;
    font-size: 14px;
    color: #666;
    padding: 0;
    transition: all 0.3s ease;
    .big-img {
      position: relative;
      .item-big-img {
        width: 100%;
        height: 350px;
      }
      .label-status {
        position: absolute;
        width: 100px;
        height: 50px;
        top: 24px;
        left: -9px;
        img {
          width: 100% !important;
        }
      }
    }

    .center-bottom {
      width: 100%;
      background-color: #fff;
      padding: 0 24px;
      padding-bottom: 16px;
      .one {
        font-size: 24px;
        color: #111;
        padding-top: 30px;
        margin-bottom: 24px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .two {
        display: block;
        margin-bottom: 12px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
  }

  .center-item:hover {
    transform: translate(1px, -1px);
    box-shadow: 0px 12px 14px 0px rgba(153, 153, 153, 0.3);
  }
}

.center-swiper {
  margin-top: 80px;
  margin-bottom: 80px;
  .center-name {
    display: block;
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
}

.swiper-item {
  // height: 400px;

  .swiper-item-total {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      max-height: 190px;
      min-height: 100px;
    }
    .swiper-item-bottom {
      background: rgba(17, 17, 17, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      position: absolute;
      bottom: -45px;
      left: 5%;
      .name {
        font-size: 20px;
        color: #fff;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 15px 0 0 15px;
      }
      .price {
        font-size: 16px;
        color: #999;
        display: block;
        margin-top: 5px;
        padding-bottom: 15px;
      }
    }
  }
}

.swiper-container {
  padding-bottom: 50px;
}

.swiper-button-prev {
  left: 15px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 45%;
}
.swiper-button-next {
  right: 15px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 45%;
}
/////////////////////////////////////
.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}
/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
}

/deep/ .el-tag .el-tag__close:hover {
  background-color: #111;
}

/deep/ .clear-tag {
  background-color: #eee;
  color: #111;
  border: 0;
  cursor: pointer;
}

/deep/ .el-pager li {
  background-color: #f5f5f5;
}

/deep/ .el-pagination .btn-next {
  background-color: #f5f5f5 !important;
}

/deep/ .el-pagination .btn-prev {
  background-color: #f5f5f5 !important;
}

.footer {
  margin: 0;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding-left {
    padding-left: 5px;
  }
  .no-padding-right {
    padding-right: 5px;
  }
  .auction {
    .top-name {
      font-size: 18px;
      font-weight: 550;
      margin-left: 15px;
      margin-top: 40px;
    }
    .status {
      margin-left: 15px;
    }
  }
  .center-item {
    margin-top: 30px !important;
    .center-bottom {
      .one {
        font-size: 15px !important;
        padding-top: 10px !important;
        margin-bottom: 15px !important;
      }
    }
  }
  .swiper-button-prev {
    left: 5px;
    top: 45%;
  }
  .swiper-button-next {
    right: 5px;
    top: 45%;
  }
  .center-name {
    display: block;
    font-size: 18px !important;
    font-weight: 550 !important;
    margin-left: 15px !important;
    margin-bottom: 10px !important;
  }
  .center-swiper {
    margin-top: 30px;
  }

  .swiper-item-bottom {
    bottom: -30px !important;
    .name {
      font-size: 12px !important;
      padding-top: 10px !important;
    }
    .price {
      padding-bottom: 10px !important;
      margin-top: 0 !important;
    }
  }
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

@media (min-width: 300px) and (max-width: 992px){
  .top-item{
    span{
      font-size: 12px !important;
    }
  }

  .normal{
    width: 70px !important;
    height: 30px !important;
  }

  .swiper-item-bottom{
    width: 100% !important;
    .name{
      font-size: 12px !important;
    }

    .price{
      font-size: 12px !important;
    }
  }
}
</style>