//邮箱验证格式
let emailStr = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
let phoneStr = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
const resultEmail = n => {
    if (emailStr.test(n)) {
        return true
    } else {
        return false
    }
}

const resultPhone = n => {
    if (phoneStr.test(n)) {
        return true
    } else {
        return false
    }
}

function formatDate(time) { //设置时间转换格式
    var date = new Date(time);
    var y = date.getFullYear(); //获取年

    var m = date.getMonth() + 1; //获取月

    m = m < 10 ? '0' + m : m; //判断月是否大于10

    var d = date.getDate(); //获取日

    d = d < 10 ? ('0' + d) : d; //判断日期是否大10


    return y + '-' + m + '-' + d; //返回时间格式

};

function loopDate(time) {
    let hh = parseInt(time / 3600);
    let mm = parseInt(time % 3600 / 60);
    let ss = time % 3600 % 60;
    hh = hh > 9 ? hh : '0' + hh;
    mm = mm > 9 ? mm : '0' + mm;
    ss = ss > 9 ? ss : '0' + ss;
    return hh + ':' + mm + ':' + ss;
}

module.exports = {
    resultEmail: resultEmail,
    resultPhone: resultPhone,
    formatDate: formatDate,
    loopDate: loopDate
}