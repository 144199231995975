<template>
  <div class="info container" v-on:scroll.passive="onScroll" ref="box">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>我的订单</span>
    </div>
    <div class="col-sm-12">
      <div class="search">
        <img src="../../assets/img/search_black.png" alt="" />
        <input
          class="col-sm-10"
          type="text"
          placeholder="输入商品名称或订单号"
        />
        <div class="col-sm-2 search-btn">搜索</div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="nav-order">
        <span
          :class="{ 'nav-active': navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
          @click="navChoose(index)"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div class="col-sm-12">
      <div class="order-container">
        <div class="order-container-top">
          <div class="order-container-top-left">
            <img
              src="../../assets/img/icon_sel_s.png"
              v-if="totalChoose"
              alt=""
              @click="chooseNull"
            />
            <img
              src="../../assets/img/icon_sel_n.png"
              v-else
              alt=""
              @click="chooseAll"
            />
            <span>全选</span>
          </div>
          <div class="order-container-top-right">
            <span @click="toInvoice">申请开票</span>
          </div>
        </div>
        <div
          class="order-container-item"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div
            :class="{
              'container-position': true,
            }"
            @touchstart="start"
            @touchmove="end(item.state, $event)"
          >
            <div class="order-item-top">
              <div class="order-item-top-left">
                <img
                  src="../../assets/img/icon_sel_g.png"
                  v-if="item.state != 4"
                />
                <img
                  src="../../assets/img/icon_sel_n.png"
                  v-if="item.state == 4 && item.flag == false"
                  @click="item.flag = true"
                />
                <img
                  src="../../assets/img/icon_sel_s.png"
                  v-if="item.state == 4 && item.flag"
                  @click="item.flag = false"
                />
                <span class="order-num">订单编号: {{ item.orderNumber }}</span>
              </div>
              <div class="order-item-top-right">
                <span :class="{ 'order-state': item.state == 1 }">{{
                  stateList[item.state].text
                }}</span>
              </div>
            </div>
            <div>
              <div
                class="order-item-item"
                v-for="(item2, index2) in item.orderCommodityVOList"
                :key="index2"
                @click="orderDetail(item.id)"
              >
                <div class="order-item-item-top">
                  <div class="col-sm-5 order-img">
                    <img class="img-fuild" :src="item2.commodityImage" alt="" />
                  </div>
                  <div
                    class="col-sm-7 remove-col remove-right-col order-domain"
                  >
                    <div class="order-name">
                      <div class="col-sm-10 name remove-col">
                        <span>{{ item2.commodityName }}</span>
                      </div>
                      <div class="col-sm-2 remove-col my_qunatity">
                        <span class="qunatity">x{{ item2.quantity }}</span>
                      </div>
                    </div>
                    <div class="size">
                      <span>{{ item2.specificationValueName }}</span>
                    </div>
                    <div class="order-address">
                      <span>{{ item.outletsArea + item.outletsName }}</span>
                    </div>
                    <div class="price">
                      <span>￥{{ item2.commodityTypePrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="myForm"></div>
            <div class="getAward" v-if="item.number">
              <span class="getAwardOne">恭喜中奖!</span>
              <span class="getAwardTwo"
                >中奖号码：{{item.number}}，请在开奖后24小时内付款，否则平台将自动取消您的购买资格</span
              >
              <div class="getAwardThree" v-if="item.cautionMoney>0">
                <img src="../../assets/img/warning.png" alt="" />
                <span>已支付保证金￥{{item.cautionMoney}},将在支付订单后退还至原账户</span>
              </div>
            </div>
            <div class="order-bottom">
              <div class="bottom-left">
                <span class="one">订单金额</span>
                <span class="two">￥{{ item.orderMoney }}</span>
              </div>
              <div class="bottom-right">
                <div
                  class="bottom-btn"
                  v-if="item.state == 1"
                  @click="cancelOneOrder(item.id)"
                >
                  取消订单
                </div>
                <div
                  class="bottom-btn btn-black"
                  v-if="item.state == 1"
                  @click="topayMoney(item)"
                >
                  付款 {{ item.now }}
                </div>
                <div
                  class="bottom-btn"
                  v-if="item.state == 3 || item.state == 4"
                  @click="tologistic(item.orderNumber)"
                >
                  查看物流
                </div>
                <div
                  class="bottom-btn"
                  v-if="item.state == 4 && item.invoice == 0"
                  @click="oneAddInvoice(item)"
                >
                  申请开票
                </div>
                <div
                  class="bottom-btn"
                  v-if="item.state == 4 && item.invoice == 1"
                >
                  已申请
                </div>
              </div>
            </div>
            <div
              class="deleted"
              v-if="item.state == 0 || item.state == 4"
              @click="removeOneOrder(item.id)"
            >
              <span>删除</span>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="orderList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
        <div class="loading" :class="{ 'loading-hidden': loading }">
          {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
    <el-dialog
      title="支付方式"
      :visible.sync="typePay"
      custom-class="mobile-pay-dialog"
      center
    >
      <div class="payTypeMobile">
        <div class="payTypeMobile-item">
          <img src="../../assets/img/zhifubao.png" alt="" />
          <span>支付宝</span>
          <img
            class="to-right"
            src="../../assets/img/ic_gou_s.png"
            alt=""
            v-if="payAli"
          />
          <img
            class="to-right"
            src="../../assets/img/ic_gou_n.png"
            alt=""
            v-else
            @click="payAli = true"
          />
        </div>
        <div class="payTypeMobile-item">
          <img src="../../assets/img/wechart.png" alt="" />
          <span>微信</span>
          <img
            class="to-right"
            src="../../assets/img/ic_gou_s.png"
            alt=""
            v-if="!payAli"
          />
          <img
            class="to-right"
            src="../../assets/img/ic_gou_n.png"
            alt=""
            v-else
            @click="payAli = false"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typePay = false">取 消</el-button>
        <el-button type="primary" @click="paySure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  myOrderPage,
  deleteOrder,
  payOrder,
  removeOrder,
} from "../../assets/api/api.js";
import { loopDate } from "../../uitils/check.js";
export default {
  created() {
    this.getOrder();
  },
  data() {
    return {
      navList: [
        {
          id: 0,
          text: "全部",
        },
        {
          id: 1,
          text: "待付款",
        },
        {
          id: 2,
          text: "待发货",
        },
        {
          id: 3,
          text: "已发货",
        },
        {
          id: 4,
          text: "已完成",
        },
      ],
      orderList: [],
      stateList: [
        {
          text: "已取消",
        },
        {
          text: "待支付",
        },
        {
          text: "待发货",
        },
        {
          text: "待收货",
        },
        {
          text: "已完成",
        },
      ],
      navIndex: 0,
      totalChoose: false,
      state: -1,
      nameOrNumber: "",
      pageNo: 1,
      pageSize: 4,
      startX: 0,
      moveX: 0,
      total: 0,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      sid: 0,
      message: "",
      typePay: false,
      payAli: true,
      infoItem: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    toPreview() {
      this.$router.push("/mine");
    },
    // nav选择
    navChoose(index) {
      this.navIndex = index;
      this.pageNo = 1;
      if (index == 0) {
        console.log("...");
        this.state = -1;
      } else {
        this.state = index;
      }
      this.getOrderTwo();
    },
    // 获得订单
    async getOrder() {
      let res = await myOrderPage({
        nameOrNumber: this.nameOrNumber,
        userId: 1,
        state: this.state,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data;
      if (res.result == 0) {
        this.loading = true;
      }
      console.log(res);
      this.total = res.data.total;
      this.orderList = this.orderList.concat(res.data.items);
      this.getLoop();
    },
    async getOrderTwo() {
      this.pageNo = 1;
      this.orderList = [];
      let res = await myOrderPage({
        nameOrNumber: this.nameOrNumber,
        userId: 1,
        state: this.state,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.orderList = res.items;
      this.getLoop();
      console.log(res);
    },
    // 倒计时循环
    getLoop() {
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 1) {
          this.orderList[i].time--;
          this.orderList[i].now = loopDate(this.orderList[i].time);
          if (this.orderList[i].time == 0) {
            console.log("...");
            clearTimeout(this.sid);
            this.getOrder();
          }
        }
      }
      clearTimeout(this.sid);
      this.getLoopDetail();
    },
    getLoopDetail() {
      let that = this;
      this.sid = setTimeout(() => {
        that.getLoop();
      }, 1000);
    },
    start(e) {
      this.startX = e.touches[0].clientX;
    },
    end(id, e) {
      this.moveX = e.touches[0].clientX;
      if (id == 0 || id == 4) {
        if (this.startX - this.moveX > 50) {
          e.currentTarget.classList.add("warpLeft");
          e.currentTarget.classList.remove("warpRight");
        }
        if (this.startX - this.moveX < -30) {
          e.currentTarget.classList.add("warpRight");
          e.currentTarget.classList.remove("warpLeft");
        }
      } else {
        e.currentTarget.classList.remove("container-position");
      }
    },
    // 滚动
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        console.log(this.orderList.length);
        if (this.orderList.length < this.total) {
          this.loading = false;
          this.getOrder();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
      }
    },
    // 订单详情
    orderDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/mine/order-detail",
        query: {
          id: id,
        },
      });
    },
    // 物流
    tologistic(number) {
      this.$router.push({
        path: "/mine/logistic",
        query: {
          orderNumber: number,
        },
      });
    },
    // 全选
    chooseAll() {
      this.totalChoose = true;
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 4) {
          this.orderList[i].flag = true;
        }
      }
    },
    // 全不选
    chooseNull() {
      this.totalChoose = false;
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 4) {
          this.orderList[i].flag = false;
        }
      }
    },
    // 申请开票
    toInvoice() {
      let arr = [];
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 4 && this.orderList[i].flag) {
          arr.push(this.orderList[i].orderNumber);
        }
      }
      if (arr.length > 0) {
        this.$router.push({
          path: "/mine/add-invoice",
          query: {
            number: arr.join(","),
          },
        });
      } else {
        this.message = "请选择开票订单";
        this.showInfo();
      }
    },
    // 删除
    async removeOneOrder(id) {
      let res = await deleteOrder({
        id: id,
      });
      res = res.data;
      if (res.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      }
      console.log(res);
      this.getOrderTwo();
    },
    oneAddInvoice(item) {
      item.flag = true;
      this.toInvoice();
    },
    topayMoney(item) {
      this.payAli = true;
      this.typePay = true;
      this.infoItem = item;
    },
    paySure() {
      let item = this.infoItem;
      this.typePay = false;
      payOrder({
        orderNumber: item.orderNumber,
        type: 1,
        payType: this.payAli ? 0 : 1,
      }).then((res) => {
        console.log(res);
        if (this.payAli) {
          if (res.status == 200) {
            this.$("#myForm").html(res.data);
          }
        } else {
          location.href = res.data.h5_url;
        }
        console.log(item);
        console.log(res);
      });
    },
    cancelOneOrder(id) {
      removeOrder({
        id: id,
      }).then((res) => {
        if (res.data.result == 0) {
          this.message = "取消成功";
          this.showInfo();
          this.getOrderTwo();
        } else {
          this.message = "取消失败";
          this.showInfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    padding-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .search {
    display: flex;
    height: 40px;
    position: relative;
    margin-top: 20px;
    img {
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 999;
    }
    input {
      width: 100%;
      font-size: 14px;
      outline: none;
      height: 100%;
      padding-left: 50px;
    }
    .search-btn {
      height: 100%;
      width: 100%;
      background-color: #111;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 0;
    }
  }
  .nav-order {
    margin-top: 20px;
    font-size: 16px;
    color: #666;
    display: flex;
    justify-content: space-between;
  }
  .nav-active {
    font-size: 18px;
    color: #111;
  }
  .order-container {
    margin-top: 15px;
    .order-container-top {
      background-color: #fff;
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1px;
      .order-container-top-left {
        color: #666;
        display: flex;
        align-items: center;
        font-size: 16px;
        img {
          margin-right: 10px;
        }
      }
      .order-container-top-right {
        font-size: 16px;
        span {
          border-bottom: 1px solid #111;
        }
      }
    }
    .order-container-item {
      overflow: hidden;
      padding: 10px 12px;
      background-color: #fff;
      margin-bottom: 10px !important;
      .order-item-top {
        display: flex;
        justify-content: space-between;
        .order-item-top-left {
          .order-num {
            font-size: 16px;
            color: #999999;
            margin-left: 10px;
          }
        }
        .order-item-top-right {
          font-size: 16px;
          color: #999;
          .order-state {
            color: #ce2829;
          }
        }
      }
      .order-item-item {
        margin-top: 30px;
        .order-img {
          padding: 0;
          img {
            width: 100%;
            min-height: 125px;

            border: 1px solid #cccdcd;
          }
        }
        .order-domain {
          margin-left: 10px;
        }
        .order-item-item-top {
          display: flex;
          .order-name {
            display: flex;
            font-size: 14px;
            .name {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .qunatity {
              color: #999;
            }
          }
          .size {
            font-size: 16px;
            color: #999;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .order-address {
            font-size: 16px;
            color: #999;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          .price {
            font-size: 15px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
.container-position {
  position: relative;
  left: 0;
}
.remove-right-col {
  padding-right: 12px !important;
}
.remove-col {
  padding: 0;
}
.my_qunatity {
  width: 100%;
  text-align: right;
}
.deleted {
  position: absolute;
  width: 50px;
  background-color: #ce2829;
  right: -62px;
  top: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.order-bottom {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bottom-left {
    font-size: 12px;
    // margin-left: 20px;
    .one {
      color: #bbbbbb;
    }
    .two {
      color: #111;
      font-weight: 500;
    }
  }
  .bottom-right {
    display: flex;
    .bottom-btn {
      font-size: 16px;
      width: 85px;
      height: 30px;
      border: 1px solid #111;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-black {
      background-color: #111;
      color: #fff;
    }
  }
}

@keyframes toLeft {
  0% {
    left: 0;
  }
  100% {
    left: -60px;
  }
}

@keyframes toRight {
  0% {
    left: -60px;
  }
  100% {
    left: 0;
  }
}

.warpLeft {
  animation: toLeft 0.3s ease forwards;
}

.warpRight {
  animation: toRight 0.3s ease forwards;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.payTypeMobile-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.payTypeMobile-item .to-right {
  margin-left: auto;
}

.getAward {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .getAwardOne {
    font-size: 12px;
    font-weight: bold;
  }
  .getAwardTwo {
    font-size: 10px;
    color: #111;
    margin-top: 4px;
  }
  .getAwardThree {
    margin-top: 6px;
    display: flex;
    align-items: center;
    color: #bba492;
    font-size: 10px;
  }
  .getAwardThree img {
    margin-right: 5px;
  }
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>