import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Pagination,
  InputNumber,
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  DatePicker,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
  Scrollbar,
  Drawer,
  MessageBox,
} from 'element-ui'
import $ from './assets/plugin/jquery-3.4.1.min.js'
import Swiper from './assets/plugin/swiper.js'

import './assets/plugin/bootstrap.min.css'
import './assets/plugin/animate.css'
import './assets/plugin/swiper.css'
import '../node_modules/element-ui/lib/theme-chalk/index.css'
import './assets/global.css'
Vue.prototype.$ = $
Vue.prototype.Swiper = Swiper
Vue.prototype.$confirm = MessageBox.confirm
Vue.config.productionTip = false

Vue.use(Pagination)
Vue.use(InputNumber)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tag)
Vue.use(Scrollbar)
Vue.use(Drawer)

var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?6b133a82d6cac1a268ae68531927cd46";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')