<template>
  <div class="col-md-10 order">
    <div class="order-fff">
      <div class="top-nav">
        <div class="top-nav-left">
          <div
            :class="{ 'left-item': true, 'select-nav': chooseNav == index }"
            v-for="(item, index) in navList"
            :key="index"
            @click="toChooseNav(index)"
          >
            <span
              :class="{ 'left-item-span': true, 'border-none': index == 4 }"
              >{{ item.text }}</span
            >
          </div>
        </div>
        <div class="top-nav-right">
          <span class="time">开具时间</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="——"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
          >
          </el-date-picker>
          <button class="btn-one" @click="getData">查询</button>
        </div>
      </div>
      <div class="invoice-table">
        <!-- 头部信息 -->
        <div class="invoice-top invoice-container">
          <div class="one">
            <span>编号</span>
          </div>
          <div class="two">
            <span>发票ID</span>
          </div>
          <div class="three">
            <span>发票项目</span>
          </div>
          <div class="three">
            <span>发票类型</span>
          </div>
          <div class="three">
            <span>发票金额</span>
          </div>
          <div class="three">
            <span>申请时间</span>
          </div>
          <div class="three">
            <span>开具时间</span>
          </div>
          <div class="three">
            <span>开具用户</span>
          </div>
          <div class="three">
            <span>当前状态</span>
          </div>
          <div class="three">
            <span>操作</span>
          </div>
        </div>
        <!-- 主体信息 -->
        <div class="container-bg">
          <div
            class="invoice-container-item"
            v-for="(item, index) in invoiceList"
            :key="index"
          >
            <div class="one">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="two">
              <span>{{ item.invoiceId?item.invoiceId:'暂无' }}</span>
            </div>
            <div class="three">
              <span>商品</span>
            </div>
            <div class="three">
              <span>{{ item.isType == 0 ? "纸质发票" : "电子发票 " }}</span>
            </div>
            <div class="three">
              <span>￥{{ item.payMoney }}</span>
            </div>
            <div class="three">
              <span>{{ item.createTime }}</span>
            </div>
            <div class="three">
              <span v-if="item.invoiceTime != null">{{
                item.invoiceTime
              }}</span>
              <span v-else>未开具</span>
            </div>
            <div class="three">
              <span>{{ item.invoiceHeader }}</span>
            </div>
            <div class="three">
              <span v-if="item.state == 0">待邮寄</span>
              <span v-if="item.state == 1">已邮寄</span>
              <span v-if="item.state == 2">未开票</span>
              <span v-if="item.state == 3">已开票</span>
            </div>
            <div class="three four">
              <span
                class="four-one"
                v-if="item.state == 1"
                @click="toLogistic(item)"
                >查看物流</span
              >
              <span class="four-two" v-if="item.state == 0">查看物流</span>
              <span class="four-two" v-if="item.state == 2">下载发票</span>
              <span
                class="four-one"
                v-if="item.state == 3"
                @click="download(item.image, item.link, 'invoince')"
                >下载发票</span
              >
            </div>
          </div>
          <div class="null-info" v-if="invoiceList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
        </div>
      </div>
      <div class="discount-bottom container" v-if="invoiceList>0">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
  </div>
</template>


<script>
import { getMineInvoicePage } from "../../assets/api/api.js";
import { formatDate } from "../../uitils/check.js";
export default {
  // name:'shopList',
  created() {
    this.getData();
  },
  data() {
    return {
      chooseNav: 0,
      value1: "",
      navList: [
        {
          text: "全部发票",
        },
        {
          text: "纸质发票",
        },
        {
          text: "电子发票",
        },
      ],
      startTime: "",
      endTime: "",
      isType: -1,
      pageSize: 8,
      total: 0,
      currentPage: 1,
      invoiceList: [],
    };
  },
  watch: {
    value1: function () {
      if (this.value1 != null) {
        this.startTime = formatDate(this.value1[0]);
        this.endTime = formatDate(this.value1[1]);
        console.log(this.startTime);
        console.log(this.endTime);
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
  },
  methods: {
    toChooseNav(index) {
      this.chooseNav = index;
      this.isType = index - 1;
      this.getData();
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      console.log(val);
    },
    // 获得数据
    async getData() {
      this.currentPage = 1;
      let res = await getMineInvoicePage({
        userId: 1,
        startTime: this.startTime,
        endTime: this.endTime,
        isType: this.isType,
        pageNo: 1,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.invoiceList = res.items;
      console.log(res);
    },
    // 下载发票
    download(imgsrc, link, name) {
      if (imgsrc) {
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          let canvas = document.createElement("canvas");

          canvas.width = image.width;

          canvas.height = image.height;

          let context = canvas.getContext("2d");

          context.drawImage(image, 0, 0, image.width, image.height);

          let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

          let a = document.createElement("a"); // 生成一个a元素

          let event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性

          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
      }else{
        window.open(link);
      }
    },
    toLogistic(item) {
      console.log(item);
      // console.log(item);
      this.$router.push({
        path: "/invoiceLogistic",
        query: {
          orderNumber: item.invoiceNumber,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-fff {
  background-color: #fff;
  padding: 15px;
}
.order {
  background-color: #fff;
  padding-bottom: 50px;
  padding-left: 15px;
  background-color: #f5f5f5;
  .top-nav {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-nav-left {
      display: flex;
      align-items: center;
      height: 100%;
      .left-item {
        width: 110px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .left-item-span {
          font-size: 16px;
          text-align: center;
          border-right: 1px solid #eee;
          display: inline-block;
          width: 100%;
          color: #666;
        }
        .border-none {
          border-right: 0;
        }
      }
      .select-nav {
        font-size: 18px;
        font-weight: 500;
        color: #111;
        border-bottom: 2px solid #111;
      }
    }
    .top-nav-right {
      display: flex;
      font-size: 14px;
      color: #111;
      align-items: center;
      .time {
        margin-right: 20px;
      }
      .btn-one {
        height: 30px;
        width: 50px;
        background-color: #111;
        color: #fff;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        outline: none;
        margin-left: 20px;
      }
    }
  }
  .invoice-table {
    margin-top: 20px;
    font-size: 14px;
    color: #111;
    border: 1px solid #cccdcd;
    .invoice-container {
      height: 40px;
      background-color: #f5f5f5;
      color: #666;
      display: flex;
      align-items: center;
      text-align: center;

      .one {
        width: 8%;
      }
      .two {
        width: 12%;
      }
      .three {
        width: 10%;
      }
    }
    .container-bg {
      background-color: #fff;
      .invoice-container-item {
        height: 75px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-top: 1px solid #cccdcd;
        .one {
          width: 8%;
        }
        .two {
          width: 12%;
        }
        .three {
          width: 10%;
        }
        .four {
          height: 100%;
          border-left: 1px solid #cccdcd;
          display: flex;
          align-items: center;
          justify-content: center;
          .four-one {
            border-bottom: 1px solid #111;
            color: #111;
            cursor: pointer;
          }
          .four-two {
            border-bottom: 1px solid #ccc;
            color: #ccc;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//////////////////////////////////
/deep/ .el-range-editor--mini .el-range-input {
  font-size: 14px;
  background-color: #f5f5f5;
}
.el-input__inner {
  background-color: #f5f5f5;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px !important;
}

/deep/ .el-date-editor .el-range__icon {
  line-height: 22px;
}

/deep/ .el-date-editor .el-range__close-icon {
  line-height: 22px;
}

/deep/ .el-date-editor .el-range-separator {
  font-size: 14px;
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.null-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>