<template>
  <!-- 待付款 -->
  <div class="order-container-two col-md-10">
    <div class="item">
      <div class="top">
        <span>下单时间：{{ obj.orderTime }}</span>
        <span class="top-center">订单号：{{ obj.orderNumber }}</span>
        <span v-if="obj.state == 0">已取消</span>
        <span v-if="obj.state == 1">待付款</span>
        <span v-if="obj.state == 2">待发货</span>
        <span v-if="obj.state == 3">待收货</span>
        <span v-if="obj.state == 4">已完成</span>
      </div>
      <div class="center">
        <div class="center-left">
          <div class="center-left-item">
            <span class="one">收货人:</span>
            <span>{{ obj.username }}</span>
          </div>

          <div class="center-left-item">
            <span class="one">联系方式：</span>
            <span>{{ obj.phone }}</span>
          </div>

          <div class="center-left-item">
            <span class="one">收货地址：</span>
            <span>{{ obj.address }}</span>
          </div>
        </div>
        <div class="center-right">
          <div class="center-right-item">
            <span class="one">商品合计：</span>
            <span class="two">￥{{ obj.orderMoney }}</span>
          </div>
          <div class="center-right-item">
            <span class="one">邮费：</span>
            <span class="two">￥0</span>
          </div>
          <div class="center-right-item">
            <span class="one">活动优惠：</span>
            <span class="two">-￥0</span>
          </div>
          <div class="center-right-line"></div>
          <div class="center-right-item">
            <span class="one">订单总额：</span>
            <span class="two three">￥{{ obj.orderMoney }}</span>
          </div>
          <div class="right-btn" v-if="obj.state == 1">
            <button class="cancel" @click="removeOrder">取消订单</button>
            <button class="sure" @click="toOrderMoney">
              付款 {{ obj.now }}
            </button>
          </div>
        </div>
      </div>
      <div class="bottomed">
        <div class="bottom-title">
          <span class="one">商品信息</span>
          <span class="two">数量</span>
          <span class="three">单价</span>
          <span class="four">小计</span>
          <span class="five">网点</span>
        </div>
        <div class="bottom-container">
          <div
            class="bottom-container-item"
            v-for="(item, index) in obj.orderCommodityVOList"
            :key="index"
          >
            <div class="one">
              <img :src="item.commodityImage" alt="" />
              <div class="one-right">
                <span class="one-item">{{ item.commodityName }}</span>
                <span class="two-item">{{ item.specificationValueName }}</span>
              </div>
            </div>
            <div class="two">
              <span>x{{ item.quantity }}</span>
            </div>
            <div class="two">
              <span>￥{{ item.commodityTypePrice }}</span>
            </div>
            <div class="two">
              <span>￥{{ item.commodityTypePrice * item.quantity }}</span>
            </div>
            <div class="three">
              <span>{{ obj.outletsArea + obj.outletsName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="myForm"></div>
    <div class="message" id="message">{{ message }}</div>
    <div class="scanPay" v-show="isshowScan">
      <img
        class="closeIMg"
        src="../../assets/img/ic_menu_close.png"
        alt=""
        @click="isshowScan = false"
      />
      <span>扫码付款</span>
      <div id="qrcode" ref="qrcode"></div>
      <div class="isPay" @click="seeStatus">如果已付款，请点击</div>
    </div>
    <el-dialog
      title="选择付款类型"
      :visible.sync="typeVisible"
      width="400px"
      center
    >
      <div class="moneyType">
        <div class="moneyType-item">
          <img src="../../assets/img/zhifubao.png" alt="">
          <span>支付宝</span>
          <img v-if="payAli" src="../../assets/img/ic_gou_s.png" class="to-right" alt="">
          <img v-else src="../../assets/img/ic_gou_n.png" class="to-right" alt="" @click="payAli = true">
        </div>
        <div class="moneyType-item">
          <img src="../../assets/img/wechart.png" alt="">
          <span>微信</span>
          <img v-if="!payAli" class="to-right" src="../../assets/img/ic_gou_s.png" alt="">
          <img v-else src="../../assets/img/ic_gou_n.png" class="to-right" alt="" @click="payAli = false">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogPayMoney"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderDetailApi,
  removeOrder,
  payOrder,
  wxResult,
} from "../../assets/api/api.js";
import { loopDate } from "../../uitils/check.js";
import QRCode from 'qrcodejs2'
export default {
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  data() {
    return {
      obj: {},
      sid: 0,
      message: "",
      orderId: "",
      isshowScan: false,
      typeVisible:false,
      payAli:true,
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getData(id) {
      let res = await orderDetailApi({
        id: id,
      });
      res = res.data.data;
      this.obj = res;
      console.log(res);
      if (this.obj.state == 1) {
        this.getLoop();
      }
    },
    getLoop() {
      this.obj.time--;
      this.obj.now = loopDate(this.obj.time);
      if (this.obj.time == 0) {
        clearTimeout(this.sid);
        this.getData(this.obj.id);
      }
      clearTimeout(this.sid);
      this.getLoopDetail();
    },
    getLoopDetail() {
      this.sid = setTimeout(this.getLoop, 1000);
    },
    // 取消订单
    async removeOrder() {
      let res = await removeOrder({
        id: this.obj.id,
      });
      console.log(res);
      this.getData(this.obj.id);
    },
    toOrderMoney() {
      this.payAli = true;
      this.typeVisible = true;
      // payOrder({
      //   orderNumber: this.obj.orderNumber,
      //   type: 0,
      // }).then((res) => {
      //   this.orderId = res.data.order_number;
      //   if (this.obj.payType == 0) {
      //     if (res.status == 200) {
      //       this.$("#myForm").html(res.data);
      //     }
      //   } else {
      //     this.isshowScan = true;
      //     this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
      //     let qrcode = new QRCode("qrcode", {
      //       width: 200, // 设置宽度，单位像素
      //       height: 200, // 设置高度，单位像素
      //       text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
      //     });
      //   }
      // });
    },
    dialogPayMoney(){
      this.typeVisible = false;
      payOrder({
        orderNumber: this.obj.orderNumber,
        type: 0,
        payType:this.payAli?0:1
      }).then((res) => {
        this.orderId = res.data.order_number;
        if (this.payAli) {
          if (res.status == 200) {
            this.$("#myForm").html(res.data);
          }
        } else {
          this.isshowScan = true;
          this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
          let qrcode = new QRCode("qrcode", {
            width: 200, // 设置宽度，单位像素
            height: 200, // 设置高度，单位像素
            text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
          });
        }
      });
    },
    seeStatus(){
      wxResult({
        orderId:this.orderId
      }).then((res)=>{
        console.log(res);
        if(res.data.result == 0){
          this.message = res.data.msg;
          this.showInfo();
          this.getData(this.obj.id);
        }else{
          this.message = res.data.msg;
          this.showInfo();
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.order-container-two {
  width: 100%;
  background-color: #fff;
  .item {
    border: 1px solid #cccdcd;
    margin-top: 30px;
    margin-bottom: 30px;
    .top {
      height: 45px;
      background-color: #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .top-center {
        margin-right: auto;
        margin-left: 40px;
      }
    }
    .center {
      font-size: 14px;
      color: #111;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      .center-left {
        .center-left-item {
          .one {
            display: inline-block;
            width: 70px;
            margin-bottom: 10px;
            color: #999;
          }
        }
      }
      .center-right {
        .center-right-item {
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-end;
          .one {
            display: inline-block;
            width: 70px;
            text-align: right;
            color: #999;
          }
          .two {
            display: inline-block;
            width: 100px;
            text-align: right;
          }
          .three {
            color: #ce2829;
            font-weight: bold;
          }
        }
        .center-right-line {
          width: 255px;
          border-bottom: 1px dotted #cccdcd;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        .right-btn {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          button {
            border: 0;
            outline: none;
            width: 120px;
            height: 40px;
            cursor: pointer;
          }
          .cancel {
            background-color: #fff;
            border: 1px solid #111;
          }
          .sure {
            background-color: #111;
            color: #fff;
          }
        }
      }
    }
    .bottomed {
      font-size: 12px;
      color: #111;
      .bottom-title {
        height: 40px;
        background-color: #f3f3f3;
        display: flex;
        // justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          text-align: center;
        }
        .one {
          width: 29%;
        }
        .two {
          width: 17%;
        }
        .three {
          width: 17%;
        }
        .four {
          width: 17%;
        }
        .five {
          width: 20%;
        }
      }
      .bottom-container {
        background-color: #fff !important;
        .bottom-container-item {
          height: 150px;
          display: flex;
          align-items: center;
          border-bottom: 1px dotted #cccdcd;
          .one {
            display: flex;
            align-items: center;
            width: 29%;
            img {
              width: 100px;
              height: 100px;
              border: 1px solid #cccdcd;
              margin-left: 20px;
            }
            .one-right {
              margin-left: 15px;
              .one-item {
                font-size: 14px;
                // font-weight: bold;
              }
              .two-item {
                color: #666;
                display: block;
                margin-top: 10px;
              }
            }
          }
          .two {
            width: 17%;
            display: flex;
            justify-content: center;
            color: #666;
          }
          .three {
            width: 20%;
            display: flex;
            justify-content: center;
            color: #666;
          }
        }
        .bottom-container-item:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 999999;
}


.scanPay {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9999;
  font-size: 20px;
  #qrcode {
    margin-bottom: 30px;
  }
  span {
    margin-top: 10px;
  }
  .isPay {
    margin-bottom: 20px;
    cursor: pointer;
    color: red;
  }
  .closeIMg{
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}

.moneyType-item{
  display: flex;
  margin-top: 10px;
  align-items: center;
}


.to-right{
  margin-left: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
</style>