<template>
  <div class="auction-detail">
    <div class="auction-detail-top container">
      <div class="row">
        <div class="auction-detail-top-left col-lg-5 col-sm-12">
          <div class="swiper-item">
            <img class="img-fluid swiper-top-img" :src="obj.image" alt="" />
            <img
              class="swiper-img-status"
              src="../../assets/img/activity-pre.png"
              alt=""
              v-if="obj.state == 0"
            />
            <img
              class="swiper-img-status"
              src="../../assets/img/activity-loading.png"
              alt=""
              v-if="obj.state == 1"
            />
            <img
              class="swiper-img-status"
              src="../../assets/img/activity-end.png"
              alt=""
              v-if="obj.state == 2"
            />
          </div>
        </div>

        <div class="auction-detail-top-left col-lg-7">
          <span class="one">{{ obj.name }}</span>
          <div class="two">
            <button
              v-for="(item, index) in obj.activityLabelVOList"
              :key="index"
            >
              {{ item.name }}
            </button>
          </div>
          <span class="seven d-lg-none">报名费：￥{{ obj.signUpMoney }}</span>
          <div class="three">
            <span class="six"
              >活动时间：{{ obj.activityStartTime }}至{{
                obj.activityEndTime
              }}</span
            >
            <span class="six"
              >报名时间：{{ obj.signUpStartTime }}至{{
                obj.signUpEndTime
              }}</span
            >
            <span class="six">活动地点：{{ obj.address }}</span>
            <span class="six"
              >活动名额：{{ obj.signUpPeople }} / {{ obj.quota }}</span
            >
            <span class="six">主办方：{{ obj.sponsor }}</span>
          </div>
          <span class="seven d-sm-none d-lg-block"
            >报名费：{{
              obj.signUpMoney == 0 ? "免费" : "￥" + obj.signUpMoney
            }}</span
          >
          <div class="auction-btn">
            <div class="btn-one" v-if="obj.signUp == 1">已报名</div>
            <div
              v-else
              :class="{ 'btn-one': true, 'btn-three': obj.signUpState == 0 }"
              @click="toSignUp"
            >
              {{
                obj.signUpState == 0
                  ? "立即报名"
                  : obj.signUpState == 1
                  ? "报名截止"
                  : obj.signUpState == 2
                  ? "活动结束"
                  : "立即报名"
              }}
            </div>
            <div class="btn-two" v-if="obj.attention == 0" @click="toAttention">
              <span style="font-size: 28px" class="add">+</span>
              <span>关注</span>
            </div>
            <div class="btn-two" v-if="obj.attention == 1" @click="toAttention">
              <span>已关注</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="myForm"></div>

    <!-- 最近报名 -->
    <div
      class="center-swiper container"
      v-if="obj.state != 2 && obj.signUpUserVOList"
    >
      <span class="center-name col-sm-12">最近报名</span>
      <div class="menber-total">
        <div
          class="member-item"
          v-for="(item, index) in obj.signUpUserVOList"
          :key="index"
        >
          <img :src="item.avatar" alt="" />
          <div class="member-item-intro">
            <span class="one">{{ item.nickname }}</span>
            <span class="two">{{ item.time }} 报名</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 最近关注 -->
    <div
      class="center-swiper container"
      v-if="obj.state != 2 && obj.attentionUserVOList"
    >
      <span class="center-name col-sm-12">最近关注</span>
      <div class="menber-total">
        <div
          class="member-item"
          v-for="(item, index) in obj.attentionUserVOList"
          :key="index"
        >
          <img :src="item.avatar" alt="" />
          <div class="member-item-intro">
            <span class="one">{{ item.nickname }}</span>
            <span class="two">{{ item.time }} 关注</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 活动结束 -->
    <div class="container" v-if="obj.state == 2">
      <div class="member-activity-end">
        <img src="../../assets/img/face.png" alt="" />
        <span>您查看的活动已结束~</span>
      </div>
      <div class="line"></div>
    </div>

    <!-- 活动总结 -->
    <div class="container" v-if="obj.state == 2 && obj.summarize">
      <div class="member-summary col-sm-12">
        <div class="member-summary-top">
          <span class="one">活动总结</span>
          <span class="two">上传于{{ obj.modifiedTime }}</span>
        </div>

        <div class="member-summary-center">
          <span>{{ obj.summarize }}</span>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <!-- 活动视频 -->
    <div class="container" v-if="obj.state == 2">
      <div class="member-summary col-sm-12">
        <div class="member-summary-top" v-if="obj.videoVOList">
          <span class="one">活动视频</span>
          <span class="two">上传于{{ obj.videoVOList[0].createTime }}</span>
        </div>

        <div class="member-summary-center" v-if="obj.videoVOList">
          <div class="member-summary-center-img">
            <div class="swiper-container" id="videoSwiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide big-img-domian"
                  v-for="(item, index) in obj.videoVOList"
                  :key="index"
                >
                  <video :id="index" class="myVideo" :src="item.url" alt="" />
                  <div class="circle">
                    <img
                      src="../../assets/img/ic_close.png"
                      alt=""
                      v-if="item.flag"
                      @click="toCloseVideo(index)"
                    />
                    <img
                      src="../../assets/img/ic_open.png"
                      alt=""
                      v-else
                      @click="toOpenVedio(index)"
                    />
                  </div>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <!-- <span class="title" style="z-index: 999">活动标题名称</span> -->
          </div>
          <div class="line line-item"></div>
        </div>

        <div
          class="member-summary-top member-summary-top-top"
          v-if="obj.imagesVOList"
        >
          <span class="one">活动瞬间</span>
          <span class="two">上传于{{ obj.imagesVOList[0].createTime }}</span>
        </div>

        <div class="bottom-swiper" v-if="obj.imagesVOList">
          <div class="swiper-container" id="swiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide col-lg-2 col-sm-4 no-padding"
                v-for="(item, index) in obj.imagesVOList"
                :key="index"
              >
                <img
                  class="img-fluid swiper-slide-img"
                  :src="item.url"
                  alt=""
                />
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
      <div class="line" v-if="obj.imagesVOList"></div>
    </div>

    <!-- 活动内容 -->
    <div class="center-swiper container member-detail-bottom">
      <span class="center-name col-sm-12">活动内容</span>
      <div class="shopping-intro col-sm-12" v-html="obj.content"></div>
    </div>

    <!-- Dialog -->
    <el-dialog title="报名表单" :visible.sync="centerDialogVisible" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="姓名">
          <el-input
            v-model.trim="form.name"
            placeholder="输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入"
            v-model.trim="form.message"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="报名费" v-if="obj.signUpMoney > 0">
          <el-input disabled :value="'￥' + obj.signUpMoney"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" v-if="obj.signUpMoney > 0">
          <div class="pay-way">
            <div class="pay-way-item">
              <img src="../../assets/img/circle_y.png" v-if="payAli" />
              <img
                src="../../assets/img/circle_n.png"
                v-else
                @click="
                  payAli = true;
                  payWechart = false;
                "
              />
              <span>支付宝</span>
            </div>
            <div class="pay-way-item">
              <img src="../../assets/img/circle_y.png" v-if="payWechart" />
              <img
                src="../../assets/img/circle_n.png"
                v-else
                @click="
                  payAli = false;
                  payWechart = true;
                "
              />
              <span>微信</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <!-- 底部 -->

      <span slot="footer" class="dialog-footer">
        <button class="sure" @click="makeSure">确 定</button>
        <button class="cancel" @click="centerDialogVisible = false">
          取 消
        </button>
      </span>
    </el-dialog>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
    <div class="scanPay" v-show="isshowScan">
      <img class="closeIMg" src="../../assets/img/ic_menu_close.png" alt="" @click="isshowScan = false">
      <span>扫码付款</span>
      <div id="qrcode" ref="qrcode"></div>
      <div class="isPay" @click="seeStatus">如果已付款，请点击</div>
    </div>
  </div>
</template>


<script>
import {
  getActivityOne,
  getActiveAttention,
  getActiveSignUp,
  getIsLogin,
  wxResult
} from "../../assets/api/api.js";
import QRCode from "qrcodejs2";
export default {
  created() {
    let id = this.$route.query.id;
    this.getDetailList(id);
  },
  data() {
    return {
      obj: {},
      flag: 1,
      centerDialogVisible: false,
      form: {
        name: "",
        sex: "",
        tel: "",
        email: "",
        message: "",
      },
      options: [
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      message: "",
      payAli: true,
      payWechart: false,
      isshowScan: false,
      orderNumber:''
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getDetailList(id) {
      let res = await getActivityOne({ userId: 1, activityId: id });
      if (res.data.msg == "token非法") {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      } else {
        res = res.data.data;
        this.obj = res;
        console.log(res);
        let that = this;
        this.$nextTick(function () {
          that.getSwiper1();
        });
      }
    },
    getSwiper1() {
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: "auto",
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    toOpenVedio(index) {
      this.$("#" + index).trigger("play");
      this.obj.videoVOList[index].flag = true;
    },
    toCloseVideo(index) {
      this.$("#" + index).trigger("pause");
      this.obj.videoVOList[index].flag = false;
    },
    // 关注
    async toAttention() {
      let res = await getActiveAttention({
        activityId: this.obj.id,
      });
      if (res.data.result == 0) {
        this.getDetailList(this.obj.id);
        this.message = res.data.msg;
        this.showInfo();
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    // 报名
    toSignUp() {
      if (this.obj.signUpState == 0) {
        getIsLogin().then((res) => {
          if (res.data.result == 0) {
            this.centerDialogVisible = true;
          } else {
            sessionStorage.setItem("address", this.$route.fullPath);
            this.$router.push("/login");
          }
        });
      }
    },
    // 活动报名
    async makeSure() {
      let isType = 0;
      let height = document.documentElement.clientWidth || window.innerWidth;

      if (height > 992) {
        isType = 0;
      } else {
        isType = 1;
      }
      if (!this.form.name) {
        this.message = "请输入姓名";
        this.showInfo();
      } else if (!this.form.sex) {
        this.message = "请选择性别";
        this.showInfo();
      } else {
        let res = await getActiveSignUp({
          activityId: this.obj.id,
          remark: this.form.message,
          sex: this.form.sex == 0 ? 1 : 0,
          userId: 1,
          username: this.form.name,
          type: this.payAli ? 0 : 1,
          isType: isType,
        });
        console.log(res);
        this.orderNumber = res.data.order_number
        if (this.payAli) {
          if (res.status == 200) {
            if (this.obj.signUpMoney > 0) {
              if (res.status == 200) {
                this.$("#myForm").html(res.data);
              } else {
                this.message = "付款失败";
                this.showInfo();
              }
            } else {
              if (res.data.result == 0) {
                this.message = "报名成功";
                this.showInfo();
                this.centerDialogVisible = false;
              } else {
                this.message = "报名失败";
                this.showInfo();
              }
            }
          } else {
            sessionStorage.setItem("address", this.$route.fullPath);
            this.$router.push("/login");
          }
        } else {
          if (isType == 0) {
            this.isshowScan = true;
            this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
            let qrcode = new QRCode("qrcode", {
              width: 200, // 设置宽度，单位像素
              height: 200, // 设置高度，单位像素
              text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
            });
          }else{
            location.href = res.data.h5_url;
          }
        }
      }
    },
     seeStatus(){
      wxResult({
        orderId:this.orderNumber
      }).then((res)=>{
        console.log(res);
        if(res.data.result == 0){
          this.message = res.data.msg;
          this.showInfo();
          this.$router.push('/my-order')
        }else{
          this.message = res.data.msg;
          this.showInfo();
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.auction-detail-top {
  margin-top: 40px;
  padding-bottom: 60px !important;
  border-bottom: 1px dotted #cccccc;
  .swiper-item {
    position: relative;
    display: flex;
    justify-content: center;
    .swiper-top-img {
      width: 90%;
      height: 400px;
      box-shadow: 14px 14px 24px 0px rgba(17, 17, 17, 0.5);
    }
    .swiper-img-status {
      position: absolute;
      width: 100px;
      height: 50px;
      top: 24px;
      left: 12px;
    }
  }
  .auction-detail-top-left {
    color: #111;
    font-size: 14px;
    .one {
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-top: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .two {
      margin-top: 15px;
      button {
        outline: none;
        border: 1px solid #bba492;
        background-color: transparent;
        margin-right: 10px;
        color: #bba492;
      }
    }
    .three {
      padding: 2px 14px 20px;
      background-color: #f3f3f3;
      margin-top: 15px;
      .six {
        display: block;
        margin-top: 10px;
      }
    }
    .seven {
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-top: 20px;
    }
    .auction-btn {
      margin-top: 20px;
      display: flex;
      .btn-one {
        width: 200px;
        height: 50px;
        background-color: #cccccc;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        margin-right: 10px;
      }
      .btn-two {
        width: 200px;
        height: 50px;
        background: rgba(17, 17, 17, 0.04);
        border: 1px solid #111111;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        .add {
          margin-right: 5px;
          display: inline-block;
          margin-top: -3px;
        }
      }
      .btn-three {
        background-color: #111;
      }
    }
  }
}

.center-swiper {
  margin-top: 60px;
  .center-name {
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
}

/deep/.shopping-intro {
  margin-top: 50px;
  font-size: 16px;
  .span-intro {
    font-size: 14px;
    color: #111;
    display: block;
  }
  img {
    width: 100%;
    margin-top: 50px;
  }
}

.member-detail-bottom {
  margin-bottom: 100px;
}

.menber-total {
  display: flex;
  box-sizing: border-box;
  margin-top: 50px;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 60px;
  .member-item {
    width: 20%;
    height: 90px;
    display: flex;
    align-items: center;
    img {
      width: 40%;
      height: 100%;
    }
    .member-item-intro {
      width: 52%;
      background-color: #f5f5f5;
      height: 80%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .one {
        display: block;
        font-size: 18px;
        color: #111;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .two {
        font-size: 14px;
        color: #999;
      }
    }
  }
}

.member-activity-end {
  height: 176px;
  background-color: #f8f8f8;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 20px;
    color: #999;
    font-weight: 500;
    margin-left: 24px;
  }
  img {
    width: 40px;
    height: 40px;
  }
}

.member-summary {
  margin-top: 60px;
  .member-summary-top {
    display: flex;
    align-items: center;
    .one {
      font-size: 36px;
      font-weight: 500;
      color: #111;
    }
    .two {
      font-size: 14px;
      color: #999;
      margin-left: 14px;
    }
  }
  .member-summary-center {
    margin-top: 50px;
    font-size: 14px;
    color: #111;
    .member-summary-center-img {
      position: relative;
      .myVideo {
        width: 100%;
      }
      .big-img-domian {
        position: relative;
        .circle {
          position: absolute;
          width: 80px;
          height: 80px;
          background: rgba(187, 164, 146, 0.6);
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s ease;
          opacity: 0;
          img {
            width: 50px;
          }
        }
      }
      .big-img-domian:hover .circle {
        opacity: 1;
      }
      .title {
        position: absolute;
        left: -20px;
        bottom: 40px;
        font-size: 24px;
        color: #fff;
        font-weight: 500;
        padding: 30px 100px 30px 40px;
        background: rgba(187, 164, 146, 0.6);
      }
    }
  }
}

.bottom-swiper {
  margin-top: 50px;
}

.line {
  border-bottom: 1px dotted #ccc;
  margin-top: 60px;
}

.swiper-button-prev {
  left: 15px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
}
.swiper-button-next {
  right: 15px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
}

///////////////////////////////////
.dialog-footer {
  font-size: 16px;
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 30px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

/deep/.el-dialog {
  width: 610px;
}

/deep/ .el-select {
  width: 100%;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding {
    padding: 5px;
  }
  .swiper-top-img {
    width: 100% !important;
  }
  .swiper-img-status {
    left: -7px !important;
  }
  .auction-detail-top-left {
    .one {
      margin-top: 30px !important;
    }
  }
  .title {
    width: 90% !important;
    bottom: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(50%);
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .member-summary {
    margin-top: 30px;
  }
  .member-summary-center {
    margin-top: 30px !important;
  }
  .member-summary-top-top {
    margin-top: 30px;
  }
  .member-summary-top {
    .one {
      font-size: 18px !important;
    }
  }
  #swiper2 .swiper-button-prev {
    left: 6px;
  }
  #swiper2 .swiper-button-next {
    right: 6px;
  }
  .line-item {
    margin-top: 70px !important;
  }
  .center-name {
    font-size: 18px !important;
  }
  .menber-total {
    overflow-x: auto;
    display: block;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0 15px 60px;
    .member-item {
      width: 160px;
      display: inline-block;

      .member-item-intro {
        display: inline-block;
      }
    }
  }
}

.pay-way {
  display: flex;
  .pay-way-item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    img {
      margin-right: 8px;
    }
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 999999;
}

.scanPay {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9999;
  font-size: 20px;
  #qrcode {
    margin-bottom: 30px;
  }
  span {
    margin-top: 10px;
  }
  .isPay {
    margin-bottom: 20px;
    cursor: pointer;
    color: red;
  }
  .closeIMg{
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}

@media (min-width: 300px) and (max-width: 992px){
  .auction-detail{
    margin-top: 60px !important;
    padding-bottom: 20px;
  }

  .auction-detail-top .swiper-item .swiper-top-img{
    height: 300px;
  }

  .auction-detail-top{
    padding-bottom: 20px !important;
  }

  .auction-detail-top-left{
    .one{
      font-size: 16px !important;
    }
    .two{
      margin-top: 10px !important;
    }
    .seven{
      margin-top: 10px !important;
      font-size: 16px !important;
    }

    .six{
      font-size: 12px !important;
    }
  }

  .member-activity-end{
    height: 130px !important;
    margin-top: 30px;
  }

  .line{
    margin-top:30px;
  }

  .member-detail-bottom{
    margin-top: 20px;
    margin-bottom: 0;
  }

  .shopping-intro{
    margin-top: 20px;
  }
}
</style>