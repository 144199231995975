<template>
  <div :class="{ 'col-md-10': true, 'info-container': true, none: isShow }">
    <div class="row">
      <!-- 基础信息 -->
      <div class="info-container-one">
        <!-- 头部 -->
        <div class="info-container-one-top">
          <div class="left">
            <img
              class="mine-img"
              :src="infoList.avatar"
              alt=""
            />
            <div class="hidden-img" @click="getAvatar">
              <span>修改头像</span>
            </div>
          </div>
          <div class="right">
            <span class="right-title">{{ infoList.name }}</span>
            <div class="right-item">
              <span>NO.{{ infoList.userId }}</span>
              <span>注册时间 {{ infoList.createTime }}</span>
            </div>
          </div>
        </div>
        <input type="file" @change="changeAvater" v-show="false" id="file" />
        <!-- 主题部分 -->
        <!-- 个人信息 -->
        <div class="info-container-one-bottom">
          <div class="info-container-one-bottom-nav">
            <div
              :class="{
                one: true,
                'one-active': chooseRightInfoNav == 0,
              }"
              @click="toChooseRightInfoNav(0)"
            >
              <span>注册信息</span>
            </div>
            <div
              :class="{
                one: true,
                'one-active': chooseRightInfoNav == 1,
              }"
              @click="toChooseRightInfoNav(1)"
            >
              <span>我的地址</span>
              <img
                src="../../assets/img/add-black.png"
                alt=""
                @click="centerDialogVisible5 = true"
                v-if="chooseRightInfoNav == 1"
              />
              <img src="../../assets/img/add_n.png" alt="" v-else />
            </div>
            <div
              :class="{
                one: true,
                'one-active': chooseRightInfoNav == 2,
              }"
              @click="toChooseRightInfoNav(2)"
            >
              <span>开票信息</span>
              <img
                src="../../assets/img/edit_y.png"
                alt=""
                v-if="chooseRightInfoNav == 2"
                @click="edit"
              />
              <img src="../../assets/img/edit.png" alt="" v-else />
            </div>
          </div>
          <!-- 注册信息 -->
          <div
            class="info-container-one-bottom-item-one"
            v-if="chooseRightInfoNav == 0"
          >
            <!-- 姓名 -->
            <div class="item">
              <div class="left">
                <label class="left-label">用户昵称</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="infoList.name"
                  disabled
                />
              </div>
              <div class="right">
                <span class="pointer" @click="dialogVisible = true">修改</span>
              </div>
            </div>
            <!-- 邮箱 -->
            <div class="item">
              <div class="left">
                <label class="left-label">绑定邮箱</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="infoList.email"
                  disabled
                />
              </div>
              <div class="right">
                <span class="pointer" @click="dialogVisible1 = true">修改</span>
              </div>
            </div>
            <!-- 手机 -->
            <div class="item">
              <div class="left">
                <label class="left-label">绑定手机</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="infoList.tel"
                  disabled
                />
              </div>
              <div class="right">
                <span class="pointer" @click="dialogVisible2 = true"
                  >更换手机号</span
                >
              </div>
            </div>
            <!-- 密码 -->
            <div class="item">
              <div class="left">
                <label class="left-label">密码</label>
                <input
                  class="left-input"
                  type="password"
                  v-model="infoList.password"
                  disabled
                />
              </div>
              <div class="right">
                <span class="pointer" @click="dialogVisible3 = true">重置</span>
              </div>
            </div>
            <!-- 微信 -->
            <div class="item">
              <div class="left">
                <label class="left-label">微信</label>
                <!-- <input
                  v-if="infoList.wechart !='未绑定'"
                  class="left-input"
                  type="password"
                  v-model="infoList.wechart"
                  disabled
                /> -->
                <div v-if="infoList.wechart != '未绑定'" class="already-bind">
                  <img :src="baseInfoObj.avatar" alt="" />
                  <span>{{ baseInfoObj.nickname }}</span>
                </div>
                <input v-else disabled type="text" v-model="infoList.wechart" />
              </div>
              <div class="right">
                <span
                  class="pointer"
                  v-if="infoList.wechart != '未绑定'"
                  @click="torevolveBind"
                  >解绑</span
                >
                <span class="pointer" v-else @click="wxBind">绑定</span>
              </div>
            </div>
          </div>
          <!-- 我的地址 -->
          <div
            class="info-container-one-bottom-item-one"
            v-if="chooseRightInfoNav == 1"
          >
            <div
              class="item"
              v-for="(item, index) in addressedList"
              :key="index"
            >
              <div class="left">
                <div class="left-address">
                  <span>{{ item.username }}</span>
                  <span class="line"> | </span>
                  <span>{{ item.phone }}</span>
                  <span v-if="item.isDefault == 1" class="default">默认</span>
                </div>
                <div class="left-bottom">
                  <span>{{
                    item.provinceName +
                    item.cityName +
                    item.districtName +
                    item.addressDetailed
                  }}</span>
                </div>
              </div>
              <div class="right">
                <img
                  style="margin-right: 20px"
                  src="../../assets/img/ic_edi.png"
                  alt=""
                  @click="editOneAddress(item)"
                />
                <img
                  src="../../assets/img/ic_del.png"
                  alt=""
                  @click="removeAddress(item.id)"
                />
              </div>
            </div>
            <div class="null-info" v-if="addressedList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
          </div>
          <!-- 开票信息 -->
          <div
            class="info-container-one-bottom-item-one"
            id="edit"
            v-if="chooseRightInfoNav == 2"
          >
            <!-- 发票抬头 -->
            <div class="item">
              <div class="left">
                <label class="left-label"
                  >发票抬头<span v-if="editFlag" class="required-hidden"
                    >*</span
                  ></label
                >
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.invoice"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <!-- 税号 -->
            <div class="item">
              <div class="left">
                <label class="left-label"
                  >税号<span v-if="editFlag" class="required-hidden"
                    >*</span
                  ></label
                >
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.tax"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <!-- 开户银行 -->
            <div class="item">
              <div class="left">
                <label class="left-label">开户银行</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.bank"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <!-- 银行账户 -->
            <div class="item">
              <div class="left">
                <label class="left-label">银行账户</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.bankAccount"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <!-- 企业地址 -->
            <div class="item">
              <div class="left">
                <label class="left-label">企业地址</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.companyAddress"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <!-- 企业电话 -->
            <div class="item">
              <div class="left">
                <label class="left-label">企业电话</label>
                <input
                  class="left-input"
                  type="text"
                  v-model="invoiceInfo.companyTel"
                  placeholder="暂未填写"
                  disabled
                />
              </div>
            </div>
            <div class="see-more" v-if="editFlag" @click="saveInvoice">
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改昵称 -->
    <div class="item-one">
      <el-dialog title="修改用户昵称" :visible.sync="dialogVisible">
        <span class="item-one-span">用户昵称</span>
        <div class="item-one-item">
          <input type="text" :value="infoList.name" @input="getNewName" />
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureDialogName">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 修改邮箱 -->
    <div class="item-one">
      <el-dialog title="修改邮箱" :visible.sync="dialogVisible1">
        <div class="item-one-item">
          <input
            type="password"
            placeholder="请输入登录密码"
            v-model="password"
          />
        </div>
        <div class="item-one-item">
          <input type="text" placeholder="请输入新邮箱" v-model="email1" />
        </div>
        <div class="item-one-item">
          <input type="text" placeholder="请再次输入新邮箱" v-model="email2" />
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="sureDialogEmail">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 手机号 -->
    <div class="item-one">
      <el-dialog title="更换手机号" :visible.sync="dialogVisible2">
        <span class="item-one-span">原手机号</span>
        <div class="item-one-item">
          <input type="text" :value="infoList.tel" disabled />
        </div>
        <span class="item-one-span">新手机号</span>
        <div class="item-one-item">
          <input
            type="text"
            v-model.trim="newsTel"
            placeholder="请输入新手机号"
          />
          <span
            class="getcheckCode pointer"
            style="color: #111"
            @click="getCheckCode"
            v-if="codeFlag"
            >获取验证码</span
          >
          <span
            class="getcheckCode pointer"
            style="color: #111"
            @click="getCheckCode"
            v-else
            >({{ codeTime }})</span
          >
        </div>

        <div class="item-one-item">
          <input
            type="text"
            v-model.trim="checkCode"
            placeholder="请输入验证码"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="sureDialogTel">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 重置密码 -->
    <div class="item-one">
      <el-dialog
        title="重置密码"
        :visible.sync="dialogVisible3"
        @close="dialog4Close"
      >
        <div class="item-one-item">
          <input
            type="password"
            placeholder="请输入登录密码"
            v-model="password1"
          />
        </div>
        <div class="item-one-item">
          <input
            type="password"
            placeholder="请输入新密码"
            v-model="password2"
          />
        </div>
        <div class="item-one-item">
          <input
            type="password"
            placeholder="请再次输入新密码"
            v-model="password3"
          />
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="sureDialogPassword"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- 绑定微信 -->
    <div class="item-one">
      <el-dialog :visible.sync="dialogVisible4" center style="height: 700px">
        <div class="domian-dialog">
          <div id="login_container">
            <!-- <img src="../../assets/img/erweima.png" alt="" /> -->
          </div>
          <!-- <span class="one">请使用微信扫描二维码绑定</span>
          <span class="two">巨永家居</span> -->
        </div>
      </el-dialog>
    </div>
    <!-- 增加地址 -->
    <div class="no-address-dialog">
      <el-dialog
        title="新建地址"
        :visible.sync="centerDialogVisible5"
        center
        @close="closeDialog"
      >
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <el-form
          ref="form"
          :model="form2"
          label-width="80px"
          label-position="left"
        >
          <el-form-item class="locationItem" label="所在地区">
            <div class="select-area">
              <select
                :class="{ 'select-color': provinceIndex == -1 }"
                v-model="provinceIndex"
                @click="getProvince"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :value="item.provinceValue"
                >
                  {{ item.provinceName }}
                </option>
              </select>
              <select
                :class="{ 'select-color': cityIndex == -1 }"
                v-model="cityIndex"
                @click="getCity"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :value="item.cityValue"
                >
                  {{ item.cityName }}
                </option>
              </select>
              <select
                :class="{ 'select-color': regionIndex == -1 }"
                v-model="regionIndex"
                @click="getRegion"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in regionList"
                  :key="index"
                  :value="item.districtValue"
                >
                  {{ item.districtName }}
                </option>
              </select>
            </div>
          </el-form-item>

          <el-form-item>
            <textarea
              class="my-textarea"
              placeholder="详细地址，街道、门牌号"
              v-model="textareaText"
            ></textarea>
          </el-form-item>

          <el-form-item label="收货人">
            <el-input v-model="form2.name" placeholder="输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="form2.tel" placeholder="输入手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="isDefault">
              <img
                src="../../assets/img/icon_sel_s.png"
                alt=""
                v-if="flag"
                @click="flag = false"
              />
              <img
                src="../../assets/img/icon_sel_n.png"
                alt=""
                v-else
                @click="flag = true"
              />
              <span>设为默认地址</span>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="provideAddress">提 交</button>
          <button class="cancel" @click="centerDialogVisible5 = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getOrderAddress,
  getAddress,
  addAddress,
  editAddressApi,
  removeAddressApi,
  editInvoice,
  getInvoiceApi,
  getBasicInfoApi,
  editNameApi,
  editMail,
  getCode,
  changePhone,
  changePassword,
  removeBindApi,
  bindWX2,
  bindWX3,
  fileUpload,
  changeAvatarInfo,
} from "../../assets/api/api.js";
import { resultEmail, resultPhone } from "../../uitils/check.js";
export default {
  created() {
    if (this.$route.query.type == 1) {
      this.$confirm("是否改用微信名称和头像", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bindWX3().then((res) => {
            if (res.data.result == 0) {
              this.message = "已成功绑定";
              this.showInfo();
              this.$router.push("/basic-info");
              this.getBasicInfo();
            }
          });
        })
        .catch(() => {});
    }
    let res = document.documentElement.clientWidth || window.innerWidth;

    console.log(res);
    if (res <= 998) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
    let that = this;
    window.onresize = function () {
      let res1 = document.documentElement.clientWidth || window.innerWidth;
      console.log(res1);
      if (res1 <= 998) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    };
    this.getBasicInfo();
  },
  data() {
    return {
      addressedList: [],
      infoList: {
        name: "",
        tel: "",
        email: "",
        password: "",
        createTime: "",
        avatar: "",
        userId: -1,
        wechart: "未绑定",
      },
      provinceList: [],
      cityList: [],
      regionList: [],
      provinceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      chooseRightInfoNav: 0,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      centerDialogVisible5: false,
      editFlag: false,
      addressFlag: -1,
      // 是否默认地址
      flag: false,
      // 发票id
      invoiceId: "",
      // 是否发送验证码
      codeFlag: true,
      // 验证码有效时间
      codeTime: 180,
      form2: {
        name: "",
        tel: "",
      },
      // 发票
      invoiceInfo: {
        invoice: "",
        tax: "",
        bank: "",
        bankAccount: "",
        companyAddress: "",
        companyTel: "",
      },
      newName: "",
      password: "",
      newsTel: "",
      email1: "",
      email2: "",
      checkCode: "",
      password1: "",
      password2: "",
      password3: "",
      textareaText: "",
      message: "",
      isShow: false,
      baseInfoObj: {},
      typeFlag: 1,
    };
  },
  watch: {
    provinceIndex: function () {
      this.cityList = [];
      this.regionList = [];
      this.cityIndex = -1;
      this.regionIndex = -1;
    },
    cityIndex: function () {
      this.regionList = [];
      this.regionIndex = -1;
    },
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async toChooseRightInfoNav(index) {
      this.chooseRightInfoNav = index;
      if (this.chooseRightInfoNav == 0) {
        this.getBasicInfo();
      }
      if (this.chooseRightInfoNav == 1) {
        this.getAddressMine();
      }
      if (this.chooseRightInfoNav == 2) {
        this.getInvoice();
      }
    },
    // 获得新的名字
    getNewName(e) {
      this.newName = e.currentTarget.value;
    },
    // 绑定昵称
    async sureDialogName() {
      if (this.newName != "") {
        let res = await editNameApi({
          id: 1,
          nickname: this.newName,
        });
        console.log(res);
        this.getBasicInfo();
        this.dialogVisible = false;
      } else {
        this.message = "昵称不能为空";
        this.showInfo();
      }
    },
    // 修改邮箱
    async sureDialogEmail() {
      if (this.password == "") {
        this.message = "请输入登入密码";
        this.showInfo();
      } else if (this.email1 == "" || this.email2 == "") {
        this.message = "请输入新邮箱";
        this.showInfo();
      } else if (
        (resultEmail(this.email1) && resultEmail(this.email2)) == false
      ) {
        this.message = "邮箱格式错误";
        this.showInfo();
      } else if (this.email1 != this.email2) {
        this.message = "两次输入不一致";
        this.showInfo();
      } else {
        let res = await editMail({
          id: 1,
          password: this.password,
          newMail: this.email1,
          twoMail: this.email2,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = res.msg;
          this.showInfo();
        } else {
          this.message = "邮箱修改成功";
          this.showInfo();
          this.dialogVisible1 = false;
          this.getBasicInfo();
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
        console.log(res);
      }
    },
    // 获得验证码
    async getCheckCode() {
      if (resultPhone(this.newsTel)) {
        this.codeFlag = false;
        let that = this;
        let tid = setInterval(() => {
          that.codeTime--;
          if (that.codeTime == 0) {
            that.codeFlag = true;
            that.codeTime = 180;
            clearInterval(tid);
          }
        }, 1000);
        let res = await getCode({
          phone: this.newsTel,
        });
        console.log(res);
      } else {
        this.message = "请输入正确的手机号";
        this.showInfo();
      }
    },
    // 更新电话
    async sureDialogTel() {
      if (resultPhone(this.newsTel) == false) {
        this.message = "请输入手机号";
        this.showInfo();
      } else if (this.checkCode == "") {
        this.message = "请输入验证码";
        this.showInfo();
      } else {
        let res = await changePhone({
          id: 1,
          phone: this.newsTel,
          code: this.checkCode,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = "设置失败";
          this.showInfo();
        }
        if (res.result == 0) {
          this.message = "设置成功";
          this.showInfo();
          this.dialogVisible2 = false;
          this.getBasicInfo();
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
        console.log(res);
      }
    },
    // 重置密码
    async sureDialogPassword() {
      if (this.password1 == "") {
        this.message = "请输入登入密码";
        this.showInfo();
      } else if (this.password2 == "" || this.password3 == "") {
        this.message = "请输入新密码";
        this.showInfo();
      } else if (this.password2 != this.password3) {
        this.message = "两次密码输入不一致";
        this.showInfo();
      } else {
        let res = await changePassword({
          id: 1,
          password: this.password1,
          newPassword: this.password2,
          twoPassword: this.password3,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = res.msg;
          this.showInfo();
        }
        if (res.result == 0) {
          this.message = res.msg;
          this.showInfo();
          this.dialogVisible3 = false;
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
      }
    },
    // 密码结束
    dialog4Close() {
      this.password1 = "";
      this.password2 = "";
      this.password3 = "";
    },
    edit() {
      this.editFlag = true;
      this.$("#edit input").removeAttr("disabled");
    },
    // 获得基本消息
    async getBasicInfo() {
      let res = await getBasicInfoApi({
        id: 1,
      });
      res = res.data.data;
      this.infoList.avatar = res.avatar;
      this.infoList.name = res.nickname;
      this.infoList.password = res.password;
      this.infoList.email = res.mail;
      this.infoList.tel = res.phone;
      this.infoList.createTime = res.createTime;
      this.infoList.userId = res.userId;
      this.infoList.wechart = res.openid ? res.openid : "未绑定";
      this.baseInfoObj = res;
      console.log(res);
    },
    // 获得地址
    async getAddressMine() {
      let res = await getOrderAddress({ userId: 1 });
      res = res.data.data;
      this.addressedList = res;
      console.log(res);
    },
    // 获得省
    async getProvince() {
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
    },
    // 获得市
    async getCity() {
      let res = await getAddress({
        provinceValue: this.provinceIndex,
      });
      res = res.data.data;
      this.cityList = res;
    },
    // 获得区
    async getRegion() {
      let res = await getAddress({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
      });
      res = res.data.data;
      this.regionList = res;
    },
    // 地址提交
    async provideAddress() {
      if (this.regionIndex == -1 || this.textareaText == "") {
        this.message = "请完整输入地址";
        this.showInfo();
      } else if (this.form2.name == "") {
        this.message = "请输入收货人名称";
        this.showInfo();
      } else if (this.form2.tel == "") {
        this.message = "请输入电话号码";
        this.showInfo();
      } else if (resultPhone(this.form2.tel) == false) {
        this.message = "电话号码错误";
        this.showInfo();
      } else {
        if (this.addressFlag != -1) {
          let res = await editAddressApi({
            addressDetailed: this.textareaText,
            areaValue: this.regionIndex,
            isDefault: this.flag ? 1 : 0,
            phone: this.form2.tel,
            userId: 1,
            id: this.addressFlag,
            username: this.form2.name,
          });
        } else {
          let res = await addAddress({
            addressDetailed: this.textareaText,
            areaValue: this.regionIndex,
            isDefault: this.flag ? 1 : 0,
            phone: this.form2.tel,
            userId: 1,
            username: this.form2.name,
          });
        }
        this.centerDialogVisible5 = false;
        this.getAddressMine();
      }
    },
    // 修改地址
    async editOneAddress(item) {
      this.textareaText = item.addressDetailed;
      this.form2.name = item.username;
      this.form2.tel = item.phone;
      this.flag = item.isDefault == 1 ? true : false;
      // 省
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
      this.provinceIndex = item.provinceValue;
      // 市
      let res1 = await getAddress({
        provinceValue: this.provinceIndex,
      });
      res1 = res1.data.data;
      this.cityList = res1;
      this.cityIndex = item.cityValue;
      // 区
      let res2 = await getAddress({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
      });
      res2 = res2.data.data;
      this.regionList = res2;
      this.regionIndex = item.districtValue;
      this.addressFlag = item.id;
      this.centerDialogVisible5 = true;
    },
    // 删除地址
    async removeAddress(id) {
      let res = await removeAddressApi({
        id: id,
      });
      console.log(res);
      if (res.data.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      } else {
        this.message = "删除失败";
        this.showInfo();
      }
      this.getAddressMine();
    },
    // 地址关闭回调
    closeDialog() {
      this.addressFlag = -1;
      this.provinceIndex = -1;
      this.textareaText = "";
      this.form2.name = "";
      this.form2.tel = "";
    },
    // 获取发票信息
    async getInvoice() {
      let res = await getInvoiceApi({
        userId: 1,
      });
      res = res.data.data;
      this.invoiceInfo.invoice = res.invoiceHeader;
      this.invoiceInfo.tax = res.taxNumber;
      this.invoiceInfo.bank = res.bankName;
      this.invoiceInfo.bankAccount = res.bankAccount;
      this.invoiceInfo.companyAddress = res.companyAddress;
      this.invoiceInfo.companyTel = res.companyPhone;
      this.invoiceId = res.id;
      console.log(res);
    },
    // 修改发票信息
    async saveInvoice() {
      let res = await editInvoice({
        invoiceHeader: this.invoiceInfo.invoice,
        taxNumber: this.invoiceInfo.tax,
        bankName: this.invoiceInfo.bank,
        bankAccount: this.invoiceInfo.bankAccount,
        companyAddress: this.invoiceInfo.companyAddress,
        companyPhone: this.invoiceInfo.companyTel,
        userId: 1,
        id: this.invoiceId,
      });
      console.log(res);
      if (res.data.result == 0) {
        this.message = "保存成功";
        this.showInfo();
        this.editFlag = false;
        this.$("#edit input").attr("disabled", true);
      } else {
        this.message = "保存失败";
        this.showInfo();
      }
    },
    wxBind() {
      if (this.infoList.wechart == "未绑定") {
        this.dialogVisible4 = true;
        let that = this;
        bindWX2().then((res) => {
          if (res.data.result == 0) {
            console.log(res);
            let info = res.data.data;
            //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
            var obj = new WxLogin({
              self_redirect: false, //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
              id: "login_container", //需要承载二维码的容器id
              appid: info.appid,
              scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
              redirect_uri: "http://www.antiker.cn/mWx", //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
              state: info.state,
              style: "black",
              href: "",
            });
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    torevolveBind() {
      removeBindApi().then((res) => {
        console.log(res);
        if (res.data.result == 0) {
          this.message = "解绑成功";
          this.showInfo();
          this.getBasicInfo();
        } else {
          this.message = "解绑失败";
          this.showInfo();
        }
      });
    },
    changeAvater(e) {
      console.log(e);
      console.log(e.target.files[0]);
      let formData = new window.FormData();
      formData.append("photo", e.target.files[0]);
      fileUpload(formData).then((res) => {
        if (res.data.result == 0) {
          let url = res.data.data;
          changeAvatarInfo({ avatar: url }).then((res) => {
            if (res.data.result == 0) {
              this.getBasicInfo();
              this.message = "上传成功";
              this.showInfo();
            } else {
              this.message = "图片上传失败";
              this.showInfo();
            }
          });
        } else {
          this.message = "图片上传失败";
          this.showInfo();
        }
      });
    },
    getAvatar() {
      this.$("#file").click();
    },
  },
  mounted() {
    let that = this;
    window.onresize = function () {
      let res1 = document.documentElement.clientWidth || window.innerWidth;
      if (res1 <= 998) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    };
  },
};
</script>

<style lang="less" scoped>
.info-container-one {
  width: 100%;
  .info-container-one-top {
    background-color: #fff;
    padding: 40px;
    display: flex;
    justify-content: flex-start;
    .left {
      position: relative;
      .mine-img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
      }
      .hidden-img{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0;
        width: 0;
        border-radius: 50%;
        background: rgba(111, 111, 111, 0.7) !important;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .left:hover .hidden-img{
      width: 100px;
      height: 100px;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;
      .right-title {
        font-size: 26px;
        color: #0d0d0d;
      }
      .right-item {
        display: flex;
        flex-direction: column;
        span {
          font-size: 14px;
          color: #0d0d0d;
        }
      }
    }
  }
  .info-container-one-bottom {
    margin-top: 30px;
    padding: 0 40px;
    background-color: #fff;
    padding-bottom: 80px;
    .info-container-one-bottom-nav {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      cursor: pointer;
      .one {
        height: 46px;
        color: #999;
        font-weight: bold;
        border-bottom: 1px solid #cccccc;
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .one-active {
        border-bottom: 2px solid #111;
        color: #111;
      }
    }
    .info-container-one-bottom-item-one {
      font-size: 14px;
      color: #111;
      .left-address {
        display: flex;
        align-items: center;
        .line {
          display: inline-block;
          margin: 0 5px;
          margin-top: -3px;
        }
        .default {
          font-size: 12px;
          display: inline-block;
          background-color: #111;
          color: #fff;
          padding: 2px 5px 2px 7px;
          margin-left: 20px;
        }
      }
      .item {
        padding: 22px 0;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-label {
          color: #666;
          width: 100px;
        }
        .left-input {
          color: #666;
          width: 300px;
        }
        .right {
          span {
            border-bottom: 1px solid #111;
          }
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
        .left-bottom {
          margin-top: 15px;
          color: #666;
        }
      }
    }
    input {
      background-color: #fff;
      border: 0;
      outline: none;
    }
  }
}

.select-area {
  select {
    width: 110px;
    height: 30px;
    margin-right: 15px;
    outline: none;
    border: 0;
    border: 1px solid #cccdcd;
    padding-left: 5px;
  }
  select:nth-last-child(1) {
    margin-right: 0;
  }
  .select-color {
    color: #ccc;
  }
}

.isDefault {
  display: flex;
  align-items: center;
  margin-top: -25px;
  span {
    margin-left: 15px;
  }
}

.my-textarea {
  width: 100%;
  outline: none;
  padding-left: 5px;
  margin-top: -20px;
  border: 1px solid #ccc;
}
.my-textarea::placeholder {
  color: #ccc;
}

.pointer {
  cursor: pointer;
}

/deep/ .el-dialog {
  width: 490px !important;
}

/deep/ .el-button--primary {
  background-color: #111;
  color: #fff;
}

/deep/ .el-button--primary:hover {
  background-color: #111;
  color: #fff;
}

/deep/ .el-button--default {
  border: 1px solid #111;
  color: #111;
}

/deep/ .el-button--default:hover {
  border: 1px solid #111;
  color: #111;
  background-color: #fff;
}

/deep/ .el-button--primary:active {
  background-color: #111;
  color: #fff;
}

/deep/ .el-button--primary:focus {
  background-color: #111;
  color: #fff;
}

.item-one {
  .item-one-span {
    font-size: 12px;
    color: #666;
  }
  input {
    border: 0;
    outline: none;
    display: block;
    width: 100%;
    border-bottom: 2px solid #666;
    margin-top: 15px;
  }
  .item-one-item {
    margin-bottom: 50px;
    position: relative;
  }
  .item-one-item:nth-last-child(1) {
    margin-bottom: 0;
  }
  .danger {
    font-size: 12px;
    color: red;
  }
  .getcheckCode {
    position: absolute;
    right: 0;
    bottom: 3px;
  }
}

.domian-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  .one {
    display: block;
    margin-top: 20px;
  }
  .two {
    margin-top: 5px;
  }
  .domian-dialog-img {
    border: 1px solid #cccdcd;
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 240px;
      height: 240px;
    }
  }
}
.required-hidden {
  color: #ce2829;
}

.dialog-footer {
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 14px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}
.see-more {
  width: 200px;
  height: 50px;
  background-color: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.none {
  display: none;
}

.already-bind {
  display: inline-block;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>