<template>
  <div class="info container">
    <div class="top" v-if="infoObj.imagesUrlList">
      <div class="swiper-container" id="swiper1">
        <div class="swiper-wrapper">
          <div
            v-if="videoImage"
            class="swiper-slide"
          >
            <img class="img-fuild big-img-item" :src="videoImage" alt="" />
            <img src="http://file.antiker.cn/fba6388858bb4acb85ad07bd8a702f2d.png" alt="" class="video-icon" @click="openVideo">
            <video :src="video" controls class="video-open" v-show="videoShow" id="videos"></video>
          </div>
          <div
            class="swiper-slide"
            v-for="(item, index) in infoObj.imagesUrlList"
            :key="index"
          >
            <img class="img-fuild big-img-item" :src="item" alt="" />
          </div>
        </div>
      </div>
      <div class="swiper-num">
        <span>{{ swiperIndex }}/{{ infoObj.imagesUrlList.length + (videoImage?1:0) }}</span>
      </div>
    </div>
    <div class="center">
      <!-- 信息介绍 -->
      <div class="center-info">
        <span class="info-name">{{ infoObj.commodityName }}</span>
        <span class="info-introduction">{{ infoObj.introduction }}</span>
        <div class="price">
          <div class="price-now">
            <span class="one">￥</span>
            <span class="two">{{ nowPrice }}</span>
          </div>
          <!-- <div class="price-pre">
            <span>￥{{ prePrice }}</span>
          </div> -->
        </div>
      </div>
      <!-- 网点 -->
      <div class="center-item" @click="wangdianOpen(0)" v-show="wangdianName!=0">
        <span class="one">网点</span>
        <span class="two">{{ wangdianName }}</span>
        <img src="../../assets/img/right-gray.png" alt="" />
      </div>
      <div class="center-item" v-show="wangdianName==0">
        <span class="one">网点</span>
        <span class="two">暂无网点</span>
      </div>
      <!-- 规格 -->
      <div class="center-item" @click="drawer2 = true">
        <span class="one">规格</span>
        <span class="two">{{ sizeName }}</span>
        <img src="../../assets/img/right-gray.png" alt="" />
      </div>
      <!-- 介绍 -->
      <div class="center-introduction">
        <div class="shop-info">
          <div class="shop-info-top">
            <span>商品介绍</span>
          </div>
          <div
            class="IntroductionText"
            v-html="infoObj.commodityIntroduction"
          ></div>
          <div class="shop-info-top">
            <span>规格与包装</span>
          </div>
          <div
            class="IntroductionText"
            v-html="infoObj.specificationPackage"
          ></div>
          <div class="shop-info-top">
            <span>其他说明</span>
          </div>
          <div class="IntroductionText" v-html="infoObj.description"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="domain">
        <div class="bottom-left">
          <div class="domian-item" @click="toConsult">
            <img src="../../assets/img/consult.png" alt="" />
            <span>咨询</span>
          </div>
          <div
            class="domian-item"
            v-if="infoObj.isFavorites == 0"
            @click="addFarvourite"
          >
            <img src="../../assets/img/save.png" alt="" />
            <span>收藏</span>
          </div>
          <div
            class="domian-item"
            v-if="infoObj.isFavorites == 1"
            @click="removeFarvourite"
          >
            <img src="../../assets/img/save_black.png" alt="" />
            <span>已收藏</span>
          </div>
        </div>

        <div class="bottom-right">
          <div class="btn-item-left" @click="toSort">加入购物车</div>
          <div class="btn-item-right" @click="toOrder">立即下单</div>
        </div>
      </div>
    </div>
    <!-- 弹出框1 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="stricky">
          <div class="drawer-top">
            <span>配送至</span>
            <span @click="sureAddress">确定</span>
          </div>
          <div class="line"></div>
          <div class="drawer-center">
            <div class="drawer-center-item">
              <span
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 0,
                }"
                @click="provinceChoose"
                >{{ provinceName }}</span
              >
              <span
                v-if="provinceName != '省份'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 1,
                }"
                @click="cityChoose"
                >{{ cityName }}</span
              >
              <span
                v-if="cityName != '城市'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 2,
                }"
                @click="regionChoose"
                >{{ regionName }}</span
              >
              <span
                v-if="regionName != '区域'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 3,
                }"
                @click="wangdianChoose"
                >{{ wangdian }}</span
              >
            </div>
          </div>
        </div>

        <div class="drawer-bottom">
          <template v-if="drawerNavIndex == 0">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active':
                  item.provinceValue == provinceIndex,
              }"
              v-for="(item, index) in procinceList"
              :key="index"
              @click="provinceOne(item.provinceValue, item.provinceName)"
            >
              {{ item.provinceName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 1">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.cityValue == cityIndex,
              }"
              v-for="(item, index) in cityList"
              :key="index"
              @click="cityOne(item.cityValue, item.cityName)"
            >
              {{ item.cityName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 2">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.districtValue == regionIndex,
              }"
              v-for="(item, index) in regionList"
              :key="index"
              @click="regionOne(item.districtValue, item.districtName)"
            >
              {{ item.districtName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 3">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.areaValue == wangdianIndex,
              }"
              v-for="(item, index) in wangdianList"
              :key="index"
              @click="wangdianOne(item.areaValue, item.name, item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
    <!-- 弹出框2 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-size" v-if="infoObj.imagesUrlList">
        <div class="top">
          <div class="left">
            <img class="img-fuild" :src="sizeImg" alt="" />
          </div>
          <div class="right-special">
            <div class="right-top">
              <img src="../../assets/img/login_close.png" @click="drawer2 = false" alt="" />
            </div>
            <div class="right-bottom">
              <div class="price">
                <span>价格：￥{{ nowPrice }}</span>
              </div>
              <div class="select">
                <span>已选择：{{ sizeName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="size-item"
          v-for="(item, index) in infoObj.specificationVOList"
          :key="index"
        >
          <span class="classicName">{{ item.name }}</span>
          <div class="size-total-item">
            <template v-for="(item2, index2) in infoObj.specificationValueVOList">
            <span :key="index2">
              <span
                class="size-item-item"
                v-if="item.id == item2.specificationId && item2.image == null"
              >
                <span
                  :class="{
                    'border-size': true,
                    'border-size-active': item2.flag,
                  }"
                  @click="sizeChoose(item2, index2)"
                  >{{ item2.value }}</span
                >
                <img
                  v-if="item2.flag"
                  src="../../assets/img/detail_choose.png"
                  alt=""
                />
              </span>
              <span
                class="size-item-item color-block"
                v-if="item.id == item2.specificationId && item2.image != null"
                @click="sizeChoose(item2, index2)"
              >
                <img
                  :src="item2.image"
                  :class="{
                    'border-size-active': item2.flag,
                    'color-img': true,
                  }"
                />
                <img
                  v-if="item2.flag"
                  src="../../assets/img/detail_choose.png"
                  class="color-choose"
                />
              </span>
            </span>
          </template>
          </div>
          
        </div>
        <div class="drawer-qunatity">
          <span>购买数量</span>
          <div class="e-number">
            <div class="add" @click="reduce">
              <img src="../../assets/img/reduce_gray.png" alt="" />
            </div>
            <div class="add number">{{ num }}</div>
            <div class="add" @click="add">
              <img src="../../assets/img/add_gray.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 size-sure">
        <div class="btn" @click="toDetail">确定</div>
      </div>
    </el-drawer>
    <el-dialog title="立即咨询" :visible.sync="dialogVisible" center>
      <div class="domian-dialog">
        <div class="domian-dialog-img">
          <img :src="consultImg" alt="" />
        </div>
        <span class="one">扫描二维码，或者公众号搜索“巨永环境”</span>
      </div>
    </el-dialog>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import {
  specialDetail,
  getRegion,
  getWangdian,
  getShoppingPrice,
  saveApi,
  removeSaveApi,
  consultApi,
  addSortApi,
  orderImmediately,
} from "../../assets/api/api.js";
export default {
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  data() {
    return {
      id: 0,
      swiperIndex: 1,
      infoObj: {},
      provinceName: "省份",
      cityName: "城市",
      regionName: "区域",
      wangdian: "网点",
      wangdianName: "请选择网点",
      provinceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      wangdianIndex: -1,
      procinceList: [],
      cityList: [],
      regionList: [],
      wangdianList: [],
      drawer1: false,
      drawer2: false,
      drawerNavIndex: 0,
      nowPrice: 0,
      prePrice: 0,
      message: "",
      num: 1,
      dialogVisible: false,
      consultImg: "",
      sizeName: "",
      flag: 0,
      isOrder: false,
      mid: 0,
      wid: 0,
      bigNum: 2,
      shopType: 0,
      isSpecialFlag: 0,

      video:'',
      videoImage:'',
      videoShow:false,
      sizeImg:''
    };
  },
  watch: {
    // provinceIndex() {
    //   this.cityName = "城市";
    //   this.cityIndex = -1;
    //   this.regionName = "区域";
    //   this.regionIndex = -1;
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
    // cityIndex() {
    //   this.regionName = "区域";
    //   this.regionIndex = -1;
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
    // regionIndex() {
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
  },
  methods: {
    openVideo(){
      this.videoShow = true;
      let video = document.getElementById("videos");
      video.play();
    },
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getInfo() {
      let that = this;
      let res = await specialDetail({
        commodityId: this.id,
      });
      res = res.data.data;
      this.shopType = res.type;
      this.videoImage = res.videoImage;
      this.video = res.video;
      this.infoObj = res;
      this.sizeImg = this.infoObj.imagesUrlList[0]
      this.provinceIndex = res.outletsVO.provinceValue;
      this.provinceName = res.outletsVO.provinceName;
      this.cityIndex = res.outletsVO.cityValue;
      this.cityName = res.outletsVO.cityName;
      this.regionIndex = res.outletsVO.districtValue;
      this.regionName = res.outletsVO.districtName;
      this.wangdianIndex = res.outletsVO.areaValue;
      this.wangdian = res.outletsVO.name;
      this.wangdianName =
        this.provinceName + this.cityName + this.regionName + this.wangdian;
        this.wid = res.outletsVO.id;
      this.$nextTick(function () {
        that.getSwiper1();
      });
      this.sizeOpen();
      console.log(res);
    },
    // 选择网点
    async wangdianOpen(id) {
      this.isSpecialFlag = id;
      this.drawer1 = true;
      this.provinceChoose();
    },
    // 选择省
    async provinceChoose() {
      this.drawerNavIndex = 0;
      let res = await getRegion({
        commodityId: this.$route.query.id,
      });
      res = res.data.data;
      this.procinceList = res;
      console.log(res);
    },
    // 选中省
    provinceOne(id, name) {
      this.provinceIndex = id;
      this.provinceName = name;
      this.cityName = "城市";
      this.cityIndex = -1;
      this.regionName = "区域";
      this.regionIndex = -1;
      this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.cityChoose();
    },
    // 选择市
    async cityChoose() {
      this.drawerNavIndex = 1;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        commodityId: this.$route.query.id,
      });
      res = res.data.data;
      this.cityList = res;
      console.log(res);
    },
    // 选中市
    cityOne(id, name) {
      this.cityIndex = id;
      this.cityName = name;
      this.regionName = "区域";
      this.regionIndex = -1;
      this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.regionChoose();
    },
    // 选择区
    async regionChoose() {
      this.drawerNavIndex = 2;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
        commodityId: this.$route.query.id,
      });
      res = res.data.data;
      this.regionList = res;
      console.log(res);
    },
    // 选中区
    regionOne(id, name) {
      this.regionIndex = id;
      this.regionName = name;
        this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.wangdianChoose();
    },
    // 选择网点
    async wangdianChoose() {
      this.drawerNavIndex = 3;
      let res = await getWangdian({
        areaValue: this.regionIndex,
        commodityId: this.$route.query.id,
      });
      res = res.data.data;
      this.wangdianList = res;
      console.log(res);
    },
    // 选中网点
    wangdianOne(id, name, index) {
      this.wangdianIndex = id;
      this.wangdian = name;
      this.wid = index;
    },
    // 确定
    sureAddress() {
      if (this.wangdianIndex != -1) {
        this.drawer1 = false;
        if (this.provinceName == this.cityName) {
          this.wangdianName =
            this.provinceName + this.regionName + this.wangdian;
        } else {
          this.wangdianName =
            this.provinceName + this.cityName + this.regionName + this.wangdian;
        }
        if (this.isSpecialFlag != 0) {
          this.drawer2 = true;
        }
      }else{
        this.message = "请选择网点";
        this.showInfo();
      }
    },
    // 打开规格
    sizeOpen() {
      // this.drawer2 = true;
      this.sizeName = "";
      let arr = [];
      let arrSpecial = [];
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          arr.indexOf(
            this.infoObj.specificationValueVOList[i].specificationId
          ) == -1
        ) {
          arr.push(this.infoObj.specificationValueVOList[i].specificationId);
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.infoObj.specificationValueVOList[i].flag = true;
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }
      this.getPrice(arrSpecial);
    },
    async getPrice(arrSpecial) {
      for (let i = 0; i < this.infoObj.commodityTypeVOList.length; i++) {
        if (
          arrSpecial.join(",") ==
          this.infoObj.commodityTypeVOList[i].specificationValueIdList.join(",")
        ) {
          this.mid = this.infoObj.commodityTypeVOList[i].id;
        }
      }
      let res = await getShoppingPrice({
        id: this.id,
        specificationValueIds: arrSpecial.join(","),
      });
      res = res.data;
      console.log(res);
      if (res.result == 0) {
        this.isOrder = true;
        this.nowPrice = res.data.specialOffer;
        this.prePrice = res.data.price;
        this.bigNum = res.data.inventory;
      } else {
        this.isOrder = false;
        this.message = res.msg;
        this.showInfo();
      }
    },
    // 选择规格
    sizeChoose(item, index) {
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          this.infoObj.specificationValueVOList[i].specificationId ==
          item.specificationId
        ) {
          this.infoObj.specificationValueVOList[i].flag = false;
        }
      }
      this.infoObj.specificationValueVOList[index].flag = true;
      let arrSpecial = [];
      this.sizeName = "";
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (this.infoObj.specificationValueVOList[i].flag) {
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }
      if(item.image!=null){
        this.sizeImg = item.image;
      }
      console.log(arrSpecial);
      this.getPrice(arrSpecial);
    },
    // 收藏
    async addFarvourite() {
      let res = await saveApi({
        userId: 1,
        commodityId: this.id,
      });
      // res = res.data;
      // this.infoObj.isFavorites = 1;
      if (res.data.result == 0) {
        res = res.data;
        this.infoObj.isFavorites = 1;
        this.message = '收藏成功';
        this.showInfo();
      } else {
        sessionStorage.setItem('address',this.$route.fullPath)
        this.$router.push("/login");
      }
    },
    // 取消收藏
    async removeFarvourite() {
      let res = await removeSaveApi({
        userId: 1,
        commodityId: this.id,
      });
      // res = res.data;
      // this.infoObj.isFavorites = 0;
      if (res.data.result == 0) {
        res = res.data;
        this.infoObj.isFavorites = 0;
        this.message = '取消收藏成功';
        this.showInfo();
      } else {
        sessionStorage.setItem('address',this.$route.fullPath)
        this.$router.push("/login");
      }
    },
    async toConsult() {
      let res = await consultApi();
      res = res.data.data;
      this.consultImg = res;
      this.dialogVisible = true;
    },
    reduce() {
      if (this.num != 1) {
        this.num--;
      }
    },
    add() {
      if (this.shopType == 0) {
        this.num = 1;
        this.message = '抱歉，当前商品限购一件';
        this.showInfo();
      } else {
        if (this.bigNum > this.num) {
          this.num++;
        }else{
          this.message = "已达到上限";
          this.showInfo();
        }
      }
    },
    // 添加购物车
    toSort() {
      if (this.wangdianIndex == -1) {
        this.flag = 1;
        this.wangdianOpen(1);
      } else {
        this.drawer2 = true;
        this.flag = 1;
      }
    },
    // 去下单
    toOrder() {
      if (this.wangdianIndex == -1) {
        this.flag = 2;
        this.wangdianOpen(2);
      } else {
        this.drawer2 = true;
        this.flag = 2;
      }
    },
    // 详情
    async toDetail() {
      if (this.isOrder) {
        if (this.flag == 1) {
          if (this.wangdianIndex == -1) {
            this.message = "请选择网点";
            this.showInfo();
          } else {
            let res = await addSortApi({
              commodityId: this.id,
              commodityTypeId: this.mid,
              outletsId: this.wid,
              quantity: this.num,
              userId: 1,
            });
            res = res.data;
            if (res.result == 0) {
              this.message = "添加成功";
              this.showInfo();
              this.$emit("fatherSort");
              this.drawer2 = false;
            }else{
              sessionStorage.setItem('address',this.$route.fullPath)
              this.$router.push("/login");
            }
          }
        } else if (this.flag == 2) {
          if (this.wangdianIndex == -1) {
            this.message = "请选择网点";
            this.showInfo();
          } else {
            let res = await orderImmediately({
              id: this.mid,
              quantity: this.num,
              outletsId: this.wid,
            });
            res = res.data;
            if (res.result == 0) {
              this.$router.push({
                path: "/mobile/order",
                query: {
                  id: res.data,
                  path: 0,
                },
              });
            } else {
              sessionStorage.setItem('address',this.$route.fullPath)
              this.$router.push("/login");
            }
          }
        } else {
          this.drawer2 = false;
        }
      } else {
        this.message = "该商品库存不足";
        this.showInfo();
      }
    },
    getSwiper1() {
      let that = this;
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        on: {
          slideChangeTransitionEnd() {
            that.swiperIndex = this.activeIndex + 1;
            that.videoShow = false;
            let video = document.getElementById("videos");
            video.pause();
          },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-slide{
  position: relative;
  .video-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    transform: translate(-50%,-50%);
  }
}

.video-open{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // object-fit: fill;
   background-color: #fff;
}

.info {
  position: absolute;
  top: 63px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    position: relative;
    #swiper1 {
      .big-img-item {
        width: 100%;
      }
    }
    .swiper-num {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: inline-block;
      background: rgba(17, 17, 17, 0.5);
      font-size: 12px;
      color: #fff;
      padding: 3px 10px;
      z-index: 999;
    }
  }
  .center {
    margin-bottom: 10px;
    .center-info {
      padding: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .info-name {
        font-size: 16px;
        color: #111;
        font-weight: 500;
      }
      .info-introduction {
        font-size: 12px;
        color: #666;
      }
      .price {
        display: flex;
        align-items: flex-end;
        .price-now {
          .one {
            font-size: 14px;
            color: #111;
          }
          .two {
            font-size: 24px;
            color: #111;
            font-weight: 500;
          }
        }
        .price-pre {
          font-size: 14px;
          color: #999;
          text-decoration: line-through;
          margin-left: 10px;
          position: relative;
          bottom: 5px;
        }
      }
    }
    .center-item {
      margin-top: 10px;
      background-color: #fff;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #111;
      display: flex;
      align-items: center;
      .one {
        display: inline-block;
        width: 20%;
        color: #999;
        flex-shrink: 0;
      }
      .two {
        flex-grow: 1;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .center-introduction {
      background-color: #fff;
      margin-top: 10px;
      .shop-info {
        padding: 15px;
        .shop-info-top {
          font-size: 14px;
          color: #999;
          padding-bottom: 15px;
        }
        .shop-info-item {
          font-size: 12px;
          padding-bottom: 15px;
          display: flex;
          .one {
            color: #999;
            width: 20%;
            display: inline-block;
            flex-shrink: 0;
          }
          .two {
            color: #666;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
          }
        }
        .super-img {
          width: 100%;
        }
      }
    }
  }
  .bottom {
    position: sticky;
    bottom: 0;
    height: 80px;
    background-color: #fff;
    padding: 15px;
    .domain {
      display: flex;
      justify-content: space-between;
      .bottom-left {
        display: flex;
        .domian-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          margin-right: 10px;
          font-size: 12px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .bottom-right {
        display: flex;
        .btn-item-left {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 40px;
          background: rgba(17, 17, 17, 0.04);
          border: 1px solid #111111;
          font-size: 14px;
          color: #111;
          margin-right: 10px;
        }
        .btn-item-right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 40px;
          background: #111;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}

.drawer-domain {
  .stricky {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .drawer-top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: #111;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #f3f3f3;
  }
  .drawer-center {
    border-bottom: 1px solid #f3f3f3;
    .drawer-center-item {
      padding: 0 15px;
      font-size: 15px;
      color: #111;
      display: flex;
      justify-content: flex-start;
      .wangdian-item {
        padding: 15px 0 12px 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 20%;
        text-align: center;
        height: 100%;
        margin-right: 20px;
      }
      .drawer-center-item-active {
        border-bottom: 3px solid #bba492;
      }
    }
  }
  .drawer-bottom {
    .drawer-bottom-item {
      padding: 15px;
      font-size: 15px;
      color: #999;
    }
    .drawer-bottom-item-active {
      color: #111;
      font-weight: 500;
    }
  }
}

.drawer-size {
  padding: 15px;
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;
    .left {
      flex-shrink: 0;
      width: 23% !important;
      img {
        width: 100%;
        border: 1px solid #111;
      }
    }
    .right-special {
      position: relative;
      flex-grow: 1;
      font-size: 15px;
      margin-left: 15px;
      .right-top {
        img {
          position: absolute;
          right: 0;
          width: 24px;
          height: 24px;
        }
      }
      .right-bottom {
        position: absolute;
        bottom: 0;
        .price {
          color: #bba492;
        }
        .select {
          color: #333;
        }
      }
    }
  }
  .size-item {
    padding: 15px 0 0 0;
    border-bottom: 1px solid #f3f3f3;
    .classicName {
      display: block;
      font-size: 15px;
      color: #666;
      margin-bottom: 20px;
    }
    .size-item-item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      position: relative;
      .border-size {
        border: 1px solid #cccdcd;
        padding: 5px 10px;
      }
      img {
        position: absolute;
        right: 0;
        bottom: -5px;
      }
      .border-size-active {
        border: 1px solid #bba492;
      }
    }
  }
  .drawer-qunatity {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #666;
  }
}

.size-sure {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  height: 70px;
  .btn {
    width: 100%;
    height: 40px;
    background-color: #111;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.e-number {
  display: flex;
  margin-top: 20px;
  .add {
    height: 30px;
    width: 30px;
    border: 1px solid #cccdcd;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25px;
    }
  }
  .number {
    width: 40px;
    margin: 0 3px;
  }
}

/deep/.el-dialog {
  width: 300px;
}

.domian-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .one {
    margin-top: 10px;
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

/deep/.IntroductionText img{
  display: block !important;
    width: 100% !important;
}

/deep/.IntroductionText p{
  font-size: 12px;
}

.color-block {
  width: 50px;
  height: 50px;
  position: relative;
  .color-img {
    width: 100%;
    height: 100%;
  }
  .color-choose {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}

.size-total-item{
  display: flex;
  align-items: center;
}
</style>