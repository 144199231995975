<template>
  <div class="col-md-10 order">
    <div class="order-fff">
      <div class="top-nav">
        <div class="top-nav-left">
          <div
            :class="{ 'left-item': true, 'select-nav': chooseNav == index }"
            v-for="(item, index) in navList"
            :key="index"
            @click="toChooseNav(index)"
          >
            <span
              :class="{ 'left-item-span': true, 'border-none': index == 4 }"
              >{{ item.text }}</span
            >
          </div>
        </div>
      </div>
      <!-- 主体 -->
      <div class="discount-body">
        <div class="row">
          <div
            class="col-md-3 discount-item"
            v-for="(item, index) in shopList"
            :key="index"
            @click="goDetail(item)"
          >
            <img
              class="search"
              src="../../assets/img/ic.png"
              @click="removeOne(item.commodityId,$event)"
              alt=""
            />
            <img class="discount-item-img img-fluid" :src="item.image" alt="" />
            <span class="one">{{ item.name }}</span>
            <span class="three">{{item.years}} {{item.artistName}}</span>
            <span class="two" v-if="item.isSell!=0">￥{{ item.specialOfferMin }}</span>
            <span class="two" v-else>联系客服</span>
          </div>
          <div class="null-info" v-if="shopList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
        </div>
      </div>
      <div class="discount-bottom container" v-if="shopList.length>0">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
  <!-- </div> -->
</template>


<script>
import { savePageApi, removeSaveApi } from "../../assets/api/api.js";
export default {
  // name:'shopList',
  created() {
    this.getList();
  },
  data() {
    return {
      navList: [
        {
          text: "全部",
        },
        {
          text: "古典精选",
        },
        {
          text: "现代轻奢",
        },
        {
          text: "精选商品",
        },
      ],
      shopList: [],
      chooseNav: 0,
      type: -1,
      pageSize: 12,
      total: 0,
      currentPage: 1,
      message:''
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await savePageApi({
        userId: 1,
        type: this.type,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
      console.log(res);
    },
    async getList() {
      this.currentPage = 1;
      let res = await savePageApi({
        userId: 1,
        type: this.type,
        pageNo: 1,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
      console.log(res);
    },
    // 删除
    async removeOne(id,e) {
      e.stopPropagation()
      let res = await removeSaveApi({
        userId: 1,
        commodityId: id,
      });
      if(res.data.result == 0){
        this.message = '删除成功';
        this.showInfo()
      }
      this.getList();
      console.log(res);
    },
    toChooseNav(index) {
      this.chooseNav = index;
      this.type = index - 1;
      this.getList();
    },
    // 去详情
    goDetail(item) {
      console.log(item);
      if (item.type == 1) {
        this.$router.push({
          path: "/luxery-detail",
          query: {
            id: item.commodityId,
          },
        });
      } else if (item.type == 0) {
        this.$router.push({
          path: "/classic-detail",
          query: {
            id: item.commodityId,
          },
        });
      }
    },
  },
};
</script>


<style lang="less" scoped>
.order-fff {
  padding-left: 15px;
  background-color: #fff;
}
.order {
  background-color: #fff;
  padding-bottom: 10px;
  padding-left: 15px;
  background-color: #f5f5f5;
  .top-nav {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-nav-left {
      display: flex;
      align-items: center;
      height: 100%;
      .left-item {
        width: 110px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .left-item-span {
          font-size: 16px;
          text-align: center;
          border-right: 1px solid #eee;
          display: inline-block;
          width: 100%;
          color: #666;
        }
        .border-none {
          border-right: 0;
        }
      }
      .select-nav {
        font-size: 18px;
        font-weight: 500;
        color: #111;
        border-bottom: 2px solid #111;
      }
    }
  }
}

.discount-body {
  margin-top: 30px;
}

.discount-item:hover .sanjiao {
  opacity: 1;
}

.discount-item:hover .search {
  opacity: 1;
}

.discount-item:hover {
  box-shadow: 0px 4px 14px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.discount-item {
  color: #111;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 10px;
  transition: all ease 0.3s;
  .search {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 9999;
  }
  .discount-item-img {
    max-width: 294px;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .one {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
  }
  .two {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .three {
    text-align: center;
    font-size: 12px;
    color: #999;
    display: block;
    margin-top: 10px;
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>
