<template>
  <div :class="{container:true,classic: true, classic_mobile: isMobile}">
    <div class="row">
      <div
        class="imgText"
        v-if="infoObj.type == 2"
        v-html="infoObj.description"
      ></div>
      <div class="myVideo" v-if="infoObj.type == 5" style="margin: 20px">
        <video :src="infoObj.video" controls style="width: 100%"></video>
      </div>
    </div>
  </div>
</template>


<script>
import { getAdvertising } from "../../assets/api/api.js";
export default {
  created() {
    this.id = this.$route.query.id;
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.getInfo();
  },
  data() {
    return {
      id: 0,
      infoObj: {},
      isMobile: false,
    };
  },
  methods: {
    getInfo() {
      getAdvertising({
        id: this.id,
      }).then((res) => {
        this.infoObj = res.data.data;
        console.log(this.infoObj);
      });
    },
  },
};
</script>


<style lang="less" scoped>
/deep/ .imgText {
  font-size: 14px;
  margin-top: 60px;
  padding: 10px;
  width: 100%;
  img {
    width: 100% !important;
  }
}
.myVideo {
  width: 100% !important;
  padding: 20px !important;
  display: flex;
  justify-content: center;
  video {
    width: 100% !important;
  }
}

.classic {
}
.classic_mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
</style>