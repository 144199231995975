<template>
  <div
    :class="{
      'buy-limit': true,
      'buy-limit_mobile': isMobile,
      'pcBuy-limit': !isMobile,
    }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="post-img">
      <img class="img-fluid" :src="bannerImg" alt="" />
    </div>

    <div class="domian-buyLimit container">
      <div class="add-margin" v-for="(item, index) in shopList" :key="index">
        <!-- 报名中 -->
        <div
          class="domian-one row"
          v-if="item.state == 2"
          @click="toDetail(item.id)"
        >
          <div class="col-lg-6 col-sm-12 no-padding">
            <img class="left-img img-fuild" :src="item.commodityImage" alt="" />
            <span class="status">{{
              item.state == 2 ? "报名中" : item.state == 0 ? "待开奖" : "已开奖"
            }}</span>
          </div>
          <div class="col-lg-6 col-sm-12 right">
            <div class="top">
              <span>{{ item.commodityName }}</span>
              <span class="d-sm-none d-lg-block"
                >￥{{ item.panicPriceMin }}</span
              >
            </div>
            <div class="two">
              <span class="intro">{{ item.commodityIntroduction }}</span>
              <span class="line-through-price d-sm-none d-lg-block"
                >￥{{ item.priceMin }}</span
              >
            </div>
            <span class="limit-item limit-item-top limit-only"
              >开奖日期：{{ item.panicTime }}</span
            >
            <span class="limit-item">中奖名额：{{ item.quota }}人</span>
            <span class="limit-item">已经报名：{{ item.peopleSize }}人</span>
            <div class="dottat-line d-sm-none d-lg-block"></div>

            <div class="introduce d-sm-none d-lg-block">
              <span
                >活动说明:{{item.instruction}}</span
              >
            </div>

            <div class="btn mobile-btn">
              <div
                class="btn-left mobile-btn-height"
                @click="toDetail(item.id)"
              >
                <span class="btn-one">我要报名</span>
                <span class="btn-two" v-if="item.cautionMoney > 0"
                  >保证金￥{{ item.cautionMoney }}</span
                >
              </div>
              <div class="d-sm-none d-lg-block" @click="toConsult">
                <div class="btn-right">立即咨询</div>
              </div>
            </div>
            <div class="mobile-price d-lg-none">
              <span class="mobile-price-one">￥{{ item.panicPriceMin }}</span>
              <span class="line-through-price">￥{{ item.priceMin }}</span>
            </div>
          </div>
        </div>
        <!-- 待开奖 -->
        <div
          class="domian-one row"
          v-if="item.state == 0"
          @click="toDetail(item.id)"
        >
          <div class="col-lg-6 col-sm-12 no-padding">
            <img class="left-img img-fuild" :src="item.commodityImage" alt="" />
            <span class="status">{{
              item.state == 2 ? "报名中" : item.state == 0 ? "待开奖" : "已开奖"
            }}</span>
          </div>
          <div class="col-lg-6 col-sm-12 right">
            <div class="top">
              <span>{{ item.commodityName }}</span>
              <span class="d-sm-none d-lg-block"
                >￥{{ item.panicPriceMin }}</span
              >
            </div>
            <div class="two">
              <span class="intro">{{ item.commodityIntroduction }}</span>
              <span class="line-through-price d-sm-none d-lg-block"
                >￥{{ item.priceMin }}</span
              >
            </div>
            <span class="limit-item limit-item-top limit-only"
              >开奖日期：{{ item.panicTime }}</span
            >
            <span class="limit-item">中奖名额：{{ item.quota }}人</span>
            <span class="limit-item">已经报名：{{ item.peopleSize }}人</span>
            <div class="dottat-line d-sm-none d-lg-block"></div>

            <span
              class="limit-item limit-item-top d-sm-none d-lg-block"
              v-if="item.userPanicVO"
              >已选网点：{{
                item.userPanicVO.outletsArea + item.userPanicVO.outletsName
              }}</span
            >
            <span
              class="limit-item d-sm-none d-lg-block"
              v-if="item.userPanicVO"
              >已选款式：{{ item.userPanicVO.commodityTypeName }}</span
            >

            <div class="introduce d-sm-none d-lg-block">
              <span
                >活动说明:{{item.instruction}}</span
              >
            </div>

            <div class="dottat-line d-sm-none d-lg-block"></div>
            <div class="mobile-position">
              <span class="wait">等待开奖中~</span>
              <span class="limit-item limit-item-top" v-if="item.userPanicVO"
                >我的号码：{{ item.userPanicVO.number }}</span
              >
            </div>
            <div class="mobile-price d-lg-none">
              <span class="mobile-price-one">￥{{ item.panicPriceMin }}</span>
              <span class="line-through-price">￥{{ item.priceMin }}</span>
            </div>
          </div>
        </div>
        <!-- 已开奖 -->
        <div
          class="domian-one row"
          v-if="item.state == 1"
          @click="toDetail(item.id)"
        >
          <div class="col-lg-6 col-sm-12 no-padding">
            <img class="left-img img-fuild" :src="item.commodityImage" alt="" />
            <span class="status">{{
              item.state == 2 ? "报名中" : item.state == 0 ? "待开奖" : "已开奖"
            }}</span>
          </div>
          <div class="col-lg-6 col-sm-12 right">
            <div class="top">
              <span>{{ item.commodityName }}</span>
              <span class="d-sm-none d-lg-block"
                >￥{{ item.panicPriceMin }}</span
              >
            </div>
            <div class="two">
              <span class="intro">{{ item.commodityIntroduction }}</span>
              <span class="line-through-price d-sm-none d-lg-block"
                >￥{{ item.priceMin }}</span
              >
            </div>
            <span class="limit-item limit-item-top limit-only"
              >开奖日期：{{ item.panicTime }}</span
            >
            <span class="limit-item">中奖名额：{{ item.quota }}人</span>
            <span class="limit-item">已经报名：{{ item.peopleSize }}人</span>
            <div class="dottat-line d-sm-none d-lg-block"></div>

            <span
              class="limit-item limit-item-top d-sm-none d-lg-block"
              v-if="item.userPanicVO"
              >已选网点：{{
                item.userPanicVO.outletsArea + item.userPanicVO.outletsName
              }}</span
            >
            <span
              class="limit-item d-sm-none d-lg-block"
              v-if="item.userPanicVO"
              >已选款式：{{ item.userPanicVO.commodityTypeName }}</span
            >

            <div class="introduce d-sm-none d-lg-block">
              <span
                >活动说明:{{item.instruction}}</span
              >
            </div>

            <div class="dottat-line d-sm-none d-lg-block"></div>

            <div class="mobile-position-two">
              <span class="wait" v-if="item.userPanicVO">{{
                item.userPanicVO.isJackpot == 1 ? "恭喜中奖" : "很遗憾，未中奖~"
              }}</span>

              <span class="limit-item limit-item-top" v-if="item.userPanicVO"
                >我的号码：{{ item.userPanicVO.number }}</span
              >
              <span class="limit-item">中奖号码：<span style="margin-right:10px" v-for="(item2,index2) in item.numberList" :key="index2">{{item2}}</span></span>

              <span
                class="time"
                v-if="item.userPanicVO && item.userPanicVO.isJackpot == 1"
                >24小时内有效，请及时购买</span
              >
            </div>

            <div
              class="btn-domian btn-left pointer mobile-btn mobile-btn-height"
              v-if="item.userPanicVO && item.userPanicVO.isJackpot == 1"
            >
              去领奖
            </div>
            <div class="mobile-price d-lg-none">
              <span class="mobile-price-one">￥{{ item.panicPriceMin }}</span>
              <span class="line-through-price">￥{{ item.priceMin }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="null-info" v-if="shopList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
    <!-- 分页 -->
    <div class="container d-sm-none d-lg-block">
      <div class="discount-bottom" v-if="shopList.length>0">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
    <!-- 绑定微信 -->
    <div class="item-one">
      <el-dialog title="绑定微信" :visible.sync="dialogVisible" center>
        <div class="domian-dialog">
          <div class="domian-dialog-img">
            <img :src="consultImg" alt="" />
          </div>
          <span class="one">扫一扫，添加客服微信</span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import { getLimitPage, consultApi,getBannerApi } from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.getList();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.getBanner()
  },
  data() {
    return {
      shopList: [],
      num: 1,
      chooseNum: 1,
      chooseSelect: 1,
      isClick: false,
      chooseAddress: 0,
      total: 0,
      pageSize: 3,
      currentPage: 1,
      isMobile: false,
      pageNo: 1,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      consultImg: "",
      dialogVisible: false,
      bannerImg:''
    };
  },

  methods: {
    getBanner(){
      getBannerApi({advertisingId:this.$route.query.id}).then((res)=>{
        console.log(res);
        this.bannerImg = res.data.data;
      })
    },
    // 分页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getLimitPage({
        userId: 1,
        pageNo: val,
        pageSize: this.pageSize,
      });
      console.log(res);
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
      console.log(res);
    },
    // 获得数据
    async getList() {
      this.currentPage = 1;
      let res = await getLimitPage({
        userId: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      console.log(res);
      this.total = res.data.data.total;
      this.shopList = this.shopList.concat(res.data.data.items);
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.shopList.length < this.total) {
          console.log("...");
          this.loading = false;
          this.getList();
        } else {
          this.loading = false;
          this.addMore = false;
        }
      }
    },
    // 报名
    toDetail(id) {
      if (this.isMobile) {
        this.$router.push({
          path: "/mobile/limit-detail",
          query: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          path: "/index/buy-limit/detail",
          query: {
            id: id,
          },
        });
      }
    },
    // 立即咨询
    async toConsult() {
      let res = await consultApi();
      res = res.data.data;
      this.consultImg = res;
      this.dialogVisible = true;
    },
  },

  mounted() {
    this.areaList = this.addressList;
  },
};
</script>


<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.post-img {
  img {
    width: 100%;
    max-height: 580px;
  }
}

.buy-limit {
}

.buy-limit_mobile {
  width: 100%;
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  overflow: auto;
}

.pcBuy-limit {
  margin-bottom: 80px;
}

.row {
  margin: 0;
}

.add-margin {
  margin: 0 15px;
}

.domian-one {
  background-color: #f8f8f8;
  margin-top: 135px !important;
  position: relative;
  color: #111;
  font-size: 14px;
  .left-img {
    width: 100%;
    position: absolute;
    left: 60px;
    top: -50px;
    max-height: 605px;
  }
  .status {
    display: block;
    color: #fff;
    background-color: #bba492;
    padding: 2px 8px;
    position: absolute;
    left: 80px;
    top: -30px;
  }
  .right {
    padding: 50px 60px 50px 90px;
    min-height: 580px;
    .top {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 500;
    }
    .two {
      color: #999;
      display: flex;
      justify-content: space-between;
      .intro {
        width: 400px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .limit-item {
      margin-bottom: 10px;
      display: block;
    }
    .limit-item-top {
      margin-top: 20px;
    }
    .dottat-line {
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted #cccdcd;
      margin-top: 24px;
    }
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .buy-limit {
    padding-bottom: 60px !important;
  }
  .domian-one {
    position: relative;
    margin-top: 30px !important;
    .left-img {
      position: static;
    }
    .status {
      display: block;
      color: #fff;
      background-color: #bba492;
      padding: 2px 8px;
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .no-padding {
      padding: 0;
    }
    .right {
      padding: 15px !important;
      min-height: auto !important;
      .limit-item {
        margin: 0;
      }
      .limit-item-top {
        margin: 0;
      }
      .limit-only {
        margin-top: 10px;
      }
    }
  }
  .mobile-position {
    position: absolute;
    width: 100%;
    background: rgba(17, 17, 17, 0.7);
    color: #fff;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    top: -70px;
    left: 0;
    .wait {
      margin: 0;
    }
    .limit-item {
      margin: 0;
    }
    .limit-item-top {
      margin: 0;
    }
  }
  .mobile-price {
    margin-top: 10px;
    .mobile-price-one {
      font-weight: 500;
      font-size: 20px;
      margin-right: 10px;
    }
    .line-through-price {
      color: #999;
    }
  }
  .mobile-position-two {
    position: absolute;
    width: 100%;
    background: rgba(17, 17, 17, 0.7);
    color: #fff;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: -120px;
    left: 0;
    padding-left: 15px;
    .wait {
      margin: 0;
    }
    .limit-item {
      margin: 0;
    }
    .limit-item-top {
      margin: 0;
    }
  }
  .mobile-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  .mobile-btn-height {
    height: 40px !important;
  }
}

///////////////////////////////////
.three-select {
  position: relative;
  width: 220px;
  height: 30px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccdcd;
  margin-left: 15px;
  input {
    padding-left: 20px;
    width: 160px;
    height: 14px;
    border: 0;
    outline: none;
    background-color: #fff;
    ::placeholder {
      font-size: 14px;
      color: #999;
    }
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }

  .select-absoulte {
    overflow: hidden;
    background-color: #fff;
    width: 365px;
    // height: 500px;
    position: absolute;
    padding: 14px 20px 0;
    top: 29px;
    left: -1px;
    z-index: 998;
    border: 1px solid #cccdcd;
    display: none;
    .item-button {
      position: relative;
      display: inline-block;
      width: 70px;
      height: 24px;
      font-size: 14px;
      margin-right: 10px;
      border: 1px solid #cccdcd;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .absoulte-line {
      position: absolute;
      top: 36px;
      width: 100%;
      height: 2px;
      background-color: #111;
    }
  }
}
.item-button-bottom {
  position: absolute;
  width: 66px;
  height: 3px;
  background-color: #fff;
  top: 20px;
  left: 0;
  z-index: 999;
}
.item-button-click {
  border: 2px solid #111 !important;
  border-bottom: 0 !important;
}
.select-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 200px;
  overflow-y: scroll;
  .select-loop {
    display: inline-block;
    width: 25%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      padding: 4px 5px;
    }
  }
}

.select-container::-webkit-scrollbar {
  width: 10px;
  height: 1px;
  // background-color: #f5f5f5;
}

.select-container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #bbbbbb;
}

.select-container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  background: #ededed;
}

.block {
  display: block !important;
}

////////////////////////////////////////////////////////////////////
.choose-wangdian {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

////////////////////////////////////////////////////////////
.color-catagory {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border: 1px solid #cccdcd;
}

.four {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  .four-left {
    width: 45px;
    margin-left: 14px;
    font-size: 12px;
    margin-top: 10px;
    flex-shrink: 0;
  }
  .four-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 15px;
  }
  .four-right-item {
    position: relative;
  }
  .four-right-item-position {
    position: absolute;
    right: 12px;
    bottom: 0;
  }
}

////////////////////////////////////////////////////////////////
.my-border {
  display: inline-block;
  border: 1px solid #cccdcd;
  padding: 5px 9px;
  color: #333;
  margin-right: 6px;
  margin-top: 12px;
  cursor: pointer;
}

.constructer-left {
  display: block;
  margin-top: 20px !important;
}

.my-border-two {
  border: 2px solid #bba492;
}

.size {
  display: inline-block;
  width: 56px;
}

.nine {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0 !important;
}

/////////////////////////////////////////////////////////////////
.introduce {
  font-size: 12px;
  color: #bba492;
  margin-top: 20px;
}
.btn {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.btn-left {
  width: 130px;
  height: 40px;
  background-color: #111;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btn-one {
    font-weight: 500;
    font-size: 14px;
  }
  .btn-two {
    font-size: 10px;
  }
}
.btn-right {
  font-size: 14px;
  width: 130px;
  height: 40px;
  background: #f6f6f6;
  border: 1px solid #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.wait {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-top: 25px;
}

.line-through-price {
  text-decoration: line-through;
}
.btn-domian {
  margin-top: 20px;
}

.time {
  color: #bba492;
  font-size: 12px;
}

//////////////////////////////////
//dialog部分
.address-item {
  padding: 15px 24px;
  border: 1px solid #cccdcd;
  color: #111;
  font-size: 14px;
  position: relative;
  margin-top: 15px;
  .address-item-item {
    display: flex;
    margin-top: 5px;
    .address-item-item-one {
      display: inline-block;
      width: 70px;
      flex-shrink: 0;
    }
    .address-item-item-one:first-child {
      margin: 0;
    }
  }
}
.address-item:nth-child(1) {
  margin: 0;
}
.active-address-item {
  border: 2px solid #111 !important;
}

.icon-true {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dialog-footer {
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 14px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

.default-address-span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #bba492;
}

////////////////////////////////////////////////////////////////////////////////////////

/deep/.el-dialog {
  color: #111 !important;
  width: 410px;
}

.el-form {
  margin-top: 20px;
}

.dottat-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #cccdcd;
  margin-bottom: 20px;
}

.getInfo {
  display: block;
  margin-top: 20px;
}

.select-area {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  select {
    width: 23%;
    outline: none;
  }
  .my-area {
    display: block;
  }
}

.my-textarea {
  width: 100%;
  resize: none;
  outline: none;
}

.detail-info {
  margin-top: 20px;
}

.detail-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-info-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    margin-bottom: 10px;
    .one {
      display: inline-block;
      width: 80px;
      color: #999;
    }
    .two {
      color: #111;
    }
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
  border: 0;
  cursor: pointer;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
  background-color: #111;
  border: 0;
}

/deep/ .el-tag .el-tag__close:hover {
  color: #fff;
  background-color: #111;
}

.clear-tag {
  background-color: #eee;
  color: #666;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.domian-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 260px;
    height: 260px;
    border: 1px solid #cccdcd;
    padding: 20px;
  }
  .one {
    margin-top: 20px;
  }
}

.null-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img {
  width: 200px;
  height: 200px;
}

@media (min-width: 300px) and (max-width: 992px){
  .mobile-position-two{
    height: 80px;
    top: -80px;
  }
  .domian-one .right .two .intro{
    margin-top: 10px !important;
  }
}
</style>