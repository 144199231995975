<template>
  <div class="info container" v-on:scroll.passive="onScroll" ref="box">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>我的收藏</span>
    </div>
    <div class="col-sm-12">
      <div class="nav-collection">
        <span
          :class="{ active: navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
          @click="chooseNav(item.id)"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div class="row collcetion-domian">
      <div
        :class="{
          'col-sm-6': true,
          'no-padding-left': index % 2 != 0,
          'no-padding-right': index % 2 == 0,
        }"
        v-for="(item, index) in shopList"
        :key="index"
        @click="goDetail(item)"
      >
        <div class="collection-item">
          <img class="img-fuild" :src="item.image" alt="" />
          <img
            class="closs"
            src="../../assets/img/closs.png"
            @click="deletedOne(item.commodityId,$event)"
          />
          <div class="collection-item-bottom">
            <span class="one">{{ item.name }}</span>
            <span class="two">{{ item.years }} {{ item.artistName }}</span>
            <span class="three" v-if="item.isSell==1">￥{{ item.specialOfferMin }}</span>
            <span class="three" v-else>咨询客服</span>
          </div>
        </div>
      </div>
      <div class="null-info" v-if="shopList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
      <div class="loading" :class="{ 'loading-hidden': loading }">
        {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
      </div>
    </div>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import { savePageApi, removeSaveApi } from "../../assets/api/api.js";
export default {
  created() {
    this.getCollection();
  },
  data() {
    return {
      navList: [
        {
          id: -1,
          text: "全部",
        },
        {
          id: 0,
          text: "古典精选",
        },
        {
          id: 1,
          text: "现代轻奢",
        },
        {
          id: 2,
          text: "精选商品",
        },
      ],
      shopList: [],
      navIndex: 0,
      type: -1,
      pageNo: 1,
      pageSize: 6,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      total: 0,
      message: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    toPreview() {
      this.$router.go(-1);
    },
    async getCollection() {
      let res = await savePageApi({
        userId: 1,
        type: this.type,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      console.log(res);
      res = res.data;
      if (res.result == 0) {
        this.loading = true;
      }
      this.shopList = this.shopList.concat(res.data.items);
      this.total = res.data.total;
      console.log(res);
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        console.log(this.shopList.length);
        if (this.shopList.length < this.total) {
          this.loading = false;
          this.getCollection();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
      }
    },
    // nav选择
    chooseNav(id) {
      this.navIndex = id + 1;
      this.type = id;
      this.shopList = [];
      this.pageNo = 1;
      this.getCollection();
    },
    // 删除
    async deletedOne(id,e) {
      e.stopPropagation()
      let res = await removeSaveApi({
        userId: 1,
        commodityId: id,
      });
      res = res.data;
      this.shopList = [];
      this.pageNo = 1;
      this.getCollection();
      console.log(res);
      if (res.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      }
    },
    // 去详情
    goDetail(item) {
      console.log(item);
      if (item.type == 0) {
        this.$router.push({
          path: "/mobile/classic-detail",
          query: {
            id: item.commodityId,
          },
        });
      } else if (item.type == 1) {
        this.$router.push({
          path: "/mobile/luxery-detail",
          query: {
            id: item.commodityId,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    padding-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .nav-collection {
    font-size: 16px;
    margin-top: 25px;
    span {
      margin-right: 20px;
      color: #666666;
    }
    .active {
      font-size: 18px;
      color: #111;
    }
  }
}
.collcetion-domian {
  margin-top: 20px !important;
}
.collection-item {
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 10px;
  img {
    width: 100%;
  }
  .closs {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    right: 0;
    z-index: 999;
  }
  .collection-item-bottom {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .one {
      font-size: 14px;
      color: #111;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-top: 15px;
    }
    .two {
      color: #999;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .three {
      font-size: 14px;
      color: #111;
      font-weight: 500;
    }
  }
}

.no-padding-left {
  padding-left: 5px;
}
.no-padding-right {
  padding-right: 5px;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>