<template>
  <div class="mobile-sort container">
    <div class="mobile-sort-top">
      <div class="sort-top-left">
        <img
          src="../../assets/img/icon_sel_n.png"
          v-if="totalChoose == false"
          @click="chooseAll"
        />
        <img src="../../assets/img/icon_sel_s.png" v-else @click="choosenone" />
        <span class="to-left-one">全选</span>
      </div>
      <div class="sort-top-right">
        <span @click="deletedMany">批量删除</span>
        <span class="to-left-two" @click="removeUseless">清空失效商品</span>
      </div>
    </div>
    <div class="row mobile-container">
      <div class="mobile-for" v-for="(item, index) in sortList" :key="index">
        <div
          :class="{
            'mobile-item': true,
          }"
          v-if="item.isState == 1"
          @touchstart="start"
          @touchmove="end"
        >
          <div class="col-sm-1">
            <img
              v-if="item.flag == false && item.inventory > 0"
              src="../../assets/img/icon_sel_n.png"
              @click="chooseOne(item)"
            />
            <img
              v-if="item.flag == true && item.inventory > 0"
              src="../../assets/img/icon_sel_s.png"
              @click="removeOne(item)"
            />
            <img
              v-if="item.inventory < 1"
              src="../../assets/img/icon_sel_g.png"
              alt=""
            />
          </div>
          <div class="col-sm-5 mobile-item-bigImg">
            <img :src="item.image" alt="" />
          </div>
          <div
            :class="{
              'col-sm-6': true,
              'no-padding-left': true,
            }"
          >
            <div class="mobile-item-text">
              <span
                :class="{ one: true, 'expire-color': item.inventory < 1 }"
                >{{ item.commodityName }}</span
              >
              <div
                :class="{ two: true, 'expire-color': item.inventory < 1 }"
                @click="chooseSize(item)"
              >
                <span class="three"
                  ><span
                    v-for="(item2, index2) in item.specificationValueList"
                    :key="index2"
                    >{{ item2 + "" }}</span
                  ></span
                >
                <img src="../../assets/img/down_grey.png" alt="" />
              </div>
              <div
                :class="{ two: true, 'expire-color': item.inventory < 1 }"
                @click="addressChoose(item, index)"
              >
                <span class="three">{{ wangdianName[index] }}</span>
                <img src="../../assets/img/down_grey.png" alt="" />
              </div>
              <div :class="{ four: true, 'expire-color': item.inventory < 1 }">
                <div class="four-left">
                  ￥{{ getZero(item.specialOffer,item.quantity) }}
                </div>
                <div class="four-right">
                  <div class="reduce" @click="reduce(item)">
                    <img src="../../assets/img/reduce_gray.png" alt="" />
                  </div>
                  <div
                    :class="{
                      reduce: true,
                      number: true,
                      'expire-color': item.inventory < 1,
                    }"
                  >
                    {{ item.quantity }}
                  </div>
                  <div class="reduce" @click="add(item)">
                    <img src="../../assets/img/add_gray.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="inventory offset-sm-2" v-if="item.inventory < 1">
            <img src="../../assets/img/warning.png" alt="" />
            <span>该规格已售罄请重新选择</span>
          </div>
          <div class="right-options">
            <div class="options-save" @click="toSave(item)">
              <span>移入</span><span>收藏夹</span>
            </div>
            <div class="options-delete" @click="deletedOne(item.id)">删除</div>
          </div>
        </div>
      </div>
      <div class="expire-container">
        <div class="expire-item" v-if="uselessCount > 0">
          <div class="expire-line"></div>
          <div class="expire-introduction">已下商品已失效</div>
        </div>
        <div v-for="(item, index) in sortList" :key="index">
          <div
            class="mobile-item"
            @touchstart="started"
            @touchmove="ended"
            v-if="item.isState == 0"
          >
            <div class="col-sm-1">
              <img src="../../assets/img/icon_sel_g.png" alt="" />
            </div>
            <div class="col-sm-5 mobile-item-bigImg">
              <div class="selWord">
                <img :src="item.image" alt="" />
                <div class="selNone">
                  <div>售罄</div>
                  <div>下架</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 no-padding-left" style="height: 100%">
              <div class="mobile-item-text">
                <span class="one expire-color">{{ item.commodityName }}</span>
                <div class="two expire-color">
                  <span class="three"
                    ><span
                      v-for="(item2, index2) in item.specificationValueList"
                      :key="index2"
                      >{{ item2 + "" }}</span
                    ></span
                  >
                  <img src="../../assets/img/down_grey.png" alt="" />
                </div>
                <div class="two expire-color">
                  <span class="three">{{
                    item.provinceName + item.cityName + item.districtName
                  }}</span>
                  <img src="../../assets/img/down_grey.png" alt="" />
                </div>
                <div class="four expire-colo">
                  <div class="four-left expire-color">
                    ￥{{ item.specialOffer * item.quantity }}
                  </div>
                  <div class="four-right">
                    <div class="reduce">
                      <img src="../../assets/img/reduce_gray.png" alt="" />
                    </div>
                    <div class="reduce number expire-color">
                      {{ item.quantity }}
                    </div>
                    <div class="reduce">
                      <img src="../../assets/img/add_gray.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-options right-options-one">
              <div class="options-delete" @click="deletedOne(item.id)">
                删除
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-sort-bottom">
      <div class="bottom-left">
        <img
          src="../../assets/img/icon_sel_n.png"
          v-if="totalChoose == false"
          @click="chooseAll"
        />
        <img src="../../assets/img/icon_sel_s.png" v-else @click="choosenone" />
        <span class="one">已选({{ chooseNum }})</span>
      </div>
      <div class="bottom-right">
        <span class="two">应付总额:￥{{ totalPrice }}</span>
        <div class="order" @click="toOrder">下单</div>
      </div>
    </div>

    <!-- 弹出框1 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="stricky">
          <div class="drawer-top">
            <span>配送至</span>
            <span @click="sureAddress">确定</span>
          </div>
          <div class="line"></div>
          <div class="drawer-center">
            <div class="drawer-center-item">
              <span
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 0,
                }"
                @click="provinceChoose"
                >{{ provinceName }}</span
              >
              <span
                v-if="provinceName != '省份'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 1,
                }"
                @click="cityChoose"
                >{{ cityName }}</span
              >
              <span
                v-if="cityName != '城市'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 2,
                }"
                @click="regionChoose"
                >{{ regionName }}</span
              >
              <span
                v-if="regionName != '区域'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 3,
                }"
                @click="wangdianChoose"
                >{{ wangdian }}</span
              >
            </div>
          </div>
        </div>

        <div class="drawer-bottom">
          <template v-if="drawerNavIndex == 0">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active':
                  item.provinceValue == provinceIndex,
              }"
              v-for="(item, index) in procinceList"
              :key="index"
              @click="provinceOne(item.provinceValue, item.provinceName)"
            >
              {{ item.provinceName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 1">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.cityValue == cityIndex,
              }"
              v-for="(item, index) in cityList"
              :key="index"
              @click="cityOne(item.cityValue, item.cityName)"
            >
              {{ item.cityName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 2">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.districtValue == regionIndex,
              }"
              v-for="(item, index) in regionList"
              :key="index"
              @click="regionOne(item.districtValue, item.districtName)"
            >
              {{ item.districtName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 3">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.id == wangdianIndex,
              }"
              v-for="(item, index) in wangdianList"
              :key="index"
              @click="wangdianOne(item.areaValue, item.name, item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </el-drawer>

    <!-- 弹出框2 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-size" v-if="infoObj.imagesUrlList">
        <div class="top">
          <div class="left">
            <img class="img-fuild" :src="infoObj.imagesUrlList[0]" alt="" />
          </div>
          <div class="right-special">
            <div class="right-top">
              <img
                src="../../assets/img/login_close.png"
                @click="drawer2 = false"
              />
            </div>
            <div class="right-bottom">
              <div class="price">
                <span>价格：￥{{ nowPrice }}</span>
              </div>
              <div class="select">
                <span>已选择：{{ sizeName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="size-item"
          v-for="(item, index) in infoObj.specificationVOList"
          :key="index"
        >
          <span class="classicName">{{ item.name }}</span>
          <template v-for="(item2, index2) in infoObj.specificationValueVOList">
            <div
              class="size-item-item"
              :key="index2"
              v-if="item.id == item2.specificationId"
            >
              <span
                :class="{
                  'border-size': true,
                  'border-size-active': item2.flag,
                }"
                @click="sizeChoose(item2, index2)"
                >{{ item2.value }}</span
              >
              <img
                v-if="item2.flag"
                src="../../assets/img/detail_choose.png"
                alt=""
              />
            </div>
          </template>
        </div>
      </div>
      <div class="col-sm-12 size-sure">
        <div class="btn" @click="toDetail">确定</div>
      </div>
    </el-drawer>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import {
  getSortApi,
  getRegion,
  getWangdian,
  specialDetail,
  getShoppingPrice,
  editWangdian,
  editShopping,
  editSortNum,
  removeSortApi,
  removeSaveShopping,
  removeManyShopping,
  removeOverShopping,
} from "../../assets/api/api.js";
export default {
  created() {
    this.getInfo();
  },
  data() {
    return {
      sortList: [],
      sizeList: [],
      totalChoose: false,
      drawer1: false,
      drawer2: false,
      provinceName: "省份",
      cityName: "城市",
      regionName: "区域",
      wangdian: "网点",
      wangdianName: [],
      provinceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      wangdianIndex: -1,
      procinceList: [],
      cityList: [],
      regionList: [],
      wangdianList: [],
      drawerNavIndex: 0,
      index: -1,
      commodityId: -1,
      infoObj: {},
      nowPrice: 0,
      sizeName: 0,
      sizeName: "",
      message: "",
      id: -1,
      isOrder: false,
      mid: -1,
      chooseNum: 0,
      totalPrice: 0,
      totalCount: 0,
      warpIndex: -1,
      startX: 0,
      moveX: 0,
      uselessCount: 0,
      comId: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getInfo() {
      this.totalCount = 0;
      let res = await getSortApi({
        userId: 1,
      });
      res = res.data.data;
      console.log(res);
      this.sortList = res;
      for (let i = 0; i < res.length; i++) {
        this.wangdianName.push(
          res[i].provinceName +
            res[i].cityName +
            res[i].districtName +
            res[i].outletsName
        );
        if (res[i].isState == 1) {
          this.totalCount++;
        } else {
          this.uselessCount++;
        }
        if (res[i].commodityType == 0) {
          this.sortList[i].quantity = 1;
        }
      }
    },
    // 地址选择
    async addressChoose(item, index) {
      this.index = index;
      console.log(item);
      this.id = item.id;
      this.drawer1 = true;
      this.provinceName = item.provinceName;
      this.cityName = item.cityName;
      this.regionName = item.districtName;
      this.wangdian = item.outletsName;
      this.provinceIndex = item.provinceValue;
      this.cityIndex = item.cityValue;
      this.regionIndex = item.districtValue;
      this.wangdianIndex = item.outletsId;
      this.comId = item.commodityId;
      this.provinceChoose();
    },
    // 选择省
    async provinceChoose() {
      this.drawerNavIndex = 0;
      let res = await getRegion({
        commodityId: this.comId,
      });
      res = res.data.data;
      this.procinceList = res;
      console.log(res);
    },
    // 选中省
    provinceOne(id, name) {
      if (id != this.provinceIndex) {
        this.provinceIndex = id;
        this.provinceName = name;
        this.cityName = "城市";
        this.cityIndex = -1;
        this.regionName = "区域";
        this.regionIndex = -1;
        this.wangdian = "网点";
        this.wangdianIndex = -1;
      }
    },
    // 选择市
    async cityChoose() {
      this.drawerNavIndex = 1;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        commodityId: this.comId,
      });
      res = res.data.data;
      this.cityList = res;
      console.log(res);
    },
    // 选中市
    cityOne(id, name) {
      if (id != this.cityIndex) {
        this.cityIndex = id;
        this.cityName = name;
        this.regionName = "区域";
        this.regionIndex = -1;
        this.wangdian = "网点";
        this.wangdianIndex = -1;
      }
    },
    // 选择区
    async regionChoose() {
      this.drawerNavIndex = 2;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
        commodityId: this.comId,
      });
      res = res.data.data;
      this.regionList = res;
      console.log(res);
    },
    // 选中区
    regionOne(id, name) {
      if (id != this.regionIndex) {
        this.regionIndex = id;
        this.regionName = name;
        this.wangdian = "网点";
        this.wangdianIndex = -1;
      }
    },
    // 选择网点
    async wangdianChoose() {
      this.drawerNavIndex = 3;
      let res = await getWangdian({
        areaValue: this.regionIndex,
        commodityId: this.comId,
      });
      res = res.data.data;
      this.wangdianList = res;
      console.log(res);
    },
    // 选中网点
    wangdianOne(id, name, index) {
      this.wangdianIndex = index;
      this.wangdian = name;
      this.wid = index;
    },
    // 确定
    async sureAddress() {
      this.drawer1 = false;
      if (this.wangdianIndex != -1) {
        if (this.provinceName == this.cityName) {
          this.wangdianName[this.index] =
            this.provinceName + this.regionName + this.wangdian;
        } else {
          this.wangdianName[this.index] =
            this.provinceName + this.cityName + this.regionName + this.wangdian;
        }
        let res = await editWangdian({
          shoppingCartId: this.id,
          outletsId: this.wid,
        });
        console.log(res);
        console.log(this.id);
        console.log(this.wid);
      }
    },

    // 规格选择
    async chooseSize(item) {
      let that = this;
      this.id = item.id;
      this.drawer2 = true;
      this.commodityId = item.commodityId;
      let res = await specialDetail({
        commodityId: this.commodityId,
      });
      res = res.data.data;
      this.infoObj = res;
      console.log(this.infoObj);
      // //////////////////////////////
      this.sizeName = "";
      let arrSpecial = [];
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          item.specificationValueList.indexOf(
            this.infoObj.specificationValueVOList[i].value
          ) != -1
        ) {
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.infoObj.specificationValueVOList[i].flag = true;
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }

      this.getPriceTwo(arrSpecial);
    },
    async getPriceTwo(arrSpecial) {
      for (let i = 0; i < this.infoObj.commodityTypeVOList.length; i++) {
        if (
          arrSpecial.join(",") ==
          this.infoObj.commodityTypeVOList[i].specificationValueIdList.join(",")
        ) {
          this.mid = this.infoObj.commodityTypeVOList[i].id;
        }
      }
      let res = await getShoppingPrice({
        id: this.commodityId,
        specificationValueIds: arrSpecial.join(","),
      });
      res = res.data;
      console.log(res);
      if (res.result == 0) {
        this.isOrder = true;
        this.nowPrice = res.data.specialOffer;
        this.prePrice = res.data.price;
      } else {
        this.isOrder = false;
        this.message = res.msg;
        this.showInfo();
      }
    },
    // 选择规格
    sizeChoose(item, index) {
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          this.infoObj.specificationValueVOList[i].specificationId ==
          item.specificationId
        ) {
          this.infoObj.specificationValueVOList[i].flag = false;
        }
      }
      this.infoObj.specificationValueVOList[index].flag = true;
      let arrSpecial = [];
      this.sizeName = "";
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (this.infoObj.specificationValueVOList[i].flag) {
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }
      console.log(arrSpecial);
      this.getPriceTwo(arrSpecial);
    },
    // 规格确定
    async toDetail() {
      if (this.isOrder) {
        let res = await editShopping({
          shoppingCartId: this.id,
          commodityTypeId: this.mid,
        });
        res = res.data;
        if (res.result == 0) {
          this.message = "规格修改成功";
          this.showInfo();
        } else {
          this.message = "规格修改失败";
          this.showInfo();
        }
        this.getInfo();
      }
      this.drawer2 = false;
    },
    // 数量减少
    async reduce(item) {
      this.id = item.id;
      if (item.quantity > 1) {
        item.quantity--;
        let res = await editSortNum({
          shoppingCartId: this.id,
          quantity: item.quantity,
        });
        this.getPrice();
      }
    },
    // 数量增加
    async add(item) {
      this.id = item.id;
      if (item.commodityType == 0) {
        this.quantity = 1;
        this.message = "已达到最大数量";
        this.showInfo();
      } else {
        if (item.quantity < item.inventory) {
          item.quantity++;
          let res = await editSortNum({
            shoppingCartId: this.id,
            quantity: item.quantity,
          });
          this.getPrice();
        } else {
          this.message = "已达到最大数量";
          this.showInfo();
        }
      }
    },
    // 全选
    chooseAll() {
      this.totalChoose = true;
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1) {
          this.sortList[i].flag = true;
        }
      }
      this.getPrice();
    },
    // 全部取消
    choosenone() {
      this.totalChoose = false;
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1) {
          this.sortList[i].flag = false;
        }
      }
      this.getPrice();
    },
    // 单个选中
    chooseOne(item) {
      item.flag = true;
      this.getPrice();
    },
    // 单个取消
    removeOne(item) {
      item.flag = false;
      this.getPrice();
    },
    // 总价格
    getPrice() {
      this.chooseNum = 0;
      this.totalPrice = 0;
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1) {
          if (this.sortList[i].flag) {
            this.chooseNum++;
            this.totalPrice +=
              this.sortList[i].specialOffer * this.sortList[i].quantity;
            if (this.chooseNum == this.totalCount) {
              this.totalChoose = true;
            } else {
              this.totalChoose = false;
            }
          }
        }
      }
      this.totalPrice = this.totalPrice +'';
      if(this.totalPrice.indexOf('.')==-1){
        this.totalPrice = this.totalPrice +'.00';
      }
    },
    start(e) {
      this.startX = e.touches[0].clientX;
    },
    end(e) {
      this.moveX = e.touches[0].clientX;
      if (this.startX - this.moveX > 50) {
        e.currentTarget.classList.add("mobile-item-rap-left");
        e.currentTarget.classList.remove("mobile-item-rap-right");
      }
      if (this.startX - this.moveX < -30) {
        e.currentTarget.classList.add("mobile-item-rap-right");
        e.currentTarget.classList.remove("mobile-item-rap-left");
      }
    },
    started(e) {
      this.startX = e.touches[0].clientX;
    },
    ended(e) {
      this.moveX = e.touches[0].clientX;
      if (this.startX - this.moveX > 50) {
        e.currentTarget.classList.add("mobile-item-rap-left-two");
        e.currentTarget.classList.remove("mobile-item-rap-right-two");
      }
      if (this.startX - this.moveX < -30) {
        e.currentTarget.classList.add("mobile-item-rap-right-two");
        e.currentTarget.classList.remove("mobile-item-rap-left-two");
      }
    },
    // 删除一个
    async deletedOne(id) {
      let res = await removeSortApi({
        shoppingCartId: id,
      });
      res = res.data;
      if (res.result == 0) {
        this.message = res.msg;
        this.showInfo();
        this.$emit("fatherSort");
      } else {
        this.message = "删除失败";
        this.showInfo();
      }
      console.log(res);
      this.getInfo();
    },
    // 移入收藏夹
    async toSave(item) {
      console.log(item);
      let res = await removeSaveShopping({
        userId: item.userId,
        commodityId: item.commodityId,
        shoppingCartId: item.id,
      });
      console.log(res);
      if (res.data.result == 0) {
        this.message = "收藏成功";
        this.showInfo();
        this.getInfo();
        this.$emit("fatherSort");
      } else {
        this.message = "收藏失败";
        this.showInfo();
      }
    },
    // 批量删除
    async deletedMany() {
      let arr = [];
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1 && this.sortList[i].flag) {
          arr.push(this.sortList[i].id);
        }
      }
      if (arr.length > 0) {
        let res = await removeManyShopping({
          shoppingCartIdList: arr.join(","),
        });
        res = res.data;
        if (res.result == 0) {
          this.message = "删除成功";
          this.showInfo();
          this.$emit("fatherSort");
        } else {
          this.message = "删除失败";
          this.showInfo();
        }
        this.getInfo();
      }
      this.$emit("fatherSort");
    },
    // 清空失效商品
    async removeUseless() {
      let res = await removeOverShopping({
        userId: 1,
      });
      res = res.data;
      console.log(res);
      if (res.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      } else {
        this.message = "删除失败";
        this.showInfo();
      }
      this.getInfo();
    },
    // 下单
    toOrder() {
      let arr = [];
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1 && this.sortList[i].flag) {
          arr.push(this.sortList[i].id);
        }
      }
      if (arr.length > 0) {
        this.$router.push({
          path: "/mobile/order",
          query: {
            id: arr.join(","),
            path: 1,
          },
        });
      } else {
        this.message = "请选择商品";
        this.showInfo();
      }
      this.$emit("fatherSort");
    },
    getZero(money,num){
      let price = money*num;
      price +='';
      if(price.indexOf(".")==-1){
        price = price + '.00'
      };
      return price;
    }
  },
};
</script>

<style lang="less" scoped>
.no-padding-left {
  padding-left: 0;
}
.mobile-sort {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .mobile-sort-top {
    position: sticky;
    top: 0;
    background-color: #fff;
    // margin-top: 1px;
    display: flex;
    font-size: 14px;
    color: #111;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    margin-bottom: 10px;
    .sort-top-left {
      img {
        position: relative;
        top: -2px;
      }
      .to-left-one {
        margin-left: 10px;
      }
    }
    .to-left-two {
      margin-left: 20px;
    }
  }
  .mobile-container {
    margin-bottom: 80px !important;
    overflow: hidden;
  }

  .mobile-for {
    width: 100%;
  }

  .mobile-item {
    background-color: #fff;
    margin-bottom: 10px;
    display: flex;
    padding: 15px 0;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    left: 0;
    .mobile-item-bigImg {
      .selWord {
        position: relative;
      }
      img {
        width: 100%;
        border: 1px solid #cccdcd;
      }
      .selNone {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(17, 17, 17, 0.4);
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .mobile-item-text {
      height: 100%;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #111;
      .one {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .two {
        font-size: 12px;
        color: #999;
        display: flex;
        justify-content: space-between;
        background-color: #fafafa;
        padding: 5px 0;
        margin: 5px 0;
        .three {
          width: 80%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 10px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .four {
        display: flex;
        justify-content: space-between;
        .four-left {
          font-weight: 500;
        }
        .four-right {
          display: flex;
          .reduce {
            height: 25px;
            width: 25px;
            border: 1px solid #cccdcd;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
            }
          }
          .number {
            width: 30px;
            height: 25px;
            color: #666;
            margin: 0 3px;
          }
        }
      }
    }
    .inventory {
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      color: #ce2829;
      img {
        margin-right: 5px;
      }
    }
    .right-options {
      position: absolute;
      height: 100%;
      right: -100px;
      display: flex;
      .options-save {
        width: 50px;
        background-color: #111;
        font-size: 12px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .options-delete {
        font-size: 12px;
        width: 50px;
        background-color: #ce2829;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right-options-one {
      right: -50px;
    }
  }
  .mobile-sort-bottom {
    position: fixed;
    width: 100%;
    height: 70px;
    bottom: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    .bottom-left {
      display: flex;
      align-items: center;
      .one {
        font-size: 14px;
        color: #999;
        margin-left: 12px;
      }
    }
    .bottom-right {
      font-size: 14px;
      display: flex;
      align-items: center;
      .two {
        font-weight: 500;
        color: #111;
      }
      .order {
        width: 80px;
        height: 40px;
        background-color: #111;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
}

.expire-container {
  width: 100%;
  margin-top: 10px;
  .expire-item {
    height: 20px;
    position: relative;
    .expire-line {
      width: 100%;
      height: 1px;
      background-color: #dddddd;
    }
    .expire-introduction {
      color: #bbb;
      font-size: 14px;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #f5f5f5;
      padding: 0 10px;
    }
  }
}

.expire-color {
  color: #ccc !important;
}

@keyframes toLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100px;
  }
}

@keyframes toright {
  0% {
    left: -100px;
  }
  100% {
    left: 0;
  }
}

@keyframes toLeft-two {
  0% {
    left: 0;
  }
  100% {
    left: -50px;
  }
}

@keyframes toRight-two {
  0% {
    left: -50px;
  }
  100% {
    left: 0;
  }
}

.mobile-item-rap-left {
  animation: toLeft 0.3s ease forwards;
}

.mobile-item-rap-right {
  animation: toright 0.3s ease forwards;
}

.mobile-item-rap-left-two {
  animation: toLeft-two 0.3s ease forwards;
}

.mobile-item-rap-right-two {
  animation: toRight-two 0.3s ease forwards;
}

////////////////////////drawer1/////////////////////////////////
.drawer-domain {
  .stricky {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .drawer-top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: #111;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #f3f3f3;
  }
  .drawer-center {
    border-bottom: 1px solid #f3f3f3;
    .drawer-center-item {
      padding: 0 15px;
      font-size: 15px;
      color: #111;
      display: flex;
      justify-content: flex-start;
      .wangdian-item {
        padding: 15px 0 12px 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 20%;
        text-align: center;
        height: 100%;
        margin-right: 20px;
      }
      .drawer-center-item-active {
        border-bottom: 3px solid #bba492;
      }
    }
  }
  .drawer-bottom {
    .drawer-bottom-item {
      padding: 15px;
      font-size: 15px;
      color: #999;
    }
    .drawer-bottom-item-active {
      color: #111;
      font-weight: 500;
    }
  }
}

/////////////////drawer2////////////////
.drawer-size {
  padding: 15px;
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;
    .left {
      flex-shrink: 0;
      width: 23% !important;
      img {
        width: 100%;
        border: 1px solid #111;
      }
    }
    .right-special {
      position: relative;
      flex-grow: 1;
      font-size: 15px;
      margin-left: 15px;
      .right-top {
        img {
          position: absolute;
          right: 0;
          width: 24px;
          height: 24px;
        }
      }
      .right-bottom {
        position: absolute;
        bottom: 0;
        .price {
          color: #bba492;
        }
        .select {
          color: #333;
        }
      }
    }
  }
  .size-item {
    padding: 15px 0 0 0;
    border-bottom: 1px solid #f3f3f3;
    .classicName {
      display: block;
      font-size: 15px;
      color: #666;
      margin-bottom: 20px;
    }
    .size-item-item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      position: relative;
      .border-size {
        border: 1px solid #cccdcd;
        padding: 5px 10px;
      }
      img {
        position: absolute;
        right: 0;
        bottom: -5px;
      }
      .border-size-active {
        border: 1px solid #bba492;
      }
    }
  }
  .drawer-qunatity {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #666;
  }
}

.size-sure {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  height: 70px;
  .btn {
    width: 100%;
    height: 40px;
    background-color: #111;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>