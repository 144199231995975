<template>
  <div class="info container">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>申请开票</span>
    </div>
    <div class="col-sm-12">
      <div class="price">
        <span>合计开票</span>
        <span>￥{{ price }}</span>
      </div>
    </div>
    <div class="col-sm-12">
      <!-- 发票类型 -->
      <div class="invoice-info">
        <div class="invoice-info-item">
          <span>发票类型</span>
          <div class="invoice-info-right" @click="drawer1 = true">
            <input
              type="text"
              disabled
              placeholder="111"
              :value="invoiceType[typeIndex].text"
            />
            <img
              class="right-img"
              src="../../assets/img/to-right-arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 抬头类型 -->
      <div class="invoice-info">
        <div class="invoice-info-item">
          <span>抬头类型</span>
          <div class="invoice-info-right">
            <div class="invoice-info-right-item" v-if="personHead == 0">
              <img
                class="right-img"
                src="../../assets/img/ic_gou_s.png"
                v-if="headIndex == 1"
              />
              <img
                class="right-img"
                src="../../assets/img/ic_gou_n.png"
                v-else
                @click="headChoose"
              />
              <span>个人</span>
            </div>
            <div class="invoice-info-right-item">
              <img
                class="right-img"
                src="../../assets/img/ic_gou_s.png"
                v-if="headIndex == 0"
              />
              <img
                class="right-img"
                src="../../assets/img/ic_gou_n.png"
                v-else
                @click="headChoose"
              />
              <span>企业</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 发票抬头 -->
      <div class="invoice-info">
        <div class="invoice-info-item">
          <span>发票抬头</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.head"
              placeholder="填写抬头名称"
            />
          </div>
        </div>
      </div>
      <!-- 公司税号 -->
      <div class="invoice-info" v-if="headIndex == 0">
        <div class="invoice-info-item">
          <span>公司税号</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.tax"
              placeholder="公司纳税人识别号"
            />
          </div>
        </div>
      </div>
      <!-- 注册地址 -->
      <div class="invoice-info" v-if="headIndex == 0">
        <div class="invoice-info-item">
          <span>注册地址</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.address"
              placeholder="选填"
            />
          </div>
        </div>
      </div>
      <!-- 公司电话 -->
      <div class="invoice-info" v-if="headIndex == 0">
        <div class="invoice-info-item">
          <span>公司电话</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.phone"
              placeholder="选填"
            />
          </div>
        </div>
      </div>
      <!-- 开户银行 -->
      <div class="invoice-info" v-if="headIndex == 0">
        <div class="invoice-info-item">
          <span>开户银行</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.bank"
              placeholder="选填"
            />
          </div>
        </div>
      </div>
      <!-- 银行账号 -->
      <div class="invoice-info" v-if="headIndex == 0">
        <div class="invoice-info-item">
          <span>银行账号</span>
          <div class="invoice-info-right">
            <input
              type="text"
              v-model.trim="invoiceObj.bankAccount"
              placeholder="选填"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="col-sm-12 person-info" v-if="typeIndex == 1 || typeIndex == 3">
      <!-- 收件员 -->
      <div class="invoice-info" @click="getAddress">
        <div class="invoice-info-item">
          <span>收件员</span>
          <div class="invoice-info-right">
            <input
              type="text"
              disabled
              placeholder="填写收件人"
              :value="oneAddress.username"
            />
            <img
              class="right-img"
              src="../../assets/img/to-right-arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 联系电话 -->
      <div class="invoice-info">
        <div class="invoice-info-item">
          <span>联系电话</span>
          <div class="invoice-info-right">
            <input
              type="text"
              disabled
              placeholder="填写联系电话"
              :value="oneAddress.phone"
            />
          </div>
        </div>
      </div>
      <!-- 收件地址 -->
      <div class="invoice-info">
        <div class="invoice-info-item textarea-span">
          <span>收件地址</span>
          <div class="invoice-info-right">
            <textarea
              disabled
              placeholder="填写收件地址"
              :value="
                oneAddress.provinceName +
                oneAddress.cityName +
                oneAddress.districtName +
                oneAddress.addressDetailed
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框1 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-container">
        <div class="drawer-top">
          <span>选择发票类型</span>
          <img src="../../assets/img/delete_n.png" @click="drawer1 = false" />
        </div>
        <div class="drawer-container">
          <div
            class="drawer-container-item"
            v-for="(item, index) in invoiceType"
            :key="index"
            @click="typeChooseOne(index)"
          >
            <span>{{ item.text }}</span>
            <img
              src="../../assets/img/ic_gou_s.png"
              v-if="typeIndex == index"
            />
            <img src="../../assets/img/ic_gou_n.png" v-else />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 弹出框2 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="address">
        <div class="address-top">
          <span>选择地址</span>
          <img src="../../assets/img/delete_n.png" @click="drawer2 = false" />
        </div>
        <div class="address-container">
          <div
            class="address-container-item"
            v-for="(item, index) in addressList"
            :key="index"
            @click="chooseAddressIndex(index)"
          >
            <div class="address-container-item-left">
              <img
                src="../../assets/img/ic_gou_s.png"
                v-if="index == addressIndex"
                alt=""
              />
              <img src="../../assets/img/ic_gou_n.png" v-else alt="" />
            </div>
            <div class="address-container-item-right">
              <span>{{ item.username }}</span>
              <span class="default" v-if="item.isDefault == 1">默认</span>
              <div>{{ item.phone }}</div>
              <div class="detailAddress">
                {{
                  item.provinceName +
                  item.cityName +
                  item.districtName +
                  item.addressDetailed
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="sure-btn" @click="sureAddress">确定</div>
      </div>
    </el-drawer>
    <!-- 提交 -->
    <div class="provide col-sm-12">
      <div class="btn" @click="addInvoice">提交申请</div>
    </div>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import {
  getOrderAddress,
  invoiceAdd,
  getInvoicePrice,
  getInvoiceApi,
} from "../../assets/api/api.js";
export default {
  created() {
    this.numList = this.$route.query.number;
    this.getPrice();
    this.getInfo();
  },
  data() {
    return {
      invoiceType: [
        {
          id: 0,
          text: "增值税电子发票",
        },
        {
          id: 1,
          text: "增值税纸质发票",
        },
        {
          id: 2,
          text: "普通电子发票",
        },
        {
          id: 3,
          text: "普通纸质发票",
        },
      ],
      invoiceObj: {
        head: "",
        tax: "",
        address: "",
        phone: "",
        bank: "",
        bankAccount: "",
      },
      drawer1: false,
      drawer2: false,
      typeIndex: 0,
      headIndex: 0,
      addressList: [],
      addressIndex: 0,
      oneAddress: {
        id: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        addressDetailed: "",
      },
      message: "",
      numList: "",
      price: 0,
      IType: 1,
      MType: 1,
      personHead: 1,
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    toPreview() {
      this.$router.go(-1);
    },
    // 发票默认信息
    getInfo() {
      getInvoiceApi().then((res) => {
        console.log(res);
        if (res.data.result == 0) {
          res = res.data.data;
          this.invoiceObj.head = res.invoiceHeader;
          this.invoiceObj.tax = res.taxNumber;
          this.invoiceObj.address = res.companyAddress;
          this.invoiceObj.phone = res.companyPhone;
          this.invoiceObj.bank = res.bankName;
          this.invoiceObj.bankAccount = res.bankAccount;
        }
      });
    },
    // 获得价格
    async getPrice() {
      let res = await getInvoicePrice({
        numberList: this.numList,
      });
      res = res.data.data;
      this.price = res;
      console.log(res);
    },
    async getAddress() {
      this.drawer2 = true;
      let res = await getOrderAddress({
        userId: 1,
      });
      res = res.data.data;
      this.addressList = res;
      console.log(res);
      if (this.addressList.length == 0) {
        this.$router.push("/mine/address");
      }
    },
    // 选择地址
    chooseAddressIndex(index) {
      this.addressIndex = index;
    },
    // 确定
    sureAddress() {
      this.oneAddress = this.addressList[this.addressIndex];
      console.log(this.oneAddress);
      this.drawer2 = false;
    },
    // 提交申请
    addInvoice() {
      // if (this.typeIndex == 0) {
      //   if (this.headIndex == 0) {
      //     if (this.invoiceObj.head == "") {
      //       this.message = "请填写发票抬头";
      //       this.showInfo();
      //     } else if (this.invoiceObj.tax == "") {
      //       this.message = "请填写公司税号";
      //       this.showInfo();
      //     } else {
      //       this.invoiceAdded();
      //     }
      //   } else {
      //     if (this.invoiceObj.head == "") {
      //       this.message = "请填写发票抬头";
      //       this.showInfo();
      //     } else {
      //       this.invoiceAdded();
      //     }
      //   }
      // } else {
      //   if (this.headIndex == 0) {
      //     if (this.invoiceObj.head == "") {
      //       this.message = "请填写发票抬头";
      //       this.showInfo();
      //     } else if (this.invoiceObj.tax == "") {
      //       this.message = "请填写税号";
      //       this.showInfo();
      //     } else if (this.oneAddress.id == "") {
      //       this.message = "请选择地址";
      //       this.showInfo();
      //     } else {
      //       this.invoiceAdded();
      //     }
      //   } else {
      //     if (this.invoiceObj.head == "") {
      //       this.message = "请输入开票抬头";
      //       this.showInfo();
      //     } else if (this.oneAddress.id == "") {
      //       this.message = "请选择地址";
      //       this.showInfo();
      //     } else {
      //       this.invoiceAdded();
      //     }
      //   }
      // }
      if (this.typeIndex == 0) {
        if (this.invoiceObj.head == "") {
          this.message = "请填写发票抬头";
          this.showInfo();
        } else if (this.invoiceObj.tax == "") {
          this.message = "请填写公司税号";
          this.showInfo();
        } else {
          this.invoiceAdded();
        }
      } else if (this.typeIndex == 1) {
        if (this.invoiceObj.head == "") {
          this.message = "请填写发票抬头";
          this.showInfo();
        } else if (this.invoiceObj.tax == "") {
          this.message = "请填写税号";
          this.showInfo();
        } else if (this.oneAddress.id == "") {
          this.message = "请选择地址";
          this.showInfo();
        } else {
          this.invoiceAdded();
        }
      } else if (this.typeIndex == 2) {
        if (this.headIndex == 0) {
          if (this.invoiceObj.head == "") {
            this.message = "请填写发票抬头";
            this.showInfo();
          } else if (this.invoiceObj.tax == "") {
            this.message = "请填写税号";
            this.showInfo();
          } else {
            this.invoiceAdded();
          }
        } else {
          if (this.invoiceObj.head == "") {
            this.message = "请输入开票抬头";
            this.showInfo();
          } else {
            this.invoiceAdded();
          }
        }
      } else if (this.typeIndex == 3) {
        if (this.headIndex == 0) {
          if (this.invoiceObj.head == "") {
            this.message = "请填写发票抬头";
            this.showInfo();
          } else if (this.invoiceObj.tax == "") {
            this.message = "请填写税号";
            this.showInfo();
          } else if (this.oneAddress.id == "") {
            this.message = "请选择地址";
            this.showInfo();
          } else {
            this.invoiceAdded();
          }
        } else {
          if (this.invoiceObj.head == "") {
            this.message = "请输入开票抬头";
            this.showInfo();
          }else if (this.oneAddress.id == "") {
            this.message = "请选择地址";
            this.showInfo();
          } else {
            this.invoiceAdded();
          }
        }
      }
    },
    async invoiceAdded() {
      console.log("...");
      let res = await invoiceAdd({
        isType: this.IType,
        type: this.MType,
        invoiceHeader: this.invoiceObj.head,
        taxNumber: this.invoiceObj.tax,
        bankName: this.invoiceObj.bank,
        bankAccount: this.invoiceObj.bankAccount,
        companyAddress: this.invoiceObj.address,
        companyPhone: this.invoiceObj.phone,
        numberList: this.numList.split(","),
        userAddressId: this.oneAddress.id,
        userId: 1,
      });
      if(res.data.result == 0){
        this.message='提交成功';
        this.showInfo();
        this.$router.push('/mine/invoice-manage');
      }else{
        this.message='提交失败';
        this.showInfo();
      }
    },
    // 清空
    clearInfo() {
      console.log("...");
      this.invoiceObj.head = "";
      this.invoiceObj.tax = "";
      this.invoiceObj.address = "";
      this.invoiceObj.phone = "";
      this.invoiceObj.bank = "";
      this.invoiceObj.bankAccount = "";

      this.oneAddress = {
        id: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        addressDetailed: "",
      };
    },
    typeChooseOne(index) {
      if (this.typeIndex != index) {
        this.clearInfo();
        this.typeIndex = index;
        this.getInfo();
        this.drawer1 = false;
      }
      if (this.typeIndex == 0) {
        this.IType = 1;
        this.MType = 1;
        this.personHead = 1;
      } else if (this.typeIndex == 1) {
        this.IType = 0;
        this.MType = 1;
        this.personHead = 1;
      } else if (this.typeIndex == 2) {
        this.IType = 1;
        this.MType = 0;
        this.personHead = 0;
      } else if (this.typeIndex == 3) {
        this.IType = 0;
        this.MType = 0;
        this.personHead = 0;
      }
    },
    headChoose() {
      if (this.headIndex == 0) {
        this.headIndex = 1;
        this.invoiceObj.head = "";
        this.invoiceObj.tax = "";
        this.invoiceObj.address = "";
        this.invoiceObj.phone = "";
        this.invoiceObj.bank = "";
        this.invoiceObj.bankAccount = "";
      } else {
        this.headIndex = 0;
        this.getInfo();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .price {
    margin-top: 20px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    font-size: 15px;
    color: #111;
    margin-bottom: 10px;
  }
  .invoice-info {
    background-color: #fff;
    padding: 20px;
    .invoice-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      .invoice-info-right {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .invoice-info-right-item {
          color: #666;
          margin-left: 20px;
          img {
            margin-right: 5px;
          }
        }
        input {
          width: 100%;
          border: 0;
          outline: none;
          text-align: right;
          color: #666;
        }
        input::placeholder {
          color: #ccc;
        }
        input:disabled {
          background-color: #fff;
        }
        textarea {
          padding-left: 10px;
          width: 100%;
          border: 0;
          outline: none;
          text-align: right;
          color: #666;
          display: flex;
        }
        textarea::placeholder {
          color: #ccc;
        }
        textarea:disabled {
          background-color: #fff;
        }
        .right-img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.textarea-span {
  align-items: flex-start !important;
}

.drawer-container {
  padding: 15px;
  .drawer-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #111;
    font-weight: 500;
    padding-bottom: 15px;
    img {
      position: absolute;
      right: 0;
    }
  }
  .drawer-container {
    .drawer-container-item {
      padding: 20px 15px;
      background-color: #fff;
      font-size: 17px;
      color: #111;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.bottom-margin {
  margin-bottom: 100px;
}
/deep/.el-drawer {
  background-color: #f5f5f5;
}

.person-info {
  margin-top: 10px;
}

.address {
  padding: 15px;
  height: 100%;
  .address-top {
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #111;
    font-weight: 500;
    padding-bottom: 15px;
    img {
      position: absolute;
      right: 0;
    }
  }
  .address-container {
    .address-container-item {
      background-color: #fff;
      font-size: 14px;
      padding: 10px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .address-container-item-left {
        margin-right: 20px;
      }
      .default {
        font-size: 12px;
        background-color: #111;
        color: #fff;
        margin-left: 5px;
        padding: 0 3px 0 5px;
      }
    }
  }
  .sure-btn {
    position: sticky;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 50px;
    background-color: #111;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.provide {
  margin-top: 40px;
  position: sticky;
  bottom: 0;
  height: 76px;
  background-color: #f5f5f5;
  .btn {
    background-color: #111;
    color: #fff;
    height: 42px;
    width: 100%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>