<template>
  <div
    :class="{ classic: true, classic_mobile: isMobile, pcClassic: !isMobile }"
    @click="selectone = false"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="classic-top">
      <div class="post-img">
        <img class="img-fluid" :src="banner" alt="" />
      </div>
    </div>
    <!-- 主体部分 -->
    <div class="container classic-container">
      <!-- 内容主题 -->
      <div class="row flex-classic">
        <div
          :class="{
            'col-lg-3': true,
            'col-sm-6': true,
            pointer: true,
            'no-padding-right': index % 2 == 0,
            'no-padding-left': index % 2 != 0,
          }"
          v-for="(item, index) in shopList"
          :key="index"
          @click="toClassicDetail(item.id, item.type)"
        >
          <div class="discount-item">
            <div class="sanjiao"></div>
            <img class="search" src="../../assets/img/search.png" alt="" />
            <span class="three">{{ item.name }}</span>
            <img class="discount-item-img img-fuild" :src="item.image" alt="" />
            <span class="one">{{ item.years }} {{ item.artistName }}</span>
            <span class="two" v-if="item.isSell == 1"
              >￥{{ item.priceMin }}</span
            >
            <span class="two" v-if="item.isSell == 0">咨询客服</span>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>


<script>
import { getClassicPage, getAdvertising } from "../../assets/api/api.js";
export default {
  created() {
    this.getData();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      chooseNav: 0,
      selectone: false,
      containerSelect: 0,
      num: 130,
      navList: [],
      itemsList: [],
      shopList: [],
      navChildrenList: [],
      itemChildrenList: [],
      allBtn: true,
      text: "全部",
      pageSize: 12,
      status: 0,
      total: 0,
      tid: null,
      currentPage: 1,
      twoId: 0,
      oneId: 0,
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
      banner: "",
    };
  },
  methods: {
    
    // axios
    async getData() {
      this.currentPage = 1;
      let res = await getAdvertising({
        id: this.$route.query.id,
      });
      console.log(res);
      res = res.data.data;
      this.banner = res.banner;
      this.shopList = this.shopList.concat(res.commodityVOList);
    },
    // 去详情
    toClassicDetail(id, type) {
      if (type == 0) {
        if (this.isMobile) {
          this.$router.push({
            path: "/mobile/classic-detail",
            query: {
              id: id,
            },
          });
        } else {
          this.$router.push({
            path: "/classic-detail",
            query: {
              id: id,
            },
          });
        }
      }else if (type == 1) {
        if (this.isMobile) {
          this.$router.push({
            path: "/mobile/luxery-detail",
            query: {
              id: id,
            },
          });
        } else {
          this.$router.push({
            path: "/luxery-detail",
            query: {
              id: id,
            },
          });
        }
      }
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.shopList.length < this.total) {
          this.loading = false;
          this.getData();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>


<style lang="less" scoped>
.row {
  margin: 0;
}
.classic {
}
.classic_mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.post-img {
  width: 100%;
  img {
    width: 100%;
    max-height: 580px;
  }
}

.pcClassic {
  margin-bottom: 80px;
}

.classic-top {
  position: relative;
}
.classic-nav {
  width: 100%;
  height: 46px;
  background: rgba(17, 17, 17, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  .my-nav {
    height: 100%;
    .nav-item {
      font-size: 16px;
      display: inline-block;
      height: 100%;
      line-height: 46px;
      padding: 0 20px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }
    .back-white {
      background-color: #fff;
      color: #111;
      font-weight: bold;
    }
  }
}

.classic-container {
  margin-top: 30px;
  .classic-container-item {
    font-size: 16px;
    width: 128px;
    height: 42px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
  }
  .triangle {
    width: 10px;
    height: 10px;
  }
  .position-item {
    width: 406px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 52px;
    left: 0;
    padding: 15px 15px 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 999;
    .position-item-item {
      width: 184px;
      height: 34px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      .circle-span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-left: 10px;
      }
      .circle-choosed {
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
      .position-word {
        margin-left: 15px;
      }
    }
    .sure-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      background-color: #f5f5f5;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 30px;
        background: #111111;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .position-item::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    left: 10px;
    top: -20px;
  }
}
.none {
  display: none !important;
}

.classic-title {
  font-size: 40px;
  font-weight: 500;
  color: #111;
  text-align: center;
  margin-top: 100px;
}

.classic-container-nav {
  margin-top: 40px;
  span {
    display: inline-block;
    margin-right: 30px;
    color: #666;
    height: 40px;
    cursor: pointer;
    font-size: 16px;
  }
  .container-nav-select {
    color: #111;
    border-bottom: 2px solid #111;
    font-weight: 500;
    cursor: pointer;
  }
}

.classic-container {
  .line {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin-bottom: 60px;
  }
}

///////////////////////////////////////////////////////////
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .classic-container {
    margin-top: 20px;
    .classic-container-item {
      margin-right: 0;
      width: 23%;
      margin: 0 3px;
    }
  }
  .mobile-nav {
    width: 100%;
    background-color: #f5f5f5;
    .mobile-nav-item {
      display: inline-block;
      width: 50%;
      padding: 20px 40px;
      color: #999;
      font-size: 14px;
      img {
        height: 18px;
        width: 18px;
        margin-left: 10px;
        margin-top: -5px;
      }
      .colorChange {
        color: #111;
      }
    }
    .mobile-nav-bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
      div {
        width: 40%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .one {
        border: 1px solid #111;
      }
      .two {
        background-color: #111;
        color: #fff;
      }
    }
  }
  .tag-container {
    padding-left: 20px;
    .el-tag {
      margin-bottom: 10px;
    }
  }
  .classic-title {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    span {
      border-bottom: 2px solid #111;
      padding-bottom: 10px;
    }
  }
  .classic-container-nav {
    padding-left: 20px;
  }
  .no-padding-right {
    padding-right: 5px;
  }
  .no-padding-left {
    padding-left: 5px;
  }
  .line {
    margin-bottom: 30px !important;
  }
}

.discount-poster {
  width: 100%;
  img {
    width: 100%;
    height: 580px;
  }
}

.discount-body {
  margin-top: 80px;
}

.discount-item:hover .sanjiao {
  opacity: 1;
}

.discount-item:hover .search {
  opacity: 1;
}

.discount-item:hover {
  box-shadow: 0px 4px 14px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.discount-item {
  color: #111;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  position: relative;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  .sanjiao {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    border: 45px solid transparent;
    border-top-color: #bba492;
    border-left-color: #bba492;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .search {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .discount-item-img {
    max-width: 294px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .one {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
  .two {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .three {
    font-size: 20px;
    color: #111;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 0 10px;
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

.tag-container {
  margin-top: 30px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
  margin-top: 20px;
  border: 0;
  cursor: pointer;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
  background-color: #111;
  border: 0;
}

/deep/ .el-tag .el-tag__close:hover {
  color: #fff;
  background-color: #111;
}

.clear-tag {
  background-color: #eee;
  color: #666;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

@media (min-width: 300px) and (max-width: 992px) {
  .discount-item {
    margin-bottom: 0;
    .three{
      font-size: 16px;
    }
    .two{
      font-size: 14px;
    }
  }


}
</style>