<template>
  <div class="container order">
    <div class="address">
      <div class="top">
        <span class="get-info">收货信息</span>
      </div>
      <div class="no-address" v-if="addressList.length == 0 || isAddAddress">
        <div class="no-address-left">
          <!-- 地区 -->
          <div class="one">
            <span>所在地区：</span>
            <!-- 省 -->
            <select
              :class="{ 'order-select': true, nine: proviceIndex == -1 }"
              v-model="proviceIndex"
              @click="chooseProvince"
            >
              <option value="-1" disabled>请选择</option>
              <option
                v-for="(item, index) in provinceList"
                :key="index"
                :value="item.provinceValue"
              >
                {{ item.provinceName }}
              </option>
            </select>
            <!-- 市 -->
            <select
              :class="{ 'order-select': true, nine: this.cityIndex == -1 }"
              @click="chooseCity"
              v-model="cityIndex"
            >
              <option value="-1" disabled>请选择</option>
              <option
                v-for="(item, index) in cityList"
                :key="index"
                :value="item.cityValue"
              >
                {{ item.cityName }}
              </option>
            </select>
            <!-- 区 -->
            <select
              :class="{ 'order-select': true, nine: this.regionIndex == -1 }"
              @click="chooseRegion"
              v-model="regionIndex"
            >
              <option value="-1" disabled>请选择</option>
              <option
                v-for="(item, index) in regionList"
                :key="index"
                :value="item.districtValue"
              >
                {{ item.districtName }}
              </option>
            </select>
          </div>
          <!-- 详细地址 -->
          <div class="two">
            <span>详细地址：</span>
            <textarea
              class="detail_address"
              type="text"
              placeholder="详细地址，街道、门牌号"
              v-model="detailAddress"
            />
          </div>
          <!-- 详细地址 -->
          <div class="three">
            <div class="three-left">
              <span>收货人：</span>
              <input
                type="text"
                placeholder="输入收货人姓名"
                v-model="humanName"
              />
            </div>
            <div class="three-right">
              <span>手机号码：</span>
              <input
                type="text"
                placeholder="输入收货人手机号码"
                v-model="phone"
              />
            </div>
          </div>
        </div>

        <div class="no-address-right">
          <div class="top">
            <img
              class="checkbox-img"
              src="../../assets/img/icon_sel_s.png"
              alt=""
              v-if="totalChoose"
              @click="totalChoose = false"
            />
            <img
              class="checkbox-img"
              src="../../assets/img/icon_sel_n.png"
              alt=""
              v-else
              @click="totalChoose = true"
            />
            <span style="margin-left: 10px">设为默认</span>
          </div>
          <div class="bottom">
            <div class="save pointer" @click="saveAddress">保存地址</div>
            <div class="save cancel pointer" @click="isAddAddress = false">
              取消
            </div>
          </div>
        </div>
      </div>
      <div class="has-address" v-else>
        <div class="has-address-left">
          <div class="top-address">
            <img src="../../assets/img/default_address.png" alt="" />
            <span class="deafult-address" v-if="oneAddress.isDefault == 1"
              >默认地址</span
            >
            <button
              class="modift-btn"
              @click="editAddress(oneAddress.id)"
              style="cursor: pointer"
            >
              修改
            </button>
          </div>
          <div class="center">
            <span class="one">收货人:</span>
            <span>{{ oneAddress.username }}</span>
          </div>
          <div class="center">
            <span class="one">联系方式:</span>
            <span>{{ oneAddress.phone }}</span>
          </div>
          <div class="center">
            <span class="one">收货地址：</span>
            <span>{{
              oneAddress.provinceName +
              oneAddress.cityName +
              oneAddress.districtName +
              oneAddress.addressDetailed
            }}</span>
          </div>
        </div>
        <div class="has-address-right">
          <span @click="centerDialogVisible = true" style="cursor: pointer"
            >地址切换</span
          >
          <button @click="addAnotherAddress" style="cursor: pointer">
            新建地址
          </button>
        </div>
        <!-- 选择地址对话框 -->
        <div class="has-address-dialog">
          <el-dialog
            title="选择地址"
            :visible.sync="centerDialogVisible"
            center
          >
            <!-- <span>需要注意的是内容是默认不居中的</span> -->
            <div
              v-for="(item, index) in addressList"
              :key="index"
              @click="chooseOneAddress(index)"
              :class="{
                'address-item': true,
                'active-address-item': chooseAddress == index,
              }"
            >
              <div class="address-item-item">
                <span class="address-item-item-one">收货人：</span>
                <span>{{ item.username }}</span>
              </div>
              <div class="address-item-item">
                <span class="address-item-item-one">联系方式：</span>
                <span>{{ item.phone }}</span>
              </div>
              <div class="address-item-item">
                <span class="address-item-item-one">收货地址：</span>
                <span>{{
                  item.provinceName +
                  item.cityName +
                  item.districtName +
                  item.addressDetailed
                }}</span>
              </div>
              <div class="icon-true" v-if="chooseAddress == index">
                <img src="../../assets/img/address_choose.png" alt="" />
              </div>
              <div class="default-address-span" v-if="item.isDefault == 1">
                默认地址
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <button class="sure" @click="addressChange">确 定</button>
              <button class="cancel" @click="centerDialogVisible = false">
                取 消
              </button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>

    <div class="address goods_info" v-if="commodityList.length > 0">
      <div class="goods_info_all">
        <div class="one">商品信息</div>
        <div class="four">数量</div>
        <div class="four">单价</div>
        <div class="four">小计</div>
        <div class="five">网点</div>
      </div>

      <div
        class="goods_info_all goods-info-item"
        v-for="(item, index) in commodityList"
        :key="index"
      >
        <div class="one">
          <img class="goods-big-img" :src="item.image" alt="" />
          <div class="goods-info-detail">
            <span class="goods-one">{{ item.commodityName }}</span>
            <span class="goods-two"
              ><span
                v-for="(item2, index2) in item.specificationValueList"
                :key="index2"
                >{{ item2 }}</span
              ></span
            >
          </div>
        </div>
        <div class="four">{{ item.quantity }}</div>
        <div class="four">￥{{ item.specialOffer }}</div>
        <div class="four">
          ￥{{ getZero(item.specialOffer, item.quantity) }}
        </div>
        <div class="five">
          {{
            item.provinceName +
            item.cityName +
            item.districtName +
            item.outletsName
          }}
        </div>
      </div>
    </div>

    <div class="to-pay">
      <div class="to-pay-left">
        <span>支付方式：</span>
        <div class="zhifu">
          <img src="../../assets/img/circle_y.png" alt="" v-if="payAli" />
          <img
            src="../../assets/img/circle_n.png"
            alt=""
            v-else
            @click="aliTrue"
          />
          <img class="one" src="../../assets/img/zhifubao.png" alt="" />
          <span class="one">支付宝</span>
        </div>
        <div class="zhifu">
          <img src="../../assets/img/circle_y.png" alt="" v-if="payWeChart" />
          <img
            src="../../assets/img/circle_n.png"
            alt=""
            v-else
            @click="wxTrue"
          />
          <img class="one" src="../../assets/img/wechart.png" alt="" />
          <span class="one">微信</span>
        </div>
      </div>
      <div class="to-pay-right">
        <div>
          <span class="right-one">商品合计：</span>
          <span>￥{{ totalPrice }}</span>
        </div>
        <div class="poster">
          <span class="right-one">邮费：</span>
          <span>￥0.00</span>
        </div>

        <div class="pay-line"></div>

        <div class="pay-main">
          <span class="right-one">应付总额：</span>
          <span class="pay-price">￥{{ totalPrice }}</span>
        </div>
        <div class="to-pay-div">
          <div class="left">
            <img
              class="checkbox-img"
              src="../../assets/img/icon_sel_s.png"
              alt=""
              v-if="isRead"
              @click="isRead = false"
            />
            <img
              class="checkbox-img"
              src="../../assets/img/icon_sel_n.png"
              alt=""
              v-else
              @click="isRead = true"
            />
            <span class="left-one">我已阅读</span>
            <span
              class="left-two pinter"
              @click="shoppingKnow"
              style="cursor: pointer"
              >《购物须知》</span
            >
          </div>
          <div class="right" @click="payMoney" style="cursor: pointer">
            去付款
          </div>
        </div>
      </div>
    </div>
    <div id="myForm"></div>
    <div class="dialog">
      <el-dialog title="购物须知" :visible.sync="centerDialogVisible2" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->

        <div class="read">
          <el-scrollbar>
            <div class="read-item" v-html="notes"></div>
          </el-scrollbar>
        </div>

        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="centerDialogVisible2 = false">
            确 定
          </button>
          <button class="cancel" @click="centerDialogVisible2 = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>
    <div class="message" id="message">{{ message }}</div>
    <div class="scanPay" v-show="isshowScan">
      <img
        class="closeIMg"
        src="../../assets/img/ic_menu_close.png"
        alt=""
        @click="isshowScan = false"
      />
      <span>扫码付款</span>
      <div id="qrcode" ref="qrcode"></div>
      <div class="isPay" @click="seeStatus">如果已付款，请点击</div>
    </div>
  </div>
</template>



<script>
import {
  getOrderAddress,
  getAddress,
  addAddress,
  editAddressApi,
  getOrderThing,
  getOrderUuid,
  shoppingNotes,
  payApi,
  payAli,
  payPcWX,
  wxResult,
} from "../../assets/api/api.js";
import { resultPhone } from "../../uitils/check.js";
import QRCode from "qrcodejs2";
export default {
  created() {
    let id = this.$route.query.id;
    if (this.$route.query.path == 1) {
      this.status = 1;
      this.getOrderList(id);
    } else {
      this.status = 0;
      this.getOrderList2(id);
    }
    console.log(this.$route);
  },
  data() {
    return {
      commodityList: [],
      totalChoose: false,
      payAli: true,
      payWeChart: false,
      isRead: false,
      addressList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      centerDialogVisible: false,
      centerDialogVisible2: false,
      chooseAddress: 0,
      proviceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      detailAddress: "",
      humanName: "",
      phone: "",
      message: "",
      oneAddress: {},
      isAddAddress: false,
      isEdit: false,
      editId: -1,
      totalPrice: 0,
      notes: "",
      status: 0,
      isshowScan: false,
      orderSearchId: "",
    };
  },
  methods: {
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getOrderList2(id) {
      this.getTotalAddress();
      let res = await getOrderUuid({
        uuid: id,
      });
      if (res.data.result == 0) {
        res = res.data.data;
        this.commodityList.push(res);
        console.log(res);
        for (let i = 0; i < this.commodityList.length; i++) {
          this.totalPrice +=
            this.commodityList[i].quantity * this.commodityList[i].specialOffer;
        }
        this.totalPrice += "";
        if (this.totalPrice.indexOf(".") == -1) {
          this.totalPrice = this.totalPrice + ".00";
        }
      } else {
        this.message = res.data.msg;
        this.showInfo();
        this.$router.go(-1);
      }
    },
    async getOrderList(id) {
      this.getTotalAddress();
      // 拿商品
      let res1 = await getOrderThing({
        idList: id,
      });
      if (res1.data.result == 0) {
        res1 = res1.data.data;
        console.log(res1);
        this.commodityList = res1;
        for (let i = 0; i < this.commodityList.length; i++) {
          this.totalPrice +=
            this.commodityList[i].quantity * this.commodityList[i].specialOffer;
        }
        this.totalPrice += "";
        if (this.totalPrice.indexOf(".") == -1) {
          this.totalPrice = this.totalPrice + ".00";
        }
      } else {
        this.message = res1.data.msg;
        this.showInfo();
        this.$router.go(-1);
      }
    },
    async getTotalAddress() {
      let res = await getOrderAddress({ userId: 1 });
      res = res.data.data;
      this.addressList = res;
      if (!this.isEdit) {
        this.oneAddress = this.addressList[0];
        console.log(this.oneAddress);
      }
    },
    chooseOneAddress(index) {
      this.chooseAddress = index;
    },
    // 选择省
    async chooseProvince() {
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
      console.log(res);
    },

    // 选择市
    async chooseCity() {
      console.log(this.proviceIndex);
      // if (this.proviceIndex != -1) {
      let res = await getAddress({ provinceValue: this.proviceIndex });
      res = res.data.data;
      this.cityList = res;
      console.log(res);
      // }
    },

    // 选择区
    async chooseRegion() {
      let res = await getAddress({
        provinceValue: this.proviceIndex,
        cityValue: this.cityIndex,
      });
      res = res.data.data;
      this.regionList = res;
      console.log(this.regionList);
    },

    // 保存地址
    async saveAddress() {
      console.log(this.isEdit);
      if (this.isEdit) {
        if (this.regionIndex == -1) {
          this.message = "请选择地址";
          this.showInfo();
        } else if (this.detailAddress == "") {
          this.message = "请输入详细地址";
          this.showInfo();
        } else if (this.humanName == "") {
          this.message = "请填写收货人";
          this.showInfo();
        } else if (!resultPhone(this.phone)) {
          this.message = "请填写正确的手机号码";
          this.showInfo();
        } else {
          let obj = {
            addressDetailed: this.detailAddress,
            areaValue: this.regionIndex,
            id: this.oneAddress.id,
            isDefault: this.totalChoose ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.humanName,
          };
          let res = await editAddressApi(obj);
          console.log(res);
          if (res.data.result == 0) {
            this.message = "编辑成功";
            this.showInfo();
            this.isAddAddress = false;
          } else {
            this.message = "编辑失败";
            this.showInfo();
          }
        }
      } else {
        if (this.regionIndex == -1) {
          this.message = "请选择地址";
          this.showInfo();
        } else if (this.detailAddress == "") {
          this.message = "请输入详细地址";
          this.showInfo();
        } else if (this.humanName == "") {
          this.message = "请填写收货人";
          this.showInfo();
        } else if (!resultPhone(this.phone)) {
          this.message = "请填写正确的手机号码";
          this.showInfo();
        } else {
          let res = await addAddress({
            addressDetailed: this.detailAddress,
            areaValue: this.regionIndex,
            isDefault: this.totalChoose ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.humanName,
          });
          console.log(res);
          if (res.data.result == 0) {
            this.message = "新增成功";
            this.showInfo();
            this.isAddAddress = false;
          } else {
            this.message = "新增失败";
            this.showInfo();
          }
        }
      }
      this.getTotalAddress();
      this.isEdit = false;
    },
    // 阿里支付
    aliTrue() {
      this.payWeChart = false;
      this.payAli = true;
    },
    // 微信支付
    wxTrue() {
      this.payWeChart = true;
      this.payAli = false;
    },
    // 修改地址
    async editAddress(id) {
      this.editId = id;
      // 省
      let res1 = await getAddress();
      res1 = res1.data.data;
      this.provinceList = res1;
      this.proviceIndex = this.oneAddress.provinceValue;
      // 市
      let res2 = await getAddress({ provinceValue: this.proviceIndex });
      res2 = res2.data.data;
      this.cityList = res2;
      this.cityIndex = this.oneAddress.cityValue;
      // 区
      let res3 = await getAddress({
        provinceValue: this.proviceIndex,
        cityValue: this.cityIndex,
      });
      res3 = res3.data.data;
      this.regionList = res3;
      this.regionIndex = this.oneAddress.districtValue;
      // 详细地址
      this.detailAddress = this.oneAddress.addressDetailed;
      // 用户
      this.humanName = this.oneAddress.username;
      // 手机号
      this.phone = this.oneAddress.phone;
      this.isAddAddress = true;
      if (this.oneAddress.isDefault == 1) {
        this.totalChoose = true;
      }

      this.isEdit = true;
    },
    // 新建地址
    addAnotherAddress() {
      this.proviceIndex = -1;
      this.cityIndex = -1;
      this.regionIndex = -1;
      (this.detailAddress = ""), (this.humanName = ""), (this.phone = "");
      this.isAddAddress = true;
    },
    // 切换地址
    addressChange() {
      console.log(this.oneAddress);
      this.oneAddress = this.addressList[this.chooseAddress];
      this.centerDialogVisible = false;
    },
    // 购物须知
    async shoppingKnow() {
      let res = await shoppingNotes();
      res = res.data.data;
      this.notes = res;
      this.centerDialogVisible2 = true;
    },
    // 去付款
    async payMoney() {
      if (this.addressList.length == 0) {
        this.message = "请保存地址";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 1000);
      } else {
        if (this.isRead) {
          let arr = [];
          for (let i = 0; i < this.commodityList.length; i++) {
            let obj = {
              commodityTypeId: this.commodityList[i].commodityTypeId,
              outletsId: this.commodityList[i].outletsId,
              quantity: this.commodityList[i].quantity,
              price: this.commodityList[i].specialOffer,
            };
            arr.push(obj);
          }
          console.log(arr);
          let res = await payApi({
            addressId: this.oneAddress.id,
            price: this.totalPrice,
            type: this.payAli ? 0 : 1,
            orderCommodityTypeDTOList: arr,
            isShop: this.status,
          });
          res = res.data;
          this.orderSearchId = res.data;
          console.log(res);
          if (res.result == 0) {
            if (this.payAli) {
              let id = res.data;
              let forms = "";
              let res1 = await payAli({
                uuid: id,
                type: this.payAli ? 0 : 1,
              });
              if (res1.status == 200) {
                forms = res1.data;
                this.$("#myForm").html(forms);
              }
              console.log(res1);
            } else {
              payPcWX({
                orderUuid: res.data,
              }).then((res) => {
                this.isshowScan = true;
                this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
                let qrcode = new QRCode("qrcode", {
                  width: 200, // 设置宽度，单位像素
                  height: 200, // 设置高度，单位像素
                  text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
                });
              });
            }
          } else {
            this.message = '商品已下架或售罄';
            this.$("#message").css("display", "block");
            setTimeout(() => {
              this.$("#message").css("display", "none");
            }, 1000);
          }
        } else {
          console.log("ppp");
          this.message = "请选择购物须知";
          this.$("#message").css("display", "block");
          setTimeout(() => {
            this.$("#message").css("display", "none");
          }, 1000);
        }
      }
    },
    seeStatus() {
      wxResult({
        orderId: this.orderSearchId,
      }).then((res) => {
        console.log(res);
        if (res.data.result == 0) {
          this.message = res.data.msg;
          this.showInfo();
          this.$router.push("/my-order");
        } else {
          this.message = res.data.msg;
          this.showInfo();
        }
      });
    },
    getZero(money, num) {
      let price = money * num;
      price += "";
      if (price.indexOf(".") == -1) {
        price = price + ".00";
      }
      return price;
    },
  },

  watch: {
    proviceIndex: function () {
      this.cityIndex = -1;
      this.regionIndex = -1;
    },
    cityIndex: function () {
      this.regionIndex = -1;
    },
  },
  mounted() {
    this.$(".order-select").css("color", "#999");
    this.$(".order-select option").css("color", "#111");
    let that = this;
    this.$(".order-select").change(function () {
      var selItem = that.$(this).val();
      if (selItem == that.$(this).find(".order-select option:first").val()) {
        that.$(this).css("color", "#999");
      } else {
        that.$(this).css("color", "#111");
      }
    });
  },
};
</script>


<style lang="less" scoped>
.order {
  margin-top: 50px;
  font-size: 14px;
  color: #111;
  margin-bottom: 80px;
  .address {
    border: 1px solid #cccdcd;
    .top {
      padding: 14px 20px;
      background-color: #f3f3f3;
      border-bottom: 1px solid #cccdcd;
    }
    .no-address {
      padding: 30px 30px;
      display: flex;
    }
  }
}

.nine {
  color: #999;
}

.has-address {
  padding: 30px 30px;
  display: flex;
  .top-address {
    margin-bottom: 20px;
    img {
      width: 24px;
      height: 25px;
    }
    .deafult-address {
      margin-left: 10px;
    }
    .modift-btn {
      display: inline-block;
      width: 60px;
      height: 28px;
      background: #ffffff;
      border: 1px solid #bba492;
      outline: none;
      margin-left: 20px;
      color: #bba492;
      font-size: 12px;
    }
  }
  .has-address-left {
    width: 80%;
    border-right: 1px dotted #cccdcd;
  }

  .has-address-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin-top: 20px;
      width: 144px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #111111;
      outline: none;
    }
    span {
      font-size: 12px;
    }
  }

  .center {
    margin-top: 20px;
    .one {
      display: inline-block;
      width: 70px;
      color: #666;
    }
  }
}

.order-select {
  width: 190px;
  margin-left: 15px;
  outline: none;
  // color: #999;
  padding: 2px 5px;
  border-color: #cccdcd;
}

.two {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
}

.detail_address {
  width: 600px;
  margin-left: 15px;
  height: 70px;
  outline: none;
  resize: none;
  padding-left: 7px;
  border-color: #cccdcd;
}

.detail_address::placeholder {
  color: #999;
}

.three {
  margin-top: 20px;
  display: flex;
  .three-left {
    margin-left: 13px;
    input {
      margin-left: 15px;
      outline: none;
      width: 240px;
      padding-left: 5px;
      // border-color: #cccdcd;
      border: 1px solid #cccdcd;
    }
  }
  .three-right {
    margin-left: 42px;
    span {
      margin-right: 10px;
    }
    input {
      width: 240px;
      padding-left: 5px;
      outline: none;
      // border-color: #cccdcd;
      border: 1px solid #cccdcd;
    }
  }
}

.no-address-right {
  margin-left: 40px;
  border-left: 1px dotted #cccdcd;
  padding-left: 40px;
  .top {
    margin-top: 30px;
    background-color: #fff !important;
    border: 0 !important;
    padding: 0 !important;
    .get-info {
      margin-left: 10px;
    }
  }
  .bottom {
    display: flex;
    margin-top: 40px;
    background-color: #fff !important;
    .save {
      width: 120px;
      height: 42px;
      color: #fff;
      background-color: #111;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      color: #111;
      margin-left: 20px;
      background-color: #fff !important;
      border: 1px solid #111111;
    }
  }
}

.checkbox-img {
  width: 18px;
  height: 18px;
}

.goods_info {
  margin-top: 30px;
}

.goods-info-item {
  background-color: #fff !important;
}

.goods_info_all {
  display: flex;
  align-items: flex-start;
  padding: 14px 0;
  background-color: #f3f3f3;
  padding-left: 10px;
  .one {
    width: 35%;
    display: flex;
    align-items: flex-start;
    .goods-big-img {
      width: 80px;
      height: 80px;
      border: 1px solid #cccdcd;
    }
    .goods-info-detail {
      display: inline-block;
      width: 260px;
      margin-left: 10px;
      span {
        display: block;
      }
      .goods-two {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .four {
    width: 15%;
  }
  .five {
    width: 20%;
    padding-right: 30px;
  }
}

.to-pay {
  padding: 30px 40px;
  background: #f5f5f5;
  border: 1px solid #cccdcd;
  border-top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .zhifu {
    margin-top: 10px;
    .one {
      margin-left: 7px;
    }
  }
  .to-pay-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .poster {
      margin-top: 15px;
    }
    .right-one {
      margin-right: 80px;
    }
    .pay-line {
      width: 220px;
      height: 2px;
      background: #cccdcd;
      margin-top: 20px;
    }
    .pay-main {
      margin-top: 20px;
      .pay-price {
        font-size: 22px;
        font-weight: 500;
      }
    }
    .to-pay-div {
      margin-top: 25px;
      display: flex;
      align-items: center;
      .left {
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      .left-one {
        font-size: 12px;
        color: #666;
        margin-left: 10px;
      }
      .left-two {
        font-size: 12px;
        color: #111;
      }
      .right {
        width: 170px;
        height: 50px;
        background-color: #111;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

//////////////////////////////////
//dialog部分
.address-item {
  padding: 15px 24px;
  border: 1px solid #cccdcd;
  color: #111;
  font-size: 14px;
  position: relative;
  margin-top: 15px;
  .address-item-item {
    display: flex;
    margin-top: 5px;
    .address-item-item-one {
      display: inline-block;
      width: 70px;
      flex-shrink: 0;
    }
    .address-item-item-one:first-child {
      margin: 0;
    }
  }
}
.address-item:nth-child(1) {
  margin: 0;
}
.active-address-item {
  border: 2px solid #111 !important;
}

.icon-true {
  position: absolute;
  bottom: -1px;
  right: -1px;
}

.dialog-footer {
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 14px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

.default-address-span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #bba492;
}

////////////////////////////////////////////////////////////////////////////////////////
.read {
  height: 400px;
}
.read-item {
  display: block;
  margin-bottom: 20px;
  padding-right: 10px;
}

/deep/ .el-scrollbar {
  height: 100%;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-dialog {
  width: 610px;
}

.pointer {
  cursor: pointer;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  display: none;
  line-height: 56px;
  text-align: center;
}
.scanPay {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9999;
  font-size: 20px;
  #qrcode {
    margin-bottom: 30px;
  }
  span {
    margin-top: 10px;
  }
  .isPay {
    margin-bottom: 20px;
    cursor: pointer;
    color: red;
  }
  .closeIMg {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}
</style>