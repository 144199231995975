<template>
  <div
    :class="{
      'discount-container': true,
      discount_mobile: isMobile,
      pcDiscount: !isMobile,
    }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="discount-poster">
      <img class="img-fluid" :src="bannerImg" alt="" />
    </div>
    <div class="container discount-body">
      <div class="row">
        <div
          :class="{
            'col-lg-3': true,
            'col-md-4': true,
            'col-sm-6': true,
            'no-padding-right': index % 2 == 0,
            'no-padding-left': index % 2 != 0,
          }"
          v-for="(item, index) in newProductList"
          :key="index"
        >
          <div class="discount-item" @click="toDetail(item.cid)">
            <div class="sanjiao d-sm-none d-lg-block"></div>
            <img
              class="search pointer d-sm-none d-lg-block"
              src="../../assets/img/search.png"
              alt=""
            />
            <img class="discount-item-img img-fuild" :src="item.image" alt="" />
            <span class="one">{{ item.name }}</span>
            <span class="three">{{ item.introduction }}</span>
            <span class="two">￥{{ item.specialOfferMin }}</span>
            <div class="pointer d-sm-none d-lg-block">
              <div class="sort">
                <img src="../../assets/img/sort-white.png" />
              </div>
            </div>
          </div>
        </div>
          <div class="null-info" v-if="newProductList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
      </div>
    </div>
    <div class="container d-sm-none d-lg-block">
      <div class="discount-bottom" v-if="newProductList.length>0">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>

<script>
import { specialPrice,getBannerApi } from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.getNewFun();
    this.getBanner();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      total: 0,
      newProductList: [],
      value: 1,
      pageSize: 8,
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
      bannerImg:''
    };
  },
  methods: {
    getBanner(){
      getBannerApi({advertisingId:this.$route.query.id}).then((res)=>{
        console.log(res);
        this.bannerImg = res.data.data;
      })
    },
    async getNewFun() {
      let res = await specialPrice({
        type: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.newProductList = this.newProductList.concat(res.items);
      this.pageSize = 12;
      console.log(res);
    },
    // 分类改变
    async handleCurrentChange(val) {
      this.value = val;
      let res = await specialPrice({
        type: 1,
        pageNo: this.value,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.newProductList = res.items;
      console.log(res);
    },
    // 去详情
    toDetail(id) {
      if (this.isMobile) {
        this.$router.push({
          path: "/mobile/newProduct-detail",
          query: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          path: "/index/new-product/detail",
          query: {
            id: id,
          },
        });
      }
    },
    // 滚动
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.newProductList.length < this.total) {
          this.loading = false;
          this.getNewFun();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.discount-container {
}

.discount_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.pcDiscount {
  padding-bottom: 80px;
}

.discount-poster {
  width: 100%;
  img {
    width: 100%;
    max-height: 580px;
  }
}

.row {
  margin: 0;
}

.discount-body {
  margin-top: 80px;
}

.discount-item:hover {
  border-bottom: 1px solid #111;
}

.discount-item:hover .sanjiao {
  opacity: 1;
}

.discount-item:hover .search {
  opacity: 1;
}

.discount-item:hover .see-more {
  opacity: 1;
}

.discount-item:hover {
  box-shadow: 0px 4px 14px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.discount-item:hover .sort {
  opacity: 1;
}

.no-padding-left {
  padding-left: 5px;
}

.no-padding-right {
  padding-right: 5px;
}

.discount-item {
  color: #111;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 30px;
  transition: all 0.5s ease;
  .sanjiao {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    border: 45px solid transparent;
    border-top-color: #bba492;
    border-left-color: #bba492;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .search {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .discount-item-img {
    max-width: 294px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .one {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    width: 80%;
    margin: 20px auto 0;
    // height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .two {
    display: block;
    font-size: 18px;
    font-weight: bold;
    width: 80%;
    margin: 20px auto 0;
  }
  .three {
    display: block;
    font-size: 14px;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    margin: 0 auto;
  }
  .sort {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    bottom: 20px;
    opacity: 0;
    transition: all 0.5s ease;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .discount-body {
    margin-top: 30px;
    .discount-item {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
      margin-bottom: 0;
      .discount-item-img {
        width: 100%;
        margin-top: 0;
      }
    }
    .one {
      font-size: 14px;
      width: 90%;
      text-align: center;
      height: 18px;
    }
    .two {
      margin-top: 10px;
    }
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.null-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img {
  width: 200px;
  height: 200px;
}
</style>