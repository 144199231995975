<template>
  <div :class="{ tasting: true, classic_mobile: isMobile }">
    <div class="tasting-top-img">
      <!-- <img class="img-fluid" src="../../assets/img/banner9.png" alt="" /> -->
      <img
        class="img-fluid"
        src="http://file.antiker.cn/36fc422083c548a08eb28e54beb3cbca.png"
        alt=""
      />
    </div>
    <!-- 拍卖会 -->
    <div class="container tasting-container" v-if="auctionList.length > 0">
      <div class="tasting-item">
        <div class="top-name">
          <span class="one">拍卖会</span>
          <span class="two">AUCTION</span>
        </div>
      </div>
      <!-- 第一个pc,移动 -->
      <div class="tasting-item-item row" v-if="auctionList.length > 0">
        <!-- 左侧 -->
        <div class="col-lg-6 col-sm-12 left-domain">
          <div class="left">
            <img
              class="tasting-item-img img-fuild"
              :src="auctionList[0].image"
              alt=""
            />
            <div class="position-img">
              <img
                v-if="auctionList[0].type == 0"
                src="../../assets/img/auction-pre.png"
                alt=""
              />
              <img
                v-if="auctionList[0].type == 1"
                src="../../assets/img/auction-loading.png"
                alt=""
              />
              <img
                v-if="auctionList[0].type == 2"
                src="../../assets/img/auction-end.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="col-lg-6 col-sm-12 right-domain">
          <div class="right">
            <span class="name">{{ auctionList[0].name }}</span>
            <div class="price">
              <span class="first"
                >起拍价<span
                  class="second"
                  v-if="auctionList[0].startPrice != null"
                  >￥<span class="third">{{
                    auctionList[0].startPrice
                  }}</span></span
                ><span class="forth" v-else>暂无</span></span
              >
              <span class="first"
                >成交价<span
                  class="second"
                  v-if="auctionList[0].finalPrice != null"
                  >￥<span class="third">{{
                    auctionList[0].finalPrice
                  }}</span></span
                ><span class="forth" v-else>暂无</span></span
              >
            </div>
            <span class="intro">{{ auctionList[0].introduction }}</span>
            <div class="dotted-line"></div>
            <span class="right-span"
              >拍品来源：{{ auctionList[0].source }}</span
            >
            <span class="right-span"
              >拍卖时间：{{ auctionList[0].startTime }}</span
            >
            <span class="right-span"
              >拍卖地点：{{ auctionList[0].address }}</span
            >
            <div class="see-more" @click="toAuctionDetail(auctionList[0].id)">
              <img src="../../assets/img/sanjiao.png" alt="" />
              <span>VIEW</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二个移动 -->
      <div
        class="tasting-item-item row d-lg-none"
        v-if="auctionList.length > 1"
      >
        <!-- 左侧 -->
        <div class="col-lg-6 col-sm-12 left-domain">
          <div class="left">
            <img
              class="tasting-item-img img-fuild"
              :src="auctionList[1].image"
              alt=""
            />
            <div class="position-img">
              <img
                v-if="auctionList[1].type == 0"
                src="../../assets/img/auction-pre.png"
                alt=""
              />
              <img
                v-if="auctionList[1].type == 1"
                src="../../assets/img/auction-loading.png"
                alt=""
              />
              <img
                v-if="auctionList[1].type == 2"
                src="../../assets/img/auction-end.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="col-lg-6 col-sm-12 right-domain">
          <div class="right">
            <span class="name">{{ auctionList[1].name }}</span>
            <div class="price">
              <span class="first"
                >起拍价<span
                  class="second"
                  v-if="auctionList[1].startPrice != null"
                  >￥<span class="third">{{
                    auctionList[1].startPrice
                  }}</span></span
                ><span class="forth" v-else>暂无</span></span
              >
              <span class="first"
                >成交价<span
                  class="second"
                  v-if="auctionList[1].finalPrice != null"
                  >￥<span class="third">{{
                    auctionList[1].finalPrice
                  }}</span></span
                ><span class="forth" v-else>暂无</span></span
              >
            </div>
            <span class="intro">{{ auctionList[1].introduction }}</span>
            <div class="dotted-line"></div>
            <span class="right-span"
              >拍品来源：{{ auctionList[1].source }}</span
            >
            <span class="right-span"
              >拍卖时间：{{ auctionList[1].startTime }}</span
            >
            <span class="right-span"
              >拍卖地点：{{ auctionList[1].address }}</span
            >
            <div class="see-more" @click="toAuctionDetail(auctionList[1].id)">
              <img src="../../assets/img/sanjiao.png" alt="" />
              <span>VIEW</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 线 -->
      <div class="container-bottom d-sm-none d-lg-block"></div>
      <!-- 第二个pc -->
      <div
        class="tasting-item-item d-sm-none d-lg-block"
        v-if="auctionList.length > 1"
      >
        <div class="flex-no">
          <!-- 左侧 -->
          <div class="col-lg-6">
            <div class="left">
              <span class="name">{{ auctionList[1].name }}</span>
              <div class="price">
                <span class="first"
                  >起拍价<span
                    class="second"
                    v-if="auctionList[1].startPrice != null"
                    >￥<span class="third">{{
                      auctionList[1].startPrice
                    }}</span></span
                  ><span class="forth" v-else>暂无</span></span
                >
                <span class="first"
                  >成交价<span
                    class="second"
                    v-if="auctionList[1].finalPrice != null"
                    >￥<span class="third">{{
                      auctionList[1].finalPrice
                    }}</span></span
                  ><span class="forth" v-else>暂无</span></span
                >
              </div>
              <span class="intro">{{ auctionList[1].introduction }}</span>
              <div class="dotted-line"></div>
              <span class="right-span"
                >拍品来源：{{ auctionList[1].source }}</span
              >
              <span class="right-span"
                >拍卖时间：{{ auctionList[1].startTime }}</span
              >
              <span class="right-span"
                >拍卖地点：{{ auctionList[1].address }}</span
              >
              <div class="see-more" @click="toAuctionDetail(auctionList[1].id)">
                <img src="../../assets/img/sanjiao.png" alt="" />
                <span>VIEW</span>
              </div>
            </div>
          </div>
          <!-- 右侧 -->
          <div class="col-lg-6 right-domain">
            <div class="right right-two">
              <img
                class="tasting-item-img"
                :src="auctionList[1].image"
                alt=""
              />
              <div class="position-img">
                <img
                  v-if="auctionList[1].type == 0"
                  src="../../assets/img/pre-right.png"
                  alt=""
                />
                <img
                  v-if="auctionList[1].type == 1"
                  src="../../assets/img/loading-right.png"
                  alt=""
                />
                <img
                  v-if="auctionList[1].type == 2"
                  src="../../assets/img/end-right.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more pointer" @click="toAuction">查看更多</div>
    </div>

    <!-- 艺术工匠家 -->
    <div class="artist" v-if="artistList.length > 0">
      <div class="container">
        <div class="tasting-item">
          <div class="top-name">
            <span class="one">艺术工匠家</span>
            <span class="two">The ARTIST</span>
          </div>
        </div>
        <div class="row d-sm-none d-lg-block" v-if="artistList.length > 2">
          <div class="dircetion">
            <div class="left">
              <img
                src="../../assets/img/left_white.png"
                alt=""
                v-show="swiperIndex == 3"
                class="pointer"
              />
              <img
                src="../../assets/img/left_black.png"
                alt=""
                v-show="swiperIndex != 3"
                class="pointer"
                id="prew"
              />
            </div>
            <div class="right">
              <img
                src="../../assets/img/right_white.png"
                alt=""
                v-show="swiperIndex == artistList.length"
                class="pointer"
              />
              <img
                src="../../assets/img/right_black.png"
                alt=""
                v-show="swiperIndex != artistList.length"
                class="pointer"
                id="next"
              />
            </div>
          </div>
        </div>
        <div class="swiper-artist" v-if="artistList.length > 0">
          <div class="swiper-container row d-sm-none d-lg-block" id="swiper1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide col-lg-4"
                v-for="(item, index) in artistList"
                :key="index"
              >
                <div
                  class="artist-swiper-item"
                  @click="artistDetail(item.id, item.name)"
                >
                  <img class="img-fuild" :src="item.image" alt="" />
                  <div class="swiper-item-intro">
                    <div class="one">THE<span class="two">ARTIST</span></div>
                  </div>
                  <div class="swiper-item-intro-bottom">
                    <div class="one">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="two">
                      <span>{{ item.nationality }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-lg-none" v-if="artistList.length > 0">
            <!-- <div
              class="col-sm-12"
              v-for="(item, index) in artistList"
              :key="index"
            >
              <div
                class="artist-swiper-item"
                @click="artistDetail(item.id, item.name)"
              >
                <img class="img-fuild" :src="item.image" alt="" />
                <div class="swiper-item-intro">
                  <div class="one">THE<span class="two">ARTIST</span></div>
                </div>
                <div class="swiper-item-intro-bottom">
                  <div class="one">
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="two">
                    <span>{{ item.nationality }}</span>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-sm-12">
              <div
                class="artist-swiper-item"
                @click="artistDetail(artistList[0].id, artistList[0].name)"
              >
                <img class="img-fuild" :src="artistList[0].image" alt="" />
                <div class="swiper-item-intro">
                  <div class="one">THE<span class="two">ARTIST</span></div>
                </div>
                <div class="swiper-item-intro-bottom">
                  <div class="one">
                    <span>{{ artistList[0].name }}</span>
                  </div>
                  <div class="two">
                    <span>{{ artistList[0].nationality }}</span>
                  </div>
                </div>
              </div>
              <div
                class="artist-swiper-item"
                @click="artistDetail(artistList[1].id, artistList[1].name)"
              >
                <img class="img-fuild" :src="artistList[1].image" alt="" />
                <div class="swiper-item-intro">
                  <div class="one">THE<span class="two">ARTIST</span></div>
                </div>
                <div class="swiper-item-intro-bottom">
                  <div class="one">
                    <span>{{ artistList[1].name }}</span>
                  </div>
                  <div class="two">
                    <span>{{ artistList[1].nationality }}</span>
                  </div>
                </div>
              </div>
              <div
                class="artist-swiper-item"
                @click="artistDetail(artistList[2].id, artistList[2].name)"
              >
                <img class="img-fuild" :src="artistList[2].image" alt="" />
                <div class="swiper-item-intro">
                  <div class="one">THE<span class="two">ARTIST</span></div>
                </div>
                <div class="swiper-item-intro-bottom">
                  <div class="one">
                    <span>{{ artistList[2].name }}</span>
                  </div>
                  <div class="two">
                    <span>{{ artistList[2].nationality }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="more artist-more pointer"
          style="margin-top: 100px"
          @click="artistMore"
        >
          查看更多
        </div>
      </div>
    </div>

    <!-- 品鉴师 -->
    <div class="tasting-division" v-if="tastingList.length > 0">
      <div class="container">
        <div class="tasting-item">
          <div class="top-name">
            <span class="one">品鉴师</span>
            <span class="two">TASTING DIVISION</span>
          </div>
        </div>

        <div class="tasting-division-item" v-if="tastingList.length > 0">
          <div class="logo d-lg-none">TASTING DIVISION</div>
          <div class="row swiper-container" id="swiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in tastingList"
                :key="index"
                id="swiper-slide"
                style="cursor: pointer"
              >
                <div class="left">
                  <img class="img-fuild" :src="item.image" alt="" />
                </div>
                <div class="right">
                  <span class="one">{{ item.name }}</span>
                  <div class="line"></div>
                  <span class="intro">{{ item.introduction }}</span>
                  <!-- <span class="logo d-sm-none d-lg-block"
                    >TASTING DIVISION</span
                  > -->
                  <div
                    class="d-sm-none d-lg-block tasting-sanjiao"
                    @click="tastingDetail(item.id, item.name)"
                  >
                    <img src="../../assets/img/sanjiao.png" alt="" />
                    <span>了解更多</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div
          class="more tasting-more pointer"
          style="margin-top: 80px"
          @click="goTasting"
        >
          查看更多
        </div>
      </div>
    </div>
    <div class="tasting-bottom d-lg-none"></div>
  </div>
</template>

<script>
import { getTastingOnline } from "../../assets/api/api.js";
export default {
  created() {
    this.getTastingOnline();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      bulletChoose: 0,
      artistList: [],
      tastingList: [],
      auctionList: [],
      swiperIndex: 3,
      isMobile: false,
    };
  },
  methods: {
    // 获取数据
    async getTastingOnline() {
      let res = await getTastingOnline();
      if (res.data.msg == "token非法") {
        this.$router.push("/login");
      } else {
        res = res.data.data;
        this.auctionList = res.auctionMerchandiseVOList;
        this.artistList = res.simpleArtistVOPage.items;
        this.tastingList = res.simpleTestingVOList;
        console.log(res);
        console.log(this.auctionList);
        let that = this;
        this.$nextTick(function () {
          that.getSwiper();
        });
      }
    },

    // 去拍卖会
    toAuction() {
      this.$router.push("/auction");
    },

    // 去艺术工匠家
    artistMore() {
      this.$router.push("/artist");
    },

    // 去品鉴师
    goTasting() {
      this.$router.push("/tasting-division");
    },

    // 拍卖品详情
    toAuctionDetail(id) {
      this.$router.push({
        path: "/auction-item-detail",
        query: {
          id: id,
        },
      });
    },
    // 艺术工匠家详情
    artistDetail(id, name) {
      this.$router.push({
        path: "/artist-detail",
        query: {
          id: id,
          name: name,
        },
      });
    },
    // 品鉴师详情
    tastingDetail(id, name) {
      this.$router.push({
        path: "/tasting-division-detail",
        query: {
          id: id,
          name: name,
        },
      });
    },
    getSwiper() {
      let that = this;
      var mySwiper = new this.Swiper("#swiper1", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 3,
        on: {
          slideChangeTransitionEnd: function () {
            that.swiperIndex = this.activeIndex + 3;
            console.log(that.swiperIndex);
          },
        },
      });
      this.$("#prew").click(function () {
        mySwiper.slidePrev();
      });
      this.$("#next").click(() => {
        mySwiper.slideNext();
      });
      var mySwiper2 = new this.Swiper("#swiper2", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },
  },
};
</script>


<style lang="less">
.classic_mobile {
  margin-top: 63px;
}
.row {
  margin: 0 !important;
}
.tasting {
  @media (min-width: 1500px) {
    .container {
      max-width: 1440px !important;
    }
  }
  .tasting-top-img {
    width: 100%;
    img {
      width: 100%;
      max-height: 580px;
    }
  }
  .tasting-item {
    font-size: 14px;
    color: #111;
    padding-top: 80px;
    .top-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      .one {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 5px;
        border-bottom: 2px solid #111;
        padding-bottom: 20px;
      }
      .two {
        letter-spacing: 10px;
        text-align: center;
        display: block;
        margin-top: 15px;
      }
    }
  }

  .flex-no {
    display: flex;
  }
  .tasting-item-item {
    font-size: 14px;
    color: #111;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 70px;
    .left {
      width: 75%;
      position: relative;
      .position-img {
        position: absolute;
        top: 20px;
        left: -8px;
        img {
          width: 100px;
        }
      }
    }
    .tasting-item-img {
      box-shadow: 14px 14px 24px 0px rgba(17, 17, 17, 0.5);
      width: 100%;
      max-width: 500px;
    }

    .right {
      width: 75%;
      position: relative;
      .position-img {
        position: absolute;
        top: 20px;
        right: -10px;
        img {
          width: 100px;
        }
      }
    }
    .left-domain {
      width: 100%;
    }
    .right-domain {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .left-two {
      width: 85%;
    }
    .right-two {
      display: flex;
      justify-content: flex-end;
    }
    .name {
      font-size: 24px;
      font-weight: 500;
    }
    .price {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .first {
        .second {
          font-size: 24px;
          font-weight: 500;
          margin-left: 10px;
          .third {
            font-size: 36px;
          }
        }
        .forth {
          font-size: 36px;
          color: #cccccc;
          margin-left: 10px;
        }
      }
    }
    .intro {
      color: #666;
      display: block;
      margin-top: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .dotted-line {
      width: 100%;
      border-bottom: 1px dotted #ccc;
      margin-top: 20px;
    }
    .right-span {
      display: block;
      margin-top: 12px;
    }
    .see-more {
      display: flex;
      align-items: center;
      margin-top: 30px;
      img {
        transform: rotate(180deg);
      }
      span {
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .container-bottom {
    border-bottom: 1px dotted #ccc;
  }

  .more {
    width: 220px;
    height: 60px;
    background-color: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 60px;
    font-size: 16px;
  }

  .artist {
    padding-bottom: 80px;
    background-color: #eeeeee;
    .dircetion {
      margin-top: 36px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .swiper-artist {
    margin-top: 30px;
    .artist-swiper-item {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 100px;
      cursor: pointer;
      img {
        width: 90%;
      }
      .swiper-item-intro {
        position: absolute;
        top: -10px;
        left: -10px;
        .one {
          font-size: 30px;
          font-weight: 500;
          color: #111;
          .two {
            display: block;
          }
        }
      }
      .swiper-item-intro-bottom {
        width: 90%;
        padding: 30px;
        background-color: #fff;
        position: absolute;
        left: -15px;
        bottom: 30px;
        .one {
          font-size: 24px;
          font-weight: 500;
          color: #111;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .two {
          color: #666;
          display: block;
        }
      }
    }
  }

  .tasting-division {
    .tasting-division-item {
      margin-top: 80px;
      box-shadow: 14px 14px 24px 0px rgba(0, 0, 0, 0.2);
      #swiper-slide {
        width: 100%;
        padding: 70px 100px;
        display: flex;
      }
      .left {
        width: 45%;
        img {
          width: 85%;
          // height: 480px;
        }
      }
      .right {
        width: 55%;
        position: relative;
        .one {
          font-size: 24px;
          color: #111;
          font-weight: 500;
          display: block;
          margin-top: 60px;
        }
        .line {
          width: 100%;
          border-bottom: 2px solid #111;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        .intro {
          font-size: 14px;
          color: #666;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 11 !important;
          overflow: hidden;
        }
      }
    }
  }

  .logo {
    display: block;
    margin-top: 60px;
    font-size: 40px;
    font-weight: 500;
    color: #eee;
  }
  .swiper-pagination {
    text-align: right;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 60px;
    left: -8%;
  }
  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 10px;
  }
  .swiper-pagination-bullet-active {
    background-color: #111;
  }
}

.pointer {
  cursor: pointer;
}

@media (min-width: 300px) and (max-width: 992px) {
  .tasting-item {
    padding-top: 30px !important;
    .one {
      font-size: 20px !important;
      font-weight: 550 !important;
      padding-bottom: 10px !important;
    }
  }
  .tasting-item-item {
    padding-bottom: 30px !important;
    margin-top: 30px !important;
    background-color: #f8f8f8 !important;
  }
  .left {
    width: 100% !important;
    .tasting-item-img {
      width: 100% !important;
      box-shadow: none !important;
    }
  }
  .right {
    padding: 0 20px !important;
    width: 100% !important;
    .name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: 15px !important;
    }
    .price {
      display: flex;
      justify-content: flex-start !important;
      margin-top: 10px;
      .first {
        margin-right: 20px;
      }
      .second {
        font-size: 10px !important;
        .third {
          font-size: 16px !important;
          font-weight: 600;
        }
      }
    }
    .intro {
      margin-top: 6px !important;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2 !important;
      overflow: hidden;
    }
  }
  .right-domain {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 15px;
  }

  .artist-swiper-item {
    padding-bottom: 30px !important;
    img {
      width: 100% !important;
    }
    .swiper-item-intro {
      position: absolute;
      top: 10px !important;
      left: 10px !important;
    }
    .swiper-item-intro-bottom {
      width: 80% !important;
      height: 95px !important;
      position: absolute !important;
      bottom: 70px !important;
      padding: 0 !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .artist-more {
    margin-top: 10px !important;
  }
  .tasting-division {
    .logo {
      text-align: center;
      margin-top: 20px !important;
    }
    .tasting-division-item {
      margin: 0 15px;
      margin-top: 20px !important;
      #swiper-slide {
        padding: 0 !important;
        display: flex !important;
        flex-direction: column !important;
      }
      .left {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
      .right {
        .one {
          margin-top: 20px !important;
          width: 90%;
          margin: 0 auto;
        }
        .line {
          width: 90% !important;
          margin: 15px auto !important;
        }
        .intro {
          -webkit-line-clamp: 3 !important;
          width: 90%;
          margin: 0 auto;
          margin-bottom: 70px;
        }
      }
    }
    .swiper-pagination {
      bottom: 20px !important;
      left: 0 !important;
      display: flex;
      justify-content: center;
    }
    .tasting-more {
      margin-top: 30px !important;
    }
  }
}

.tasting-bottom {
  height: 30px;
}

.tasting-sanjiao {
  position: absolute;
  bottom: 50px;
  left: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #111;
  img {
    transform: rotate(180deg);
    margin-right: 8px;
    margin-bottom: 3px;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .tasting {
    .forth {
      font-size: 28px !important;
    }

    .intro {
      font-size: 12px !important;
    }

    .right-span {
      font-size: 12px !important;
    }

    .see-more {
      margin-top: 15px !important;
    }
    .more{
      width: 180px;
      height: 45px;
      font-size: 12px;
    }

    .artist-swiper-item{
      .one{
        font-size: 18px !important;
      }
    }
    .artist{
      padding-bottom: 10px;
    }

    .tasting-division-item{
      .logo{
        font-size: 30px;
      }
      .right {
        .one{
          font-size: 18px !important;
        }
      }
    }
  }
}
</style>