var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"box",class:{
    'discount-container': true,
    discount_mobile: _vm.isMobile,
    pcDiscount: !_vm.isMobile,
  },on:{"&scroll":function($event){return _vm.onScroll.apply(null, arguments)}}},[_c('div',{staticClass:"discount-poster"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.bannerImg,"alt":""}})]),_c('div',{staticClass:"container discount-body"},[_c('div',{staticClass:"row"},[_vm._l((_vm.newProductList),function(item,index){return _c('div',{key:index,class:{
          'col-lg-3': true,
          'col-md-4': true,
          'col-sm-6': true,
          'no-padding-right': index % 2 == 0,
          'no-padding-left': index % 2 != 0,
        }},[_c('div',{staticClass:"discount-item",on:{"click":function($event){return _vm.toDetail(item.cid)}}},[_c('div',{staticClass:"sanjiao d-sm-none d-lg-block"}),_c('img',{staticClass:"search pointer d-sm-none d-lg-block",attrs:{"src":require("../../assets/img/search.png"),"alt":""}}),_c('img',{staticClass:"discount-item-img img-fuild",attrs:{"src":item.image,"alt":""}}),_c('span',{staticClass:"one"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"three"},[_vm._v(_vm._s(item.introduction))]),_c('span',{staticClass:"two"},[_vm._v("￥"+_vm._s(item.specialOfferMin))]),_vm._m(0,true)])])}),(_vm.newProductList.length == 0)?_c('div',{staticClass:"null-info"},[_c('img',{attrs:{"src":require("../../assets/img/all-null.png"),"alt":""}}),_c('span',[_vm._v("这里空空的什么也没有~")])]):_vm._e()],2)]),_c('div',{staticClass:"container d-sm-none d-lg-block"},[(_vm.newProductList.length>0)?_c('div',{staticClass:"discount-bottom"},[_c('el-pagination',{staticClass:"discount-bottom-pagation",attrs:{"layout":"prev, pager, next","total":_vm.total,"page-size":_vm.pageSize},on:{"current-change":_vm.handleCurrentChange}}),_c('span',{staticClass:"page-span"},[_vm._v("共"+_vm._s(_vm.total)+"个")])],1):_vm._e()]),_c('div',{staticClass:"loading",class:{ 'loading-hidden': _vm.loading }},[_vm._v(" "+_vm._s(_vm.addMore ? "加载中" : "没有啦 o(╥﹏╥)o")+" ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pointer d-sm-none d-lg-block"},[_c('div',{staticClass:"sort"},[_c('img',{attrs:{"src":require("../../assets/img/sort-white.png")}})])])}]

export { render, staticRenderFns }