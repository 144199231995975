<template>
  <div :class="{ 'index-container': true, classic_mobile: isMobile }">
    <!-- swiper轮播图 -->
    <div class="swiper-container" id="swiper1" v-if="bigImgList.length > 0">
      <div class="swiper-wrapper">
        <!-- 轮播处 -->
        <div
          class="swiper-slide"
          v-for="(item, index) in bigImgList"
          :key="index"
          @click="toAdvertDetail(index)"
        >
          <img
            :class="{ 'img-fluid': true, pointer: item.type != 0 }"
            :src="item.image"
            alt=""
          />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <!-- 最新活动 -->
    <div class="container new-activity">
      <!-- 活动头部 -->
      <div class="row">
        <div class="col-lg-12">
          <div class="new-activity-top">
            <span class="name">最新活动</span>
            <span class="line d-sm-none d-lg-block"></span>
            <span class="introduce pointer">{{ activityName }}</span>
          </div>
        </div>
      </div>
      <!-- 活动详情 -->
      <div class="add-margin">
        <div class="row activity-detail pointer" @click="activityOneMore">
          <!-- 左侧 -->
          <div class="col-lg-6 col-sm-12 no-padding">
            <div class="activity-img">
              <img :src="activityVO.image" alt="" class="img-fluid" />
              <img
                class="bianzu"
                src="../../assets/img/activity-pre.png"
                alt=""
                v-if="activityVO.state == 0"
              />
              <img
                class="bianzu"
                src="../../assets/img/activity-loading.png"
                alt=""
                v-if="activityVO.state == 1"
              />
              <img
                class="bianzu"
                src="../../assets/img/activity-end.png"
                alt=""
                v-if="activityVO.state == 2"
              />
            </div>
          </div>

          <!-- 右侧 -->
          <div class="col-lg-6 col-sm-12 activity-info">
            <!-- 介绍 -->
            <div class="activity-info-one">
              <span>{{ activityVO.name }}</span>
            </div>
            <!-- 标签 -->
            <div class="activity-three">
              <span
                v-for="(item, index) in activityVO.activityLabelVOList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
            <!-- 活动时间 -->
            <div class="activity-info-two">
              <span
                >活动时间：{{ activityVO.activityStartTime }} 至
                {{ activityVO.activityEndTime }}</span
              >
            </div>
            <!-- 报名时间 -->
            <div class="activity-info-two">
              <span
                >报名时间：{{ activityVO.signUpStartTime }} 至
                {{ activityVO.signUpEndTime }}</span
              >
            </div>
            <!-- 活动地址 -->
            <div class="activity-info-two">
              <span>活动地点：{{ activityVO.address }}</span>
            </div>
            <!-- 活动名额 -->
            <div class="activity-info-two">
              <span
                >活动名额：{{ activityVO.signUpPeople }} /
                {{ activityVO.quota }}</span
              >
            </div>
            <!-- 主办方 -->
            <div class="activity-info-two">
              <span>主办方：{{ activityVO.sponsor }}</span>
            </div>
            <!-- 报名费 -->
            <div class="activity-info-four">
              <span v-if="activityVO.signUpMoney > 0"
                >报名费：￥{{ activityVO.signUpMoney }}</span
              >
              <span v-else>报名费：免费</span>
            </div>
            <!-- 提交 -->
            <div class="activity-info-five">
              <button
                style="background-color: #ccc"
                v-if="activityVO.signUpState == 3"
              >
                待开始
              </button>
              <button
                style="background-color: #ccc"
                v-if="activityVO.signUpState == 4"
              >
                已报名
              </button>
              <button
                v-if="activityVO.signUpState == 0"
                @click="centerDialogVisible2 = true"
              >
                立即报名
              </button>
              <button
                style="background-color: #ccc"
                v-if="activityVO.signUpState == 2"
              >
                已结束
              </button>
              <button
                style="background-color: #ccc"
                v-if="activityVO.signUpState == 1"
              >
                报名截止
              </button>
            </div>
            <!-- 切换 -->
            <div class="activity-info-six">
              <div class="arrow-left pointer">
                <img
                  src="../../assets/img/left_white.png"
                  alt=""
                  v-if="activityIndex == 1"
                  @click="$event.stopPropagation()"
                />
                <img
                  src="../../assets/img/left_black.png"
                  alt=""
                  v-else
                  @click="toLeftActivity"
                />
              </div>
              <div style="margin-left: 30px" class="arrow-right pointer">
                <img
                  src="../../assets/img/right_white.png"
                  alt=""
                  v-if="totalActivityNum == activityIndex"
                  @click="$event.stopPropagation()"
                />
                <img
                  src="../../assets/img/right_black.png"
                  alt=""
                  v-else
                  @click="toRightActivity"
                />
              </div>
              <div class="more pointer" @click="toActivity">
                <span>MORE</span>
                <img src="../../assets/img/sanjiao.png" alt="" />
                <div class="more-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 特价优惠 -->
    <div class="container discount" v-if="classifyList.length > 0">
      <div class="row">
        <div class="col-lg-6 col-sm-12 discount-item-bottom">
          <div class="discount-left" @click="chooseAdvertist(0)">
            <img class="img-fuild" :src="classifyList[0].image" alt="" />
            <div class="my-font">
              <span>{{ classifyList[0].name }}</span>
              <span class="font-bottom">{{
                classifyList[0].introduction
              }}</span>
            </div>
          </div>
        </div>
        <!-- 限时抢购 -->
        <div class="col-lg-3 col-sm-6 no-padding-right">
          <div class="discount-normal" @click="chooseAdvertist(1)">
            <img class="img-fuild" :src="classifyList[1].image" alt="" />
            <div class="my-font">
              <span>{{ classifyList[1].name }}</span>
              <span class="font-bottom">{{
                classifyList[1].introduction
              }}</span>
            </div>
          </div>
          <!-- 自定义 -->
          <div class="discount-normal discount-mar" @click="chooseAdvertist(3)">
            <img :src="classifyList[3].image" alt="" />
            <div class="my-font">
              <span>{{ classifyList[3].name }}</span>
              <span class="font-bottom">{{
                classifyList[3].introduction
              }}</span>
            </div>
          </div>
        </div>
        <!-- 限时抢购 -->
        <div class="col-lg-3 col-sm-6 no-padding-left">
          <div class="discount-normal" @click="chooseAdvertist(2)">
            <img :src="classifyList[2].image" alt="" />
            <div class="my-font">
              <span>{{ classifyList[2].name }}</span>
              <span class="font-bottom">{{
                classifyList[2].introduction
              }}</span>
            </div>
          </div>
          <!-- 自定义 -->
          <div class="discount-normal discount-mar" @click="chooseAdvertist(4)">
            <img :src="classifyList[4].image" alt="" />
            <div class="my-font">
              <span>{{ classifyList[4].name }}</span>
              <span class="font-bottom">{{
                classifyList[4].introduction
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 经典轻奢 -->
    <div class="classic">
      <!-- 古典精选 -->
      <div class="container classic-main pointer">
        <div class="row">
          <div class="col-lg-3 classic-left-one d-sm-none d-lg-block">
            <span class="classic-span-one">古典精选</span>
            <span class="classic-span-two">{{ classicWritting }}</span>
            <div class="classic-span-three pointer" @click="toClassic">
              <img src="../../assets/img/sanjiao.png" alt="" />
              <span>MORE</span>
              <div class="classic-line"></div>
            </div>
          </div>
          <div class="col-sm-12 classic-left-one d-lg-none">
            <span class="classic-span-one">古典精选</span>
            <span class="classic-span-two">{{ classicWritting }}</span>
          </div>
          <!-- 111 -->
          <div
            class="swiper-container-two col-lg-9 swiperCon d-sm-none d-lg-block"
            v-if="classicList.length > 0"
          >
            <div class="swiper-wrapper">
              <div
                :class="{
                  'swiper-slide': true,
                  'col-lg-4': true,
                  pointer: true,
                }"
                v-for="(item, index) in classicList"
                :key="index"
              >
                <div class="classic-item" @click="toClassicItem(item.id)">
                  <div class="to-left">
                    <img src="../../assets/img/small-arrow-left.png" alt="" />
                  </div>
                  <div class="search-icon"></div>
                  <img
                    class="search-icon-img"
                    src="../../assets/img/search.png"
                    alt=""
                  />
                  <span class="classic-item-one">{{ item.name }}</span>
                  <img
                    class="classic-item-img img-fluid"
                    :src="item.image"
                    alt=""
                  />
                  <span class="classic-item-two"
                    >{{ item.years }} {{ item.artistName }}</span
                  >
                  <span class="classic-item-three" v-if="item.isSell == 1"
                    >￥{{ item.specialOfferMin }}</span
                  >
                  <span class="classic-item-three" v-else>咨询客服</span>
                  <div class="isNull" v-if="item.inventory == 0">已售罄</div>
                </div>
              </div>
            </div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <div class="row d-lg-none" v-if="classicList.length > 0">
            <div
              :class="{
                'col-sm-6': true,
                'classic-inner': true,
                'no-padding-right': index%2== 0 || index%2== 2,
                'no-padding-left': index %2== 1,
              }"
              v-for="(item, index) in classicList"
              :key="index"
            >
              <div class="classic-item" @click="toMobileClassic(index)">
                <span class="classic-item-one">{{ item.name }}</span>
                <img class="classic-item-img" :src="item.image" alt="" />
                <span class="classic-item-two">{{ item.introduction }}</span>
                <span class="classic-item-three" v-if="item.isSell == 1"
                  >￥{{ item.specialOfferMin }}</span
                >
                <span class="classic-item-three" v-else>咨询客服</span>
                <div class="isNull" v-if="item.inventory == 0">已售罄</div>
              </div>
            </div>
          </div>
          <div class="see-more" @click="toClassic">查看更多</div>
        </div>
      </div>
      <!-- 现代轻奢 -->
      <div class="container luxery pointer">
        <div class="row">
          <!-- 移动端 -->
          <div class="col-sm-12 classic-left-one d-lg-none">
            <span class="classic-span-one">现代轻奢</span>
            <span class="classic-span-two">{{ modernWritting }}</span>
          </div>
          <!-- pc -->
          <div
            class="swiper-container-two col-lg-9 swiperCon d-sm-none d-lg-block"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide col-lg-4"
                v-for="(item, index) in modernList"
                :key="index"
              >
                <div class="classic-item" @click="toLuxeryItem(item.id)">
                  <div class="to-left">
                    <img src="../../assets/img/small-arrow-left.png" alt="" />
                  </div>
                  <div class="search-icon"></div>
                  <img
                    class="search-icon-img"
                    src="../../assets/img/search.png"
                    alt=""
                  />
                  <span class="classic-item-one">{{ item.name }}</span>
                  <img class="classic-item-img" :src="item.image" alt="" />
                  <span class="classic-item-two">{{ item.introduction }}</span>
                  <span class="classic-item-three" v-if="item.isSell == 1"
                    >￥{{ item.specialOfferMin }}</span
                  >
                  <span class="classic-item-three" v-else>咨询客服</span>
                  <div class="isNull" v-if="item.inventory == 0">已售罄</div>
                </div>
              </div>
            </div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <!-- pc -->
          <div
            class="col-lg-3 classic-left-one lunxry-right d-sm-none d-lg-block"
          >
            <span class="classic-span-one">现代轻奢</span>
            <span class="classic-span-two">{{ modernWritting }}</span>
            <div class="classic-span-three" @click="toModernLuxery">
              <img src="../../assets/img/sanjiao.png" alt="" />
              <span>MORE</span>
              <div class="classic-line"></div>
            </div>
          </div>
          <!-- 移动 -->
          <div class="row d-lg-none" v-if="modernList.length > 0">
            <div
              :class="{
                'col-sm-6': true,
                'classic-inner': true,
                'no-padding-right': index %2== 0 || index %2== 2,
                'no-padding-left': index %2== 1
              }"
              v-for="(item, index) in modernList"
              :key="index"
            >
              <div class="classic-item" @click="toMobileLuxery(index)">
                <span class="classic-item-one">{{ item.name }}</span>
                <img class="classic-item-img" :src="item.image" alt="" />
                <span class="classic-item-two">{{ item.introduction }}</span>
                <span class="classic-item-three" v-if="item.isSell == 1"
                  >￥{{ item.specialOfferMin }}</span
                >
                <span class="classic-item-three" v-else>咨询客服</span>
                <div class="isNull" v-if="item.inventory == 0">已售罄</div>
              </div>
            </div>
          </div>
          <div class="see-more" @click="toModernLuxery">查看更多</div>
        </div>
      </div>
    </div>
    <!-- 经典品鉴 -->
    <div class="container new-activity pointer">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="new-activity-top">
            <span class="name">经典品鉴</span>
            <div class="line d-sm-none d-lg-block"></div>
            <span class="introduce">{{ tastingWritting }}</span>
          </div>
        </div>
      </div>
      <div class="classic-drawing">
        <div class="row">
          <div class="classic-drawing-item" v-if="tastingList.length > 0">
            <div
              class="drawing"
              @click="
                toTastingDetail(
                  tastingList[0].commodityId,
                  tastingList[0].commodityType
                )
              "
            >
              <img
                :src="tastingList[0].commodityImage"
                class="img-fuild"
                alt=""
              />
              <div class="white-sanjiao"></div>
            </div>
            <div
              class="drawing-introduce"
              @click="
                toTastingDetail(
                  tastingList[0].commodityId,
                  tastingList[0].commodityType
                )
              "
            >
              <span class="drawing-introduce-one">{{
                tastingList[0].commodityName
              }}</span>
              <span class="drawing-introduce-two">{{
                tastingList[0].commodityIntroduction
              }}</span>
              <span class="drawing-introduce-three"
                >品鉴人：{{ tastingList[0].artistName }}</span
              >
              <span class="drawing-introduce-four"
                >品鉴结果: {{ tastingList[0].result }}</span
              >
            </div>
          </div>
          <div class="classic-drawing-item" v-if="tastingList.length > 1">
            <div
              class="drawing"
              @click="
                toTastingDetail(
                  tastingList[1].commodityId,
                  tastingList[1].commodityType
                )
              "
            >
              <img
                :src="tastingList[1].commodityImage"
                class="img-fuild"
                alt=""
              />
              <div class="white-sanjiao"></div>
            </div>
            <div
              class="drawing-introduce"
              @click="
                toTastingDetail(
                  tastingList[1].commodityId,
                  tastingList[1].commodityType
                )
              "
            >
              <span class="drawing-introduce-one">{{
                tastingList[1].commodityName
              }}</span>
              <span class="drawing-introduce-two">{{
                tastingList[1].commodityIntroduction
              }}</span>
              <span class="drawing-introduce-three"
                >品鉴人：{{ tastingList[1].artistName }}</span
              >
              <span class="drawing-introduce-four"
                >品鉴结果: {{ tastingList[1].result }}</span
              >
            </div>
          </div>
          <div class="classic-drawing-item" v-if="tastingList.length > 2">
            <div
              class="drawing-introduce"
              @click="
                toTastingDetail(
                  tastingList[2].commodityId,
                  tastingList[2].commodityType
                )
              "
            >
              <span class="drawing-introduce-one">{{
                tastingList[2].commodityName
              }}</span>
              <span class="drawing-introduce-two">{{
                tastingList[2].commodityIntroduction
              }}</span>
              <span class="drawing-introduce-three"
                >品鉴人：{{ tastingList[2].artistName }}</span
              >
              <span class="drawing-introduce-four"
                >品鉴结果:{{ tastingList[2].result }}</span
              >
            </div>
            <div
              class="drawing"
              @click="
                toTastingDetail(
                  tastingList[2].commodityId,
                  tastingList[2].commodityType
                )
              "
            >
              <img
                :src="tastingList[2].commodityImage"
                class="img-fuild"
                alt=""
              />
              <div class="white-sanjiao-reverse"></div>
            </div>
          </div>
          <div class="classic-drawing-item" v-if="tastingList.length > 3">
            <div
              class="drawing-introduce"
              @click="
                toTastingDetail(
                  tastingList[3].commodityId,
                  tastingList[3].commodityType
                )
              "
            >
              <span class="drawing-introduce-one">{{
                tastingList[3].commodityName
              }}</span>
              <span class="drawing-introduce-two">{{
                tastingList[3].commodityIntroduction
              }}</span>
              <span class="drawing-introduce-three"
                >品鉴人：{{ tastingList[3].artistName }}</span
              >
              <span class="drawing-introduce-four"
                >品鉴结果:{{ tastingList[3].result }}</span
              >
            </div>
            <div
              class="drawing"
              @click="
                toTastingDetail(
                  tastingList[3].commodityId,
                  tastingList[3].commodityType
                )
              "
            >
              <img
                :src="tastingList[3].commodityImage"
                class="img-fuild"
                alt=""
              />
              <div class="white-sanjiao-reverse"></div>
            </div>
          </div>
        </div>
        <div class="see-more" @click="tastingMore">查看更多</div>
      </div>
    </div>
    <!-- 公司理念 -->
    <div class="container company pointer">
      <div class="row d-sm-none d-lg-block">
        <div class="company-pc">
          <div class="col-lg-4">
            <img :src="companyObj.leftImage" alt="" />
          </div>
          <div class="col-lg-2">
            <img :src="companyObj.rightImage" />
          </div>
          <div class="col-lg-6 company-introduce">
            <span class="one">企业经营理念：</span>
            <span class="one">{{ companyObj.idea }}</span>
            <div class="com-line"></div>
            <span class="two">{{ companyObj.ideaDescription }}</span>
            <div class="see-more" @click="toMine">查看更多</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-lg-none">
          <div class="company-introduce">
            <span class="one">企业经营理念</span>
            <span class="two">{{ companyObj.idea }}</span>
          </div>
          <div class="company-item-img">
            <div class="col-sm-4">
              <img :src="companyObj.leftImage" class="img-auto" alt="" />
            </div>
            <div class="col-sm-2 no-padding">
              <img :src="companyObj.rightImage" class="img-auto" />
            </div>
            <div class="col-sm-6">
              <span class="two">{{ companyObj.ideaDescription }}</span>
            </div>
          </div>
          <div class="see-more" @click="toMine">查看更多</div>
        </div>
      </div>
    <!-- 。。。 -->
    <!-- <div class="fixed">
      <img
        src="../../assets/img/message.png"
        alt=""
        @click="getMessage"
        class="d-sm-none d-lg-block"
      />
      <img src="../../assets/img/top.png" alt="" @click="toTop" />
    </div> -->
    </div>

    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  indexApi,
  basicGetApi,
  indexActivityApi,
  contactApi,
} from "../../assets/api/api.js";
import { resultEmail, resultPhone } from "../../uitils/check.js";
export default {
  created() {
    this.indexFun();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      activityName: "",
      form: {
        name: "",
        sex: "",
        tel: "",
        email: "",
        message: "",
      },
      options: [
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      bigImgList: [],
      classifyList: [],
      activityVO: {},
      activityIndex: 1,
      totalActivityNum: 1000,
      classicWritting: "",
      modernWritting: "",
      tastingWritting: "",
      classicList: [],
      modernList: [],
      tastingList: [],
      companyObj: {},
      centerDialogVisible: false,
      centerDialogVisible2: false,
      message: "",
      isMobile: false,
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    chooseAdvertist(index) {
      if (this.classifyList[index].name == "特价优惠") {
        this.toDiscount(index);
      } else if (this.classifyList[index].name == "限时抢购") {
        this.toBuy(index);
      } else if (this.classifyList[index].name == "新品推荐") {
        this.toPurchase(index);
      } else {
        this.floorDetail(index);
      }
    },
    //首页
    async indexFun() {
      let res1 = await indexApi();
      res1 = res1.data.data;
      console.log(res1);
      sessionStorage.setItem("flag", res1.flag);
      // 活动数组
      this.activityVO = res1.activityVO;
      // swiper
      let totalList = res1.advertisingVOList;
      for (let i = 0; i < totalList.length; i++) {
        if (totalList[i].isType == 1) {
          this.classifyList.push(totalList[i]);
        } else {
          this.bigImgList.push(totalList[i]);
        }
      }
      console.log(this.bigImgList);
      // 古典精选
      this.classicList = res1.claCommodityVOList;
      // 现代轻奢
      this.modernList = res1.modCommodityVOList;
      // 品鉴
      this.tastingList = res1.tastingWorkVOList;
      // 公司
      this.companyObj = res1.aboutUsVO;
      let res2 = await basicGetApi();
      res2 = res2.data.data;
      this.activityName = res2.activityCopywriting;
      this.classicWritting = res2.classicalCopywriting;
      this.modernWritting = res2.modernCopywriting;
      this.tastingWritting = res2.tastingCopywriting;
      console.log(res2);

      this.swiperFun();
    },
    // 左侧按钮
    async toLeftActivity(e) {
      e.stopPropagation();
      this.activityIndex--;
      let res = await indexActivityApi({ pageNo: this.activityIndex });
      res = res.data.data;
      this.activityVO = res.items[0];
      console.log(this.activityVO);
    },
    // 右侧按钮
    async toRightActivity(e) {
      e.stopPropagation();
      this.activityIndex++;
      let res = await indexActivityApi({ pageNo: this.activityIndex });
      console.log(res.data.data);
      res = res.data.data;
      this.activityVO = res.items[0];
      this.totalActivityNum = res.total;
    },
    //活动跳转
    toActivity(e) {
      e.stopPropagation();
      this.$router.push("/member");
    },
    // 单个活动跳转
    activityOneMore() {
      console.log(this.activityVO);
      this.$router.push({
        path: "/member-detail",
        query: {
          id: this.activityVO.id,
        },
      });
    },
    // 特价优惠跳转
    toDiscount(index) {
      this.$router.push({
        path:"/index/discount",
        query:{
          id:this.classifyList[index].id
        }
      });
    },
    // 新品详情跳转
    toPurchase(index) {
      this.$router.push({
        path:"/index/new-product",
        query:{
          id:this.classifyList[index].id
        }
      });
    },
    //限时抢购
    toBuy(index) {
      this.$router.push({
        path:"/index/buy-limit",
        query:{
          id:this.classifyList[index].id
        }
      });
    },
    // 古典精选
    toClassic() {
      this.$router.push("/classic-collection");
    },
    // 古典精选详情
    toClassicItem(id) {
      this.$router.push({
        path: "/classic-detail",
        query: {
          id: id,
        },
      });
    },
    // 移动古典精选详情
    toMobileClassic(id) {
      this.$router.push({
        path: "/mobile/classic-detail",
        query: {
          id: this.classicList[id].id,
        },
      });
    },
    // 移动现代轻奢详情
    toMobileLuxery(id) {
      this.$router.push({
        path: "/mobile/luxery-detail",
        query: {
          id: this.modernList[id].id,
        },
      });
    },
    // 现在轻奢
    toModernLuxery() {
      this.$router.push("/luxery-collection");
    },

    //现代轻奢详情
    toLuxeryItem(id) {
      this.$router.push({
        path: "/luxery-detail",
        query: {
          id: id,
        },
      });
    },
    // 品鉴更多
    tastingMore() {
      this.$router.push("/tasting-online");
    },
    // 我的
    toMine() {
      this.$router.push("/about-us");
    },
    // 留言
    getMessage() {
      this.centerDialogVisible = true;
    },
    //轮播图
    swiperFun() {
      new this.Swiper("#swiper1", {
        direction: "horizontal", // 垂直切换选项
        loop: true, // 循环模式选项
        autoplay: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      new this.Swiper(".swiper-container-two", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 3,
        observer: true,
        observeParents: true,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    toTop() {
      this.$("html,body").animate({ scrollTop: 0 });
    },
    toAdvertDetail(index) {
      if (this.bigImgList[index].type == 2) {
        this.$router.push({
          path: "/advertist",
          query: {
            id: this.bigImgList[index].id,
          },
        });
      } else if (this.bigImgList[index].type == 3) {
        // location.href = this.bigImgList[index].link;
        window.open(this.bigImgList[index].link);
      } else if (this.bigImgList[index].type == 1) {
        this.$router.push({
          path: "/advertistShopping",
          query: {
            id: this.bigImgList[index].id,
          },
        });
      } else if (this.bigImgList[index].type == 4) {
        this.$router.push({
          path: "/adverActivity",
          query: {
            id: this.bigImgList[index].id,
          },
        });
      }
    },
    floorDetail(index) {
      console.log(this.classifyList[index]);
      if (
        this.classifyList[index].type == 2 ||
        this.classifyList[index].type == 5
      ) {
        this.$router.push({
          path: "/advertist",
          query: {
            id: this.classifyList[index].id,
          },
        });
      } else if (this.classifyList[index].type == 3) {
        // location.href = this.classifyList[index].link;
        window.open(this.classifyList[index].link);
      } else if (this.classifyList[index].type == 1) {
        this.$router.push({
          path: "/advertistShopping",
          query: {
            id: this.classifyList[index].id,
          },
        });
      } else if (this.classifyList[index].type == 4) {
        this.$router.push({
          path: "/adverActivity",
          query: {
            id: this.classifyList[index].id,
          },
        });
      }
    },
    toTastingDetail(id, type) {
      if (type == 0) {
        if (this.isMobile) {
          this.$router.push({
            path: "/mobile/classic-detail",
            query: {
              id: id,
            },
          });
        } else {
          this.$router.push({
            path: "/classic-detail",
            query: {
              id: id,
            },
          });
        }
      } else {
        if (this.isMobile) {
          this.$router.push({
            path: "/mobile/luxery-detail",
            query: {
              id: id,
            },
          });
        } else {
          this.$router.push({
            path: "/luxery-detail",
            query: {
              id: id,
            },
          });
        }
      }
    },
  },
  // mounted() {
  //   let that = this;
  //   window.addEventListener("scroll", function (e) {
  //     if (document.documentElement.scrollTop > 1000) {
  //       that.$(".fixed").css("display", "block");
  //     } else {
  //       that.$(".fixed").css("display", "none");
  //     }
  //   });
  // },
};
</script>


<style lang="less" scoped>
.classic_mobile {
  margin-top: 63px;
}
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
@keyframes top {
  100% {
    scrolltop: 0;
  }
}

.row {
  margin: 0;
}

.to-top {
  animation: top 1s ease-in-out;
}
.index-container {
  color: #111;
  .swiper-container {
    width: 100%;
    img {
      width: 100%;
      // max-height: 580px;
    }
  }
}

.new-activity {
  margin-top: 100px;
  .new-activity-top {
    margin: 0 auto;
    width: 280px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .name {
      font-size: 36px;
      font-weight: 500;
    }
    .line {
      display: block;
      width: 100px;
      height: 1px;
      background-color: #111;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .introduce {
      font-size: 14px;
      text-align: center;
    }
  }
  .activity-detail {
    background-color: #f8f8f8;
    padding-bottom: 20px;
    margin-top: 154px !important;
    position: relative;
    .activity-img {
      position: absolute;
      left: 60px;
      bottom: 40px;
      img {
        width: 100%;
        height: 100%;
      }
      .bianzu {
        width: 100px;
        height: 50px;
        position: absolute;
        left: -10px;
        top: 25px;
      }
    }
    @media (min-width: 300px) and (max-width: 992px) {
      .activity-img {
        position: static;
        .bianzu {
          left: -7px;
        }
      }
    }
    .activity-info {
      position: relative;
      padding-left: 100px;
      margin-top: 70px;
      .activity-info-one {
        font-size: 20px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .activity-info-two {
        font-size: 14px;
        color: #444;
        margin-top: 16px;
      }
      .activity-three {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 14px;
        color: #bba492;
        span {
          display: inline-block;
          padding: 4px 15px;
          border: 1px solid #bba492;
          margin-right: 15px;
        }
      }
      .activity-info-four {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 700;
      }
      .activity-info-five {
        margin-top: 30px;
        button {
          background-color: #000;
          font-weight: 500;
          color: #fff;
          font-size: 16px;
          appearance: none;
          outline: none;
          border: 0;
          padding: 10px 30px;
        }
      }

      .activity-info-six {
        position: absolute;
        top: -160px;
        width: 100%;
        display: flex;
      }
    }
    @media (min-width: 300px) and (max-width: 992px) {
      .activity-info {
        position: static;
        padding-left: 20px;
        margin-top: 20px;
        .activity-info-six {
          top: -50px;
          left: 0;
          .more {
            right: 0;
          }
        }
      }
    }
  }
  @media (min-width: 300px) and (max-width: 992px) {
    .activity-detail {
      margin-top: 70px !important;
    }
  }
}
.more {
  position: absolute;
  right: 105px;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  color: #111;
  span {
    margin-right: 10px;
  }
}

.more-line {
  width: 90px;
  height: 1px;
  background-color: #111;
  position: absolute;
  right: 0;
  top: 35px;
}

.discount {
  margin-top: 160px;
}

@media (min-width: 300px) and (max-width: 992px) {
  .discount-item-bottom {
    margin-bottom: 30px;
    background-color: #fff !important;
  }
  .my-font {
    margin-top: 15px !important;
  }
  .discount-left {
    padding: 40px 0 !important;
    background-color: #f3f3f3;
    img {
      height: 340px !important;
    }
  }
  .discount {
    margin-top: 40px;
  }
  .discount-normal {
    padding: 20px 0 !important;
    overflow: hidden;
  }
}

.discount-item-bottom {
  background-color: #f3f3f3;
  transition: all 0.3s ease;
}

.discount-left {
  text-align: center;
  padding: 80px 0;
  img {
    width: 70%;
    height: 430px;
  }
}

.my-font {
  font-size: 24px;
  font-weight: 500;
  color: #111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 66px;
  .font-bottom {
    margin-top: 15px;
    font-size: 12px;
    color: #666;
  }
}

.discount-normal {
  padding: 33px 0 25px 0;
  text-align: center;
  background-color: #f3f3f3;
  transition: all 0.3s ease;
  height: 383px;
  img {
    width: 70%;
    max-height: 200px;
  }
  .my-font {
    margin: 10px;
  }
}

.discount-mar {
  margin-top: 30px;
}

.discount-item-bottom:hover {
  transform: translate(1px, 1px);
  box-shadow: 0px 6px 20px 0px rgba(153, 153, 153, 0.4);
}
.discount-normal:hover {
  transform: translate(1px, 1px);
  box-shadow: 0px 6px 20px 0px rgba(153, 153, 153, 0.4);
}

.classic {
  background-color: #eee;
  margin-top: 160px;
  padding: 90px 0;
}

@media (min-width: 300px) and (max-width: 992px) {
  .classic {
    background-color: #eee;
    margin-top: 30px;
    padding-top: 0;
    padding-bottom: 30px;
    .classic-item {
      width: 100%;
    }
    .classic-inner {
      padding: 0 15px;
      margin-bottom: 20px;
    }
    .classic-left-one {
      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .classic-span-one {
      margin-top: 30px !important;
      border-bottom: 1px solid #111;
      padding-bottom: 10px;
    }
    .classic-span-two {
      margin-top: 10px !important;
      margin-bottom: 30px;
      text-align: center;
    }
    .see-more {
      margin-top: 10px;
      // display: block;
    }
    .classic-main {
      padding-bottom: 30px !important;
    }
    .luxery {
      margin-top: 20px;
    }
  }
  .no-padding-right {
    padding-right: 5px !important;
  }
  .no-padding-left {
    padding-left: 5px !important;
  }
  .classic-item-one img {
    width: 134px;
    height: 134px;
  }
  .classic-item img {
    width: 134px;
    height: 134px;
  }
}

.classic-left-one {
  padding-right: 70px;
  span {
    display: inline-block;
  }
  .classic-span-one {
    font-size: 36px;
    font-weight: 500;
    color: #111;
    margin-top: 160px;
  }
  .classic-span-two {
    font-size: 14px;
    color: #111;
    margin-top: 20px;
  }
  .classic-span-three {
    font-size: 16px;
    color: #111;
    font-weight: 500;
    margin-top: 40px;
    span {
      margin-left: 10px;
    }
    .classic-line {
      width: 90px;
      height: 1px;
      background-color: #111;
    }
  }
}

.swiperCon {
  overflow: hidden;
}

.classic-item {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}
.isNull {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  width: 80%;
  height: 100px;
  background-color: rgba(17, 17, 17, 0.5);
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.search-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 45px solid #bba492;
  border-right-color: transparent;
  border-bottom-color: transparent;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.search-icon-img {
  position: absolute;
  top: 13px;
  left: 13px;
  opacity: 0;
  transition: all 0.5s ease;
}

.classic-item:hover .search-icon {
  opacity: 1;
}

.classic-item:hover .search-icon-img {
  opacity: 1;
}

.classic-item:hover {
  box-shadow: 0px 6px 20px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.classic-item-img {
  width: 80%;
  margin-top: 30px;
}

.classic-item-one {
  margin-top: 40px;
  font-size: 20px;
  padding: 0 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.classic-item-two {
  margin-top: 30px;
  font-size: 14px;
  color: #999;
  padding: 0 20px;
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.classic-item-three {
  margin-top: 25px;
  font-size: 24px;
  color: #111;
  font-weight: 500;
  margin-bottom: 40px;
}

.swiper-container-two .swiper-button-prev {
  left: 30px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #999;
  cursor: pointer;
}
.swiper-container-two .swiper-button-next {
  right: 30px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #999;
  cursor: pointer;
}

#swiper1 .swiper-button-prev {
  left: 0;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #999;
}
#swiper1 .swiper-button-next {
  right: 0;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #999;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: painted;
}

.classic-main {
  padding-bottom: 90px !important;
  border-bottom: 1px solid #fff;
}

.luxery {
  margin-top: 90px;
}

.lunxry-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 0;
  padding-left: 70px;
  .classic-span-two {
    text-align: right;
  }
}

.isNone {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(17, 17, 17, 0.5);
  height: 100px;
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 10px;
  text-align: center;
}
.drawing {
  padding: 0;
  position: relative;
}

.drawing img {
  width: 100%;
  padding: 0;
}

.white-sanjiao {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-right-color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.white-sanjiao-reverse {
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-left-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.drawing-introduce {
  display: inline-block;
  padding: 0 30px 0 30px;
  width: 50%;
  height: 300px;
  color: #111;
  .drawing-introduce-one {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
  }
  .drawing-introduce-two {
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-top: 10px;
  }
  .drawing-introduce-three {
    display: inline-block;
    font-size: 14px;
    color: #666;
    margin-top: 30px;
  }
  .drawing-introduce-four {
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-top: 10px;
  }
}

.classic-drawing {
  margin-top: 30px !important;
}

.see-more {
  width: 220px;
  height: 60px;
  background-color: #111;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.company {
  margin-top: 200px;
  margin-bottom: 90px;
}

.company img {
  width: 100%;
  height: 100%;
  max-height: 500px;
}

.company-pc {
  display: flex;
}

.company-introduce {
  .one {
    display: block;
    font-size: 38px;
    color: #111;
    font-weight: 500;
  }
  .com-line {
    width: 100px;
    height: 3px;
    background-color: #111;
    margin-top: 40px;
  }
  .two {
    display: block;
    margin-top: 60px;
    font-size: 16px;
  }
  .see-more {
    margin-top: 80px;
    margin-left: 0;
  }
}
.company-item-img {
  .two {
    font-size: 16px;
  }
}
.fixed {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 40px;
  img {
    height: 50px;
    width: 50px;
    display: block;
    margin-top: 20px;
  }
}

.company-item-img {
  margin-top: 30px;
  display: flex !important;
}

@media (min-width: 300px) and (max-width: 992px) {
  .new-activity {
    margin-top: 30px !important;
    .new-activity-top {
      width: 100% !important;
    }
    .name {
      border-bottom: 1px solid #111;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .drawing {
      margin-bottom: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .see-more {
      margin-top: 0;
    }
    .drawing-introduce-three {
      margin-top: 10px;
    }
  }
  .company {
    margin-top: 50px;
    border-top: 1px solid #eee;
    padding-top: 30px !important;
    .company-introduce {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .one {
        border-bottom: 2px solid #111;
      }
      .two {
        margin-top: 20px;
      }
    }
  }
  .isNull {
    font-size: 30px;
  }
  .classic-drawing {
    padding: 0 15px;
  }
  .classic-drawing-item {
    width: 100% !important;
  }

  .classic-drawing-item .drawing {
    width: 50%;
  }
  .classic-drawing-item .white-sanjiao {
    right: -1px;
  }
}
//////////////////////////////////

.dialog-footer {
  font-size: 16px;
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 30px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

.default-address-span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #bba492;
}

.el-dialog__body {
  padding: 25px 35px 30px !important;
}

.el-select {
  width: 100%;
}

/deep/.el-dialog {
  width: 610px !important;
}

.pointer {
  cursor: pointer;
}

.no-padding {
  padding: 0;
}

.add-margin {
  margin: 0 15px;
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

/deep/ .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: #ccc;
  opacity: 1;
  cursor: pointer;
}
/deep/
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 14px;
}

/deep/ .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
}

.classic-drawing-item {
  width: 50%;
  display: flex;
}
.classic-drawing-item .drawing {
  width: 50%;
}

@media (min-width: 300px) and (max-width: 992px) {
  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #ccc;
    opacity: 1;
    cursor: pointer;
  }
  /deep/
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 12px;
  }

  /deep/ .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 20px;
  }

  #swiper1 .swiper-button-prev {
    left: 0;
    background-image: url("../../assets/img/small-arrow-left.png");
    background-size: 18px 18px;
    background-color: #999;
    cursor: pointer;
  }
  #swiper1 .swiper-button-next {
    right: 0;
    background-image: url("../../assets/img/arrow-small-right.png");
    background-size: 18px 18px;
    background-color: #999;
    cursor: pointer;
  }

  .new-activity-top{
    .name{
      font-size: 28px !important;
    }

    .introduce{
      font-size: 12px !important;
    }
  }

  .bianzu{
    width: 80px !important;
    height: 40px !important;
  }

  .activity-info-one{
    font-size: 16px !important;
  }
  .activity-three{
    margin: 20px 0 !important;
  }
  .activity-info-two{
    font-size: 12px !important;
  }

  .activity-info-four{
    font-size: 16px !important;
    margin-top: 30px !important;
  }

  .activity-info-five{
    margin-top: 22px !important;
    font-size: 12px !important;
  }

  .my-font{
    font-size: 18px;
  }

  .classic-span-one{
    font-size: 28px !important;
  }

  .classic-span-two{
    font-size: 12px !important;
  }

  .classic-item-one{
    font-size: 16px !important;
  }

  .classic-item-two{
    font-size: 12px !important;
  }

  .classic-item-three{
    font-size: 18px !important;
  }

  .see-more{
    font-size: 12px;
    width: 180px;
    height: 50px;
  }

  .classic-drawing{
    margin-top: 10px !important;
  }

  .drawing-introduce{
    padding: 0 15px;
  }

  .drawing-introduce-one{
    font-size: 18px !important;
    margin-top: 20px !important;
  }

  .drawing-introduce-two{
    font-size: 12px !important;
  }

  .drawing-introduce-three{
    font-size: 12px !important;
  }

  .drawing-introduce-four{
    font-size: 12px !important;
  }

  .drawing-introduce{
    height: 240px;
  }

  .company-introduce{
    .one{
      font-size: 28px;
    }

    .two{
      font-size: 12px;
    }
  }

  .company-item-img .two{
    font-size: 12px;
  }

  // .company-item-img img{
  //   width: 100%;
  //   height: auto;
  // }
}
</style>

<style>
.swiper-pagination-bullet-active {
  background: #bba492 !important;
}

.img-auto{
  object-fit: cover;
}
</style>