<template>
  <div class="info container" v-on:scroll.passive="onScroll" ref="box">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>发票管理</span>
    </div>
    <div class="col-sm-12">
      <div class="time">
        <el-date-picker
          class="none-border"
          v-model="value1"
          type="date"
          placeholder="开具开始时间"
          format:yyyy-MM-dd
          @change="timeChange1"
        >
        </el-date-picker>
        <div class="line"></div>
        <el-date-picker
          class="picker-two"
          v-model="value2"
          type="date"
          placeholder="开具结束时间"
          format:yyyy-MM-dd
          @change="timeChange2"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="nav-collection">
        <span
          :class="{ active: navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
          @click="chooseNav(item.id)"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div class="col-sm-12">
      <div class="manager-domain">
        <div
          class="manager-domain-item"
          v-for="(item, index) in shopList"
          :key="index"
        >
          <div class="manager-domain-top">
            <span>发票ID：{{ item.invoiceId?item.invoiceId:'暂无' }}</span>
            <span class="user">开具用户：{{ item.invoiceHeader }}</span>
          </div>
          <div class="manager-domain-container">
            <div class="manager-domain-container-left">
              <div class="one">
                <span class="shop">商品</span>
                <span class="invoice" v-if="item.isType == 0">纸质发票</span>
                <span class="invoice" v-else>电子发票</span>
              </div>
              <div class="two">
                <span class="first">申请时间</span>
                <span class="second">{{ item.createTime }}</span>
              </div>
              <div class="two">
                <span class="first">开具时间</span>
                <span class="second">{{
                  item.invoiceTime ? item.invoiceTime : "未开具"
                }}</span>
              </div>
            </div>
            <div class="manager-domain-container-right">
              <span>￥{{ item.payMoney }}</span>
            </div>
          </div>
          <div class="manager-domain-bottom">
            <div class="bottom-left">
              <span v-if="item.state == 0">待邮寄</span>
              <span
                v-if="item.state == 1"
                @click="toLogisitic(item.invoiceNumber)"
                >已邮寄</span
              >
              <span v-if="item.state == 2">未开票</span>
              <span v-if="item.state == 3">已开票</span>
            </div>
            <div class="bottom-right">
              <div
                :class="{
                  btn: true,
                  'btn-use': item.state == 1,
                  'btn-useless': item.state == 0,
                }"
                v-if="item.state == 1 || item.state == 0"
                @click="toInvoiceLogistic(item)"
              >
                查看物流
              </div>
              <div
                :class="{
                  btn: true,
                  'btn-use': item.state == 3,
                  'btn-useless': item.state == 2,
                }"
                v-else
                @click="download(item.image, item.link, 'invoince', item.state)"
              >
                下载发票
              </div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="shopList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
      </div>
      <div class="loading" :class="{ 'loading-hidden': loading }">
        {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
      </div>
    </div>
  </div>
</template>



<script>
import { getMineInvoicePage } from "../../assets/api/api.js";
import { formatDate } from "../../uitils/check.js";
export default {
  created() {
    this.getInfo();
  },
  data() {
    return {
      navList: [
        {
          id: -1,
          text: "全部发票",
        },
        {
          id: 0,
          text: "纸质发票",
        },
        {
          id: 1,
          text: "电子发票",
        },
      ],
      shopList: [],
      navIndex: 0,
      startTime: "",
      endTime: "",
      isType: -1,
      pageNo: 1,
      pageSize: 3,
      value1: "",
      value2: "",
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      total: 0,
    };
  },
  methods: {
    toPreview() {
      this.$router.go(-1);
    },
    chooseNav(id) {
      this.navIndex = id + 1;
      this.isType = id;
      this.shopList = [];
      this.pageNo = 1;
      this.getInfo();
    },
    // 获取发票数据
    async getInfo() {
      let res = await getMineInvoicePage({
        userId: 1,
        startTime: this.startTime,
        endTime: this.endTime,
        isType: this.isType,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      if (res.data.result == 0) {
        this.loading = true;
      }
      res = res.data.data;
      this.total = res.total;
      this.shopList = this.shopList.concat(res.items);
      console.log(res);
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        console.log(this.shopList.length);
        if (this.shopList.length < this.total) {
          this.loading = false;
          this.getInfo();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
      }
    },
    timeChange1(val) {
      this.value1 = formatDate(val);
      console.log(this.value1);
    },
    timeChange2(val) {
      if (val == null) {
        this.value1 = "";
        this.value2 = "";
      } else {
        this.value2 = formatDate(val);
      }
      this.startTime = this.value1;
      this.endTime = this.value2;
      this.pageNo = 1;
      this.shopList = [];
      this.getInfo();
    },
    toLogisitic(invoiceNumber) {
      this.$router.push({
        path: "/mine/logistic",
        query: {
          orderNumber: invoiceNumber,
        },
      });
    },
    download(imgsrc, link, name, state) {
      if (link) {
        window.open(link);
      } else {
        if (state == 3) {
          let image = new Image();
          image.setAttribute("crossOrigin", "anonymous");
          image.onload = function () {
            let canvas = document.createElement("canvas");

            canvas.width = image.width;

            canvas.height = image.height;

            let context = canvas.getContext("2d");

            context.drawImage(image, 0, 0, image.width, image.height);

            let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

            let a = document.createElement("a"); // 生成一个a元素

            let event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性

            a.dispatchEvent(event); // 触发a的单击事件
          };
          image.src = imgsrc;
        }
      }
    },
    toInvoiceLogistic(item) {
      console.log(item);
      if (item.state == 1) {
        this.$router.push({
          path: "/mine/minvoice",
          query: {
            orderNumber: item.invoiceNumber,
          },
        });
      }
    },
  },
};
</script>


<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    padding-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .nav-collection {
    font-size: 16px;
    margin-top: 25px;
    span {
      margin-right: 20px;
      color: #666666;
    }
    .active {
      font-size: 18px;
      color: #111;
    }
  }
}

.manager-domain {
  margin-top: 15px;
  .manager-domain-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    .manager-domain-top {
      font-size: 12px;
      color: #999;
      .user {
        margin-left: 20px;
      }
    }
    .manager-domain-container {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      .manager-domain-container-left {
        .one {
          margin-bottom: 16px;
          .shop {
            font-size: 17px;
            color: #111;
            font-weight: 500;
          }
          .invoice {
            background-color: #bba492;
            color: #fff;
            padding: 3px;
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .two {
          font-size: 14px;
          margin-top: 10px;
          .first {
            color: #999;
          }
          .second {
            color: #666;
            margin-left: 10px;
          }
        }
      }
      .manager-domain-container-right {
        font-size: 20px;
        color: #111;
        font-weight: 500;
      }
    }
    .manager-domain-bottom {
      display: flex;
      justify-content: space-between;
      .bottom-left {
        color: #bba492;
      }
      .bottom-right {
        font-size: 16px;
        .btn {
          width: 80px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }
        .btn-use {
          border: 1px solid #111;
        }
        .btn-useless {
          background-color: #f5f5f5;
          color: #ccc;
        }
      }
    }
  }
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

/////////////////////////////////////////
.time {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #111;
  .line {
    width: 6%;
    height: 1px;
    background-color: #111;
  }
}

/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 45%;
}
/deep/ .el-input__inner {
  border: 0;
}

/deep/ .picker-two .el-input__prefix {
  display: none;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px;
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>