<template>
  <div class="container">
    <div class="row">
      <div class="imgText" v-html="infos"></div>
    </div>
  </div>
</template>


<script>
import { getDetailBrand } from "../../assets/api/api.js";
export default {
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  data() {
    return {
      id: 0,
      infos: '',
    };
  },
  methods: {
    getInfo() {
      getDetailBrand({
        id: this.id,
      }).then((res) => {
        console.log(res);
        if (this.$route.query.type == 0) {
          this.infos = res.data.data.details;
        }else if (this.$route.query.type == 1) {
          this.infos = res.data.data.content;
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
/deep/ .imgText {
  font-size: 14px;
  margin-top: 60px;
  padding: 10px;
  width: 100%;
  img {
    width: 100% !important;
  }
}
</style>