<template>
  <div class="col-lg-10">
    <div class="order">
      <div class="top-nav">
        <div class="top-nav-left">
          <div
            :class="{ 'left-item': true, 'select-nav': chooseNav == index }"
            v-for="(item, index) in navList"
            :key="index"
            @click="toChooseNav(index)"
          >
            <span
              :class="{ 'left-item-span': true, 'border-none': index == 4 }"
              >{{ item.text }}</span
            >
          </div>
        </div>
        <div class="top-nav-right">
          <img
            class="search-img"
            src="../../assets/img/search_black.png"
            alt=""
          />
          <input
            class="search-input"
            type="text"
            placeholder="输入商品名称或订单号"
            v-model="nameOrNumber"
          />
          <button
            class="btn-one"
            @click="toInputSearch"
            style="cursor: pointer"
          >
            搜索
          </button>
          <button
            class="btn-one btn-two"
            @click="applyInvoice"
            style="cursor: pointer"
          >
            申请开票
          </button>
        </div>
      </div>
      <!-- 全部信息 -->
      <div class="order-container">
        <div class="all row no-gutters">
          <div class="col-lg-2 my-center">
            <img
              src="../../assets/img/icon_sel_s.png"
              alt=""
              v-if="totalChoosed"
              @click="closeAll"
              style="cursor: pointer"
            />
            <img
              src="../../assets/img/icon_sel_n.png"
              alt=""
              @click="chooseAll"
              v-else
              style="cursor: pointer"
            />
            <span class="select-all">全选</span>
          </div>
          <div class="col-lg-3 my-center">
            <span>商品信息</span>
          </div>
          <div class="col-lg-2 my-center">
            <span>网点</span>
          </div>
          <div class="col-lg-1 my-center">
            <span>数量</span>
          </div>
          <div class="col-lg-1 my-center order-center">
            <span>订单总额</span>
          </div>
          <div class="col-lg-1 my-center order-center">
            <span>交易状态</span>
          </div>
          <div class="col-lg-2 my-center order-center">
            <span>操作</span>
          </div>
        </div>

        <div class="all-item" v-for="(item, index) in orderList" :key="index">
          <div class="all-item-top my-center">
            <div class="has-img" v-if="item.state == 4">
              <img
                src="../../assets/img/icon_sel_s.png"
                alt=""
                v-if="item.flag"
                @click="item.flag = false"
              />
              <img
                src="../../assets/img/icon_sel_n.png"
                alt=""
                @click="item.flag = true"
                v-else
              />
            </div>
            <span class="time">下单时间：{{ item.orderTime }}</span>
            <span class="order-num">订单号：{{ item.orderNumber }}</span>
            <div class="to-back-money" v-if="item.number&&item.cautionMoney>0">
              <img src="../../assets/img/warning.png" alt="">
              <span>已支付保证金￥{{item.cautionMoney}},将在支付订单后退还至原账户</span>
            </div>
            <div
              class="has-img-right"
              v-if="item.state == 0 || item.state == 4"
              @click="deleteOneOrder(item.id)"
            >
              <img
                src="../../assets/img/ic_dele.png"
                alt=""
                style="cursor: pointer"
              />
            </div>
          </div>
          <div class="all-item-container row no-gutters">
            <div class="col-lg-8">
              <div
                class="all-item-one row no-gutters"
                v-for="(item2, index2) in item.orderCommodityVOList"
                :key="index2"
              >
                <div class="col-lg-2 one">
                  <img :src="item2.commodityImage" alt="" />
                </div>
                <div class="col-lg-4 two">
                  <span class="name">{{ item2.commodityName }}</span>
                  <span class="size">{{ item2.specificationValueName }}</span>
                </div>
                <div class="col-lg-4 three">
                  <span>{{ item.outletsArea + item.outletsName }}</span>
                </div>
                <div class="col-lg-2 four">
                  <span>x{{ item2.quantity }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-1 five order-center order-border">
              <span>￥{{ item.orderMoney }}</span>
            </div>
            <div class="col-lg-1 order-center order-border">
              <span v-if="item.state == 0">已取消</span>
              <span v-if="item.state == 1" style="color: red">待付款</span>
              <span v-if="item.state == 2">待发货</span>
              <span v-if="item.state == 3">已发货</span>
              <span v-if="item.state == 4">已完成</span>
            </div>
            <div class="col-lg-2 order-center order-border">
              <div class="order-option" v-if="item.state == 1">
                <span
                  class="one two four"
                  @click="toPayMoney(item)"
                  style="cursor: pointer"
                  >付款 {{ item.now }}</span
                >
                <span
                  class="one three four"
                  style="cursor: pointer"
                  @click="cancelOrder(item.id)"
                  >取消订单</span
                >
                <span
                  class="five"
                  @click="seeDetail(item.id)"
                  style="cursor: pointer"
                  >查看详情</span
                >
              </div>
              <div class="order-option" v-if="item.state == 0">
                <span @click="seeDetail(item.id)" style="cursor: pointer"
                  >查看详情</span
                >
              </div>
              <div class="order-option" v-if="item.state == 2">
                <span @click="seeDetail(item.id)" style="cursor: pointer"
                  >查看详情</span
                >
              </div>
              <div class="order-option" v-if="item.state == 3">
                <span
                  v-if="item.state == 3"
                  class="five"
                  @click="toSeeLogistics(item.orderNumber)"
                  style="cursor: pointer"
                  >查看物流</span
                >
                <span
                  class="six"
                  @click="seeDetail(item.id)"
                  style="cursor: pointer"
                  >查看详情</span
                >
              </div>
              <div class="order-option" v-if="item.state == 4">
                <span
                  v-if="item.state == 4 && item.invoice == 0"
                  class="five"
                  @click="invoiceOne(item.orderNumber)"
                  style="cursor: pointer"
                  >申请开票</span
                >
                <span
                  v-if="item.state == 4 && item.invoice == 1"
                  class="five"
                  style="cursor: pointer"
                  >已申请</span
                >
                <span
                  class="six"
                  @click="seeDetail(item.id)"
                  style="cursor: pointer"
                  >查看详情</span
                >
              </div>
            </div>
            <div class="prize" v-if="item.number">
              <div>
                <span class="get-prize">恭喜中奖！</span>
                <span
                  >中奖号码：{{
                    item.number
                  }}，请在开奖后24小时内付款，否则平台将自动取消您的购买资格</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="orderList.length == 0">
          <img src="../../assets/img/all-null.png" alt="">
          <span>这里空空的什么也没有~</span>
        </div>
        <div id="myForm"></div>
        <div class="discount-bottom container" v-if="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            class="discount-bottom-pagation"
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
          >
          </el-pagination>
          <span class="page-span">共{{ total }}个</span>
        </div>
      </div>
    </div>
    <div class="message" id="message">{{ message }}</div>
    <div class="scanPay" v-show="isshowScan">
      <img
        class="closeIMg"
        src="../../assets/img/ic_menu_close.png"
        alt=""
        @click="isshowScan = false"
      />
      <span>扫码付款</span>
      <div id="qrcode" ref="qrcode"></div>
      <div class="isPay" @click="seeStatus">如果已付款，请点击</div>
    </div>
    <el-dialog
      title="选择付款类型"
      :visible.sync="typeVisible"
      width="400px"
      center
    >
      <div class="moneyType">
        <div class="moneyType-item">
          <img src="../../assets/img/zhifubao.png" alt="">
          <span>支付宝</span>
          <img v-if="payAli" src="../../assets/img/ic_gou_s.png" class="to-right" alt="">
          <img v-else src="../../assets/img/ic_gou_n.png" class="to-right" alt="" @click="payAli = true">
        </div>
        <div class="moneyType-item">
          <img src="../../assets/img/wechart.png" alt="">
          <span>微信</span>
          <img v-if="!payAli" class="to-right" src="../../assets/img/ic_gou_s.png" alt="">
          <img v-else src="../../assets/img/ic_gou_n.png" class="to-right" alt="" @click="payAli = false">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogPayMoney"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  myOrderPage,
  deleteOrder,
  payOrder,
  wxResult,
  removeOrder,
} from "../../assets/api/api.js";
import { loopDate } from "../../uitils/check.js";
import QRCode from "qrcodejs2";
export default {
  // name:'shopList',
  created() {
    this.getData();
  },
  data() {
    return {
      navList: [
        {
          text: "全部订单",
        },
        {
          text: "待付款",
        },
        {
          text: "待发货",
        },
        {
          text: "已发货",
        },
        {
          text: "已完成",
        },
      ],
      orderList: [],
      sid: 0,
      nameOrNumber: "",
      chooseNav: 0,
      totalChoosed: false,
      isInvoice: false,
      nameOrNumber: "",
      state: -1,
      total: 0,
      pageSize: 4,
      currentPage: 1,
      message: "",
      isshowScan: false,
      orderId: "",
      typeVisible:false,
      payAli:true,
      infoItem:''
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    toChooseNav(index) {
      this.chooseNav = index;
      if (index == 0) {
        this.state = -1;
      } else {
        this.state = index;
      }

      this.getData();
    },
    // 全选
    chooseAll() {
      this.totalChoosed = true;
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 4) {
          this.orderList[i].flag = true;
        }
      }
    },
    closeAll() {
      this.totalChoosed = false;
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 4) {
          this.orderList[i].flag = false;
        }
      }
    },
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await myOrderPage({
        nameOrNumber: this.nameOrNumber,
        userId: 1,
        state: this.state,
        pageNo: val,
        pageSize: this.pageSize,
      });
      console.log(res);
      res = res.data.data;
      this.total = res.total;
      this.orderList = res.items;
      this.getLoop();
    },
    // 获得数据
    async getData() {
      this.currentPage = 1;
      let res = await myOrderPage({
        nameOrNumber: this.nameOrNumber,
        userId: 1,
        state: this.state,
        pageNo: 1,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.orderList = res.items;
      console.log(res);
      this.getLoop();
    },
    // 查询
    toInputSearch() {
      this.getData();
    },
    // 时间减少
    getLoop() {
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].state == 1) {
          this.orderList[i].time--;
          this.orderList[i].now = loopDate(this.orderList[i].time);
          if (this.orderList[i].time == 0) {
            console.log("...");
            clearTimeout(this.sid);
            this.getData();
          }
        }
      }
      clearTimeout(this.sid);
      this.getLoopDetail();
    },
    getLoopDetail() {
      let that = this;
      this.sid = setTimeout(() => {
        that.getLoop();
      }, 1000);
    },
    // 删除订单
    async deleteOneOrder(id) {
      let res = await deleteOrder({
        id: id,
      });
      console.log(res);
      if (res.data.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      } else {
        this.message = "删除失败";
        this.showInfo();
      }
      this.getData();
    },
    // 查看详情
    seeDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/order-detail",
        query: {
          id: id,
        },
      });
    },
    // 查看物流
    toSeeLogistics(orderNumber) {
      console.log(orderNumber);
      this.$router.push({
        path: "/logistics",
        query: {
          orderNumber: orderNumber,
        },
      });
    },
    // 单个物流
    invoiceOne(orderNum) {
      this.$router.push({
        path: "/order-invoice",
        query: {
          arr: orderNum,
        },
      });
    },
    // 发票
    applyInvoice() {
      let arr = [];
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].flag) {
          arr.push(this.orderList[i].orderNumber);
        }
      }
      if (arr.length == 0) {
        this.message = "当前没有开票的订单";
        this.showInfo();
      } else {
        this.$router.push({
          path: "/order-invoice",
          ////////////////////////////////////////////////////////
          query: {
            arr: arr.join(","),
          },
        });
      }
    },
    toPayMoney(item) {
      this.payAli = true;
      this.typeVisible = true;
      this.infoItem = item;
      console.log(item);
    },
    dialogPayMoney(){
      let item = this.infoItem;
      this.typeVisible = false;
      payOrder({
        orderNumber: item.orderNumber,
        type: 0,
        payType:this.payAli?0:1
      }).then((res) => {
        console.log(res);
        this.orderId = res.data.order_number;
        if (this.payAli) {
          if (res.status == 200) {
            this.$("#myForm").html(res.data);
          }
        } else {
          this.isshowScan = true;
          this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
          let qrcode = new QRCode("qrcode", {
            width: 200, // 设置宽度，单位像素
            height: 200, // 设置高度，单位像素
            text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
          });
        }
      });
    },
    seeStatus() {
      wxResult({
        orderId: this.orderId,
      }).then((res) => {
        console.log(res);
        if (res.data.result == 0) {
          this.message = res.data.msg;
          this.showInfo();
          this.getData();
        } else {
          this.message = res.data.msg;
          this.showInfo();
        }
      });
    },
    cancelOrder(id) {
      console.log("...");
      removeOrder({
        id: id,
      }).then((res) => {
        if (res.data.result == 0) {
          this.message = "取消成功";
          this.showInfo();
          this.getData();
        } else {
          this.message = "取消失败";
          this.showInfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  background-color: #fff;
  padding: 0 15px;
  padding-bottom: 40px;
  .top-nav {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-nav-left {
      display: flex;
      align-items: center;
      height: 100%;
      .left-item {
        width: 110px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .left-item-span {
          font-size: 16px;
          text-align: center;
          border-right: 1px solid #eee;
          display: inline-block;
          width: 100%;
          color: #666;
        }
        .border-none {
          border-right: 0;
        }
      }
      .select-nav {
        font-size: 18px;
        font-weight: 500;
        color: #111;
        border-bottom: 2px solid #111;
      }
    }
    .top-nav-right {
      display: flex;
      position: relative;
      .search-input {
        padding-left: 40px;
        outline: none;
        border: 0;
        background-color: #f5f5f5;
        height: 30px;
        font-size: 12px;
        width: 240px;
      }
      .btn-one {
        height: 30px;
        width: 50px;
        background-color: #111;
        color: #fff;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        outline: none;
      }
      .btn-two {
        width: 80px;
        margin-left: 15px;
      }
      .search-img {
        position: absolute;
        top: 5px;
        left: 10px;
      }
    }
  }
  .order-container {
    font-size: 12px;
    color: #111;
    .all {
      background-color: #f3f3f3;
      margin-top: 20px !important;
      height: 40px;
      border: 1px solid #cccdcd;
      margin-bottom: 15px;
    }
  }
}
.my-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .select-all {
    margin-left: 20px;
  }
  img {
    margin-left: 20px;
  }
  .has-img-right {
    margin-left: auto;
    margin-right: 20px;
  }
}
.row {
  margin: 0;
}

.all-item {
  margin-top: 15px;
  border: 1px solid #cccdcd;
  .all-item-top {
    background: #f5f5f5;
    border-bottom: 1px solid #cccdcd;
    height: 40px;
    color: #333;
    .time {
      margin-left: 20px;
    }
    .order-num {
      margin-left: 60px;
    }
  }
  .all-item-container {
    .all-item-one {
      height: 145px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px dotted #cccdcd;
      padding-right: 20px;
      .one {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 90%;
          max-width: 100px;
          height: 100px;
          border: 1px solid #cccdcd;
          //   margin-left: 20px;
        }
      }
      .two {
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .name {
          font-size: 14px;
          font-weight: 500;
          display: block;
        }
        .size {
          display: block;
          margin-top: 20px;
          color: #666;
        }
      }
      .three {
        padding-right: 30px;
        color: #666;
      }
      .four {
        text-align: center;
        color: #666;
      }
    }
    .all-item-one:nth-last-child(1) {
      border-bottom: 0;
    }
    .five {
      font-weight: 500;
    }
    .six {
      margin-top: 17px;
    }
  }
}
.order-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-border {
  border-left: 1px solid #cccdcd;
}
.order-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .one {
    display: block;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .two {
    background-color: #111;
    color: #fff;
  }
  .three {
    border: 1px solid #111;
  }
  .four {
    margin-top: 0;
  }
  .five {
    margin-top: 7px;
  }
}

.prize {
  width: 100%;
  border-top: 1px solid #cccdcd;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  .get-prize {
    font-weight: bold;
    font-size: 14px;
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 999999;
}

@media (min-width: 992px) and (max-width: 1500px) {
  .order {
    .top-nav {
      .top-nav-left {
        .left-item {
          width: 80px;
        }
      }
      .search-input {
        width: 180px !important;
      }
      .select-nav {
        font-size: 16px !important;
      }
    }
  }
}

.scanPay {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9999;
  font-size: 20px;
  #qrcode {
    margin-bottom: 30px;
  }
  span {
    margin-top: 10px;
  }
  .isPay {
    margin-bottom: 20px;
    cursor: pointer;
    color: red;
  }
  .closeIMg {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}

.moneyType-item{
  display: flex;
  margin-top: 10px;
  align-items: center;
}


.to-right{
  margin-left: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.null-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}

.to-back-money{
  display: flex;
  align-items: center;
  color: #BBA492;
  margin-left: auto;
  margin-right: 10px;
}
</style>


