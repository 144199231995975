<template>
  <div class="sort container">
    <div class="sort-total row">
      <div class="sort-total-one">
        <img
          class="checkbox-img"
          src="../../assets/img/icon_sel_s.png"
          alt=""
          v-if="totalChoose"
          @click="totalChoosed"
        />
        <img
          class="checkbox-img"
          src="../../assets/img/icon_sel_n.png"
          alt=""
          v-else
          @click="totalChoosed"
        />
        <span class="checkbox-span">全选</span>
      </div>
      <div class="center-one">
        <span>商品名称</span>
      </div>
      <div class="center-one">
        <span>规格</span>
      </div>
      <div class="center-one">
        <span>网点</span>
      </div>
      <div class="sort-total-three">
        <span>单价</span>
      </div>
      <div class="sort-total-two">
        <span>数量</span>
      </div>
      <div class="sort-total-three">
        <span>小计</span>
      </div>
      <div class="sort-total-four">
        <span>操作</span>
      </div>
    </div>

    <div class="row sort-item-use" v-if="sortList.length > 0">
      <div
        class="sort-container-width"
        v-for="(item, index) in sortList"
        :key="index"
      >
        <div class="sort-total special-div" v-if="item.isState == 1">
          <template>
            <div class="sort-total-one">
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_g.png"
                alt=""
                v-if="item.inventory < 1"
              />
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_s.png"
                alt=""
                v-if="item.flag && item.inventory > 0"
                @click="removeSelect(index)"
              />
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_n.png"
                alt=""
                v-if="item.flag == false && item.inventory > 0"
                @click="chooseSelect(index)"
              />
              <img class="sort-img" :src="item.image" alt="" />
            </div>

            <div class="center-one">
              <span :class="{ colorccc: item.inventory < 1 }">{{
                item.commodityName
              }}</span>
            </div>
            <div class="center-one">
              <span class="guige">{{ typeList[index] }}</span>
              <div class="xiala">
                <img
                  class="pointer"
                  src="../../assets/img/down_grey.png"
                  alt=""
                  @click="
                    seeType(
                      item.id,
                      item.commodityId,
                      item.specificationValueId
                    )
                  "
                />
                <!-- 下拉 -->
              </div>
              <div
                :class="{
                  xialaBlock: true,
                  none: item.id == kid && selectId == 1,
                }"
              >
                <div
                  class="four"
                  v-for="(item, index) in detailLeft"
                  :key="index"
                >
                  <div class="four-left constructer-left">
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="four-right">
                    <template v-for="(item2, index2) in detailRight">
                      <div
                        class="four-right-item"
                        :key="index2"
                        @click="chooseConst(item, item2, index2)"
                        v-if="item.id == item2.specificationId"
                      >
                        <span
                          :class="{
                            'my-border': true,
                            'my-border-two': item2.flag,
                          }"
                          >{{ item2.value }}</span
                        >
                        <img
                          class="
                            four-right-item-position
                            five-right-item-position
                          "
                          src="../../assets/img/detail-choose.png"
                          alt=""
                          v-if="item2.flag"
                        />
                      </div>
                    </template>
                  </div>
                </div>
                <div class="btn">
                  <button class="sure pointer" @click="editType">确定</button>
                  <button class="cancel pointer" @click="kid = -1">取消</button>
                </div>
              </div>
              <div class="isNull">
                <img
                  v-if="item.inventory < 1"
                  src="../../assets/img/tips.png"
                  alt=""
                />
                <span v-if="item.inventory < 1">该规格已售罄，请重新选择</span>
              </div>
            </div>
            <div class="center-one">
              <span class="guige">{{
                item.provinceName +
                item.cityName +
                item.districtName +
                item.outletsName
              }}</span>
              <!-- 下拉 -->
              <div class="xiala">
                <img
                  class="pointer"
                  src="../../assets/img/down_grey.png"
                  alt=""
                  @click="seeTypeTwo(item.id, index)"
                />
              </div>
              <div
                :class="{
                  xialaBlock: true,
                  widthnull: true,
                  none: item.id == kid && selectId == 2,
                }"
              >
                <div class="wangdian">
                  <div :class="{ 'select-absoulte': true }">
                    <div
                      :class="{
                        'item-button': true,
                        'item-button-click': chooseSelects == 1,
                      }"
                      data-num="1"
                      @click="chooseSelected"
                    >
                      <span data-num="1" @click="chooseSelected">
                        {{ province }}
                      </span>
                      <div
                        :class="{ 'item-button-bottom': chooseSelects == 1 }"
                      ></div>
                    </div>
                    <div
                      :class="{
                        'item-button': true,
                        'item-button-click': chooseSelects == 2,
                        nosee: province == '请选择',
                      }"
                      data-num="2"
                      @click="chooseSelected"
                    >
                      <span data-num="2" @click="chooseSelected">
                        {{ city }}
                      </span>
                      <div
                        :class="{ 'item-button-bottom': chooseSelects == 2 }"
                      ></div>
                    </div>
                    <div
                      :class="{
                        'item-button': true,
                        'item-button-click': chooseSelects == 3,
                        nosee: city == '请选择',
                      }"
                      data-num="3"
                      @click="chooseSelected"
                    >
                      <span data-num="3" @click="chooseSelected">
                        {{ region }}
                      </span>
                      <div
                        :class="{ 'item-button-bottom': chooseSelects == 3 }"
                      ></div>
                    </div>
                    <div
                      :class="{
                        'item-button': true,
                        'item-button-click': chooseSelects == 4,
                        nosee: region == '请选择',
                      }"
                      data-num="4"
                      @click="chooseSelected"
                    >
                      <span data-num="4" @click="chooseSelected">
                        {{ wangdian }}
                      </span>
                      <div
                        :class="{ 'item-button-bottom': chooseSelects == 4 }"
                      ></div>
                    </div>
                    <div class="absoulte-line"></div>
                    <!-- 省市区 -->
                    <div class="select-container">
                      <div v-for="(item, index) in areaList" :key="index">
                        <span
                          :class="{
                            'select-loop': true,
                            pointer: true,
                          }"
                        >
                          <template v-if="chooseSelects == 1">
                            <span
                              @click="
                                toProvice(item.provinceName, item.provinceValue)
                              "
                              :class="{
                                cityClass: provinceIndex == item.provinceValue,
                              }"
                              >{{ item.provinceName }}</span
                            >
                          </template>
                          <template v-if="chooseSelects == 2">
                            <span
                              @click="toCity(item.cityName, item.cityValue)"
                              :class="{
                                cityClass: cityIndex == item.cityValue,
                              }"
                              >{{ item.cityName }}</span
                            >
                          </template>
                          <template v-if="chooseSelects == 3">
                            <span
                              @click="
                                toRegion(item.districtName, item.districtValue)
                              "
                              :class="{
                                cityClass: regionIndex == item.districtValue,
                              }"
                              >{{ item.districtName }}</span
                            >
                          </template>
                          <template v-if="chooseSelects == 4">
                            <span
                              @click="toWangdian(item.name, item.id)"
                              :class="{
                                cityClass: wangdianIndex == item.id,
                              }"
                              >{{ item.name }}</span
                            >
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn">
                  <button
                    class="sure pointer"
                    @click="editaddressType(item.id)"
                  >
                    确定
                  </button>
                  <button class="cancel pointer" @click="kid = -1">取消</button>
                </div>
              </div>
            </div>
            <div class="sort-total-three">
              <span class="priPrice" v-if="item.price != item.specialOffer"
                >￥{{ item.price }}</span
              >
              <span :class="{ colorccc: item.inventory < 1 }"
                >￥{{ item.specialOffer }}</span
              >
            </div>
            <div class="sort-total-two">
              <el-input-number
                v-model="item.quantity"
                @change="handleChange(item.id, item.quantity)"
                :min="1"
                :max="item.commodityType == 0 ? 1 : item.inventory"
                size="mini"
              ></el-input-number>
            </div>
            <div class="sort-total-three">
              <span :class="{ xiaoji: true, colorccc: item.inventory < 1 }"
                >￥{{ getZero(item.specialOffer,item.quantity) }}</span
              >
            </div>
            <div class="sort-total-four">
              <span class="pointer" @click="toSave(item.id, item.commodityId)"
                >移入收藏夹</span
              >
              <span
                class="sort-cancel-main pointer"
                @click="removeSort(item.id)"
                >删除</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="isOver" class="effective">
      <span>以下商品已失效</span>
    </div>

    <div
      class="row sort-item-use"
      style="margin-top: 0"
      v-if="Invalidation.length > 0"
    >
      <div
        class="sort-container-width"
        v-for="(item, index) in sortList"
        :key="index"
      >
        <div
          class="sort-total special-div iseffective"
          v-if="item.isState == 0"
        >
          <template>
            <div class="sort-total-one img-cover">
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_g.png"
                alt=""
              />
              <img class="sort-img" :src="item.image" alt="" />
              <div class="img-over">
                <span>售罄</span>
                <span>下架</span>
              </div>
            </div>
            <div class="center-one">
              <span style="color: #ccc">{{ item.commodityName }}</span>
            </div>
            <div class="center-one">
              <span class="guige">{{ typeList[index] }}</span>
            </div>
            <div class="center-one">
              <span class="guige">{{
                item.provinceName +
                item.cityName +
                item.districtName +
                item.outletsName
              }}</span>
            </div>
            <div class="sort-total-three">
              <span class="priPrice" style="color: #ccc"
                >￥{{ item.price }}</span
              >
              <span style="color: #ccc">￥{{ item.specialOffer }}</span>
            </div>
            <div class="sort-total-two">
              <el-input-number
                disabled
                v-model="item.quantity"
                @change="handleChange"
                :min="1"
                :max="99"
                size="mini"
              ></el-input-number>
            </div>
            <div class="sort-total-three">
              <span class="xiaoji" style="color: #ccc"
                >￥{{ item.specialOffer }}</span
              >
            </div>
            <div class="sort-total-four">
              <span class="pointer" @click="toSimilar(item)">找相似</span>
              <span class="sort-cancel-main pointer" @click="removeSort(item.id)"
                >删除</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="sort-item-use no-sort-sort" v-if="sortList.length == 0">
      <img src="../../assets/img/no-sort.png" alt="" />
      <span>这里空空的什么也没有~</span>
    </div>

    <div class="sort-toSummary row">
      <div class="sort-toSummary-left">
        <img
          class="checkbox-img"
          src="../../assets/img/icon_sel_s.png"
          alt=""
          v-if="totalChoose"
          @click="totalChoosed"
        />
        <img
          class="checkbox-img"
          src="../../assets/img/icon_sel_n.png"
          @click="totalChoosed"
          alt=""
          v-else
        />
        <span>已选</span>
        <span class="num">({{ selectNum }})</span>
        <span class="more-cancel pointer" @click="deleteMore">批量删除</span>
        <span @click="removeOver">清空失效商品</span>
      </div>
      <div class="sort-toSummary-right">
        <span>应付总额：</span>
        <span class="order-money">￥{{ totalNum }}</span>
        <span class="toOrder" @click="toOrder">下单</span>
      </div>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getSortApi,
  removeSortApi,
  getShopping,
  editShopping,
  removeSaveShopping,
  removeManyShopping,
  removeOverShopping,
  getRegion,
  getWangdian,
  editWangdian,
  editSortNum,
} from "../../assets/api/api.js";
export default {
  created() {
    this.getSort();
  },
  data() {
    return {
      totalChoose: false,
      num: 5,
      sortList: [],
      typeList: [],
      numList: [],
      detailLeft: [],
      detailRight: [],
      commodityList: [],
      valueList: [],
      str: "",
      sid: -1,
      kid: -1,
      mid: -1,
      selectId: 0,
      province: "请选择",
      city: "请选择",
      region: "请选择",
      wangdian: "请选择",
      provinceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      wangdianIndex: -1,
      isOver: false,
      selectNum: 0,
      totalNum: 0,
      selectTotalNum: 0,

      ///////////////////////////////////////
      chooseSelects: 1,
      areaList: [],
      comId: "",
      Invalidation: [],
      message: "",
    };
  },
  methods: {
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async handleChange(id, num) {
      let res = await editSortNum({ shoppingCartId: id, quantity: num });
      console.log(res);
      this.getSelectNum();
    },
    // 开始获取
    async getSort() {
      this.typeList = [];
      this.totalNum = 0;
      let res = await getSortApi({ userId: 1 });
      res = res.data.data;
      console.log(res);
      this.sortList = res;
      if (this.sortList.length > 0) {
        for (let i = 0; i < this.sortList.length; i++) {
          let simpleList = this.sortList[i].specificationValueList;
          if (this.sortList[i].isState == 0) {
            this.isOver = true;
            this.Invalidation.push(this.sortList[i]);
          }
          this.str = "";
          for (let j = 0; j < simpleList.length; j++) {
            this.str += simpleList[j];
          }
          this.typeList.push(this.str);

          this.$emit("fatherSort");
        }
      }
    },
    // 类型删选
    async seeType(kid, id, valueList) {
      this.selectId = 1;
      this.valueList = [];
      // 商品编号
      this.sid = id;
      //id
      this.kid = kid;
      // 规格编号
      valueList.split(",").forEach((item) => {
        this.valueList.push(Number(item));
      });
      let res = await getShopping({ id: id });
      res = res.data.data;
      // 左侧
      this.detailLeft = res.specificationVOList;
      // 右侧
      this.detailRight = res.specificationValueVOList;
      // 商品类型
      this.commodityList = res.commodityTypeVOList;

      for (let i = 0; i < this.valueList.length; i++) {
        for (let j = 0; j < this.detailRight.length; j++) {
          if (this.valueList[i] == this.detailRight[j].id) {
            this.detailRight[j].flag = true;
          }
        }
      }
      console.log(res);
      console.log(this.valueList);
    },
    // 网点筛选
    async seeTypeTwo(id, index) {
      this.kid = id;
      this.selectId = 2;
      // console.log(this.sortList[index].provinceName);
      this.province = this.sortList[index].provinceName;
      this.city = this.sortList[index].cityName;
      this.region = this.sortList[index].districtName;
      this.wangdian = this.sortList[index].outletsName;
      this.provinceIndex = this.sortList[index].provinceValue;
      this.cityIndex = this.sortList[index].cityValue;
      this.regionIndex = this.sortList[index].districtValue;
      this.wangdianIndex = this.sortList[index].outletsId;
      this.comId = this.sortList[index].commodityId;
      let res = await getRegion({
        commodityId: this.comId,
      });
      this.areaList = res.data.data;
      console.log(res);
    },
    // 规格选择一个
    chooseConst(item, item2, index2) {
      for (let i = 0; i < this.detailRight.length; i++) {
        if (item.id == this.detailRight[i].specificationId) {
          this.detailRight[i].flag = false;
        }
      }
      this.detailRight[index2].flag = true;
    },
    //规格类型修改
    async editType() {
      let arrSpecial = [];

      for (let i = 0; i < this.detailRight.length; i++) {
        if (this.detailRight[i].flag) {
          arrSpecial.push(this.detailRight[i].id);
        }
      }

      for (let j = 0; j < this.commodityList.length; j++) {
        let specialTypeList = this.commodityList[j].specificationValueIdList;
        if (arrSpecial.join(",") == specialTypeList.join(",")) {
          this.mid = this.commodityList[j].id;
        }
      }
      console.log(this.kid);
      console.log(this.mid);
      let res = await editShopping({
        shoppingCartId: this.kid,
        commodityTypeId: this.mid,
      });
      console.log(res);
      this.kid = -1;
      this.getSort();
    },
    // 单个选中
    chooseSelect(index) {
      this.sortList[index].flag = true;
      this.getSelectNum();
    },
    // 单个取消
    removeSelect(index) {
      this.sortList[index].flag = false;
      this.getSelectNum();
    },
    // 已选
    getSelectNum() {
      this.selectNum = 0;
      this.totalNum = 0;
      this.selectTotalNum = 0;
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].isState == 1 && this.sortList[i].inventory > 0) {
          this.selectTotalNum++;
        }
        if (this.sortList[i].flag) {
          this.selectNum++;
          this.totalNum +=
            this.sortList[i].quantity * this.sortList[i].specialOffer;
        }
      }
      if (this.selectTotalNum == this.selectNum) {
        this.totalChoose = true;
      } else {
        this.totalChoose = false;
      }
      this.totalNum = this.totalNum +'';
      if(this.totalNum.indexOf(".")==-1){
        this.totalNum = this.totalNum + '.00';
      }
    },
    // 全选
    totalChoosed() {
      if (this.totalChoose) {
        for (let i = 0; i < this.sortList.length; i++) {
          if (
            this.sortList[i].flag == true &&
            this.sortList[i].isState == 1 &&
            this.sortList[i].inventory > 0
          ) {
            this.sortList[i].flag = false;
          }
        }
      } else {
        for (let i = 0; i < this.sortList.length; i++) {
          if (
            this.sortList[i].flag == false &&
            this.sortList[i].isState == 1 &&
            this.sortList[i].inventory > 0
          ) {
            this.sortList[i].flag = true;
          }
        }
      }
      this.totalChoose = !this.totalChoose;
      this.getSelectNum();
    },
    // 批量删除
    async deleteMore() {
      let arr = [];
      for (let i = 0; i < this.sortList.length; i++) {
        if (this.sortList[i].flag) {
          arr.push(this.sortList[i].id);
        }
      }
      let res = await removeManyShopping({ shoppingCartIdList: arr.join(",") });
      console.log(res);
      if (res.data.result == 0) {
        this.getSort();
        this.$emit("fatherSort");
        this.message = "批量删除成功";
        this.showInfo();
      } else {
        this.message = "批量删除失败";
        this.showInfo();
      }
    },
    // 移入收藏夹
    async toSave(id, commodityId) {
      let res = await removeSaveShopping({
        userId: 1,
        commodityId: commodityId,
        shoppingCartId: id,
      });
      if (res.data.result == 0) {
        this.getSort();
        this.$emit("fatherSort");
        this.message = "移入收藏夹成功";
        this.showInfo();
      } else {
        this.message = "移入收藏夹失败";
        this.showInfo();
      }
      console.log(res);
    },
    //删除
    async removeSort(id) {
      let res = await removeSortApi({ shoppingCartId: id });
      if (res.data.result == 0) {
        this.getSort();
        this.$emit("fatherSort");
        this.message = "删除成功";
        this.showInfo();
      } else {
        this.$emit("fatherSort");
        this.message = "删除失败";
        this.showInfo();
      }
      console.log(res);
    },
    // 清空失效商品
    async removeOver() {
      let res = await removeOverShopping({ userId: 1 });
      if (res.data.result == 0) {
        this.getSort();
        this.$emit("fatherSort");
        this.message = "清除成功";
        this.showInfo();
      } else {
        this.$emit("fatherSort");
        this.message = "清除失败";
        this.showInfo();
      }
    },

    // 下单
    async toOrder() {
      let arr = [];
      if (this.selectNum > 0) {
        for (let i = 0; i < this.sortList.length; i++) {
          if (this.sortList[i].flag) {
            arr.push(this.sortList[i].id);
          }
        }
        this.$router.push({
          path: "/order",
          query: {
            id: arr.join(","),
            path: 1,
          },
        });
      }
    },

    //////////////////////////////////////////**
    async chooseSelected(e) {
      if(e!=0){
        this.chooseSelects = e.target.dataset.num;
      }
      if (this.chooseSelects == 1) {
        let res = await getRegion({
          commodityId: this.comId,
        });
        this.areaList = res.data.data;
      } else if (this.chooseSelects == 2) {
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          commodityId: this.comId,
        });
        this.areaList = res.data.data;
        console.log(res);
      } else if (this.chooseSelects == 3) {
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          cityValue: this.cityIndex,
          commodityId: this.comId,
        });
        this.areaList = res.data.data;
        console.log(res);
      } else if (this.chooseSelects == 4) {
        let res = await getWangdian({
          areaValue: this.regionIndex,
          commodityId: this.comId,
        });
        this.areaList = res.data.data;
        console.log(res);
      }
    },

    // 省份选择
    toProvice(name, id) {
      this.province = name;
      // this.provinceIndex = id;
      // if (this.provinceIndex != id) {
      //   this.provinceIndex = id;
      //   this.city = "城市";
      //   this.region = "区域";
      //   this.wangdian = "网点";
      // }
      this.provinceIndex = id;
      this.city = "请选择";
      this.region = "请选择";
      this.wangdian = "请选择";
      this.chooseSelects = 2;
      this.chooseSelected(0);
    },
    // 城市选择
    toCity(name, id) {
      this.city = name;
      // if (this.cityIndex != id) {
      //   this.cityIndex = id;
      //   this.region = "区域";
      //   this.wangdian = "网点";
      // }
        this.cityIndex = id;
        this.region = "请选择";
        this.wangdian = "请选择";
        this.chooseSelects = 3;
        this.chooseSelected(0);
    },
    // 区域选择
    toRegion(name, id) {
      this.region = name;
      // if (this.regionIndex != id) {
      //   this.regionIndex = id;
      //   this.wangdian = "网点";
      // }
        this.regionIndex = id;
        this.wangdian = "请选择";
        this.chooseSelects = 4;
        this.chooseSelected(0);
    },
    // 网点选择
    toWangdian(name, id) {
      this.wangdian = name;
      // if (this.wangdianIndex != id) {
      //   this.wangdianIndex = id;
      // }
      this.wangdianIndex = id;
    },

    //提交
    async editaddressType(id) {
      if (this.wangdian != "请选择") {
        let res = await editWangdian({
          shoppingCartId: id,
          outletsId: this.wangdianIndex,
        });
        console.log(res);
        this.getSort();
        this.kid = -1;
      }
    },
    toSimilar(item){
      console.log(item);
      if(item.commodityType == 0){
        this.$router.push('/classic-collection')
      }else{
        this.$router.push('/luxery-collection')
      }
    },
    getZero(money,num){
      let price = money*num;
      price +='';
      if(price.indexOf(".")==-1){
        price = price + '.00'
      };
      return price;
    }
  },
};
</script>



<style lang="less" scoped>
.sort {
  margin-top: 25px;
  font-size: 14px;
  color: #111;
  margin-bottom: 80px;
  .row {
    width: 100%;
  }
  .sort-container-width {
    width: 100%;
  }
  .sort-total {
    padding: 15px 10px 15px 20px;
    border: 1px solid #cccdcd;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    .sort-total-one {
      // width: 140px;
      width: 11%;
      .checkbox-img {
        width: 18px;
        height: 18px;
      }
      .checkbox-span {
        margin-left: 15px;
      }
    }
    .sort-total-right {
      width: 100px;
      span {
        cursor: pointer;
      }
    }
  }

  .center-one {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .xiala {
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%);
    }
    .isNull {
      font-size: 12px;
      color: #ce2829;
      margin-top: 25px;
    }
  }

  .sort-total-two {
    width: 10%;
  }

  .sort-total-three {
    width: 7%;
  }

  .sort-total-four {
    // width: 100px;
    width: 8%;
  }

  .sort-item-use {
    margin-top: 25px !important;
    border: 1px solid #cccdcd;
    background-color: #fff !important;
    // height: 714px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .sort .sort-item-use:nth-last-child(1){
    margin-top: 0 !important;
  }
  .special-div {
    border: 0;
    border-bottom: 1px dotted #cccdcd !important;
    width: 100%;
    background-color: #fff;
  }

  .sort-item-use .sort-container-width:nth-last-child(1) .special-div {
    border-bottom: 0 !important;
  }
  .sort-img {
    height: 80px;
    width: 80px;
    margin-left: 15px;
    border: 1px solid #cccdcd;
  }
  .guige {
    color: #999 !important;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .priPrice {
    display: block;
    text-decoration: line-through;
    color: #999;
    margin-bottom: 8px;
  }
  .el-input-number--mini {
    width: 110px;
  }
  .xiaoji {
    width: 124px;
    font-weight: bold;
  }
  .sort-cancel-main {
    display: block;
    margin-top: 10px;
  }

  .effective {
    padding: 20px 0 0 0;
    margin: 0 !important;
    font-size: 18px;
  }

  .iseffective {
    background-color: #f3f3f3;
  }

  .img-cover {
    position: relative;
  }
  .img-over {
    position: absolute;
    width: 80px;
    height: 80px;
    background: rgba(17, 17, 17, 0.4);
    border: 1px solid #cccdcd;
    left: 33px;
    top: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sort-toSummary {
    // padding: 28px 0;
    height: 70px;
    background-color: #f3f3f3;
    border: 1px solid #cccdcd;
  }

  .sort-toSummary {
    display: flex;
    justify-content: space-between;
    border-top: 0;
    span {
      cursor: pointer;
    }
    .sort-toSummary-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .checkbox-img {
        margin: 0 20px;
      }
      .num {
        margin-left: 10px;
      }
      .more-cancel {
        margin: 0 30px;
      }
    }
    .sort-toSummary-right {
      display: flex;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .toOrder {
        width: 104px;
        height: 100%;
        background-color: #111;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
      .order-money {
        margin-right: 40px;
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.xialaBlock {
  width: 600px;
  border: 1px solid #111;
  position: absolute;
  left: 50%;
  background-color: #fff;
  top: 55%;
  transform: translateX(-50%) translateY(10px);
  z-index: 999;
  display: none;
}

.widthnull {
  width: 420px !important;
}

.xialaBlock:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 1px solid #111;
  border-top: 1px solid #111;
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
  top: -11px;
  border-radius: 1px;
  background-color: #fff;
}

.xialaBlock:after {
  content: "";
  display: block;
  position: absolute;
  width: 26px;
  height: 1px;
  background-color: #fff;
  left: 50%;
  top: -1px;
  transform: translateX(-13px);
}

.four {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  margin-top: 20px;
  .four-left {
    width: 68px;
    margin-left: 14px;
    font-size: 12px;
    margin-top: 10px;
    flex-shrink: 0;
  }
  .four-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .four-right-item {
    position: relative;
  }
  .four-right-item-position {
    position: absolute;
    right: 8px;
    bottom: 0;
  }
}
.my-border {
  display: inline-block;
  border: 1px solid #cccdcd;
  padding: 5px 9px;
  color: #333;
  margin-right: 6px;
  margin-top: 12px;
  cursor: pointer;
}

.constructer-left {
  display: block;
  margin-top: 20px !important;
}

.my-border-two {
  border: 2px solid #bba492;
}

.btn {
  button {
    outline: none;
    border: 0;
    font-size: 14px;
    padding: 4px 14px;
    margin-left: 68px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sure {
    background-color: #111;
    color: #fff;
    border: 1px solid #111;
  }
  .cancel {
    background-color: #fff;
    border: 1px solid #111;
    margin-left: 20px;
  }
}

.wangdian {
  padding: 0 30px;
  margin-top: 30px;
  // margin-left: 100px;
}

.colorccc {
  color: #ccc;
}

.none {
  display: block !important;
}

.nosee {
  display: none !important;
}

.select-absoulte {
  position: relative;
  background-color: #fff;
  width: 365px;
  // height: 500px;
  padding: 14px 20px 0;
  z-index: 998;
  border: 1px solid #cccdcd;
  // border-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  .item-button {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 24px;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid #cccdcd;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .absoulte-line {
    position: absolute;
    top: 36px;
    width: 100%;
    height: 2px;
    background-color: #111;
    left: 0;
  }
  .item-button-bottom {
    position: absolute;
    width: 68px;
    height: 2px;
    background-color: #fff !important;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  .item-button-click {
    border: 2px solid #111 !important;
    border-bottom: 0 !important;
  }
}

.select-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 200px;
  width: 100%;
  overflow-y: scroll;
  .select-loop {
    display: inline-block;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      padding: 4px 5px;
      margin-right: 20px;
    }
  }
  // .select-loop-wang {
  //   display: block;
  //   width: 100%;
  //   span {
  //     padding: 4px 5px;
  //   }
  // }
}

.select-container::-webkit-scrollbar {
  width: 10px;
  height: 1px;
  // background-color: #f5f5f5;
}

.select-container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #bbbbbb;
}

.select-container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  background: #ededed;
}

.cityClass {
  background-color: #111;
  color: #fff;
}

.block {
  display: block !important;
}

.no-sort-sort {
  height: 714px;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  font-size: 14px;
  color: #999999;
}
.no-sort-sort img {
  width: 200px;
  height: 200px;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
}
</style>