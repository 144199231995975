<template>
  <div class="o-detail">
    <div class="o-top">
      <div
        class="o-top-two"
        @click="drawer2 = true"
        v-if="addressList.length > 0"
      >
        <div class="o-top-two-left">
          <div class="o-top-two-left-item-one">
            <span class="one">{{ oneAddress.username }}</span>
            <span>{{ oneAddress.phone }}</span>
          </div>
          <div class="o-top-two-left-item-two">
            <div class="btn">
              <span class="one" v-if="oneAddress.isDefault == 1">默认</span>
            </div>
            <span class="two">{{
              oneAddress.provinceName +
              oneAddress.cityName +
              oneAddress.districtName +
              oneAddress.addressDetailed
            }}</span>
          </div>
        </div>
        <div class="o-top-two-right">
          <img src="../../assets/img/arrow_right.png" alt="" />
        </div>
      </div>
      <div class="o-top-two" v-else @click="toAddAddress">
        <div class="o-top-two-left">
          <div class="o-top-two-left-item-one">
            <span class="one">添加地址</span>
          </div>
        </div>
        <div class="o-top-two-right">
          <img src="../../assets/img/arrow_right.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="o-info">
      <div class="o-info-top">
        <span>商品信息</span>
      </div>
      <div class="o-info-domain">
        <div
          class="o-info-domain-item"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div class="left-img">
            <img class="img-fuild" :src="item.image" alt="" />
          </div>
          <div class="center-container">
            <div class="one">{{ item.commodityName }}</div>
            <div class="two">
              <span
                v-for="(item2, index2) in item.specificationValueList"
                :key="index2"
                >{{ item2 + "" }}</span
              >
            </div>
            <div class="two">
              {{
                item.provinceName +
                item.cityName +
                item.districtName +
                item.outletsName
              }}
            </div>
            <div class="three">￥{{ item.specialOffer }}</div>
          </div>
          <div class="right-qunatity">
            <span>x{{ item.quantity }}</span>
          </div>
        </div>
        <div class="o-info-domain-bottom">
          <div class="o-info-domain-bottom-item">
            <span>邮费</span>
            <span>￥0.00</span>
          </div>
          <div class="o-info-domain-bottom-item">
            <span>商品合计</span>
            <span>￥{{ totalPrice }}</span>
          </div>
          <div class="o-info-domain-bottom-item">
            <span>应付总额</span>
            <span>￥{{ totalPrice }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="o-info">
      <div class="o-info-top">
        <span>支付方式</span>
      </div>
      <div class="order-information">
        <div class="o-info-domain-bottom-item">
          <div class="o-left">
            <img src="../../assets/img/zhifubao.png" alt="" />
            <span>支付宝</span>
          </div>
          <div class="o-right">
            <img src="../../assets/img/ic_gou_s.png" v-if="payAliBaBa" alt="" />
            <img
              src="../../assets/img/ic_gou_n.png"
              v-else
              @click="chooseAli"
            />
          </div>
        </div>
        <div class="o-info-domain-bottom-item">
          <div class="o-left">
            <img src="../../assets/img/wechart.png" alt="" />
            <span>微信</span>
          </div>
          <div class="o-right">
            <img src="../../assets/img/ic_gou_s.png" v-if="payWeChart" alt="" />
            <img
              src="../../assets/img/ic_gou_n.png"
              v-else
              @click="chooseWeChart"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 购物须知 -->
    <div class="need o-info-bottom">
      <img
        src="../../assets/img/icon_sel_s.png"
        v-if="isRead"
        @click="isRead = false"
      />
      <img
        src="../../assets/img/icon_sel_n.png"
        v-else
        @click="isRead = true"
      />
      <span class="need-one">我已阅读</span>
      <span class="need-two" @click="shoppingKnow">《购物须知》</span>
    </div>
    <div class="fix-bottom">
      <div class="fix-bottom-domain">
        <div class="fix-bottom-left">
          <span>应付总额: :</span>
          <span class="price">￥{{ totalPrice }}</span>
        </div>
        <div class="fix-bottom-right" @click="toOrder">
          <span>去付款</span>
        </div>
      </div>
    </div>
    <!-- 弹出框2 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="address">
        <div class="address-top">
          <span>选择地址</span>
          <img src="../../assets/img/delete_n.png" @click="drawer2 = false" />
        </div>
        <div class="address-container">
          <div
            class="address-container-item"
            v-for="(item, index) in addressList"
            :key="index"
            @click="chooseAddressIndex(index)"
          >
            <div class="address-container-item-left">
              <img
                src="../../assets/img/ic_gou_s.png"
                v-if="index == addressIndex"
                alt=""
              />
              <img src="../../assets/img/ic_gou_n.png" v-else alt="" />
            </div>
            <div class="address-container-item-right">
              <span>{{ item.username }}</span>
              <span class="default" v-if="item.isDefault == 1">默认</span>
              <div>{{ item.phone }}</div>
              <div class="detailAddress">
                {{
                  item.provinceName +
                  item.cityName +
                  item.districtName +
                  item.addressDetailed
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="sure-btn" @click="sureAddress">确定</div>
      </div>
    </el-drawer>
    <!-- 购物须知 -->
    <div class="dialog">
      <el-dialog title="购物须知" :visible.sync="centerDialogVisible2" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->

        <div class="read">
          <el-scrollbar>
            <div class="read-item" v-html="notes"></div>
          </el-scrollbar>
        </div>
      </el-dialog>
    </div>
    <div class="message" id="message">{{ message }}</div>
    <div id="myForm"></div>
  </div>
</template>

<script>
import {
  getOrderThing,
  getOrderAddress,
  shoppingNotes,
  getOrderUuid,
  payApi,
  payMobile,
} from "../../assets/api/api.js";
export default {
  created() {
    let id = this.$route.query.id;
    if (this.$route.query.path == 1) {
      this.status = 1;
      this.getOrderList(id);
    } else {
      this.status = 0;
      this.getOrderList2(id);
    }
    this.getAddress();
  },
  data() {
    return {
      status: 0,
      orderList: [],
      addressList: [],
      totalPrice: 0,
      payAliBaBa: true,
      payWeChart: false,
      drawer2: false,
      addressIndex: 0,
      oneAddress: {},
      isRead: false,
      centerDialogVisible2: false,
      notes: "",
      message: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getOrderList(id) {
      let res = await getOrderThing({
        idList: id,
      });
      if (res.data.result == 0) {
        res = res.data.data;
        this.orderList = res;
        console.log(res);
        for (let i = 0; i < this.orderList.length; i++) {
          console.log("...");
          this.totalPrice +=
            this.orderList[i].specialOffer * this.orderList[i].quantity;
        }
        this.totalPrice = this.totalPrice + "";
        if (this.totalPrice.indexOf(".") == -1) {
          this.totalPrice = this.totalPrice + ".00";
        }
      } else {
        this.message = res.data.msg;
        this.showInfo();
        this.$router.go(-1);
      }
    },
    async getOrderList2(id) {
      let res = await getOrderUuid({
        uuid: id,
      });
      if (res.data.result == 0) {
        res = res.data.data;
        this.orderList.push(res);
        console.log(res);
        for (let i = 0; i < this.orderList.length; i++) {
          console.log("...");
          this.totalPrice +=
            this.orderList[i].specialOffer * this.orderList[i].quantity;
        }
        this.totalPrice = this.totalPrice + "";
        if (this.totalPrice.indexOf(".") == -1) {
          this.totalPrice = this.totalPrice + ".00";
        }
      } else {
        this.message = res.data.msg;
        this.showInfo();
        this.$router.go(-1);
      }
    },
    // 地址
    async getAddress() {
      let res = await getOrderAddress({
        userId: 1,
      });
      console.log(res);
      res = res.data.data;
      this.addressList = res;
      if (this.addressList.length > 0) {
        this.oneAddress = this.addressList[0];
      }
      console.log(this.oneAddress);
    },
    chooseAddressIndex(index) {
      this.addressIndex = index;
    },
    // 确定
    sureAddress() {
      this.oneAddress = this.addressList[this.addressIndex];
      this.drawer2 = false;
    },
    // 选择微信
    chooseWeChart() {
      this.payWeChart = true;
      this.payAliBaBa = false;
    },
    // 选择支付宝
    chooseAli() {
      this.payAliBaBa = true;
      this.payWeChart = false;
    },
    // 购物须知
    async shoppingKnow() {
      let res = await shoppingNotes();
      res = res.data.data;
      this.notes = res;
      this.centerDialogVisible2 = true;
    },
    // 去下单
    async toOrder() {
      if (!this.oneAddress.id) {
        this.message = "请选择地址";
        this.showInfo();
      } else if (this.isRead == false) {
        this.message = "请选择购物须知";
        this.showInfo();
      } else {
        let arr = [];
        for (let i = 0; i < this.orderList.length; i++) {
          let obj = {
            commodityTypeId: this.orderList[i].commodityTypeId,
            outletsId: this.orderList[i].outletsId,
            quantity: this.orderList[i].quantity,
            price: this.orderList[i].specialOffer,
          };
          arr.push(obj);
        }
        let res = await payApi({
          addressId: this.oneAddress.id,
          price: this.totalPrice,
          type: this.payAliBaBa ? 0 : 1,
          orderCommodityTypeDTOList: arr,
          isShop: this.status,
        });
        res = res.data;
        if (res.result == 0) {
          let id = res.data;
          let forms = "";
          let res1 = await payMobile({
            orderId: id,
            type: this.payAliBaBa ? 0 : 1,
          });
          console.log(res1);
          if (this.payAliBaBa) {
            if (res1.status == 200) {
              forms = res1.data;
              this.$("#myForm").html(forms);
            }
          } else {
            location.href = res1.data.h5_url;
          }
        }
      }
    },
    // 添加地址
    toAddAddress() {
      this.$router.push({
        path: "/mine/address",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.o-detail {
  width: 100%;
  background-color: #f3f3f3;
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  overflow: auto;
  .o-top {
    margin-top: 10px;
    margin-bottom: 20px;
    .o-top-one {
      width: 100%;
      background-color: #111;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 15px;
      .status {
        font-size: 20px;
        font-weight: 500;
        margin-right: 10px;
      }
    }
    .o-top-two {
      padding: 0 15px;
      height: 77px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .o-top-two-left {
        .o-top-two-left-item-one {
          height: 25px;
          font-size: 15px;
          color: #111;
          font-weight: 500;
          .one {
            display: inline-block;
            width: 70px;
          }
        }
        .o-top-two-left-item-two {
          display: flex;
          align-items: center;
          font-size: 16px;
          .btn {
            width: 70px;
            text-align: left;
            .one {
              font-size: 16px;
              color: #bba492;
              border: 1px solid #bba492;
              padding: 0 2px 2px 5px;
              letter-spacing: 2px;
            }
          }
          .two {
            color: #999;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .o-top-two-right {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .o-info {
    padding: 20px 15px;
    background-color: #fff;
    margin-top: 10px;
    .o-info-top {
      font-size: 15px;
      margin-bottom: 24px;
    }
    .o-info-domain {
      width: 100%;
      .o-info-domain-item {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-img {
          width: 32%;
          img {
            width: 100%;
            border: 1px solid #cccdcd;
          }
        }
        .center-container {
          width: 55%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .one {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #111;
          }
          .two {
            font-size: 12px;
            color: #999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .three {
            font-size: 15px;
            color: #111;
            font-weight: 500;
          }
        }
        .right-qunatity {
          width: 5%;
          text-align: right;
        }
      }
      .o-info-domain-bottom {
        margin-top: 35px;
        .o-info-domain-bottom-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #666;
          padding: 5px 0;
        }
      }
    }
    .order-information {
      .o-info-domain-bottom-item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
        padding: 5px 0;
      }
    }
  }
  .o-info-bottom {
    margin-bottom: 80px;
  }
}

.fix-bottom {
  padding: 0 15px;
  height: 74px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .fix-bottom-domain {
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .fix-bottom-left {
    font-size: 14px;
    color: #999;
    .price {
      color: #111;
    }
  }
  .fix-bottom-right {
    font-size: 14px;
    width: 80px;
    height: 40px;
    background-color: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}

.address {
  padding: 15px;
  height: 100%;
  .address-top {
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #111;
    font-weight: 500;
    padding-bottom: 15px;
    img {
      position: absolute;
      right: 0;
    }
  }
  .address-container {
    .address-container-item {
      background-color: #fff;
      font-size: 14px;
      padding: 10px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .address-container-item-left {
        margin-right: 20px;
      }
      .default {
        font-size: 12px;
        background-color: #111;
        color: #fff;
        margin-left: 5px;
        padding: 0 3px 0 5px;
      }
    }
  }
  .sure-btn {
    position: sticky;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 50px;
    background-color: #111;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.need {
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
  .need-one {
    margin-left: 5px;
    color: #666;
  }
  .need-two {
    color: #111;
  }
}

.read {
  height: 400px;
}

.read-item {
  display: block;
  margin: bottom 0 !important;
  padding-right: 0 !important;
}

/deep/ .el-scrollbar {
  height: 100%;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-dialog {
  width: 300px;
  height: 500px;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 20px;
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>