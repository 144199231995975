<template>
    <div>验证中。。。</div>
</template>


<script>
import { activationApi } from "../../assets/api/api.js";
export default {
  created() {
    console.log(this.$route.query);
    let mail = this.$route.query.email;
    let code = this.$route.query.code;
    this.getLogin(mail, code);
  },
  methods: {
    async getLogin(mail, code) {
      let res = await activationApi({
        email: mail,
        code: code,
      });
      if(res.data.result==0){
          this.$router.push('/login')
      }
    },
  },
};
</script>