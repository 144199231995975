<template>
  <div>
    <div class="d-sm-none d-md-block" v-if="deviceWidth>768">
      <div
        class="login-main" id="loginOne"
      ></div>
      <div class="login-main2" style="padding-left: 40px; padding-right: 40px">
        <div class="container login-container">
          <div class="col-md-7 login-left-box" id="loginTwo"></div>
          <div class="col-md-5 login-right-box">
            <!--登陆-->
            <div class="lank-container" v-if="loginType == 0">
              <div class="lank-title">登录</div>
              <div class="account-box">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="account != ''"
                    >账号</span
                  >
                </div>
                <input
                  v-model="account"
                  class="account-input"
                  :class="[accountNone == true ? 'account-input-active' : '']"
                  placeholder="账号"
                  @focus="accountFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="accountNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="password != ''"
                    >密码</span
                  >
                </div>
                <input
                  v-model="password"
                  type="password"
                  class="account-input"
                  :class="[passwordNone == true ? 'account-input-active' : '']"
                  placeholder="密码"
                  @focus="passwordFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="passwordNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="login-btn" @click="loginTap">登录</div>
              <div class="free-container">
                <span class="free-text" @click="goRegister(1)">免费注册</span>
                <span class="free-text" @click="goRegister(2)">忘记密码</span>
              </div>
              <div class="wechat-box">
                <div class="wechat-line"></div>
                <div class="wechat-icon-box" @click="wechatLogin">
                  <img
                    src="../../assets/img/wechat_icon.png"
                    class="wechat-icon"
                  />
                  <span class="wechat-icon-text">微信登录</span>
                </div>
                <div class="wechat-line"></div>
              </div>
            </div>

            <!--免费注册-->
            <div class="lank-container" v-if="loginType == 1">
              <div class="lank-title" style="margin-top: 90px">免费注册</div>
              <div class="account-box" style="margin-top: 30px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="phone != ''"
                    >手机号</span
                  >
                </div>
                <input
                  v-model="phone"
                  class="account-input"
                  :class="[phoneNone == true ? 'account-input-active' : '']"
                  placeholder="手机号"
                  @focus="phoneFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="phoneNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="code != ''">验证码</span>
                </div>
                <div class="code-box">
                  <input
                    v-model="code"
                    class="code-input"
                    :class="[
                      codeNone == true
                        ? 'account-input-active'
                        : codeActive == true
                        ? 'account-input-active2'
                        : '',
                    ]"
                    placeholder="验证码"
                    @focus="codeFocus"
                    @blur="codeBlur"
                  />
                  <div
                    class="code-des"
                    :class="[
                      codeNone == true
                        ? 'account-input-active'
                        : codeActive == true
                        ? 'account-input-active2'
                        : this.sendTime != '获取验证码'
                        ? 'code-color-text'
                        : '',
                    ]"
                    @click="phoneCodeTap"
                  >
                    {{ sendTime }}
                  </div>
                </div>
                <div class="account-none-text">
                  <span class="account-none-word" v-if="codeNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="email != ''">邮箱(通过邮箱激活本账户)</span>
                </div>
                <input
                  v-model="email"
                  type="email"
                  class="account-input"
                  :class="[emailNone == true ? 'account-input-active' : '']"
                  placeholder="邮箱(通过邮箱激活本账户)"
                  @focus="emailFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="emailNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="newpassword != ''"
                    >设置密码</span
                  >
                </div>
                <input
                  v-model="newpassword"
                  type="email"
                  class="account-input"
                  :class="[
                    newpasswordNone == true ? 'account-input-active' : '',
                  ]"
                  placeholder="设置密码"
                  @focus="newpasswordFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="newpasswordNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="agreement-container">
                <img
                  src="../../assets/img/check_none.png"
                  class="check-icon"
                  v-if="isChecked == false"
                  @click="checkTap(true)"
                />
                <img
                  src="../../assets/img/check_save.png"
                  class="check-icon"
                  v-if="isChecked == true"
                  @click="checkTap(false)"
                />
                <span class="agreement-text">我已阅读并同意</span>
                <span class="agreement-text agree-word" style="color: #111111"
                @click="seeMemberText"
                  >《会员协议》</span
                >
                <span class="agreement-text">和</span>
                <span class="agreement-text agree-word" style="color: #111111"
                @click="privateMemberText"
                  >《隐私声明》</span
                >
              </div>
              <div class="login-btn" style="margin-top: 20px" @click="useTap">
                立即使用
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-top: 20px;
                  width: 360px;
                "
              >
                <span class="agreement-text">已有账号</span>
                <span
                  class="agreement-text agree-word"
                  style="color: #111111"
                  @click="goRegister(0)"
                  >去登陆</span
                >
              </div>
              <div class="wechat-box" style="margin-top: 36px">
                <div class="wechat-line"></div>
                <div class="wechat-icon-box" @click="wechatLogin">
                  <img
                    src="../../assets/img/wechat_icon.png"
                    class="wechat-icon"
                  />
                  <span class="wechat-icon-text">微信登录</span>
                </div>
                <div class="wechat-line"></div>
              </div>
            </div>

            <!--忘记密码-->
            <div class="lank-container" v-if="loginType == 2">
              <div class="lank-title">忘记密码</div>
              <div class="account-box">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="phone != ''"
                    >手机号</span
                  >
                </div>
                <input
                  v-model="phone"
                  class="account-input"
                  :class="[phoneNone == true ? 'account-input-active' : '']"
                  placeholder="手机号"
                  @focus="phoneFocus"
                />
                <div class="account-none-text">
                  <span class="account-none-word" v-if="phoneNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="code != ''">验证码</span>
                </div>
                <div class="code-box">
                  <input
                    v-model="code"
                    class="code-input"
                    :class="[
                      codeNone == true
                        ? 'account-input-active'
                        : codeActive == true
                        ? 'account-input-active2'
                        : '',
                    ]"
                    placeholder="验证码"
                    @focus="codeFocus"
                    @blur="codeBlur"
                  />
                  <div
                    class="code-des"
                    :class="[
                      codeNone == true
                        ? 'account-input-active'
                        : codeActive == true
                        ? 'account-input-active2'
                        : this.sendTime != '获取验证码'
                        ? 'code-color-text'
                        : '',
                    ]"
                    @click="phoneCodeTap"
                  >
                    {{ sendTime }}
                  </div>
                </div>
                <div class="account-none-text">
                  <span class="account-none-word" v-if="codeNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="login-btn" @click="setNewTap">设置新密码</div>
              <div class="free-container">
                <span
                  class="free-text"
                  @click="goRegister(0)"
                  style="color: #111111"
                  >去登陆</span
                >
              </div>
              <div class="wechat-box">
                <div class="wechat-line"></div>
                <div class="wechat-icon-box" @click="wechatLogin">
                  <img
                    src="../../assets/img/wechat_icon.png"
                    class="wechat-icon"
                  />
                  <span class="wechat-icon-text">微信登录</span>
                </div>
                <div class="wechat-line"></div>
              </div>
            </div>

            <!--设置新密码-->
            <div class="lank-container" v-if="loginType == 3">
              <div class="lank-title">设置新密码</div>
              <div class="account-box">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="resetPassword != ''"
                    >新密码</span
                  >
                </div>
                <input
                  v-model="resetPassword"
                  type="password"
                  class="account-input"
                  :class="[
                    resetPasswordNone == true ? 'account-input-active' : '',
                  ]"
                  placeholder="新密码"
                  @focus="resetPasswordFocus"
                />
                <div class="account-none-text">
                  <span
                    class="account-none-word"
                    v-if="resetPasswordNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="resetPasswordAgain != ''"
                    >再次输入新密码</span
                  >
                </div>
                <input
                  v-model="resetPasswordAgain"
                  type="password"
                  class="account-input"
                  :class="[
                    resetPasswordAgainNone == true
                      ? 'account-input-active'
                      : '',
                  ]"
                  placeholder="再次输入新密码"
                  @focus="resetPasswordAgainFocus"
                />
                <div class="account-none-text">
                  <span
                    class="account-none-word"
                    v-if="resetPasswordAgainNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="login-btn" @click="sureTap">确定</div>
              <div class="free-container">
                <span
                  class="free-text"
                  @click="goRegister(0)"
                  style="color: #111111"
                  >去登陆</span
                >
              </div>
              <div class="wechat-box">
                <div class="wechat-line"></div>
                <div class="wechat-icon-box" @click="wechatLogin">
                  <img
                    src="../../assets/img/wechat_icon.png"
                    class="wechat-icon"
                  />
                  <span class="wechat-icon-text">微信登录</span>
                </div>
                <div class="wechat-line"></div>
              </div>
            </div>

            <!--关联账号-->
            <div class="lank-container" v-if="loginType == 4">
              <div class="lank-title">关联账号</div>
              <div
                style="
                  color: #999999;
                  font-size: 12px;
                  line-height: 12px;
                  margin-top: 18px;
                "
              >
                小主你的微信还没有关联账号哦
              </div>
              <div class="account-box" style="margin-top: 30px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="accessAccount != ''"
                    >邮箱/手机号</span
                  >
                </div>
                <input
                  v-model="accessAccount"
                  class="account-input"
                  :class="[
                    accessAccountNone == true ? 'account-input-active' : '',
                  ]"
                  placeholder="邮箱/手机号"
                  @focus="accessAccountFocus"
                />
                <div class="account-none-text">
                  <span
                    class="account-none-word"
                    v-if="accessAccountNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="account-box" style="margin-top: 4px">
                <div class="account-des-box">
                  <span class="account-des-text" v-if="accessPassword != ''"
                    >密码</span
                  >
                </div>
                <input
                  v-model="accessPassword"
                  type="password"
                  class="account-input"
                  :class="[
                    accessPasswordNone == true ? 'account-input-active' : '',
                  ]"
                  placeholder="密码"
                  @focus="accessPasswordFocus"
                />
                <div class="account-none-text">
                  <span
                    class="account-none-word"
                    v-if="accessPasswordNone == true"
                    >输入不能为空哦</span
                  >
                </div>
              </div>
              <div class="login-btn" @click="accessTap">去关联</div>
              <div class="free-container">
                <span class="free-text" @click="goRegister(1)">免费注册</span>
                <span class="free-text" @click="goRegister(2)">忘记密码</span>
              </div>
            </div>

            <!--微信登陆-->
            <div class="wei-container" v-if="showWechat == true">
              <img
                src="../../assets/img/login_close.png"
                class="login-close"
                @click="closeWechat"
              />
              <div id="login_container"></div>
              <div class="wechat-box" style="margin-top: 66px">
                <div class="wechat-line"></div>
                <div class="wechat-icon-box">
                  <img
                    src="../../assets/img/wechat_icon.png"
                    class="wechat-icon"
                  />
                  <span class="wechat-icon-text">微信登录</span>
                </div>
                <div class="wechat-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动 -->
    <div class="mobile-login d-md-none">
      <!-- 登入 -->
      <div class="mobile-login-login" v-if="loginType == 0">
        <div class="mobile-top">登录</div>
        <div class="mobile-input">
          <input
            :class="[accountNone == true ? 'mobile-active-input' : '']"
            type="text"
            placeholder="账号"
            v-model="account"
            @focus="accountFocus"
          />
          <span class="account-none-word" v-if="accountNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="mobile-input">
          <input
            :class="[passwordNone == true ? 'mobile-active-input' : '']"
            type="password"
            placeholder="密码"
            v-model="password"
            @focus="passwordFocus"
          />
          <span class="account-none-word" v-if="passwordNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="login-btn" @click="loginTap">登录</div>
        <div class="free-container">
          <span class="free-text" @click="goRegister(1)">免费注册</span>
          <span class="free-text" @click="goRegister(2)">忘记密码</span>
        </div>
      </div>
      <!-- 注册 -->
      <div class="mobile-login-login" v-if="loginType == 1">
        <div class="mobile-top">免费注册</div>
        <div class="mobile-input">
          <input
            :class="[phoneNone == true ? 'mobile-active-input' : '']"
            type="text"
            placeholder="手机号"
            v-model="phone"
            @focus="phoneFocus"
          />
          <span class="account-none-word" v-if="phoneNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="mobile-input">
          <input
            :class="[
              codeNone == true
                ? 'mobile-active-input'
                : codeActive == true
                ? 'mobile-active2-input'
                : '',
            ]"
            type="text"
            placeholder="验证码"
            v-model="code"
            @focus="codeFocus"
            @blur="codeBlur"
          />
          <span
            class="mobile-code-number"
            :class="[
              codeNone == true
                ? 'mobile-active-input'
                : codeActive == true
                ? 'mobile-active2-input'
                : this.sendTime != '获取验证码'
                ? 'code-color-text'
                : '',
            ]"
            @click="phoneCodeTap"
            >{{ sendTime }}</span
          >
          <span class="account-none-word" v-if="codeNone == true"
            >输入不能为空哦</span
          >
        </div>
        <!-- 邮箱 -->
        <div class="mobile-input">
          <input
            :class="[emailNone == true ? 'mobile-active-input' : '']"
            type="email"
            placeholder="邮箱"
            v-model="email"
            @focus="emailFocus"
          />
          <span class="account-none-word" v-if="emailNone == true"
            >输入不能为空哦</span
          >
        </div>
        <!-- 设置密码 -->
        <div class="mobile-input">
          <input
            :class="[newpasswordNone == true ? 'mobile-active-input' : '']"
            type="password"
            placeholder="设置密码"
            v-model="newpassword"
            @focus="newpasswordFocus"
          />
          <span class="account-none-word" v-if="newpasswordNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="agreement-container">
          <img
            src="../../assets/img/check_none.png"
            class="check-icon"
            v-if="isChecked == false"
            @click="checkTap(true)"
          />
          <img
            src="../../assets/img/check_save.png"
            class="check-icon"
            v-if="isChecked == true"
            @click="checkTap(false)"
          />
          <span class="agreement-text">我已阅读并同意</span>
          <span class="agreement-text agree-word" style="color: #111111"
          @click="seeMemberText"
            >《会员协议》</span
          >
          <span class="agreement-text">和</span>
          <span class="agreement-text agree-word" style="color: #111111"
          @click="privateMemberText"
            >《隐私声明》</span
          >
        </div>
        <div class="login-btn" @click="useTap">立即注册</div>
        <div class="mobile-to-login">
          <span class="agreement-text">已有账号</span>
          <span
            class="agreement-text agree-word"
            style="color: #111111"
            @click="goRegister(0)"
            >去登陆</span
          >
        </div>
      </div>
      <!-- 忘记密码 -->
      <div class="mobile-login-login" v-if="loginType == 2">
        <div class="mobile-top">忘记密码</div>
        <div class="mobile-input">
          <input
            :class="[phoneNone == true ? 'mobile-active-input' : '']"
            type="text"
            placeholder="手机号"
            v-model="phone"
            @focus="phoneFocus"
          />
          <span class="account-none-word" v-if="phoneNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="mobile-input">
          <input
            :class="[
              codeNone == true
                ? 'mobile-active-input'
                : codeActive == true
                ? 'mobile-active2-input'
                : '',
            ]"
            type="text"
            placeholder="验证码"
            v-model="code"
            @focus="codeFocus"
            @blur="codeBlur"
          />
          <span
            class="mobile-code-number"
            :class="[
              codeNone == true
                ? 'mobile-active-input'
                : codeActive == true
                ? 'mobile-active2-input'
                : this.sendTime != '获取验证码'
                ? 'code-color-text'
                : '',
            ]"
            @click="phoneCodeTap"
            >{{ sendTime }}</span
          >
          <span class="account-none-word" v-if="codeNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="login-btn" @click="setNewTap">设置新密码</div>
        <div class="mobile-to-login">
          <span class="free-text" style="color: #111111" @click="goRegister(0)"
            >去登陆</span
          >
        </div>
      </div>
      <!-- 设置新密码 -->
      <div class="mobile-login-login" v-if="loginType == 3">
        <div class="mobile-top">设置新密码</div>
        <div class="mobile-input">
          <input
            :class="[resetPasswordNone == true ? 'mobile-active-input' : '']"
            type="password"
            placeholder="新密码"
            v-model="resetPassword"
            @focus="resetPasswordFocus"
          />
          <span class="account-none-word" v-if="resetPasswordNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="mobile-input">
          <input
            :class="[
              resetPasswordAgainNone == true ? 'mobile-active-input' : '',
            ]"
            type="password"
            placeholder="再次输入新密码"
            v-model="resetPasswordAgain"
            @focus="resetPasswordAgainFocus"
          />
          <span class="account-none-word" v-if="resetPasswordAgainNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="login-btn" @click="sureTap">确定</div>
      </div>
      <!-- 关联账号 -->
      <div class="mobile-login-login" v-if="loginType == 4">
        <div class="mobile-top">
          关联账号
          <span class="mobile-top-span">小主你的微信还没有关联账号哦</span>
        </div>
        <div class="mobile-input">
          <input
            :class="[accessAccountNone == true ? 'mobile-active-input' : '']"
            type="text"
            placeholder="邮箱/手机号"
            v-model="accessAccount"
            @focus="accessAccountFocus"
          />
          <span class="account-none-word" v-if="accessAccountNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="mobile-input">
          <input
            :class="[accessPasswordNone == true ? 'mobile-active-input' : '']"
            type="password"
            placeholder="密码"
            v-model="accessPassword"
            @focus="accessPasswordFocus"
          />
          <span class="account-none-word" v-if="accessPasswordNone == true"
            >输入不能为空哦</span
          >
        </div>
        <div class="login-btn" @click="accessTap">去关联</div>
        <div class="free-container">
          <span class="free-text" @click="goRegister(1)">免费注册</span>
          <span class="free-text" @click="goRegister(2)">忘记密码</span>
        </div>
      </div>
      <!-- 微信 -->
      <div class="mobile-wechat-box">
        <div class="wechat-icon-box" @click="wechatLogin">
          <img src="../../assets/img/wechat_icon.png" class="wechat-icon" />
          <span class="wechat-icon-text">微信登录</span>
        </div>
      </div>
      <!--微信登陆-->
      <div class="wei-container" v-if="showWechat == true">
        <img
          src="../../assets/img/login_close.png"
          class="login-close"
          @click="closeWechat"
        />
        <div id="login_container"></div>
        <div class="wechat-box" style="margin-top: 66px">
          <div class="wechat-line"></div>
          <div class="wechat-icon-box">
            <img src="../../assets/img/wechat_icon.png" class="wechat-icon" />
            <span class="wechat-icon-text">微信登录</span>
          </div>
          <div class="wechat-line"></div>
        </div>
      </div>
    </div>

    <!-- 会员协议 -->
    <div class="dialog">
      <el-dialog title="会员协议" :visible.sync="centerDialogVisible1" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->

        <div class="read">
          <el-scrollbar>
            <div class="read-item" v-html="notes"></div>
          </el-scrollbar>
        </div>
      </el-dialog>
    </div>
    <!-- 隐私声明 -->
    <div class="dialog">
      <el-dialog title="隐私声明" :visible.sync="centerDialogVisible2" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <div class="read">
          <el-scrollbar>
            <div class="read-item" v-html="notes2"></div>
          </el-scrollbar>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { resultEmail, resultPhone } from "../../uitils/check";
import {
  loginLoginApi,
  loginSendMessageApi,
  loginUserRegisterApi,
  loginTestCodeApi,
  loginEditPwdApi,
  loginWeChatApi,
  bindAccountApi,
  loginApi,
  basicGetApi
} from "../../assets/api/api";

export default {
  name: "login",
  data() {
    return {
      account: "",
      password: "",
      loginType: 0, //0-登陆,1-注册,2-忘记密码,3-设置新密码，4-关联账号
      accountNone: false, //账号
      passwordNone: false, //账号是否为空
      phone: "", //手机号
      phoneNone: false, //手机号是否为空
      code: "", //验证码
      codeNone: false, //验证码是否为空
      codeActive: false, //验证码是否为focus
      email: "", //邮箱
      emailNone: false, //邮箱是否为空
      newpassword: "", //注册设置密码
      newpasswordNone: false, //注册设置密码是否为空
      isChecked: false,
      resetPassword: "", //设置新密码中密码
      resetPasswordNone: false, //设置新密码中密码是否为空
      resetPasswordAgain: "", //设置新密码中再次确认密码
      resetPasswordAgainNone: false, //设置新密码中再次确认密码是否为空
      sendTime: "获取验证码",
      sendWait: 60,
      showWechat: false,
      accessAccount: "", //关联账号邮箱手机号
      accessAccountNone: false, //关联账号邮箱手机号是否为空
      accessPassword: "", //关联账号密码
      accessPasswordNone: false, //关联账号密码是否为空
      centerDialogVisible1:false,
      centerDialogVisible2:false,
      notes:'',
      notes2:'',
      image1:'',
      image2:'',
    };
  },
  created() {
    this.deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    if (this.$route.query.state) {
      this.loginType = 4;
    }
    if (this.$route.query.type == 1) {
      this.loginType = 0;
    } else if (this.$route.query.type == 2) {
      this.loginType = 1;
    }
    this.getInfo();
  },
  methods: {
    getInfo(){
      basicGetApi().then((res)=>{
        console.log(res);
        this.image1 = res.data.data.loginBackground;
        this.image2 = res.data.data.loginImage;
        this.$("#loginOne").css('background-image',`url(${this.image1})`);
        this.$("#loginTwo").css('background-image',`url(${this.image2})`);

      })
    },
    resetNone() {
      this.accountNone = false;
      this.passwordNone = false;
      this.phoneNone = false;
      this.codeNone = false;
      this.codeActive = false;
      this.emailNone = false;
      this.newpasswordNone = false;
      this.resetPasswordNone = false;
      this.resetPasswordAgainNone = false;
    },
    //微信登陆
    wechatLogin() {
      this.showWechat = true;
      let that = this;
      loginWeChatApi().then((res) => {
        if (res.data.result == 0) {
          console.log("...");
          let info = res.data.data;
          //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
          var obj = new WxLogin({
            self_redirect: false, //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
            id: "login_container", //需要承载二维码的容器id
            appid: info.appid,
            scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
            redirect_uri: info.redirectUrl, //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
            state: info.state,
            style: "black",
            href: "",
          });
        } else {
          Message.error(res.data.msg);
        }
      });
    },
    closeWechat() {
      this.showWechat = false;
    },
    checkTap(i) {
      this.isChecked = i;
    },
    getPhoneCode(para, inter) {
      loginSendMessageApi(para).then((res) => {
        if (res.data.result == 0) {
          Message.success("获取验证码成功");
        } else {
          Message.error(res.data.msg);
          clearInterval(inter);
        }
      });
    },
    phoneCodeTap() {
      let that = this;
      if (this.sendTime == "获取验证码") {
        if (this.phone == "") {
          this.phoneNone = true;
        } else if (resultPhone(this.phone) == false) {
          Message.error("手机号格式错误");
        } else {
          // 60秒后重新获取验证码
          var inter = setInterval(function () {
            that.sendWait = that.sendWait - 1;
            that.sendTime = "重新获取(" + that.sendWait + "s)";
            if (that.sendWait < 0) {
              clearInterval(inter);
              that.sendTime = "获取验证码";
              that.sendWait = 60;
            }
          }, 1000);
          let para = {
            phone: this.phone,
          };
          this.getPhoneCode(para, inter);
        }
      } else {
        console.log("不可点击");
      }
    },
    getTestCode() {
      let that = this;
      let para = {
        phone: this.phone,
        code: this.code,
      };
      loginTestCodeApi(para).then((res) => {
        if (res.data.result == 0) {
          that.loginType = 3;
        } else {
          Message.error(res.data.msg);
        }
      });
    },
    setNewTap() {
      if (this.phone == "") {
        this.phoneNone = true;
      } else if (resultPhone(this.phone) == false) {
        Message.error("请输入正确的手机号格式");
      } else if (this.code == "") {
        this.codeNone = true;
      } else {
        this.getTestCode();
      }
    },
    sureTap() {
      let that = this;
      let para = {
        phone: this.phone,
        newPwd: this.resetPassword,
      };
      console.log(para);
      if (this.resetPassword == "") {
        this.resetPasswordNone = true;
      } else if (this.resetPasswordAgain == "") {
        this.resetPasswordAgainNone = true;
      } else if (this.resetPassword != this.resetPasswordAgain) {
        Message.error("请确认输入同样的密码");
      } else {
        loginEditPwdApi(para).then((res) => {
          if (res.data.result == 0) {
            Message.success("新密码设置成功");
            that.loginType = 0;
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    resetPasswordAgainFocus() {
      this.resetPasswordAgainNone = false;
    },
    resetPasswordFocus() {
      this.resetPasswordNone = false;
    },
    goRegister(i) {
      this.loginType = i;
      this.isChecked = false;
      this.phone = "";
      this.code = "";
      this.email = '';
      this.newpassword = '';
      this.resetNone();
    },
    useTap() {
      let that = this;
      let para = {
        phone: this.phone,
        verifyCode: this.code,
        mail: this.email,
        password: this.newpassword,
      };
      if (this.phone == "") {
        this.phoneNone = true;
      } else if (resultPhone(this.phone) == false) {
        Message.error("请输入正确的手机号格式");
      } else if (this.code == "") {
        this.codeNone = true;
      } else if (this.email == "") {
        this.emailNone = true;
      } else if (resultEmail(this.email) == false) {
        Message.error("请输入正确的邮箱格式");
      } else if (this.newpassword == "") {
        this.newpasswordNone = true;
      } else if (this.isChecked == false) {
        Message.error("请同意《会员协议》和《隐私协议》");
      } else {
        loginUserRegisterApi(para).then((res) => {
          if (res.data.result == 0) {
            Message.success("注册成功");
            that.loginType = 0;
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    newpasswordFocus() {
      this.newpasswordNone = false;
    },
    emailFocus() {
      this.emailNone = false;
    },
    codeBlur() {
      this.codeActive = false;
    },
    codeFocus() {
      this.codeNone = false;
      this.codeActive = true;
    },
    phoneFocus() {
      this.phoneNone = false;
    },
    accessPasswordFocus() {
      this.accessPasswordNone = false;
    },
    passwordFocus() {
      this.passwordNone = false;
    },
    accessAccountFocus() {
      this.accessAccountNone = false;
    },
    accountFocus() {
      this.accountNone = false;
    },
    accessTap() {
      let that = this;
      let para = {
        phoneOrMail: this.accessAccount,
        password: this.accessPassword,
        state: this.$route.query.state,
      };

      if (this.accessAccount == "") {
        this.accessAccountNone = true;
      } else if (
        resultPhone(this.accessAccount) == false &&
        resultEmail(this.accessAccount) == false
      ) {
        Message.error("请输入正确的手机号或邮箱");
      } else if (this.accessPassword == "") {
        this.accessPasswordNone = true;
      } else {
        bindAccountApi(para).then((res) => {
          if (res.data.result == 0) {
            Message.success("登录成功");
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("avatar", res.data.data.avatar);
            sessionStorage.setItem("nickname", res.data.data.nickname);
            console.log(sessionStorage.getItem("address"));
            if(sessionStorage.getItem("address")){
              this.$router.push({ path: sessionStorage.getItem("address") });
            }else{
              this.$router.push('/index')
            }
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    loginTap() {
      let that = this;
      let para = {
        phone: this.account,
        password: this.password,
      };
      if (this.account == "") {
        this.accountNone = true;
      } else if (
        resultPhone(this.account) == false &&
        resultEmail(this.account) == false
      ) {
        Message.error("请输入正确的手机号或邮箱");
      } else if (this.password == "") {
        this.passwordNone = true;
      } else {
        loginLoginApi(para).then((res) => {
          if (res.data.result == 0) {
            Message.success("登录成功");
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("avatar", res.data.data.avatar);
            sessionStorage.setItem("nickname", res.data.data.nickname);
            if(sessionStorage.getItem("address")){
              this.$router.push({ path: sessionStorage.getItem("address") });
            }else{
              this.$router.push('/index')
            }
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    // 会员协议
    seeMemberText(){
      console.log("...");
      this.centerDialogVisible1 = true;
      loginApi({
        type:0
      }).then((res)=>{
        console.log(res);
        this.notes = res.data.data;
      })
    },
    privateMemberText(){
      this.centerDialogVisible2 = true;
      loginApi({
        type:1
      }).then((res)=>{
        this.notes2 = res.data.data;
      })
    }
  },
};
</script>

<style scoped lang="less">
.login-main,
.login-main2 {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e3e7ec;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: url("../../assets/img/home_ban.png") no-repeat;
  background-size: cover;
  min-width: 1200px;
  padding-left: 40px;
  padding-right: 40px
}


.login-main2 {
  background: rgba(85, 85, 85, 0.8);
  z-index: 999;
}

.login-container {
  display: flex;
  align-items: stretch;
  height: 700px;
  max-width: 1440px;
}

.login-left-box {
  // background: url("../../assets/img/login_left.png") no-repeat;
  background-size: cover;
 
}

.login-right-box {
  background: #ffffff;
}

.lank-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.lank-title {
  color: #111111;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 140px;
}

.account-box {
  width: 360px;
  margin-top: 60px;
}

.account-des-box {
  width: 360px;
  height: 30px;
  display: flex;
  align-items: center;
}

.account-des-text {
  color: #666666;
  font-size: 12px;
}

.account-input,
.code-input {
  width: 360px;
  height: 30px;
  border: none;
  outline: none;
  border-bottom: 1px solid #666666;
  margin: 0;
  padding: 0;
  line-height: 30px;
  color: #111111;
  font-size: 18px !important;
}

.code-box {
  width: 360px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code-input {
  width: 260px;
}

.code-des {
  flex: 1;
  height: 30px;
  text-align: right;
  color: #111111;
  font-size: 14px;
  line-height: 30px;
  border-bottom: 1px solid #666666;
}

.code-des:hover {
  cursor: pointer;
}

.account-input::placeholder {
  color: #666666;
  font-size: 18px !important;
}

.code-input::placeholder {
  color: #666666;
  font-size: 18px !important;
}

.account-input:focus {
  border-bottom: 2px solid #111111;
}

.account-input-active {
  border-bottom: 2px solid #ce2829;
}

.account-input-active2 {
  border-bottom: 2px solid #111111;
}

.code-color-text {
  color: #666666;
}

.account-none-text {
  width: 360px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
}

.account-none-word {
  color: #ce2829;
  font-size: 12px;
  line-height: 12px;
}

.login-btn {
  width: 360px;
  height: 40px;
  background: #111111;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  margin-top: 34px;
  position: relative;
  overflow: hidden;
}

.free-container {
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 12px;
}

.free-text {
  color: #666666;
  font-size: 12px;
  line-height: 12px;
}

.free-text:hover {
  cursor: pointer;
  color: #111111;
}

.wechat-box {
  width: 360px;
  height: 24px;
  margin-top: 118px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wechat-line {
  width: 130px;
  height: 1px;
  background: #eeeeee;
}

.wechat-icon-box {
  display: flex;
  align-items: center;
}

.wechat-icon-box:hover {
  cursor: pointer;
}

.wechat-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.wechat-icon-text {
  color: #111111;
  font-size: 12px;
  line-height: 12px;
}

.agreement-container {
  width: 360px;
  height: 18px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.check-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.check-icon:hover {
  cursor: pointer;
}

.agreement-text {
  color: #666666;
  font-size: 12px;
  line-height: 12px;
}

.agree-word:hover {
  cursor: pointer;
}

.login-btn:hover {
  cursor: pointer;
}

.login-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.22),
    transparent
  );
  transition: all 650ms;
}

.login-btn:hover:before {
  left: 100%;
}

.wei-container {
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  -webkit-transition: -webkit-transform 0.4s ease-out;
  -moz-transition: -moz-transform 0.4s ease-out;
  -o-transition: -o-transform 0.4s ease-out;
  -ms-transition: -ms-transform 0.4s ease-out;
}

.login-close:hover {
  cursor: pointer;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

#login_container {
  height: 410px;
  margin-top: 140px;
}

.mobile-login {
  margin-top: 63px;
  padding: 30px 24px;
  #login_container {
    width: 300px !important;
  }
  .mobile-wechat-box {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mobile-login-login {
    .mobile-top {
      font-size: 20px;
      color: #111;
      font-weight: 500;
      .mobile-top-span {
        font-size: 12px;
      }
    }
    .mobile-input {
      width: 100%;
      margin-top: 15px;
      position: relative;
      input {
        color: #111;
        font-size: 16px;
        width: 100%;
        outline: none;
        border: 0;
        padding: 14px 0;
        border-bottom: 1px solid #999999;
      }
      .mobile-active-input {
        border-bottom: 1px solid #ce2829;
      }
      .mobile-active2-input {
        border-bottom: 1px solid #999;
      }
      .account-none-word {
        display: inline-block;
        width: 100%;
        text-align: right;
      }
    }
    .login-btn {
      width: 100%;
    }
    .free-container {
      width: 100%;
    }
  }
  .agreement-container {
    margin-top: 14px;
  }
}

.mobile-code-number {
  position: absolute;
  right: 0;
  top: 20px;
  color: #111;
}

.mobile-to-login {
  margin-top: 15px;
}

.read {
  height: 400px;
}

.read-item {
  display: block;
  margin: bottom 0 !important;
  height: 400px;
  padding-right: 20px;
}

/deep/ .dialog{
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 20px;
}

/deep/ .el-scrollbar {
  height: 100% !important;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>