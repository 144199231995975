<template>
  <div
    :class="{ classic: true, classic_mobile: isMobile, pcClassic: !isMobile }"
    v-on:scroll.passive="onScroll"
    ref="box"
    @click="
      selectone = false;
      keyShow = false;
    "
  >
    <div class="classic-top">
      <div class="post-img">
        <!-- <img class="img-fluid" src="../../assets/img/banner8.png" alt="" /> -->
        <img
          class="img-fluid"
          src="http://file.antiker.cn/8a451765ab2f4580ace8e9a38842ce37.png"
          alt=""
        />
      </div>
      <div class="classic-nav">
        <div class="container my-nav">
          <span
            :class="{ 'nav-item': true, 'back-white': allBtn }"
            @click="clickAll"
            >全部</span
          >
          <span
            :class="{
              'nav-item': true,
              'back-white': chooseNav == index && allBtn == false,
            }"
            v-for="(item, index) in navList"
            :key="index"
            @click="toChooseNav(index, item.id)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="container classic-container">
      <div class="row classic-container-row">
        <template v-if="allBtn == false">
          <div class="classic-three-left">
            <div
              class="classic-container-item"
              v-for="(item, index) in navChildrenList"
              :key="index"
              @click="showOne(item.id, $event)"
            >
              <span>{{ item.name }}</span>
              <img
                class="triangle"
                src="../../assets/img/black-bottom-arrow.png"
                alt=""
                v-if="selectone == false || item.id != twoId"
              />
              <img
                class="triangle"
                src="../../assets/img/black-top-arrow.png"
                alt=""
                v-else
              />
              <div class="d-sm-none d-lg-block">
                <div
                  @click="avoidPro"
                  :class="{
                    'position-item': true,
                    none: selectone == false || item.id != twoId,
                  }"
                >
                  <div class="position-top-item">
                    <div
                      class="position-item-item"
                      v-for="(item, index) in itemsList"
                      :key="index"
                      @click="chooseItems(index, $event)"
                    >
                      <img
                        class="circle-choosed"
                        src="../../assets/img/circle-choosed.png"
                        alt=""
                        v-if="item.flag"
                      />
                      <span class="circle-span" v-else></span>
                      <span class="position-word">{{ item.name }}</span>
                    </div>
                  </div>

                  <div class="sure-btn" @click="sureBtn"><div>确定</div></div>
                </div>
              </div>
            </div>
          </div>

          <div
            :class="{ 'mobile-nav': true, 'd-lg-none': true, none: !selectone }"
            @click="avoidPro"
          >
            <div
              :class="{ 'mobile-nav-item': true }"
              v-for="(item, index) in itemsList"
              :key="index"
              @click="chooseItems(index)"
            >
              <span :class="{ colorChange: item.flag }">{{ item.name }}</span>
              <img v-if="item.flag" src="../../assets/img/sel.png" alt="" />
            </div>
            <div class="mobile-nav-bottom">
              <div class="one" @click="reset">重置</div>
              <div class="two" @click="sureBtn">确定</div>
            </div>
          </div>
        </template>

        <div class="classic-search d-sm-none d-lg-block">
          <input
            type="text"
            placeholder="你在找什么"
            v-model.trim="searchName"
            @click="seeKeyShow"
            @change="seeDetailWords"
          />
          <button @click="searchChange">搜索</button>
          <img src="../../assets/img/search_black.png" alt="" />
          <div class="search-catagory" v-if="keyShow">
            <span class="hot cursor">热门场景</span>
            <span
              class="keyspan cursor"
              v-for="(item, index) in keyList"
              :key="index"
              @click="getKOneKeyWord(item, $event)"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
      <div class="row tag-container" v-if="allBtn == false">
        <el-tag
          closable
          v-for="(item, index) in itemChildrenList"
          :key="index"
          @close="handleClose(index)"
          >{{ item.name }}</el-tag
        >
        <el-tag
          class="clear-tag"
          @click="clearTag"
          v-if="itemChildrenList.length > 0"
          >清空筛选</el-tag
        >
      </div>
    </div>

    <!-- 主体部分 -->
    <div class="container classic-container">
      <div class="classic-title">
        <span class="text-bottom-line">{{ text }}</span>
        <span class="introduction">{{ this.introduction }}</span>
      </div>
      <div class="classic-container-nav">
        <span
          :class="{ 'container-nav-select': containerSelect == 0 }"
          @click="chooseConNav(0)"
          >默认</span
        >
        <span
          :class="{ 'container-nav-select': containerSelect == 1 }"
          @click="chooseConNav(1)"
          >最新</span
        >
        <span
          :class="{ 'container-nav-select': containerSelect == 2 }"
          @click="chooseConNav(2)"
          >最热</span
        >
        <span
          :class="{ 'container-nav-select': containerSelect == 3 }"
          @click="chooseConNav(3)"
          >字母</span
        >
        <img
          class="d-sm-block d-lg-none"
          src="../../assets/img/search_black.png"
          alt=""
          @click="mobileSearch"
        />
      </div>
      <div class="line"></div>
      <div class="wholePage" v-if="mobileShow">
        <div class="toposi">
          <img src="../../assets/img/search_black.png" alt="" />
          <input type="text" v-model.trim="searchName" />
          <button @click="toSearch">搜索</button>
        </div>
        <div class="hotpot">
          <span class="hotpot-two">热门场景</span>
          <span
            class="hotpot-one"
            v-for="(item, index) in keyList"
            :key="index"
            @click="getKOneKeyWord(item)"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <!-- 内容主题 -->
      <div class="row row-discount-item">
        <div
          class="col-lg-6 col-sm-12"
          v-for="(item, index) in shopList"
          :key="index"
        >
          <div :class="{ 'discount-item': true, pointer: true }">
            <img
              v-if="item.type == 2"
              :src="item.image"
              alt=""
              @click="toLifePath(index)"
            />
            <div class="myVideo" v-if="item.type == 3">
              <!-- <video :src="item.link" :id="index"></video> -->
              <img :src="item.banner" alt="" />
            </div>

            <div v-if="item.type == 3" class="circle" @click="toOpen(index)">
              <img
                src="../../assets/img/ic_open.png"
                alt=""
                v-if="item.flag == false"
              />
              <img src="../../assets/img/ic_close.png" alt="" v-else />
            </div>
            <span class="introduction">{{ item.name }}</span>
          </div>
        </div>
        <div class="null-info" v-if="shopList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
      </div>
    </div>

    <div class="container d-sm-none d-lg-block" v-if="shopList.length > 0">
      <div class="discount-bottom">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
    <el-dialog
      :visible.sync="videoOpen"
      width="30%"
      custom-class="videoDialog"
      center
      :before-close="videoClose"
    >
      <video
        class="dialog-video"
        :src="videoSrc"
        controls
        id="videoOpen"
      ></video>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="videoOpen = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>


<script>
import {
  getcBrandLifePage,
  getClassicClassification,
  pageKeyWord,
  baseIntroductionApi,
} from "../../assets/api/api.js";
export default {
  name: "shopList",
  created() {
    this.getList();
    this.getKeyWords();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    baseIntroductionApi({ type: 3 }).then((res) => {
      console.log(res);
      this.introduction = res.data.data;
    });
  },
  data() {
    return {
      chooseNav: 0,
      selectone: false,
      containerSelect: 0,
      navList: [],
      navChildrenList: [],
      itemsList: [],
      shopList: [],
      itemChildrenList: [],
      currentPage: 1,
      allBtn: true,
      status: 0,
      pageSize: 6,
      tid: null,
      total: 0,
      searchName: "",
      text: "全部",
      oneId: "",
      twoId: "",
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
      keyShow: false,
      mobileShow: false,
      introduction: "",
      videoOpen: false,
      videoSrc: "",
    };
  },
  methods: {
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getcBrandLifePage({
        name: this.searchName,
        isType: 1,
        pageNo: val,
        pageSize: this.pageSize,
        sortType: this.status,
        classificationIdList: this.tid,
      });
      res = res.data.data;
      this.shopList = res.items;
      this.total = res.total;
      console.log(res);
    },
    // 获取分页列表
    async getList() {
      let res = await getClassicClassification({ type: 5, fid: 0 });
      res = res.data.data;
      this.navList = res.items;
      this.pageNo = 1;
      this.shopList = [];
      // 获取首页数据
      this.getData();
    },
    // 获得数据
    async getData() {
      this.currentPage = 1;
      let res = await getcBrandLifePage({
        name: this.searchName,
        isType: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        sortType: this.status,
        classificationIdList: this.tid,
      });
      // 问题
      console.log(res);
      res = res.data.data;
      this.shopList = this.shopList.concat(res.items);
      this.total = res.total;
    },
    toChooseNav(index) {
      console.log(index);
      this.chooseNav = index;
    },
    // 选择一级分类
    async toChooseNav(index, id) {
      this.introduction = this.navList[index].introduction;
      this.chooseNav = index;
      this.allBtn = false;
      this.text = this.navList[index].name;
      let res = await getClassicClassification({ type: 5, fid: id });
      res = res.data.data;
      this.navChildrenList = res.items;
      this.twoId = id;
      this.oneId = id;
      this.tid = id;
      this.itemChildrenList = [];
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
      console.log(res);
    },
    showOne(id, e) {
      this.selectone = true;
      this.twoId = id;
      this.getThreeData(id);
      e.stopPropagation();
    },
    // 获得三级数据
    async getThreeData(id) {
      let res = await getClassicClassification({ type: 5, fid: id });
      res = res.data.data;
      this.itemsList = res.items;
      console.log(res);
    },
    // 选择三级数据
    chooseItems(index, e) {
      this.itemsList[index].flag = !this.itemsList[index].flag;
      // e.stopPropagation()
    },
    // 确定
    sureBtn() {
      let arr1 = [];
      let arr2 = [];
      for (let i = 0; i < this.itemsList.length; i++) {
        if (this.itemsList[i].flag) {
          arr1.push({
            id: this.itemsList[i].id,
            name: this.itemsList[i].name,
          });
          arr2.push(this.itemsList[i].id);
        }
      }
      this.itemChildrenList = arr1;
      this.tid = arr2.join(",");
      console.log(this.tid);
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
      this.selectone = false;
    },
    // 清空tag
    clearTag() {
      this.itemChildrenList = [];
      this.tid = this.oneId;
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 诸葛清空tag
    handleClose(index) {
      this.itemChildrenList.splice(index, 1);
      let arr = [];
      for (let i = 0; i < this.itemChildrenList.length; i++) {
        arr.push(this.itemChildrenList[i].id);
      }
      this.tid = arr.join(",");
      if (this.itemChildrenList.length == 0) {
        this.tid = this.oneId;
      }
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 默认，最新
    chooseConNav(index) {
      this.containerSelect = index;
      this.status = index;
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 全选
    clickAll() {
      baseIntroductionApi({ type: 3 }).then((res) => {
        this.introduction = res.data.data;
      });
      this.allBtn = true;
      this.text = "全部";
      this.tid = null;
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 阻止冒泡
    avoidPro(e) {
      e.stopPropagation();
    },
    // 搜索结果
    searchChange() {
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    // 播放视频
    toOpen(index) {
      this.videoOpen = true;
      this.videoSrc = this.shopList[index].link;
      this.$nextTick(function () {
        this.$("#videoOpen").trigger("load");
        this.$("#videoOpen").trigger("play");
      });
    },
    // 重置
    reset() {
      for (let i = 0; i < this.itemsList.length; i++) {
        this.itemsList[i].flag = false;
      }
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.shopList.length < this.total) {
          this.loading = false;
          this.getData();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
    // 链接
    toLifePath(index) {
      console.log(this.shopList[index]);
      if (this.shopList[index].jumpType == 0) {
        location.href = this.shopList[index].link;
      } else if (this.shopList[index].jumpType == 2) {
      } else {
        this.$router.push({
          path: "/brandDetail",
          query: {
            id: this.shopList[index].id,
            type: 1,
          },
        });
      }
    },
    getKeyWords() {
      pageKeyWord({
        name: this.searchName,
      }).then((res) => {
        this.keyList = res.data.data.items;
        console.log(this.keyList);
      });
    },
    seeKeyShow(e) {
      this.keyShow = true;
      e.stopPropagation();
    },
    getKOneKeyWord(item, e) {
      console.log(item);
      this.searchName = item.name;
      e.stopPropagation();
    },
    seeDetailWords() {
      this.getKeyWords();
    },
    // 移动端显示
    mobileSearch() {
      this.mobileShow = true;
    },
    toSearch() {
      this.mobileShow = false;
      this.pageNo = 1;
      this.shopList = [];
      this.getData();
    },
    videoClose() {
      console.log("...");
      this.videoOpen = false;
      let video = document.getElementById("videoOpen");
      video.pause();
    },
  },
};
</script>


<style lang="less" scoped>
.classic::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: #fff;
}

.sclassic::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 0;

  box-shadow: none;

  background: #fff;
}

.classic::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: none;

  border-radius: 0;

  background: #fff;
}
.classic_mobile {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}
.pcClassic {
  margin-bottom: 80px;
}
.post-img {
  width: 100%;
  img {
    width: 100%;
    max-height: 580px;
  }
}
.classic-top {
  position: relative;
}
.classic-nav {
  width: 100%;
  // height: 46px;
  background: rgba(17, 17, 17, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  .my-nav {
    height: 100%;
    font-size: 16px;
    .nav-item {
      display: inline-block;
      height: 100%;
      line-height: 46px;
      padding: 0 20px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }
    .back-white {
      background-color: #fff;
      color: #111;
      font-weight: bold;
    }
  }
}

.classic-container {
  margin-top: 30px;
  font-size: 16px;
  .classic-container-item {
    width: 128px;
    height: 42px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 15px;
  }
  .triangle {
    width: 10px;
    height: 10px;
  }
  .position-item {
    width: 406px;
    height: 300px;
    // overflow-y: scroll;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 52px;
    left: 0;
    padding: 15px 15px 0 15px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .position-top-item{
      flex: 1;
      overflow: scroll;
    }
    .position-item-item {
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      .circle-span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-left: 10px;
      }
      .circle-choosed {
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
      .position-word {
        margin-left: 15px;
      }
    }
    .sure-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      background-color: #f5f5f5;
      position: sticky;
      bottom: 0;
      flex-shrink: 0;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 30px;
        background: #111111;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .position-item::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    left: 10px;
    top: -20px;
  }
}
.none {
  display: none !important;
}

.classic-title {
  font-size: 40px;
  font-weight: 500;
  color: #111;
  text-align: center;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .introduction {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    margin-top: 15px;
    border: 0;
  }
  .text-bottom-line {
    border-bottom: 1px solid #111;
    padding-bottom: 10px;
  }
}

.classic-container-nav {
  margin-top: 40px;
  position: relative;
  span {
    display: inline-block;
    margin-right: 30px;
    color: #666;
    height: 40px;
    cursor: pointer;
  }
  .container-nav-select {
    color: #111;
    border-bottom: 2px solid #111;
    font-weight: 500;
    cursor: pointer;
  }
  img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 50px;
    top: 0;
  }
}

.classic-container {
  .line {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin-bottom: 60px;
  }
}

.row {
  margin: 0;
}

///////////////////////////////////////////////////////////
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .classic-container {
    margin-top: 20px;
    .classic-container-item {
      margin-right: 0;
      width: 23%;
      margin: 0 3px;
      margin-bottom: 5px;
    }
    .classic-three-left {
      width: 100%;
    }
  }
  .mobile-nav {
    width: 100%;
    background-color: #f5f5f5;
    .mobile-nav-item {
      display: inline-block;
      width: 50%;
      padding: 20px 40px;
      color: #999;
      font-size: 14px;
      img {
        height: 18px;
        width: 18px;
        margin-left: 10px;
        margin-top: -5px;
      }
      .colorChange {
        color: #111;
      }
    }
    .mobile-nav-bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
      div {
        width: 40%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .one {
        border: 1px solid #111;
      }
      .two {
        background-color: #111;
        color: #fff;
      }
    }
  }
  .tag-container {
    padding-left: 20px;
    .el-tag {
      margin-bottom: 10px;
    }
  }
  .classic-title {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    span {
      border-bottom: 2px solid #111;
      padding-bottom: 10px;
    }
    .introduction {
      padding: 0 10px;
    }
  }
  .classic-container-nav {
    padding-left: 20px;
  }
  .no-padding-right {
    padding-right: 5px;
  }
  .no-padding-left {
    padding-left: 5px;
  }
  .line {
    margin-bottom: 30px !important;
  }
  .classic-container-row {
    display: flex;
    justify-content: flex-start !important;
  }
  .discount-item-bottom {
    height: 100px !important;
    .brand {
      margin-top: 5px !important;
    }
    .bottom-line {
      margin-top: 5px !important;
    }
    .intro {
      margin-top: 5px !important;
      font-size: 10px !important;
    }
  }
  .discount-item {
    margin-bottom: 30px !important;
    img {
      width: 100% !important;
      height: 345px !important;
    }
    .myVideo {
      height: 345px !important;
    }
    .introduction {
      position: absolute;
      height: 45px;
      width: 100%;
      background: rgba(17, 17, 17, 0.6);
      bottom: 0;
      left: 0;
      line-height: 45px;
      font-size: 15px !important;
      color: #fff;
    }
  }
  .circle {
    opacity: 1 !important;
    img {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.classic {
}
.discount-poster {
  width: 100%;
  img {
    width: 100%;
    height: 580px;
  }
}

.discount-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 40px;
  padding: 0;
  position: relative;
  margin-bottom: 50px;
  img {
    width: 100%;
    height: 600px;
    // cursor: pointer;
  }
  .myVideo {
    width: 100%;
    height: 600px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .circle {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(187, 164, 146, 0.6);
    opacity: 0;
    transition: all 0.5s ease;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .introduction {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
.discount-item:hover .circle {
  opacity: 1;
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

.tag-container {
  margin-top: 30px;
}

.classic-container-row {
  display: flex;
  justify-content: space-between;
}

.classic-search {
  position: relative;
  margin-left: auto;
  input {
    border: 0;
    outline: none;
    background-color: #f5f5f5;
    height: 40px;
    padding-left: 60px;
    font-size: 14px;
    color: #111;
  }
  ::-webkit-input-placeholder {
    color: #ccc;
  }
  button {
    border: 0;
    outline: none;
    background-color: #111;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
  }
  img {
    position: absolute;
    top: 12px;
    left: 20px;
  }
  .search-catagory {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 4px 10px 0px rgba(17, 17, 17, 0.2);
    background-color: #fff;
    .hot {
      display: block;
      margin: 20px;
      font-size: 12px;
      color: #999;
    }
    .keyspan {
      display: block;
      padding: 20px;
      font-size: 14px;
    }
  }
}

/////////////////////////////////////
/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
  margin-top: 10px;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
}

/deep/ .el-tag .el-tag__close:hover {
  background-color: #111;
}

/deep/ .clear-tag {
  background-color: #eee;
  color: #111;
  border: 0;
  cursor: pointer;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}
.cursor {
  cursor: pointer;
}
.wholePage {
  position: fixed;
  top: 61px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;
  padding: 20px;
  .toposi {
    position: relative;
    width: 100%;
    display: flex;
  }
  input {
    outline: none;
    height: 40px;
    width: 100%;
    padding-left: 45px;
    font-size: 14px;
  }
  button {
    outline: none;
    background-color: #111;
    color: #fff;
    height: 40px;
    border: 0;
    width: 20%;
  }
  img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 10px;
  }
}
.hotpot {
  span {
    display: block;
    padding: 15px 0;
  }
  .hotpot-one {
    font-size: 16px;
  }
  .hotpot-two {
    color: #999;
  }
}
.classic-three-left {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

::-webkit-scrollbar {
  width: 10px;
  height: 1px;
  // background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #bbbbbb;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  background: #ededed;
}

.null-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img {
  width: 200px;
  height: 200px;
}

.dialog-video {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

@media (min-width: 300px) and (max-width: 992px) {
  .classic-nav .my-nav {
    font-size: 12px;
    .nav-item {
      padding: 0 12px;
      height: 40px;
      line-height: 40px;
    }
  }
  .classic-container-item {
    font-size: 12px;
    width: 100px !important;
  }

  .classic-container-nav {
    span {
      font-size: 12px !important;
      margin-right: 20px;
      height: 30px;
    }
  }
}
</style>