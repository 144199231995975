<template>
  <div id="app">
    <div class="nav-total">
      <!-- 导航部分 -->
      <div class="nav d-sm-none d-lg-block" v-if="showApp == true">
        <!-- 导航上部 -->
        <div class="nav-top">
          <!-- 左部分 -->
          <div class="nav-top-center">
            <img :src="bottomObj.logo" style="width: 100px" alt="" />
            <span class="nav-top-text01">用工匠之心 打造伯爵生活</span>
          </div>
          <!-- 右部分 -->
          <div class="nav-top-right">
            <!-- 收藏 -->
            <img src="./assets/img/save.png" @click="toCollection" />
            <!-- 购物车 -->
            <div class="img-two">
              <img src="./assets/img/cart.png" alt="" @click="toCart" />
              <div class="sort">
                <div class="sort-line"></div>
                <div class="sort-main-container" v-if="sortList.length > 0">
                  <div class="sort-top">
                    <div v-for="(item, index) in sortList" :key="index">
                      <div class="sort-item" v-if="item.isState == 1">
                        <template>
                          <img class="sort-item-big" :src="item.image" alt="" />
                          <div class="sort-item-span">
                            <span class="one">
                              {{ item.commodityName }}
                            </span>
                            <div class="sort-num">
                              <span class="two">{{ typeList[index] }}</span>
                              <span class="three">×{{ item.quantity }}</span>
                            </div>
                          </div>
                          <div class="sort-price">
                            ￥{{ item.specialOffer }}
                          </div>
                          <div class="sort-cancel" @click="removeSort(item.id)">
                            ×
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="sort-disabled" v-if="uselessCount > 0">
                    <span>以下商品已失效</span>
                  </div>
                  <div class="sort-top sort-bottom">
                    <div v-for="(item, index) in sortList" :key="index">
                      <div class="sort-item" v-if="item.isState == 0">
                        <template>
                          <img class="sort-item-big" :src="item.image" alt="" />
                          <div class="sort-item-span">
                            <span class="one">
                              {{ item.commodityName }}
                            </span>
                            <div class="sort-num">
                              <span class="two"
                                >烟灰色组装式架构，值得信赖</span
                              >
                              <span class="three">×{{ item.quantity }}</span>
                            </div>
                          </div>
                          <div class="sort-price">
                            ￥{{ item.specialOffer }}
                          </div>
                          <div class="sort-cancel" @click="removeSort(item.id)">
                            ×
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sort-main-container no-sort" v-else>
                  <img src="./assets/img/no-sort.png" alt="" />
                  <span>这里空空的什么也没有~</span>
                </div>
                <div class="sort-summary">
                  <div class="sort-summary-left">
                    <span class="one">商品合计:</span>
                    <span class="two">￥{{ totalPrice }}</span>
                  </div>
                  <div class="sort-summary-right">
                    <div class="to-summary" @click="toCart">去购物车结算</div>
                  </div>
                </div>
              </div>
              <div class="sort-num-radius" v-if="sortNum > 0 && isLogin">
                {{ sortNum }}
              </div>
            </div>
            <!-- 我的 -->
            <div class="login" v-if="isLogin">
              <img class="mine" :src="userImg" alt="" />
              <div class="login-info">
                <div class="login-info-item" @click="$router.push('/mine')">
                  <span>会员中心</span>
                </div>
                <div class="login-info-item" @click="quit">
                  <span>退出登录</span>
                </div>
              </div>
            </div>
            <div v-else class="login-info">
              <span @click="toLogin(1)">登入</span>
              <span class="nav-line"></span>
              <span @click="toLogin(2)">注册</span>
            </div>
          </div>
        </div>
        <!-- 导航下部 -->
        <div class="nav-bottom">
          <!-- 左边 -->
          <div class="nav-bottom-center">
            <div
              v-for="(item, index) in navList"
              :key="index"
              class="nav-bottom-center-item"
              @click="chooseMobileNav(item.id)"
            >
              <span class="nav-catalog">{{ item.text }}</span>
              <div
                :class="{ underline: true, sureline: selectNum == item.id }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile_nav d-lg-none">
        <div class="mNav-left">
          <img :src="bottomObj.logo" alt="" />
          <span>用工匠之心 打造伯爵生活</span>
        </div>
        <div class="mNav-right">
          <img
            src="./assets/img/icon-mine.png"
            alt=""
            v-if="isLogin"
            @click="$router.push('/mine')"
          />
          <img src="./assets/img/save.png" @click="toClooection2" />
          <div class="mNav-right-sort">
            <img src="./assets/img/cart.png" @click="toCart2" />
            <span v-if="isLogin && sortNum > 0">{{ sortNum }}</span>
          </div>
          <img
            src="./assets/img/menu.png"
            @click="drawer = true"
            v-if="drawer == false"
          />
          <img
            src="./assets/img/ic_menu_close.png"
            v-if="drawer"
            @click="drawer = false"
          />
        </div>
      </div>
    </div>

    <div class="d-sm-none d-lg-block">
      <div class="nav-animation animate__animated" v-if="showApp == true">
        <div>
          <!-- 固定部分下部 -->
          <div class="nav-bottom nav-bottom-fixed">
            <!-- 左边 -->
            <div class="nav-bottom-left nav-bottom-left-fixed">
              <img src="./assets/img/title.png" alt="" />
              <span class="nav-top-text01">用工匠之心 打造伯爵生活</span>
            </div>
            <!-- 中间 -->
            <div class="nav-bottom-center nav-bottom-fixed-center">
              <div
                v-for="(item, index) in navList"
                :key="index"
                class="nav-bottom-center-item nav-bottom-center-item-fixed"
                @click="chooseMobileNav(item.id)"
              >
                <span>{{ item.text }}</span>
                <div
                  :class="{ underline: true, sureline: selectNum == item.id }"
                ></div>
              </div>
            </div>
            <!-- 右部分 -->
            <div class="nav-top-right-fixed">
              <img
                class="right-img"
                src="./assets/img/save.png"
                @click="toCollection"
              />
              <div class="img-two">
                <img class="right-img" src="./assets/img/cart.png" alt="" @click="toCart" />
                <div class="sort">
                  <div class="sort-line"></div>
                  <div class="sort-main-container" v-if="sortList.length > 0">
                    <div class="sort-top">
                      <div v-for="(item, index) in sortList" :key="index">
                        <div class="sort-item" v-if="item.isState == 1">
                          <template>
                            <img
                              class="sort-item-big"
                              :src="item.image"
                              alt=""
                            />
                            <div class="sort-item-span">
                              <span class="one">
                                {{ item.commodityName }}
                              </span>
                              <div class="sort-num">
                                <span class="two">{{ typeList[index] }}</span>
                                <span class="three">×{{ item.quantity }}</span>
                              </div>
                            </div>
                            <div class="sort-price">
                              ￥{{ item.specialOffer }}
                            </div>
                            <div
                              class="sort-cancel"
                              @click="removeSort(item.id)"
                            >
                              ×
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="sort-disabled" v-if="uselessCount > 0">
                      <span>以下商品已失效</span>
                    </div>
                    <div class="sort-top sort-bottom">
                      <div v-for="(item, index) in sortList" :key="index">
                        <div class="sort-item" v-if="item.isState == 0">
                          <template>
                            <img
                              class="sort-item-big"
                              :src="item.image"
                              alt=""
                            />
                            <div class="sort-item-span">
                              <span class="one">
                                {{ item.commodityName }}
                              </span>
                              <div class="sort-num">
                                <span class="two"
                                  >烟灰色组装式架构，值得信赖</span
                                >
                                <span class="three">×{{ item.quantity }}</span>
                              </div>
                            </div>
                            <div class="sort-price">
                              ￥{{ item.specialOffer }}
                            </div>
                            <div
                              class="sort-cancel"
                              @click="removeSort(item.id)"
                            >
                              ×
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sort-main-container no-sort" v-else>
                    <img src="./assets/img/no-sort.png" alt="" />
                    <span>这里空空的什么也没有~</span>
                  </div>

                  <div class="sort-summary">
                    <div class="sort-summary-left">
                      <span class="one">商品合计:</span>
                      <span class="two">￥{{ totalPrice }}</span>
                    </div>
                    <div class="sort-summary-right">
                      <div class="to-summary" @click="toCart">去购物车结算</div>
                    </div>
                  </div>
                </div>
                <div class="sort-num-radius" v-if="sortNum > 0 && isLogin">
                  {{ sortNum }}
                </div>
              </div>
              <!-- 我的 -->
              <!-- <img
              v-if="isLogin"
              class="mine right-img"
              src="./assets/img/mine.jpg"
              alt=""
            /> -->
              <div class="login" v-if="isLogin">
                <img class="mine" :src="userImg" alt="" />
                <div class="login-info">
                  <div class="login-info-item" @click="$router.push('/mine')">
                    <span>会员中心</span>
                  </div>
                  <div class="login-info-item" @click="quit">
                    <span>退出登录</span>
                  </div>
                </div>
              </div>
              <div v-else class="login-info">
                <span @click="toLogin(1)" style="cursor: pointer">登入</span>
                <span class="nav-line"></span>
                <span @click="toLogin(2)" style="cursor: pointer">注册</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <keep-alive include="shopList">
      <router-view :key="$route.fullPath" @fatherSort="mySort" />
    </keep-alive>
    
    <div class="footer d-sm-none d-lg-block" v-if="showApp == true">
      <div class="container">
        <div class="row footer-top">
          <div class="col-md-4">
            <img src="./assets/img/name-white.png" alt="" />
            <div><span>用工匠之心 打造伯爵生活</span></div>
            <div class="footer-left">
              <img src="./assets/img/tel.png" alt="" />
              <span>{{ bottomObj.consultationPhone }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="footer-top-title">
              <span>服务项目</span>
            </div>
            <div class="footer-top-detail">
              <span @click="toSpeicalPage">特价优惠</span>
              <span @click="toLimitPage">限时抢购</span>
              <span @click="toNewPage">新品推荐</span>
              <span
                @click="
                  $router.push('/classic-collection');
                  selectNum = 1;
                "
                >古典精选</span
              >
              <span
                @click="
                  $router.push('/luxery-collection');
                  selectNum = 2;
                "
                >现代轻奢</span
              >
              <span
                @click="
                  $router.push('/European-famous-brand');
                  selectNum = 3;
                "
                >欧洲名品</span
              >
              <span
                @click="
                  $router.push('/European-life');
                  selectNum = 4;
                "
                >欧洲生活</span
              >
              <span
                @click="
                  $router.push('/tasting-online');
                  selectNum = 5;
                "
                >品鉴在线</span
              >
              <span
                @click="
                  $router.push('/member');
                  selectNum = 6;
                "
                >会员活动</span
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="footer-top-title">
              <span>关于我们</span>
            </div>
            <div class="footer-top-detail">
              <span @click="toMemberMine">会员中心</span>
              <span
                @click="
                  $router.push('/about-us');
                  selectNum = 7;
                "
                >关于我们</span
              >
              <span @click="shoppingKnow">购物须知</span>
              <span @click="serviceKnow">售后服务</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="footer-top-title">
              <span>关注我们</span>
            </div>
            <div class="footer-top-detail erweima">
              <img :src="bottomObj.weChatQrCode" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="footer-main">
        <div class="footer-center">
          <span>中国·上海</span>
          <div class="footer-center-item">
            <span>电话：{{ bottomObj.phone }}</span>
            <span>邮件：{{ bottomObj.mail }}</span>
            <span style="cursor: pointer" @click="goChinsesWeb"
              >网址：{{ bottomObj.url }}</span
            >
            <span>地址：{{ bottomObj.address }}</span>
          </div>
        </div>
        <div class="footer-center" v-if="bottomObj.europeUrl">
          <span>欧洲·瑞士</span>
          <div class="footer-center-item">
            <span style="cursor: pointer" @click="goEuroWeb">{{
              bottomObj.europeUrl
            }}</span>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <span>copyright{{ timeDate }} 上海巨永环境科技有限公司</span>
        <span class="footer-bottom-left pointer" @click="toOtherWeb"
          >ICP备案号：{{ bottomObj.icp }}</span
        >
        <span class="footer-police-pc">
          <img src="./assets/img/police.png" alt="" />
          沪公网安备：{{ bottomObj.publicSecurity }}号
        </span>
      </div>
    </div>
    <div class="d-lg-none bottom" v-if="showBottom">
      <div class="bottom-container">
        <div class="bottom-top">
          <div class="bottom-top-left">
            <img class="company-img" src="./assets/img/name-white.png" alt="" />
            <span class="company-intro">用工匠之心 打造伯爵生活</span>
            <div class="tel">
              <img src="./assets/img/tel.png" alt="" />
              <span class="company-intro">{{
                bottomObj.consultationPhone
              }}</span>
            </div>
          </div>
          <div class="bottom-top-right">
            <img :src="bottomObj.weChatQrCode" alt="" />
          </div>
        </div>
        <div class="bottom-item">
          <span class="bottom-title">服务项目</span>
          <div class="bottom-item-item company-intro">
            <span
              class="bottom-item-item-span"
              @click="toSpeicalPage"
              >特价优惠</span
            >
            <span
              class="bottom-item-item-span bottom-span-center"
              @click="$router.push('/classic-collection')"
              >古典精选</span
            >
            <span
              class="bottom-item-item-span bottom-span-right"
              @click="$router.push('/European-life')"
              >欧洲生活</span
            >
            <span
              class="bottom-item-item-span"
              @click="toLimitPage"
              >限时抢购</span
            >
            <span
              class="bottom-item-item-span bottom-span-center"
              @click="$router.push('/luxery-collection')"
              >现代轻奢</span
            >
            <span
              class="bottom-item-item-span bottom-span-right"
              @click="$router.push('/tasting-online')"
              >品鉴在线</span
            >
            <span
              class="bottom-item-item-span"
              @click="toNewPage"
              >新品推荐</span
            >
            <span
              class="bottom-item-item-span bottom-span-center"
              @click="$router.push('/European-famous-brand')"
              >欧洲名品</span
            >
            <span
              class="bottom-item-item-span bottom-span-right"
              @click="$router.push('/member')"
              >会员活动</span
            >
          </div>
        </div>
        <div class="bottom-item">
          <span class="bottom-title">关于我们</span>
          <div class="bottom-item-two">
            <div class="bottom-special company-intro">
              <span class="bottom-item-two-item" @click="toMemberMine"
                >会员中心</span
              >
              <span
                class="bottom-item-two-item bottom-item-two-item-center"
                @click="shoppingKnow"
                >购物须知</span
              >
            </div>
            <div class="bottom-special">
              <span
                class="bottom-item-two-item company-intro"
                @click="$router.push('/about-us')"
                >关于我们</span
              >
              <span
                class="
                  bottom-item-two-item bottom-item-two-item-center
                  company-intro
                "
                @click="serviceKnow"
                >售后服务</span
              >
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom-center">
          <span class="title">中国·上海</span>
          <div class="bottom-center-item company-intro">
            <span>电话：{{ bottomObj.phone }}</span>
            <span>邮件：{{ bottomObj.mail }}</span>
            <span @click="goChinsesWeb">网址：{{ bottomObj.url }}</span>
            <span>地址：{{ bottomObj.address }}</span>
          </div>
        </div>
        <div
          class="bottom-center bottom-center-two company-intro"
          v-if="bottomObj.europeUrl"
        >
          <span class="title">欧洲·瑞士</span>
          <div class="bottom-center-item" @click="goEuroWeb">
            <span>{{ bottomObj.europeUrl }}</span>
          </div>
        </div>
        <div class="line-two"></div>
        <div class="bottom-bottom company-intro">
          <span>copyright{{ timeDate }}</span>
          <span>上海巨永环境科技有限公司</span>
          <span style="display: block; margin: 5px 0" @click="toOtherWeb"
            >ICP备案号：{{ bottomObj.icp }}</span
          >
          <span
            ><img src="./assets/img/police.png" alt="" /> 沪公网安备：{{
              bottomObj.publicSecurity
            }}号</span
          >
        </div>
      </div>
    </div>

    <!-- 购物须知 -->
    <div class="dialog">
      <el-dialog :title="title" :visible.sync="centerDialogVisible1" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->

        <div class="read">
          <el-scrollbar>
            <div class="read-item" v-html="notes"></div>
          </el-scrollbar>
        </div>
      </el-dialog>
    </div>
    <!-- 导航 -->
    <el-drawer
      size="100%"
      :visible.sync="drawer"
      :with-header="false"
      direction="rtl"
    >
      <div class="mobile_nav_nav">
        <span
          :class="{ 'active-span': navIndex == item.id }"
          v-for="(item, index) in navList"
          :key="index"
          @click="chooseMobileNav(item.id)"
          >{{ item.text }}</span
        >
      </div>
    </el-drawer>
    <!-- 提示 -->
    <div class="message-info">
      <div class="message" id="messaged" style="color:#fff">{{ message }}</div>
    </div>

    <div class="fixed">
      <img
        src="./assets/img/message.png"
        alt=""
        @click="getMessage"
        class="d-sm-none d-lg-block"
      />
      <img src="./assets/img/top.png" alt="" @click="toTop" />
    </div>
    <div class="liuyanmessage">
      <el-dialog title="留言" :visible.sync="centerDialogVisible" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="姓名">
            <el-input
              v-model.trim="form.name"
              placeholder="输入您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model.trim="form.sex" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              v-model.trim="form.tel"
              placeholder="输入电话号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input
              v-model.trim="form.email"
              placeholder="输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="留言内容">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="输入您的留言内容"
              v-model.trim="form.message"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 底部 -->

        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="MessgaeSure">确 定</button>
          <button class="cancel" @click="centerDialogVisible = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {
  getAllSortApi,
  removeSortApi,
  basicGetApi,
  shoppingNotes,
  servicesNotes,
  loginOutApi,
  getIsLogin,
  contactApi
} from "./assets/api/api.js";
import { resultEmail, resultPhone } from "./uitils/check.js";
export default {
  mounted() {
    let that = this;
    window.addEventListener("scroll", function (e) {
      if (document.documentElement.scrollTop < 165) {
        that
          .$(".nav-animation")
          .css("display", "none")
          .removeClass("animate__fadeInDown");
      } else {
        that
          .$(".nav-animation")
          .css("display", "block")
          .addClass("animate__fadeInDown");
      }
    });
    window.addEventListener("scroll", function (e) {
      if (document.documentElement.scrollTop > 1000) {
        that.$(".fixed").css("display", "block");
      } else {
        that.$(".fixed").css("display", "none");
      }
    });
  },
  data() {
    return {
      form: {
        name: "",
        sex: "",
        tel: "",
        email: "",
        message: "",
      },
      isLogin: false,
      timeDate: "",
      options: [
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      navList: [
        {
          id: 0,
          text: "首页",
        },
        {
          id: 1,
          text: "古典精选",
        },
        {
          id: 2,
          text: "现代轻奢",
        },
        {
          id: 3,
          text: "欧洲名品",
        },
        {
          id: 4,
          text: "欧洲生活",
        },
        {
          id: 5,
          text: "品鉴在线",
        },
        {
          id: 6,
          text: "会员活动",
        },
        {
          id: 7,
          text: "关于我们",
        },
      ],
      typeList: [],
      sortList: [],
      serviceList: [
        {
          id: 1,
          text: "特价优惠",
        },
        {
          id: 2,
          text: "古典精选",
        },
        {
          id: 3,
          text: "欧洲生活",
        },
        {
          id: 4,
          text: "限时抢购",
        },
        {
          id: 5,
          text: "现代轻奢",
        },
        {
          id: 6,
          text: "品鉴在线",
        },
        {
          id: 7,
          text: "新品推荐",
        },
        {
          id: 8,
          text: "欧洲名品",
        },
        {
          id: 9,
          text: "会员活动",
        },
      ],
      str: "",
      selectNum: 0,
      sortNum: 0,
      showApp: true,
      showBottom: true,
      bottomObj: {},
      centerDialogVisible1: false,
      notes: "",
      drawer: false,
      navIndex: 0,
      totalPrice: 0.00,
      uselessCount: 0,
      userImg: "",
      message: "",
      deviceWidth: 0,
      title: "",
      centerDialogVisible:false

    };
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
    showApp() {
      // console.log("...");
      this.getStatus();
      this.mySort();
    },
  },
  created() {
    this.deviceWidth =
      document.documentElement.clientWidth || window.innerWidth;
    if (this.$route.name == "Login" || this.$route.name == "LoginSuccess") {
      this.showApp = false;
    } else {
      this.showApp = true;
    }
    if (this.$route.name == "Index") {
      this.showBottom = true;
    } else {
      this.showBottom = false;
    }
    this.mySort();
    this.getBottom();
    let that = this;

    that.getStatus();

    let date = new Date();
    this.timeDate = date.getFullYear();
  },
  methods: {
    // 显示信息
    showInfo() {
      console.log(this.$("#messaged"));
      this.$("#messaged").css("display", "block");
      setTimeout(() => {
        this.$("#messaged").css("display", "none");
      }, 1000);
    },

    // 登入状态
    getStatus() {
      getIsLogin().then((res) => {
        console.log(res);
        if (res.data.result == 1) {
          this.isLogin = false;
        } else {
          this.isLogin = true;
          this.userImg = res.data.data.avatar;
          sessionStorage.setItem("token", res.data.data.token);
          sessionStorage.setItem("avatar", res.data.data.avatar);
          sessionStorage.setItem("nickname", res.data.data.nickname);
        }
      });
    },

    fetchDate() {
      document.documentElement.scrollTop = 0;
      if (this.$route.name == "Login" || this.$route.name == "LoginSuccess") {
        this.showApp = false;
      } else {
        this.showApp = true;
      }
      if (
        this.$route.name == "Index" ||
        // this.$route.name == "ClassicCollection" ||
        // this.$route.name == "LuxeryCollection" ||
        // this.$route.name == "EuropeanBrand" ||
        // this.$route.name == "EuropeanLife" ||
        this.$route.name == "TastingOnline" ||
        // this.$route.name == "Member" ||
        this.$route.name == "AboutUs"
      ) {
        this.showBottom = true;
        this.getStatus();
      } else {
        this.showBottom = false;
        this.getStatus();
      }
    },
    toTop() {
      this.$("html,body").animate({ scrollTop: 0 });
    },
    async mySort() {
      let res = await getAllSortApi({ userId: 1 });
      res = res.data.data;
      if (res != null) {
        this.sortList = res;
        this.sortNum = res.length;
        this.totalPrice = 0;
        this.uselessCount = 0;
        for (let i = 0; i < this.sortList.length; i++) {
          let simpleList = this.sortList[i].specificationValueList;
          this.str = "";
          if (this.sortList[i].isState == 1) {
            this.totalPrice +=
              this.sortList[i].specialOffer * this.sortList[i].quantity;
          } else {
            this.uselessCount++;
          }
          for (let j = 0; j < simpleList.length; j++) {
            this.str += simpleList[j];
          }
          if (this.sortList[i].commodityType == 0) {
            this.sortList[i].quantity = 1;
          }
          this.typeList.push(this.str);
        }
      }
      this.totalPrice = this.totalPrice +'';
      if(this.totalPrice.indexOf('.')==-1){
        this.totalPrice = this.totalPrice +'.00'
      }
      console.log(this.totalPrice);
      console.log(this.sortList);
    },
    // 底部信息
    async getBottom() {
      let res = await basicGetApi();
      res = res.data.data;
      this.bottomObj = res;
      console.log(this.bottomObj);
    },
    // 删除购物车
    async removeSort(id) {
      let res = await removeSortApi({ shoppingCartId: id });
      this.mySort();
    },
    // 去购物车详情页
    toCart() {
      if (this.isLogin) {
        this.$router.push("/sort");
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    toClooection2() {
      if (this.isLogin) {
        this.$router.push("/mine/collection");
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    toCart2() {
      if (this.isLogin) {
        this.$router.push("/mobile/sort");
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    // 购物须知
    async shoppingKnow() {
      this.centerDialogVisible1 = true;
      let res = await shoppingNotes();
      res = res.data.data;
      this.notes = res;
      this.title = "购物须知";
    },
    // 售后服务
    async serviceKnow() {
      this.centerDialogVisible1 = true;
      let res = await servicesNotes();
      res = res.data.data;
      this.notes = res;
      this.title = "售后服务";
    },
    // 移动端跳转
    chooseMobileNav(id) {
      this.navIndex = id;
      this.selectNum = id;
      if (id == 0) {
        this.$router.push("/index");
      } else if (id == 1) {
        this.$router.push("/classic-collection");
      } else if (id == 2) {
        this.$router.push("/luxery-collection");
      } else if (id == 3) {
        this.$router.push("/European-famous-brand");
      } else if (id == 4) {
        this.$router.push("/European-life");
      } else if (id == 5) {
        this.$router.push("/tasting-online");
      } else if (id == 6) {
        this.$router.push("/member");
      } else if (id == 7) {
        this.$router.push("/about-us");
      }
      this.drawer = false;
    },
    // 登入
    toLogin(id) {
      sessionStorage.setItem("address", this.$route.fullPath);
      this.$router.push({
        path: "/login",
        query: {
          type: id,
        },
      });
    },
    // 收藏
    toCollection() {
      if (this.isLogin) {
        this.$router.push("/collection");
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    toMemberMine() {
      if (this.isLogin) {
        this.$router.push("/mine");
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    // 退出登入
    async quit() {
      let res = await loginOutApi();
      if (res.data.result == 0) {
        this.message = "退出成功";
        this.showInfo();
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }
    },
    goChinsesWeb() {
      window.open(this.bottomObj.url);
    },
    goEuroWeb() {
      window.open(this.bottomObj.europeUrl);
    },
    toOtherWeb() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    getMessage() {
      this.centerDialogVisible = true;
    },
    async MessgaeSure() {
      if (this.form.name == "") {
        this.message = "请输入姓名";
        this.showInfo();
      } else if (this.form.sex == "") {
        this.message = "请选择性别";
        this.showInfo();
      } else if (!resultPhone(this.form.tel)) {
        this.message = "请输入正确的手机号码";
        this.showInfo();
      } else if (!resultEmail(this.form.email)) {
        this.message = "请输入正确的邮箱";
        this.showInfo();
      } else if (this.form.message == "") {
        this.message = "请输入留言内容";
        this.showInfo();
      } else {
        let res = await contactApi({
          content: this.form.message,
          mail: this.form.email,
          phone: this.form.tel,
          sex: this.form.sex == 0 ? 1 : 0,
          username: this.form.name,
        });
        res = res.data;
        if (res.result == 0) {
          this.message = "提交成功";
          this.showInfo();
          this.centerDialogVisible = false;
        } else {
          this.message = "提交失败";
          this.showInfo();
        }
      }
    },
    toSpeicalPage(){
      this.$router.push({
        path:'/index/discount',
        query:{
          id:1
        }
      })
    },
    toNewPage(){
      this.$router.push({
        path:'/index/new-product',
        query:{
          id:2
        }
      })
    },
    toLimitPage(){
      this.$router.push({
        path:'/index/buy-limit',
        query:{
          id:3
        }
      })
    }
  },
};
</script>


<style lang="less">
body {
  height: 100%;
}
#app {
  height: 100%;
}
.container {
  padding: 0 !important;
}
.row {
  width: 100%;
}

.nav-total {
  border-bottom: 1px solid #cccdcd;
}

.nav {
  padding-top: 48px !important;
}

.nav-catalog {
  display: block;
  padding-bottom: 10px;
  cursor: pointer;
}

.nav-top {
  width: 100%;
  position: relative;
}

.nav-top-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: block;
    font-size: 12px;
    color: #111;
    margin-top: 10px;
  }
}

.img-two {
  position: relative;
  .sort {
    width: 376px;
    height: 368px;
    position: absolute;
    top: 34px;
    left: -175px;
    border: 1px solid #111;
    box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.6);
    z-index: 997;
    background-color: #fff;
    display: none;
  }
  .sort:before {
    position: absolute;
    left: 49%;
    top: -5px;
    width: 12px;
    height: 12px;
    margin-top: -2px;
    border-right: 1px solid #111;
    border-bottom: 1px solid #111;
    transform: rotate(225deg);
    content: "";
  }
  .sort-line {
    height: 1px;
    width: 16px;
    position: absolute;
    top: -1px;
    left: 181px;
    background-color: #fff;
  }
}

.sort-num-radius {
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #ce2829;
  color: #fff;
  top: -13px;
  right: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-two:hover .sort {
  display: block;
}

.sort-top {
  padding-left: 10px;
}

.sort-item {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .sort-item-big {
    width: 60px !important;
    height: 60px !important;
    margin-right: 0;
  }
  .sort-item-span {
    .one {
      margin-top: 5px;
      display: block;
      font-size: 14px;
      color: #333;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .two {
      display: inline-block;
      width: 96px;
      font-size: 12px;
      color: #888;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .three {
      font-size: 12px;
      color: #888;
      margin-left: 15px;
    }
  }
}

.sort-cancel {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
}
.sort-price {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  color: #111;
  font-weight: 500;
}

.sort-num {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.sort-disabled {
  width: 100%;
  height: 40px;
  background-color: #999999;
  color: #666666;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  padding-left: 10px;
}

.sort-bottom {
  background-color: #f8f8f8;
  .sort-item {
    margin: 0;
    padding-top: 10px;
  }
}

.sort-summary {
  height: 76px;
  background-color: #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 10px;
  .sort-summary-left {
    display: flex;
    flex-direction: column;
    .one {
      font-size: 14px;
      color: #666;
    }
    .two {
      font-size: 14px;
      font-weight: bold;
      color: #111;
    }
  }
  .to-summary {
    height: 36px;
    width: 110px;
    background-color: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
}

.login {
  position: relative;
  .login-info {
    position: absolute;
    top: 35px;
    left: -80px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(17, 17, 17, 0.3);
    padding-top: 10px;
    z-index: 999;
    display: none;
    .login-info-item {
      font-size: 16px;
      width: 180px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
    }
    .login-info-item:hover {
      background-color: #f8f8f8;
    }
  }
  .login-info::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    left: 80px;
    top: -20px;
  }
}
.login:hover .login-info {
  display: block;
}

.sort-main-container {
  overflow-y: scroll;
  height: 290px;
}

.sort-main-container::-webkit-scrollbar {
  width: 10px;
  height: 1px;
  // background-color: #f5f5f5;
}

.sort-main-container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/

  border-radius: 10px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  background: #bbbbbb;
}

.sort-main-container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;

  background: #ededed;
}
//////////////////////////////////////

.nav-top-right {
  position: absolute;
  top: 30%;
  right: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #111;
  img {
    margin-right: 36px;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
  }
  .login-info span {
    display: inline-block;
    cursor: pointer;
  }
  .nav-line {
    width: 1px;
    height: 10px;
    background-color: #111;
    margin: 0 15px 0 10px;
  }
  .mine {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.nav-bottom {
  padding: 10px 0;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  .nav-bottom-left {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #111;
  }
  img {
    width: 58px;
    height: 26px;
  }
  .nav-bottom-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #111;
  }
  .underline {
    height: 4px;
    border-radius: 2px;
    width: 0;
    background-color: #111;
    transition: all 0.5s ease-in-out;
  }
  .sureline {
    height: 4px;
    border-radius: 2px;
    width: 30px;
    background-color: #111;
  }
  .nav-bottom-center-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    align-items: center;
  }
  // .nav-bottom-center-item:hover .underline {
  //   width: 100%;
  // }
}
.nav-bottom-fixed {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 10px;
  padding-bottom: 0;
  .nav-bottom-left-fixed {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-bottom-fixed-center {
    margin: 0 auto;
    width: 55%;
  }
  .nav-bottom-center-item-fixed {
    div {
      margin-top: 10px;
    }
  }
  .nav-top-right-fixed {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    .right-img {
      margin-right: 36px;
      width: 24px !important;
      height: 24px !important;
      cursor: pointer;
    }
    .login-info span {
      display: inline-block;
      font-size: 14px;
    }
    .nav-line {
      width: 1px;
      height: 10px;
      background-color: #111;
      margin: 0 15px 0 10px;
    }
    .mine {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
}
.nav-animation {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  background-color: #fff;
  z-index: 9999;
}

.footer {
  background-color: #111;
  padding-top: 80px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  // margin-top: 160px;
  .footer-left {
    margin-top: 30px;
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .footer-top-detail {
    font-size: 14px;
    color: #ccc;
    margin-top: 30px;
    height: 180px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: space-between;
    span {
      display: inline-block;
      margin-top: 10px;
      cursor: pointer;
    }
    span:hover {
      color: #fff;
    }
  }
}

.erweima {
  img {
    height: 120px;
    width: 120px;
    background-color: #fff;
  }
}

.footer-top {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
}

.footer-center {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-center-item {
  font-size: 14px;
  color: #ccc;
  margin-top: 20px;
}

.footer-main {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 40px;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  padding: 38px 0;
  font-size: 14px;
}
.footer-center-item span {
  margin-right: 20px;
}

.footer-bottom-left {
  margin-left: 20px;
}

.mobile_nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  .mNav-left {
    display: flex;
    flex-direction: column;
    img {
      width: 40px;
      height: 20px;
      margin-bottom: 5px;
    }
    span {
      font-size: 10px;
    }
  }
  .mNav-right {
    display: flex;
    .mNav-right-sort {
      position: relative;
      span {
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ce2829;
        top: -8px;
        right: -8px;
        color: #fff;
        text-align: center;
        line-height: 16px;
        font-size: 14px !important;
      }
    }
    img {
      margin-left: 20px;
      width: 24px;
      height: 24px;
    }
  }
}

.bottom {
  background-color: #111;
  .bottom-title {
    font-size: 14px;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .bottom-container {
    width: 100%;
    padding: 15px;
    color: #fff;
    .bottom-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bottom-top-left {
        display: flex;
        flex-direction: column;
        .company-img {
          width: 80px;
        }
        .tel {
          margin-top: 10px;
          display: flex;
          // align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          span {
            letter-spacing: 2px;
          }
        }
      }
      .bottom-top-right {
        width: 84px;
        height: 84px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bottom-item {
      .bottom-item-item {
        height: 90px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .bottom-item-item-span {
          display: block;
          width: 33%;
          color: #ccc;
        }
        .bottom-span-right {
          text-align: right;
        }
        .bottom-span-center {
          text-align: center;
        }
      }
    }
    .bottom-item-two {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      height: 60px;
      color: #ccc;
      .bottom-special {
        width: 100%;
      }
      .bottom-item-two-item {
        display: inline-block;
        width: 33%;
      }
      .bottom-item-two-item-center {
        text-align: center;
      }
    }
    .line {
      border: 1px dashed rgba(255, 255, 255, 0.3);
      margin: 20px 0;
    }
    .bottom-center {
      .title {
        font-size: 14px;
        display: block;
        margin-bottom: 20px;
      }
      .bottom-center-item {
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: 5px;
          color: #ccc;
        }
      }
    }
    .bottom-center-two {
      margin-top: 20px;
      span {
        font-size: 13px;
      }
    }
    .line-two {
      border: 1px solid rgba(255, 255, 255, 0.3);
      margin: 20px 0;
    }
    .bottom-bottom {
      span {
        margin-right: 10px;
      }
    }
  }
}

.read {
  height: 400px;
}

.read-item {
  display: block;
  margin: bottom 0 !important;
  height: 400px;
  padding-right: 20px;
}

/deep/ .el-scrollbar {
  height: 100% !important;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-dialog {
  width: 300px;
  height: 500px;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 20px;
}

@media (min-width: 300px) and (max-width: 600px) {
  .el-dialog {
    width: 90% !important;
    height: 500px;
  }
  .el-drawer__wrapper {
    top: 60px !important;
  }
  .v-modal {
    top: 60px !important;
  }
}

@media (min-width: 601px) and (max-width: 992px) {
  .el-dialog {
    width: 70% !important;
    height: 500px;
  }
  .el-drawer__wrapper {
    top: 60px !important;
  }
  .v-modal {
    top: 60px !important;
  }
}

.mobile_nav_nav {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    font-size: 15px;
    color: #666;
    margin-bottom: 40px;
  }
  .active-span {
    color: #111;
  }
}
.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999 !important;
}

.company-intro {
  font-size: 16px;
}

.footer-police-pc {
  margin-left: 20px;
}

.pinter {
  cursor: pointer;
}

.no-sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999999;
}
.no-sort img {
  width: 90px !important;
  height: 90px !important;
  // margin-left: 20px;
  margin-right: 0;
}

.fixed {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 40px;
  img {
    height: 50px;
    width: 50px;
    display: block;
    margin-top: 20px;
  }
}

.liuyanmessage .el-dialog{
  width: 610px;
}


.dialog-footer {
  font-size: 16px;
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 30px;
    border: 0;
    cursor: pointer;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
    cursor: pointer;
  }
}

.ql-syntax{
  font-size: 12px;
}
</style>


<style lang="less">
@media (min-width: 300px) and (max-width: 600px){
.company-intro{
  font-size: 12px;
}
}
</style>
