<template>
  <div class="info container">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>查看物流</span>
    </div>
    <div class="logistic-status col-sm-12">
      <div class="logistic-status-one">
        <span>{{ logisticObj.state }}</span>
      </div>
      <div class="logistic-status-two">
        <span>运单号码： {{ logisticObj.expressageId }} </span>
      </div>
      <div class="logistic-status-two">
        <span>物流公司： {{ logisticObj.expressageName }} </span>
      </div>
    </div>
    <!-- 主体信息 -->
    <div class="col-sm-12">
      <div class="logistic-domain">
        <div
          class="logistic-domain-item"
          v-for="(item, index) in logisticObj.jsonObject"
          :key="index"
        >
          <div class="logistic-domain-item-left">
            <div class="circle"></div>
            <div
              class="line"
              v-if="index != logisticObj.jsonObject.length - 1"
            >
            </div>
          </div>
          <div
            :class="{
              'logistic-domain-item-right': true,
              'color-normal': index == 0,
            }"
          >
            <div class="one">
              <span>{{ item.time }} {{ item.date }}</span>
            </div>
            <div class="two">
              <span>{{ item.context }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { invoiceLogisitic } from "../../assets/api/api.js";
export default {
  created() {
    let id = this.$route.query.orderNumber;
    this.logisticInfo(id);
  },
  data() {
    return {
      logisticObj: {},
    };
  },
  methods: {
    toPreview() {
      this.$router.go(-1);
    },
    // 获取物流信息
    async logisticInfo(id) {
      let res = await invoiceLogisitic({
        orderUuid: id,
      });
      console.log(res);
      res = res.data.data;
      this.logisticObj = res;
      
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .logistic-status {
    margin-top: 30px;
    .logistic-status-one {
      font-size: 16px;
      color: #111;
    }
    .logistic-status-two {
      color: #111;
      font-size: 12px;
      margin-top: 5px;
    }
  }
  .logistic-domain {
    margin-top: 15px;
    background-color: #fff;
  }
  .logistic-domain {
    padding: 20px;
    .logistic-domain-item {
      display: flex;
      .logistic-domain-item-left {
        width: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #d8d8d8;
        }
        .line {
          width: 1px;
          height: 100%;
          background-color: #d8d8d8;
        }
      }
      .logistic-domain-item-right {
        position: relative;
        left: 10px;
        top: -5px;
        color: #999;
        .one {
          font-size: 14px;
        }
        .two {
          text-align: left;
          padding-bottom: 20px;
          font-size: 16px;
        }
      }
      .color-normal {
        color: #111;
      }
    }
  }
}
</style>