<template>
  <div class="info container">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>我的地址</span>
    </div>
    <div
      class="a-container col-sm-12"
      v-if="flag == true && addressList.length > 0"
    >
      <div
        class="a-container-item"
        v-for="(item, index) in addressList"
        :key="index"
      >
        <div class="a-container-item-top">
          <div class="a-container-item-top-left">
            <div class="one">
              <span>{{ item.username }}</span>
              <span class="default" v-if="item.isDefault == 1">默认</span>
            </div>
            <div class="two">
              <span>{{ item.phone }}</span>
            </div>
          </div>
          <div class="a-container-item-top-right">
            <img
              src="../../assets/img/ic_edi.png"
              alt=""
              @click="toEditMyAddress(index)"
            />
            <img
              src="../../assets/img/ic_del.png"
              @click="removeAddress(item.id)"
              alt=""
            />
          </div>
        </div>
        <div class="a-container-item-bottom">
          <span>{{
            item.provinceName +
            item.cityName +
            item.districtName +
            item.addressDetailed
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 addAddressPar" v-if="flag == true">
      <div
        class="addAddress"
        @click="
          flag = false;
          isEdit = false;
        "
      >
        <img src="../../assets/img/add_n.png" alt="" />
        <span>新增</span>
      </div>
    </div>
    <div class="null-img" v-if="flag == true && addressList.length == 0">
      <img src="../../assets/img/null.png" alt="" />
      <div class="null-span">暂时还没有地址，快去添加吧~</div>
    </div>
    <div class="col-sm-12" v-if="flag == false">
      <div class="add-new">
        <div class="add-new-add">
          <span>收货人</span>
          <input
            type="text"
            placeholder="请输入收货人姓名"
            v-model.trim="people"
          />
        </div>
        <div class="add-new-add">
          <span>手机号</span>
          <input
            type="text"
            placeholder="请输入手机号码"
            v-model.trim="phone"
          />
        </div>
        <div class="add-new-add" @click="chooseAddress">
          <span>收货地址</span>
          <input
            type="text"
            placeholder="请选择省/市/区"
            :value="detailAddress"
          />
          <img
            class="chooseAddress"
            src="../../assets/img/down_grey.png"
            alt=""
          />
        </div>
        <div class="add-new-add">
          <span>详细地址</span>
          <textarea
            placeholder="请输入"
            v-model.trim="detailAddressed"
          ></textarea>
        </div>
        <div class="isDefault">
          <img
            src="../../assets/img/check_none.png"
            v-if="defaulted == false"
            alt=""
            @click="defaulted = true"
          />
          <img
            src="../../assets/img/check_save.png"
            v-else
            alt=""
            @click="defaulted = false"
          />
          <span>设为默认地址</span>
        </div>
      </div>
      <div class="addAddressPar">
        <div class="addAddress" @click="sureAddAddress">
          <span>{{isEdit?'确定编辑':'确定添加'}}</span>
        </div>
      </div>
    </div>
    <!-- 地址选择 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="add-container">
        <div class="address-domain">
          <div class="add-top">
            <span @click="drawer1 = false">取消</span>
            <span>所在地区</span>
            <span @click="drawerSure">确定</span>
          </div>
          <div class="add-top-nav">
            <span :class="{ active: navIndex == 0 }" @click="chooseOne">{{
              provinceName
            }}</span>
            <span :class="{ active: navIndex == 1 }" @click="chooseTwo">{{
              cityName
            }}</span>
            <span :class="{ active: navIndex == 2 }" @click="chooseThree">{{
              regionName
            }}</span>
          </div>
        </div>
        <div class="address-container">
          <template v-if="navIndex == 0">
            <div
              :class="{
                'address-container-item': true,
                'active-item': provinceIndex == item.provinceValue,
              }"
              v-for="(item, index) in provinceList"
              :key="index"
              @click="chooseProvince(item.provinceValue, item.provinceName)"
            >
              {{ item.provinceName }}
            </div>
          </template>
          <template v-if="navIndex == 1">
            <div
              :class="{
                'address-container-item': true,
                'active-item': cityIndex == item.cityValue,
              }"
              v-for="(item, index) in cityList"
              :key="index"
              @click="chooseCity(item.cityName, item.cityValue)"
            >
              {{ item.cityName }}
            </div>
          </template>
          <template v-if="navIndex == 2">
            <div
              :class="{
                'address-container-item': true,
                'active-item': regionIndex == item.districtValue,
              }"
              v-for="(item, index) in regionList"
              :key="index"
              @click="chooseRegion(item.districtValue, item.districtName)"
            >
              {{ item.districtName }}
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getOrderAddress,
  removeAddressApi,
  getAddress,
  addAddress,
  editAddressApi
} from "../../assets/api/api.js";
import { resultPhone } from "../../uitils/check.js";
export default {
  created() {
    this.getAddress();
  },
  data() {
    return {
      addressList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      message: "",
      detailAddress: "",
      people: "",
      phone: "",
      detailAddressed: "",
      flag: true,
      defaulted: false,
      drawer1: false,
      navIndex: 0,
      provinceIndex: "",
      provinceName: "选择省",
      cityIndex: "",
      cityName: "选择市",
      regionIndex: "",
      regionName: "选择区",
      isEdit: false,
      id:0
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    //返回上一级
    toPreview() {
      this.$router.push("/mine");
    },
    // 获得地址
    async getAddress() {
      let res = await getOrderAddress({
        userId: 1,
      });
      res = res.data.data;
      this.addressList = res;
      console.log(res);
    },
    // 删除地址
    async removeAddress(id) {
      let res = await removeAddressApi({
        id: id,
      });
      res = res.data;
      if (res.result == 0) {
        this.message = res.msg;
        this.showInfo();
        this.getAddress();
      } else {
        this.message = "删除失败";
        this.showInfo();
      }
      console.log(res);
    },
    // 选择省地址
    async chooseAddress() {
      this.drawer1 = true;
      this.chooseOne();
    },
    async chooseOne() {
      this.navIndex = 0;
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
      console.log(res);
    },
    // 选择省
    chooseProvince(index, name) {
      this.provinceIndex = index;
      this.provinceName = name;
    },
    // 市地址
    async chooseTwo() {
      this.navIndex = 1;
      let res = await getAddress({
        provinceValue: this.provinceIndex,
      });
      res = res.data.data;
      this.cityList = res;
      console.log(res);
    },
    // 选择市
    chooseCity(name, index) {
      this.cityIndex = index;
      this.cityName = name;
    },
    // 区地址
    async chooseThree() {
      this.navIndex = 2;
      let res = await getAddress({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
      });
      res = res.data.data;
      this.regionList = res;
      console.log(res);
    },
    // 选择区
    chooseRegion(index, name) {
      this.regionIndex = index;
      this.regionName = name;
    },
    // 地区确定
    drawerSure() {
      if (
        this.provinceIndex != "" &&
        this.cityIndex != "" &&
        this.regionIndex != ""
      ) {
        this.drawer1 = false;
        this.detailAddress =
          this.provinceName + this.cityName + this.regionName;
      } else {
        this.message = "请选择完整地址";
        this.showInfo();
      }
    },
    // 保存地址
    async sureAddAddress() {
      if (this.people == "") {
        this.message = "请输入收货人";
        this.showInfo();
      } else if (resultPhone(this.phone) == false) {
        this.message = "请输入正确的手机号";
        this.showInfo();
      } else if (this.detailAddress == "") {
        this.message = "请选择地址";
        this.showInfo();
      } else if (this.detailAddressed == "") {
        this.message = "请输入详细地址";
        this.showInfo();
      } else {
        if (this.isEdit) {
          let res = await editAddressApi({
            addressDetailed: this.detailAddressed,
            areaValue: this.regionIndex,
            isDefault: this.defaulted ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.people,
            id:this.id
          });
          res = res.data;
          this.message = res.msg;
          this.showInfo();
          this.flag = true;
          this.getAddress();
        } else {
          let res = await addAddress({
            addressDetailed: this.detailAddressed,
            areaValue: this.regionIndex,
            isDefault: this.defaulted ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.people,
          });
          res = res.data;
          this.message = res.msg;
          this.showInfo();
          this.flag = true;
          this.getAddress();
          console.log(res);
        }
      }
    },
    toEditMyAddress(index) {
      this.flag = false;
      this.isEdit = true;
      console.log(this.addressList[index]);
      this.people = this.addressList[index].username;
      this.phone = this.addressList[index].phone;
      this.detailAddress =
        this.addressList[index].provinceName +
        this.addressList[index].cityName +
        this.addressList[index].districtName;
      this.detailAddressed = this.addressList[index].addressDetailed;
      this.provinceIndex = this.addressList[index].provinceValue;
      this.cityIndex = this.addressList[index].cityValue;
      this.regionIndex = this.addressList[index].districtValue;
      this.defaulted = this.addressList[index].isDefault == 1 ? true : false;
      this.id = this.addressList[index].id;
    },
  },
  watch: {
    provinceIndex() {
      this.cityIndex = "";
      this.cityName = "选择市";
    },
    cityIndex() {
      this.regionIndex = "";
      this.regionName = "选择区";
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding-bottom: 80px !important;
  .top {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .a-container {
    .a-container-item {
      margin: 40px 0;
      .a-container-item-top {
        font-size: 14px;
        font-weight: 500;
        color: #111;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .a-container-item-top-left {
          .one {
            .default {
              font-size: 12px;
              color: #fff;
              background-color: #111;
              padding: 2px 5px;
              margin-left: 10px;
            }
          }
          .two {
            margin-top: 5px;
          }
        }
        .a-container-item-top-right {
          img {
            width: 20px;
            height: 20px;
            margin-left: 15px;
          }
        }
      }
      .a-container-item-bottom {
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }
  .addAddressPar {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 76px;
    background-color: #fff;
    padding: 0 15px;
  }
  .addAddress {
    width: 100%;
    height: 42px;
    background-color: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 7px;
    }
  }
}

.add-new {
  font-size: 15px;
  font-weight: 500;
  color: #111;
  .add-new-add {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input {
    color: #666;
    border: 0;
    outline: none;
    flex-grow: 1;
    text-align: right;
  }
  input::placeholder {
    color: #ccc;
  }
  textarea {
    //   height: 24px;
    color: #666;
    border: 0;
    outline: none;
    flex-grow: 1;
    text-align: right;
  }
  textarea::placeholder {
    color: #ccc;
  }
}

.isDefault {
  position: relative;
  top: -20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #111;
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

.null-img {
  width: 200px;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
}

.chooseAddress {
  width: 16px;
  height: 16px;
}

.add-container {
  font-size: 16px;
  font-weight: 500;
  color: #111;
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .address-domain {
    height: 99px;
    background-color: #fff;
    flex-shrink: 0;
  }
  .add-top {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted #ccc;
  }
  .add-top-nav {
    position: sticky;
    top: 55px;
    margin-top: 10px;
    display: flex;
    font-size: 14px;
    border-bottom: 1px dotted #ccc;
    span {
      white-space: nowrap;
      display: block;
      padding-bottom: 10px;
      width: 33%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .address-container {
    font-size: 14px;
    margin-top: 10px;
    flex-grow: 1;
    overflow: auto;
    .address-container-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
}
.active {
  border-bottom: 2px solid #111;
}
.active-item {
  background-color: #ccc;
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>



