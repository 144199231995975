<template>
  <div class="info container">
    <div class="top" v-if="infoObj.imagesUrlList" v-show="isApply">
      <div class="swiper-container" id="swiper1">
        <div class="swiper-wrapper">
          <div
            v-if="videoImage"
            class="swiper-slide"
          >
            <img class="img-fuild big-img-item" :src="videoImage" alt="" />
            <img src="http://file.antiker.cn/fba6388858bb4acb85ad07bd8a702f2d.png" alt="" class="video-icon" @click="openVideo">
            <video :src="video" controls class="video-open" v-show="videoShow" id="videos"></video>
          </div>
          <div
            class="swiper-slide"
            v-for="(item, index) in infoObj.imagesUrlList"
            :key="index"
          >
            <img class="img-fuild big-img-item" :src="item" alt="" />
          </div>
        </div>
      </div>
      <div class="swiper-num">
        <span>{{ swiperIndex }}/{{ infoObj.imagesUrlList.length + (videoImage?1:0) }}</span>
      </div>
    </div>
    <div class="center" v-show="isApply">
      <!-- 信息介绍 -->
      <div class="center-info">
        <span class="info-name">{{ infoObj.commodityName }}</span>
        <span class="info-introduction">{{ infoObj.introduction }}</span>
        <div class="price">
          <div class="price-now">
            <span class="one">￥</span>
            <span class="two">{{ nowPrice }}</span>
          </div>
          <div class="price-pre">
            <span>￥{{ prePrice }}</span>
          </div>
        </div>
      </div>
      <!-- 网点 -->
      <div class="center-item" @click="wangdianOpen" v-show="wangdianName!=0">
        <span class="one">网点</span>
        <span class="two">{{ wangdianName }}</span>
        <img src="../../assets/img/right-gray.png" alt="" />
      </div>
      <div class="center-item" v-show="wangdianName==0">
        <span class="one">网点</span>
        <span class="two">暂无网点</span>
      </div>
      <!-- 规格 -->
      <div class="center-item" @click="drawer2 = true">
        <span class="one">规格</span>
        <span class="two">{{ sizeName }}</span>
        <img src="../../assets/img/right-gray.png" alt="" />
      </div>
      <div id="myForm"></div>
      <!-- 介绍 -->
      <div class="center-introduction">
        <div class="shop-info">
          <div class="shop-info-top">
            <span>商品介绍</span>
          </div>
          <div
            class="IntroductionText"
            v-html="infoObj.commodityIntroduction"
          ></div>
          <div class="shop-info-top">
            <span>规格与包装</span>
          </div>
          <div
            class="IntroductionText"
            v-html="infoObj.specificationPackage"
          ></div>
          <div class="shop-info-top">
            <span>其他说明</span>
          </div>
          <div class="IntroductionText" v-html="infoObj.description"></div>
        </div>
      </div>
    </div>
    <div class="bottom" v-show="isApply">
      <div class="domain">
        <div class="bottom-left">
          <div class="domian-item" @click="toConsult">
            <img src="../../assets/img/consult.png" alt="" />
            <span>咨询</span>
          </div>
        </div>

        <div class="bottom-right" v-if="infoObj.state == 2">
          <div class="btn-item-right" @click="toOrder">
            <span>我要报名</span>
            <span v-if="infoObj.cautionMoney > 0"
              >保证金￥{{ infoObj.cautionMoney }}</span
            >
          </div>
        </div>
        <div
          class="bottom-right"
          v-if="
            infoObj.state == 1 &&
            infoObj.userPanicVO &&
            infoObj.userPanicVO.isJackpot == 1
          "
        >
          <div class="btn-item-right" @click="toGetAward">
            <span>去领奖</span>
          </div>
        </div>
      </div>
    </div>
    <div class="apply" v-show="!isApply">
      <div class="o-top-two" @click="drawer3 = true" v-if="oneAddress">
        <div class="o-top-two-left">
          <div class="o-top-two-left-item-one">
            <span class="one">{{ oneAddress.username }}</span>
            <span>{{ oneAddress.phone }}</span>
          </div>
          <div class="o-top-two-left-item-two">
            <div class="btn">
              <span class="one" v-if="oneAddress.isDefault == 1">默认</span>
            </div>
            <span class="two">{{
              oneAddress.provinceName +
              oneAddress.cityName +
              oneAddress.districtName +
              oneAddress.addressDetailed
            }}</span>
          </div>
        </div>
        <!-- <div class="o-top-two-left">
          <span class="no-address-info">请选择地址</span>
        </div> -->
        <div class="o-top-two-right">
          <img src="../../assets/img/arrow_right.png" alt="" />
        </div>
      </div>
      <div class="o-top-two" @click="toAddAddress" v-else>
        <div class="o-top-two-left">
          <span class="no-address-info">请选择地址</span>
        </div>
        <div class="o-top-two-right">
          <img src="../../assets/img/arrow_right.png" alt="" />
        </div>
      </div>
      <div class="apply-info">
        <div class="apply-info-item">
          <span>接收中奖信息</span>
        </div>
        <div class="apply-info-item apply-input">
          <span>手机号码</span>
          <input
            type="text"
            placeholder="输入接收中奖信息的手机号"
            v-model.trim="limitPhone"
          />
        </div>
        <div class="payWay">
          <div class="payway-item">
            <img src="../../assets/img/zhifubao.png" alt="" />
            <span>支付宝</span>
            <div class="circle">
              <img src="../../assets/img/ic_gou_s.png" alt="" v-if="payAli" />
              <img
                src="../../assets/img/ic_gou_n.png"
                alt=""
                v-else
                @click="
                  payAli = true;
                  payWechart = false;
                "
              />
            </div>
          </div>
          <div class="payway-item">
            <img src="../../assets/img/wechart.png" alt="" />
            <span>微信</span>
            <div class="circle">
              <img
                src="../../assets/img/ic_gou_s.png"
                alt=""
                v-if="payWechart"
              />
              <img
                src="../../assets/img/ic_gou_n.png"
                alt=""
                v-else
                @click="
                  payAli = false;
                  payWechart = true;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="provide" @click="limitProvide">提交</div>
    </div>
    <!-- 弹出框1 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="stricky">
          <div class="drawer-top">
            <span>配送至</span>
            <span @click="sureAddress">确定</span>
          </div>
          <div class="line"></div>
          <div class="drawer-center">
            <div class="drawer-center-item">
              <span
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 0,
                }"
                @click="provinceChoose"
                >{{ provinceName }}</span
              >
              <span
                v-if="provinceName != '省份'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 1,
                }"
                @click="cityChoose"
                >{{ cityName }}</span
              >
              <span
                v-if="cityName != '城市'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 2,
                }"
                @click="regionChoose"
                >{{ regionName }}</span
              >
              <span
                v-if="regionName != '区域'"
                :class="{
                  'wangdian-item': true,
                  'drawer-center-item-active': drawerNavIndex == 3,
                }"
                @click="wangdianChoose"
                >{{ wangdian }}</span
              >
            </div>
          </div>
        </div>

        <div class="drawer-bottom">
          <template v-if="drawerNavIndex == 0">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active':
                  item.provinceValue == provinceIndex,
              }"
              v-for="(item, index) in procinceList"
              :key="index"
              @click="provinceOne(item.provinceValue, item.provinceName)"
            >
              {{ item.provinceName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 1">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.cityValue == cityIndex,
              }"
              v-for="(item, index) in cityList"
              :key="index"
              @click="cityOne(item.cityValue, item.cityName)"
            >
              {{ item.cityName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 2">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.districtValue == regionIndex,
              }"
              v-for="(item, index) in regionList"
              :key="index"
              @click="regionOne(item.districtValue, item.districtName)"
            >
              {{ item.districtName }}
            </div>
          </template>
          <template v-if="drawerNavIndex == 3">
            <div
              :class="{
                'drawer-bottom-item': true,
                'drawer-bottom-item-active': item.areaValue == wangdianIndex,
              }"
              v-for="(item, index) in wangdianList"
              :key="index"
              @click="wangdianOne(item.areaValue, item.name, item.id)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
    <!-- 弹出框2 -->
    <el-drawer
      size="500px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-size" v-if="infoObj.imagesUrlList">
        <div class="top">
          <div class="left">
            <img class="img-fuild" :src="sizeImg" alt="" />
          </div>
          <div class="right-speical">
            <div class="right-top">
              <img
                src="../../assets/img/login_close.png"
                @click="drawer2 = false"
                alt=""
              />
            </div>
            <div class="right-bottom">
              <div class="price">
                <span>价格：￥{{ nowPrice }}</span>
              </div>
              <div class="select">
                <span>已选择：{{ sizeName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="size-item"
          v-for="(item, index) in infoObj.specificationVOList"
          :key="index"
        >
          <span class="classicName">{{ item.name }}</span>
          <div class="size-total-item">
            <template v-for="(item2, index2) in infoObj.specificationValueVOList">
            <span :key="index2">
              <span
                class="size-item-item"
                v-if="item.id == item2.specificationId && item2.image == null"
              >
                <span
                  :class="{
                    'border-size': true,
                    'border-size-active': item2.flag,
                  }"
                  @click="sizeChoose(item2, index2)"
                  >{{ item2.value }}</span
                >
                <img
                  v-if="item2.flag"
                  src="../../assets/img/detail_choose.png"
                  alt=""
                />
              </span>
              <span
                class="size-item-item color-block"
                v-if="item.id == item2.specificationId && item2.image != null"
                @click="sizeChoose(item2, index2)"
              >
                <img
                  :src="item2.image"
                  :class="{
                    'border-size-active': item2.flag,
                    'color-img': true,
                  }"
                />
                <img
                  v-if="item2.flag"
                  src="../../assets/img/detail_choose.png"
                  class="color-choose"
                />
              </span>
            </span>
          </template>
          </div>
          
        </div>
        <div class="drawer-qunatity">
          <span>购买数量</span>
          <div class="e-number">
            <div class="add" @click="reduce">
              <img src="../../assets/img/reduce_gray.png" alt="" />
            </div>
            <div class="add number">{{ num }}</div>
            <div class="add" @click="add">
              <img src="../../assets/img/add_gray.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 size-sure">
        <div class="btn" @click="toDetail">确定</div>
      </div>
    </el-drawer>
    <!-- 弹出框3 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer3"
      :with-header="false"
      direction="btt"
    >
      <div class="address">
        <div class="address-top">
          <span>选择地址</span>
          <img src="../../assets/img/delete_n.png" @click="drawer3 = false" />
        </div>
        <div class="address-container">
          <div
            class="address-container-item"
            v-for="(item, index) in addressList"
            :key="index"
            @click="chooseAddressIndex(index)"
          >
            <div class="address-container-item-left">
              <img
                src="../../assets/img/ic_gou_s.png"
                v-if="index == addressIndex"
                alt=""
              />
              <img src="../../assets/img/ic_gou_n.png" v-else alt="" />
            </div>
            <div class="address-container-item-right">
              <span>{{ item.username }}</span>
              <span class="default" v-if="item.isDefault == 1">默认</span>
              <div>{{ item.phone }}</div>
              <div class="detailAddress">
                {{
                  item.provinceName +
                  item.cityName +
                  item.districtName +
                  item.addressDetailed
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="sure-btn" @click="sureAddressed">确定</div>
      </div>
    </el-drawer>
    <el-dialog
      title="立即咨询"
      :visible.sync="dialogVisible"
      center
      style="height: 500px"
    >
      <div class="domian-dialog">
        <div class="domian-dialog-img">
          <img :src="consultImg" alt="" />
        </div>
        <span class="one">扫描二维码，或者公众号搜索“巨永环境”</span>
      </div>
    </el-dialog>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import {
  getRegion,
  getWangdian,
  consultApi,
  getOrderAddress,
  getLimitDetail,
  panicAdd,
  getLimitPrice,
} from "../../assets/api/api.js";
import { resultPhone } from "../../uitils/check.js";
export default {
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  data() {
    return {
      payAli: true,
      payWechart: false,
      id: 0,
      swiperIndex: 1,
      infoObj: {},
      provinceName: "省份",
      cityName: "城市",
      regionName: "区域",
      wangdian: "网点",
      wangdianName: "请选择网点",
      provinceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      wangdianIndex: -1,
      procinceList: [],
      cityList: [],
      regionList: [],
      wangdianList: [],
      drawer1: false,
      drawer2: false,
      drawerNavIndex: 0,
      nowPrice: 0,
      prePrice: 0,
      message: "",
      num: 1,
      dialogVisible: false,
      consultImg: "",
      sizeName: "",
      flag: 0,
      isOrder: false,
      mid: 0,
      wid: 0,
      isApply: true,
      drawer3: false,
      addressList: [],
      addressIndex: 0,
      oneAddress: {},
      limitPhone: "",

      video:'',
      videoImage:'',
      videoShow:false,
      sizeImg:''
    };
  },
  watch: {
    // provinceIndex() {
    //   this.cityName = "城市";
    //   this.cityIndex = -1;
    //   this.regionName = "区域";
    //   this.regionIndex = -1;
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
    // cityIndex() {
    //   this.regionName = "区域";
    //   this.regionIndex = -1;
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
    // regionIndex() {
    //   this.wangdian = "网点";
    //   this.wangdianIndex = -1;
    // },
  },
  methods: {
    openVideo(){
      this.videoShow = true;
      let video = document.getElementById("videos");
      video.play();
    },
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getInfo() {
      let that = this;
      let res = await getLimitDetail({
        id: this.id,
        userId: 1,
      });
      res = res.data.data;
      this.infoObj = res;
      this.sizeImg = this.infoObj.imagesUrlList[0]
      this.videoImage = res.videoImage;
      this.video = res.video;
      this.provinceIndex = res.outletsVO.provinceValue;
      this.provinceName = res.outletsVO.provinceName;
      this.cityIndex = res.outletsVO.cityValue;
      this.cityName = res.outletsVO.cityName;
      this.regionIndex = res.outletsVO.districtValue;
      this.regionName = res.outletsVO.districtName;
      this.wangdianIndex = res.outletsVO.areaValue;
      this.wangdian = res.outletsVO.name;
      this.wangdianName =
        this.provinceName + this.cityName + this.regionName + this.wangdian;
      this.wid = res.outletsVO.id;
      this.$nextTick(function () {
        that.getSwiper1();
      });
      this.id = this.infoObj.commodityId;
      this.sizeOpen();
      console.log(res);
    },
    // 选择网点
    async wangdianOpen() {
      this.drawer1 = true;
      this.provinceChoose();
    },
    // 选择省
    async provinceChoose() {
      this.drawerNavIndex = 0;
      let res = await getRegion({
        commodityId: this.infoObj.commodityId,
      });
      res = res.data.data;
      this.procinceList = res;
      console.log(res);
    },
    // 选中省
    provinceOne(id, name) {
      this.provinceIndex = id;
      this.provinceName = name;
      this.cityName = "城市";
      this.cityIndex = -1;
      this.regionName = "区域";
      this.regionIndex = -1;
      this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.cityChoose();
    },
    // 选择市
    async cityChoose() {
      this.drawerNavIndex = 1;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        commodityId: this.infoObj.commodityId,
      });
      res = res.data.data;
      this.cityList = res;
      console.log(res);
    },
    // 选中市
    cityOne(id, name) {
      this.cityIndex = id;
      this.cityName = name;
      this.regionName = "区域";
      this.regionIndex = -1;
      this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.regionChoose();
    },
    // 选择区
    async regionChoose() {
      this.drawerNavIndex = 2;
      let res = await getRegion({
        provinceValue: this.provinceIndex,
        cityValue: this.cityIndex,
        commodityId: this.infoObj.commodityId,
      });
      res = res.data.data;
      this.regionList = res;
      console.log(res);
    },
    // 选中区
    regionOne(id, name) {
      this.regionIndex = id;
      this.regionName = name;
      this.wangdian = "网点";
      this.wangdianIndex = -1;
      this.wangdianChoose();
    },
    // 选择网点
    async wangdianChoose() {
      this.drawerNavIndex = 3;
      let res = await getWangdian({
        areaValue: this.regionIndex,
        commodityId: this.infoObj.commodityId,
      });
      res = res.data.data;
      this.wangdianList = res;
      console.log(res);
    },
    // 选中网点
    wangdianOne(id, name, index) {
      this.wangdianIndex = id;
      this.wangdian = name;
      this.wid = index;
    },
    // 确定
    sureAddress() {
      this.drawer1 = false;
      if (this.wangdianIndex != -1) {
        if (this.provinceName == this.cityName) {
          this.wangdianName =
            this.provinceName + this.regionName + this.wangdian;
        } else {
          this.wangdianName =
            this.provinceName + this.cityName + this.regionName + this.wangdian;
        }
      }
    },
    // 打开规格
    sizeOpen() {
      this.sizeName = "";
      let arr = [];
      let arrSpecial = [];
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          arr.indexOf(
            this.infoObj.specificationValueVOList[i].specificationId
          ) == -1
        ) {
          arr.push(this.infoObj.specificationValueVOList[i].specificationId);
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.infoObj.specificationValueVOList[i].flag = true;
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }
      console.log(arrSpecial);
      this.getPrice(arrSpecial);
    },
    async getPrice(arrSpecial) {
      for (let i = 0; i < this.infoObj.commodityTypeVOList.length; i++) {
        if (
          arrSpecial.join(",") ==
          this.infoObj.commodityTypeVOList[i].specificationValueIdList.join(",")
        ) {
          this.mid = this.infoObj.commodityTypeVOList[i].id;
        }
      }
      let res = await getLimitPrice({
        id: this.$route.query.id,
        specificationValueIds: arrSpecial.join(","),
      });
      res = res.data;
      console.log(res);
      if (res.result == 0) {
        this.isOrder = true;
        this.nowPrice = res.data.specialOffer;
        this.prePrice = res.data.price;
      } else {
        this.isOrder = false;
        this.message = res.msg;
        this.showInfo();
      }
    },
    // 选择规格
    sizeChoose(item, index) {
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (
          this.infoObj.specificationValueVOList[i].specificationId ==
          item.specificationId
        ) {
          this.infoObj.specificationValueVOList[i].flag = false;
        }
      }
      this.infoObj.specificationValueVOList[index].flag = true;
      let arrSpecial = [];
      this.sizeName = "";
      for (let i = 0; i < this.infoObj.specificationValueVOList.length; i++) {
        if (this.infoObj.specificationValueVOList[i].flag) {
          arrSpecial.push(this.infoObj.specificationValueVOList[i].id);
          this.sizeName += this.infoObj.specificationValueVOList[i].value;
        }
      }
      if(item.image!=null){
        this.sizeImg = item.image;
      }
      console.log(arrSpecial);
      this.getPrice(arrSpecial);
    },
    async toConsult() {
      let res = await consultApi();
      res = res.data.data;
      this.consultImg = res;
      this.dialogVisible = true;
    },
    reduce() {
      if (this.num != 1) {
        this.num--;
      }
    },
    add() {
      this.num++;
    },
    // 去下单
    toOrder() {
      if (this.wangdianIndex == -1) {
        this.message = "请选择网点";
        this.showInfo();
        this.wangdianOpen();
      } else if (!this.isOrder) {
        this.message = "商品库存不足";
        this.showInfo();
      } else {
        this.isApply = false;
        this.getAddress();
      }
    },
    // 详情
    async toDetail() {
      if (this.isOrder) {
        this.drawer2 = false;
      } else {
        this.message = "该商品库存不足";
        this.showInfo();
      }
    },
    // 获取地址
    async getAddress() {
      let res = await getOrderAddress({
        userId: 1,
      });
      if (res.data.result == 0) {
        res = res.data.data;
        this.addressList = res;
        this.oneAddress = this.addressList[0];
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }

      console.log(this.oneAddress);
    },
    chooseAddressIndex(index) {
      this.addressIndex = index;
    },
    sureAddressed() {
      this.oneAddress = this.addressList[this.addressIndex];
      this.drawer3 = false;
    },
    // 报名
    async limitProvide() {
      if (!this.oneAddress.id) {
        this.message = "请选择地址";
        this.showInfo();
      } else if (!resultPhone(this.limitPhone)) {
        this.message = "请输入正确的手机号";
        this.showInfo();
      } else {
        let res = await panicAdd({
          commodityId: this.id,
          commodityTypeId: this.mid,
          outletsId: this.wid,
          panicBuyId: this.$route.query.id,
          phone: this.limitPhone,
          userAddressId: this.oneAddress.id,
          userId: 1,
          isType: 1,
          type: this.payAli ? 0 : 1,
        });
        console.log(res);
        if (this.payAli) {
          if (res.status == 200) {
            if (this.infoObj.cautionMoney > 0) {
              if (res.status == 200) {
                this.$("#myForm").html(res.data);
              } else {
                this.message = "付款失败";
                this.showInfo();
              }
            } else {
              if (res.data.result == 0) {
                this.message = "报名成功";
                this.showInfo();
                this.centerDialogVisible = false;
              } else {
                this.message = "报名失败";
                this.showInfo();
              }
            }
          } else {
            this.message = "请登入";
            this.showInfo();
          }
        }else{
          location.href = res.data.h5_url;
        }
      }
    },
    getSwiper1() {
      let that = this;
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        on: {
          slideChangeTransitionEnd() {
            that.swiperIndex = this.activeIndex + 1;
            that.videoShow = false;
            let video = document.getElementById("videos");
            video.pause();
          },
        },
      });
    },
    toGetAward() {
      this.$router.push("/mine/myOrder");
    },
    toAddAddress(){
      this.$router.push('/mine/address')
    }
  },
};
</script>

<style lang="less" scoped>
.swiper-slide{
  position: relative;
  .video-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    transform: translate(-50%,-50%);
  }
}

.video-open{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // object-fit: fill;
   background-color: #fff;
}
.info {
  position: absolute;
  top: 63px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    position: relative;
    #swiper1 {
      .big-img-item {
        width: 100%;
      }
    }
    .swiper-num {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: inline-block;
      background: rgba(17, 17, 17, 0.5);
      font-size: 12px;
      color: #fff;
      padding: 3px 10px;
      z-index: 999;
    }
  }
  .center {
    margin-bottom: 10px;
    .center-info {
      padding: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .info-name {
        font-size: 16px;
        color: #111;
        font-weight: 500;
      }
      .info-introduction {
        font-size: 12px;
        color: #666;
      }
      .price {
        display: flex;
        align-items: flex-end;
        .price-now {
          .one {
            font-size: 14px;
            color: #111;
          }
          .two {
            font-size: 24px;
            color: #111;
            font-weight: 500;
          }
        }
        .price-pre {
          font-size: 14px;
          color: #999;
          text-decoration: line-through;
          margin-left: 10px;
          position: relative;
          bottom: 5px;
        }
      }
    }
    .center-item {
      margin-top: 10px;
      background-color: #fff;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #111;
      display: flex;
      align-items: center;
      .one {
        display: inline-block;
        width: 20%;
        color: #999;
        flex-shrink: 0;
      }
      .two {
        flex-grow: 1;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .center-introduction {
      background-color: #fff;
      margin-top: 10px;
      .shop-info {
        padding: 15px;
        .shop-info-top {
          font-size: 14px;
          color: #999;
          padding-bottom: 15px;
        }
        .shop-info-item {
          font-size: 12px;
          padding-bottom: 15px;
          display: flex;
          .one {
            color: #999;
            width: 20%;
            display: inline-block;
            flex-shrink: 0;
          }
          .two {
            color: #666;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
          }
        }
        .super-img {
          width: 100%;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    position: sticky;
    bottom: 0;
    height: 80px;
    background-color: #fff;
    padding: 15px;
    .domain {
      display: flex;
      justify-content: space-between;
      .bottom-left {
        flex-shrink: 0;
        display: flex;
        .domian-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          margin-right: 10px;
          font-size: 12px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .bottom-right {
        display: flex;
        flex-grow: 1;
        width: 100%;
        .btn-item-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 40px;
          background: #111;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}

.drawer-domain {
  .stricky {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  .drawer-top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: #111;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #f3f3f3;
  }
  .drawer-center {
    border-bottom: 1px solid #f3f3f3;
    .drawer-center-item {
      padding: 0 15px;
      font-size: 15px;
      color: #111;
      display: flex;
      justify-content: flex-start;
      .wangdian-item {
        padding: 15px 0 12px 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 20%;
        text-align: center;
        height: 100%;
        margin-right: 20px;
      }
      .drawer-center-item-active {
        border-bottom: 3px solid #bba492;
      }
    }
  }
  .drawer-bottom {
    .drawer-bottom-item {
      padding: 15px;
      font-size: 15px;
      color: #999;
    }
    .drawer-bottom-item-active {
      color: #111;
      font-weight: 500;
    }
  }
}

.drawer-size {
  padding: 15px;
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;
    .left {
      flex-shrink: 0;
      width: 23% !important;
      img {
        width: 100%;
        border: 1px solid #111;
      }
    }
    .right-speical {
      position: relative;
      flex-grow: 1;
      font-size: 15px;
      margin-left: 15px;
      .right-top {
        img {
          position: absolute;
          right: 0;
          width: 24px;
          height: 24px;
        }
      }
      .right-bottom {
        position: absolute;
        bottom: 0;
        .price {
          color: #bba492;
        }
        .select {
          color: #333;
        }
      }
    }
  }
  .size-item {
    padding: 15px 0 0 0;
    border-bottom: 1px solid #f3f3f3;
    .classicName {
      display: block;
      font-size: 15px;
      color: #666;
      margin-bottom: 20px;
    }
    .size-item-item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      position: relative;
      .border-size {
        border: 1px solid #cccdcd;
        padding: 5px 10px;
      }
      img {
        position: absolute;
        right: 0;
        bottom: -5px;
      }
      .border-size-active {
        border: 1px solid #bba492;
      }
    }
  }
  .drawer-qunatity {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #666;
  }
}

.size-sure {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  height: 70px;
  .btn {
    width: 100%;
    height: 40px;
    background-color: #111;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.e-number {
  display: flex;
  margin-top: 20px;
  .add {
    height: 30px;
    width: 30px;
    border: 1px solid #cccdcd;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25px;
    }
  }
  .number {
    width: 40px;
    margin: 0 3px;
  }
}

/deep/.el-dialog {
  width: 300px;
}

.domian-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .one {
    margin-top: 10px;
  }
}

.o-top-two {
  margin-top: 10px;
  padding: 0 15px;
  height: 77px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .o-top-two-left {
    width: 100%;
    .o-top-two-left-item-one {
      height: 25px;
      font-size: 15px;
      color: #111;
      font-weight: 500;
      .one {
        display: inline-block;
        width: 70px;
      }
    }
    .o-top-two-left-item-two {
      display: flex;
      font-size: 16px;
      .btn {
        width: 70px;
        text-align: left;
        height: 22px;
        .one {
          color: #bba492;
          border: 1px solid #bba492;
          padding: 0 2px 2px 5px;
          letter-spacing: 2px;
          font-size: 16px;
        }
      }
      .two {
        color: #999;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .o-top-two-right {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.apply {
  .apply-info {
    margin-top: 10px;
    background-color: #fff;
    .apply-info-item {
      padding: 15px;
      font-size: 14px;
      color: #111;
    }
    .apply-input {
      display: flex;
      align-items: center;
      color: #666;
      span {
        flex-shrink: 0;
      }
      input {
        flex-grow: 1;
        border: 0;
        outline: none;
        text-align: right;
        color: #666;
      }
      input::placeholder {
        color: #ccc;
      }
    }
  }
  .provide {
    width: 100%;
    height: 40px;
    background-color: #111;
    font-size: 14px;
    position: fixed;
    bottom: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.address {
  padding: 15px;
  height: 100%;
  .address-top {
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #111;
    font-weight: 500;
    padding-bottom: 15px;
    img {
      position: absolute;
      right: 0;
    }
  }
  .address-container {
    .address-container-item {
      background-color: #fff;
      font-size: 14px;
      padding: 10px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .address-container-item-left {
        margin-right: 20px;
      }
      .default {
        font-size: 12px;
        background-color: #111;
        color: #fff;
        margin-left: 5px;
        padding: 0 3px 0 5px;
      }
    }
  }
  .sure-btn {
    position: sticky;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 50px;
    background-color: #111;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.payway-item {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
  padding: 10px;
  .circle {
    margin-left: auto;
  }
}

/deep/.IntroductionText img {
  display: block !important;
  width: 100% !important;
}

/deep/.IntroductionText {
  font-size: 12px;
}

.no-address-info{
  font-size: 16px;
}

.color-block {
  width: 50px;
  height: 50px;
  position: relative;
  .color-img {
    width: 100%;
    height: 100%;
  }
  .color-choose {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}

.size-total-item{
  display: flex;
  align-items: center;
}
</style>