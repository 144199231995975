<template>
  <div
    :class="{ auction: true, auction_mobile: isMobile }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="container top">
      <div class="top-bread d-sm-none d-lg-block">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/tasting-online' }"
            >品鉴在线</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{path:'/artist'}">艺术工匠家</el-breadcrumb-item>
          <el-breadcrumb-item>{{this.$route.query.name}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top-name">
        <span>{{this.$route.query.name}}</span>
      </div>

      <div class="status">
        <button
          :class="{ normal: true, active: index == statusChoose }"
          v-for="(item, index) in statusList"
          :key="index"
          @click="getLetterChoose(item.text, index)"
        >
          {{ item.text }}
        </button>
        <div class="search-btn d-sm-none d-lg-block">
          <input
            type="text"
            placeholder="艺术工匠家名"
            v-model.trim="name"
            @keydown="searchName"
          />
          <img src="../../assets/img/search_black.png" alt="" />
        </div>
      </div>
    </div>

    <div class="center">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-sm-12"
            v-for="(item, index) in artistList"
            :key="index"
            @click="artistDetail(item.id, item.name)"
          >
            <div class="center-item">
              <div class="big-img">
                <img class="img-fluid" :src="item.image" alt="" />
                <div class="label-status">
                  <span>THE</span>
                  <span>ARTIST</span>
                </div>
              </div>
              <div class="center-bottom">
                <span class="one">{{ item.name }}</span>
                <span class="two">{{ item.nationality }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-sm-none d-lg-block">
        <div class="discount-bottom">
          <el-pagination
            @current-change="handleCurrentChange"
            class="discount-bottom-pagation"
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
          >
          </el-pagination>
          <span class="page-span">共{{ total }}个</span>
        </div>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>

<script>
import {
  getArtistList,
  getClassicClassification,
} from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.getList();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      statusList: [
        {
          text: "全部",
        },
        {
          text: "A",
        },
        {
          text: "B",
        },
        {
          text: "C",
        },
        {
          text: "D",
        },
        {
          text: "E",
        },
        {
          text: "F",
        },
        {
          text: "G",
        },
        {
          text: "H",
        },
        {
          text: "I",
        },
        {
          text: "J",
        },
        {
          text: "K",
        },
        {
          text: "L",
        },
        {
          text: "M",
        },
        {
          text: "N",
        },
        {
          text: "O",
        },
        {
          text: "P",
        },
        {
          text: "Q",
        },
        {
          text: "R",
        },
        {
          text: "S",
        },
        {
          text: "T",
        },
        {
          text: "U",
        },
        {
          text: "V",
        },
        {
          text: "W",
        },
        {
          text: "X",
        },
        {
          text: "Y",
        },
        {
          text: "Z",
        },
      ],
      artistList: [],
      classList: [],
      statusChoose: 0,
      num: 130,
      name: "",
      letter: "",
      classificationId:'',
      pageSize: 9,
      currentPage: 1,
      total: 0,
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
    };
  },
  methods: {
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getArtistList({
        name: this.name,
        letter: this.letter,
        classificationId:this.$route.query.id,
        type: 0,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.artistList = res.items;
    },
    getList() {
      this.getArtist();
    },
    // 获取主体信息
    async getArtist() {
      this.currentPage = 1;
      let res = await getArtistList({
        name: this.name,
        letter: this.letter,
        classificationId:this.$route.query.id,
        type: 0,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.artistList = this.artistList.concat(res.items);
      console.log(res);
    },
    // 字母选择
    getLetterChoose(letter, index) {
      this.statusChoose = index;
      if (index == 0) {
        this.letter = "";
      } else {
        this.letter = letter;
      }
      this.pageNo = 1;
      this.artistList = [];
      this.getArtist();
    },
    // 姓名查询
    searchName(e) {
      console.log(e);
      if (e.key == "Enter") {
        this.pageNo = 1;
        this.artistList = []
        this.getArtist();
      }
    },
    // 艺术工匠家详情
    artistDetail(id, name) {
      this.$router.push({
        path: "/artist-detail",
        query: {
          id: id,
          name: name,
        },
      });
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.artistList.length < this.total) {
          this.loading = false;
          this.getArtist();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

.row {
  margin: 0;
}

.auction_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.top {
  .top-bread {
    margin-top: 50px;
  }
  .top-name {
    margin-top: 80px;
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
  .top-sort {
    margin-top: 20px !important;
    .top-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 90px;
      }
      span {
        display: block;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  .status {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .normal {
      border: 0;
      outline: none;
      background-color: #f5f5f5;
      border-radius: 20px;
      padding: 8px 15px;
      font-size: 12px;
      color: #111;
      margin-right: 7px;
      cursor: pointer;
    }
    .active {
      background-color: #111;
      color: #fff;
    }
    .search-btn {
      position: relative;
      flex-grow: 1;
      font-size: 12px;
      input {
        width: 200px;
        height: 30px;
        border: 0;
        outline: none;
        background-color: #f5f5f5;
        padding-left: 50px;
        border-radius: 20px;
      }
      ::-webkit-input-placeholder {
        color: #ccc;
      }
      img {
        position: absolute;
        top: 5px;
        left: 14px;
      }
    }
  }
}

////////////////////////////////////////////
.center {
  background-color: #f5f5f5;
  padding-bottom: 80px;

  .center-total {
    display: flex;
    justify-content: space-around;
  }
  .center-item {
    margin-top: 100px;
    font-size: 14px;
    color: #666;
    padding: 0;
    transition: all 0.3s ease;
    position: relative;
    .big-img {
      position: relative;
      cursor: pointer;
      img {
        width: 90%;
      }
      .label-status {
        color: #111;
        font-size: 30px;
        font-weight: 500;
        position: absolute;
        top: 0;
        left: -8%;
        span {
          display: block;
          height: 30px;
        }
      }
    }

    .center-bottom {
      position: absolute;
      bottom: -55px;
      left: -8%;
      width: 100%;
      background-color: #fff;
      padding: 20px 44px;
      .one {
        display: block;
        font-size: 24px;
        color: #111;
        display:block;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .two {
        display: block;
        margin-top: 14px;
      }
    }
  }

  .center-item:hover {
    transform: translate(1px, -1px);
    box-shadow: 0px 12px 14px 0px rgba(153, 153, 153, 0.3);
  }
}

/////////////////////////////////////
.discount-bottom {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}
/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
}

/deep/ .el-tag .el-tag__close:hover {
  background-color: #111;
}

/deep/ .clear-tag {
  background-color: #eee;
  color: #111;
  border: 0;
  cursor: pointer;
}

/deep/ .el-pager li {
  background-color: #f5f5f5;
}

/deep/ .el-pagination .btn-next {
  background-color: #f5f5f5 !important;
}

/deep/ .el-pagination .btn-prev {
  background-color: #f5f5f5 !important;
}

.footer {
  margin: 0;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding-left {
    padding-left: 5px;
  }
  .no-padding-right {
    padding-right: 5px;
  }
  .auction {
    .top-name {
      font-size: 18px;
      font-weight: 550;
      margin-left: 15px;
      margin-top: 40px;
    }
    .status {
      margin-left: 15px;
      flex-wrap: wrap !important;
      margin-top: 15px;
      .normal {
        background-color: #fff;
        color: #999;
        padding: 0;
        width: 28px;
        height: 20px;
        margin-bottom: 10px;
      }
      .active {
        color: #111;
      }
    }
    .center-item {
      margin-top: 30px;
    }
    .label-status {
      top: 20px !important;
      left: 20px !important;
    }
    .center-bottom {
      padding: 0 !important;
      height: 64px !important;
      background: rgba(255, 255, 255, 0.8) !important;
      width: 90% !important;
      bottom: 5% !important;
      left: 5% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .two {
        margin-top: 5px !important;
      }
    }
  }
  .big-img{
    img{
      width: 100% !important;
    }
  }
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

@media (min-width: 300px) and (max-width: 992px){
  .center-bottom{
    .one{
      font-size: 18px !important;
    }
  }
}
</style>