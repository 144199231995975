<template>
  <!-- 活动主体 -->
  <div class="activity-body col-md-10">
    <div class="top-nav">
      <span
        :class="{ 'active-span': index == chooseNav }"
        v-for="(item, index) in navList"
        :key="index"
        @click="toChooseNav(index)"
        >{{ item.text }}</span
      >
    </div>
    <div
      class="activity-item row"
      v-for="(item, index) in shopList"
      :key="index"
      @click="toActivityDetail(item.id)"
    >
      <div class="activity-body-left col-md-6">
        <img class="img-fluid left-img" :src="item.image" alt="" />
        <img
          v-if="item.state == 0"
          class="small-icon"
          src="../../assets/img/activity-pre.png"
          alt=""
        />
        <img
          v-if="item.state == 1"
          class="small-icon"
          src="../../assets/img/activity-loading.png"
          alt=""
        />
        <img
          v-if="item.state == 2"
          class="small-icon"
          src="../../assets/img/activity-end.png"
          alt=""
        />
      </div>
      <div class="activity-body-right col-md-6">
        <span class="activity-one">{{ item.name }}</span>
        <div class="label-btn">
          <button
            v-for="(item2, index2) in item.activityLabelVOList"
            :key="index2"
          >
            {{ item2.name }}
          </button>
        </div>
        <span class="activity-two"
          >活动时间：{{ item.activityStartTime }}至{{
            item.activityEndTime
          }}</span
        >
        <span class="activity-two"
          >报名时间：{{ item.signUpStartTime }}至{{ item.signUpEndTime }}</span
        >
        <span class="activity-two">活动地点：{{ item.address }}</span>
        <span class="activity-two"
          >活动名额：{{ item.signUpPeople }} / {{ item.quota }}</span
        >
        <span class="activity-two">主办方：{{ item.sponsor }}</span>
        <span class="activity-three">报名费：￥{{ item.signUpMoney }}</span>
        <button
          :class="{
            'activity-four': true,
            'activity-four-item': item.signUpState == 0,
          }"
        >
          {{
            item.signUpState == 0
              ? "立即报名"
              : item.signUpState == 1
              ? "报名截止"
              : item.signUpState == 2
              ? "已结束"
              : item.signUpState == 3
              ? "待开始"
              : "已报名"
          }}
        </button>
        <div class="line"></div>
        <div class="activity-info">
          <span class="activity-five">活动瞬间</span>
          <span v-if="item.imagesVOList">{{
            item.imagesVOList[0].createTime
          }}</span>
          <span v-else>暂无</span>
        </div>
        <div class="activity-message">
          <!-- swiper -->
          <div class="swiper-container" v-if="item.imagesVOList">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item3, index3) in item.imagesVOList"
                :key="index3"
              >
                <img class="item" :src="item3.url" />
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <div v-else>
            <div class="item item-one">巨永家居</div>
          </div>
        </div>
      </div>
    </div>
    <div class="null-info" v-if="shopList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
    <div class="discount-bottom container" v-if="shopList.length>0">
      <el-pagination
        @current-change="handleCurrentChange"
        class="discount-bottom-pagation"
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      >
      </el-pagination>
      <span class="page-span">共{{ total }}个</span>
    </div>
  </div>
</template>


<script>
import { getActivePage } from "../../assets/api/api.js";
export default {
  // name:'shopList',
  created() {
    this.getData();
  },
  data() {
    return {
      navList: [
        {
          text: "全部",
        },
        {
          text: "待开始",
        },
        {
          text: "进行中",
        },
        {
          text: "已结束",
        },
      ],
      shopList: [],
      chooseNav: 0,
      num: 130,
      type: -1,
      pageSize: 3,
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      let res = await getActivePage({
        userId: 1,
        state: this.type,
        type: 0,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
      console.log(res);
      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
    },
    async getData() {
      this.currentPage = 1;
      let res = await getActivePage({
        userId: 1,
        state: this.type,
        type: 0,
        pageNo: 1,
        pageSize: this.pageSize,
      });
      console.log(res);
      res = res.data.data;
      this.total = res.total;
      this.shopList = res.items;
      console.log(res);
      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
    },
    toChooseNav(index) {
      this.chooseNav = index;
      this.type = index - 1;
      this.getData();
    },
    getSwiper() {
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 4,
        observer: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    toActivityDetail(id){
     this.$router.push({
        path: "/member-detail",
        query: {
          id: id,
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.activity-body {
  .top-nav {
    margin-bottom: 30px;
    span {
      position: relative;
      padding: 11px 37px;
      font-size: 18px;
      color: #666;
      cursor: pointer;
    }
    span::after {
      display: block;
      content: "";
      position: absolute;
      height: 18px;
      width: 1px;
      right: 0;
    }
    .active-span {
      color: #111;
      border-bottom: 2px solid #111;
    }
  }
  .activity-item {
    background-color: #f8f8f8;
    margin-bottom: 30px !important;
    .activity-body-left {
      position: relative;
      .left-img {
        width: 100%;
        height: 530px;
      }
      .small-icon {
        position: absolute;
        width: 100px;
        top: 24px;
        left: 5px;
      }
    }
    .activity-body-right {
      font-size: 12px;
      color: #444;
      padding-top: 20px;
      .activity-one {
        border-bottom: 2px solid #111;
        font-size: 14px;
        font-weight: bold;
        color: #111;
      }
      .label-btn {
        margin-top: 13px;
        margin-bottom: 20px;
        button {
          outline: none;
          border: 1px solid #bba492;
          color: #bba492;
          margin-right: 10px;
          background-color: #fff;
        }
      }
      .activity-two {
        display: block;
        margin-bottom: 10px;
      }
      .activity-three {
        font-size: 14px;
        font-weight: bold;
        color: #111;
        margin-top: 20px;
        display: block;
      }
      .activity-four {
        outline: none;
        background-color: #cccccc;
        width: 90px;
        height: 30px;
        border: 0;
        margin-top: 10px;
        color: #fff;
      }
      .activity-four-item {
        background-color: #111;
      }
      .line {
        width: 100%;
        border-bottom: 1px dotted #cccccc;
        margin-top: 20px;
      }
      .activity-info {
        margin-top: 10px;
        .activity-five {
          font-size: 14px;
          color: #111;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .activity-message {
        margin-top: 20px;
        .item {
          height: 127px;
          width: 127px;
          background-color: #fff;
        }
        .item-one {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: #fff;
          background-color: #eee;
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0;
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

.swiper-button-prev {
  left: 0;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
}
.swiper-button-next {
  right: 12px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pager li {
  background-color: #f5f5f5;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-next {
  background-color: #f5f5f5;
}

/deep/ .el-pagination .btn-prev {
  background-color: #f5f5f5;
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>