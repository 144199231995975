<template>
  <div
    :class="{ member: true, member_mobile: isMobile, pcMenber: !isMobile }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="big-img">
      <img class="img-fluid" src="../../assets/img/banner10.png" alt="" />
      <div class="introduce">
        <span class="title">会员活动</span>
        <span class="english"
          >MEMBER<span style="margin-left: 30px">ACTIVITIES</span></span
        >
      </div>
    </div>


    <div class="container" v-if="activityList.length > 0">
      <div class="member-center">
        <div
          class="member-center-item row"
          v-for="(item, index) in activityList"
          :key="index"
          @click="toMemberDetail(item.id)"
        >
          <div class="col-lg-6 member-center-item-left">
            <div class="left">
              <img class="left-img img-fuild" :src="item.image" alt="" />
              <img
                class="left-icon"
                src="../../assets/img/activity-pre.png"
                alt=""
                v-if="item.state == 0"
              />
              <img
                class="left-icon"
                src="../../assets/img/activity-loading.png"
                alt=""
                v-if="item.state == 1"
              />
              <img
                class="left-icon"
                src="../../assets/img/activity-end.png"
                alt=""
                v-if="item.state == 2"
              />
            </div>
          </div>
          <div class="col-lg-6 member-center-item-right">
            <span class="one">{{ item.name }}</span>
            <div class="two">
              <button
                v-for="(item2, index2) in item.activityLabelVOList"
                :key="index2"
              >
                {{ item2.name }}
              </button>
            </div>
            <span class="three"
              >活动时间：{{ item.activityStartTime }}至{{
                item.activityEndTime
              }}
            </span>
            <span class="three"
              >报名时间：{{ item.signUpStartTime }}至{{
                item.signUpEndTime
              }}</span
            >
            <span class="three">活动地点：{{ item.address }}</span>
            <span class="three"
              >活动名额：{{ item.signUpPeople }} / {{ item.quota }}</span
            >
            <span class="three">主办方：{{ item.sponsor }}</span>

            <span class="four"
              >报名费：{{
                item.signUpMoney == 0 ? "免费" : "￥" + item.signUpMoney
              }}</span
            >
            <div :class="{five:true,'color-choose':true}" v-if="item.signUpState==0">立即报名</div>
            <div class="five" v-if="item.signUpState==1">报名截止</div>
            <div class="five" v-if="item.signUpState==2">已结束</div>
            <div class="five" v-if="item.signUpState==3">待开始</div>
            <div class="five" v-if="item.signUpState==4">已报名</div>
          </div>

          <div class="row bottom-line" v-if="item.state == 2">
            <div class="line col-lg-11"></div>
          </div>
          <div class="row bottom-line" v-if="item.state == 2">
            <div class="col-lg-11" v-if="item.imagesVOList">
              <div class="bottom-line-top">
                <span class="one">活动瞬间</span>
                <span class="two"
                  >上传于{{ item.imagesVOList[0].createTime }}</span
                >
                <img
                  class="three"
                  src="../../assets/img/to-right-arrow.png"
                  alt=""
                />
              </div>

              <div class="bottom-swiper">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide col-lg-2 col-sm-4 no-padding-left"
                      v-for="(item2, index2) in item.imagesVOList"
                      :key="index2"
                    >
                      <img
                        class="img-fluid swiper-slide-img"
                        :src="item2.url"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>


<script>
import { getFloorPage ,getAdvertising } from "../../assets/api/api.js";
export default {
  created() {
    this.getActivityList();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      value1: "",
      statusList: [
        {
          id: -1,
          text: "全部",
        },
        {
          id: 0,
          text: "待开始",
        },
        {
          id: 1,
          text: "进行中",
        },
        {
          id: 2,
          text: "已结束",
        },
      ],
      activityList: [],
      statusIndex: -1,
      num: 130,
      name: "",
      activityStartTime: "",
      activityEndTime: "",
      state: -1,
      pageSize: 4,
      total: 20,
      currentPage: 1,
      drawer: false,
      value3: "",
      value4: "",
      pageNo: 1,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      isMobile: false,
    };
  },


  methods: {
    
    getActivityList() {
      this.getData();
    },
    async getData() {
      this.currentPage = 1;
      let res = await getAdvertising ({
        id:this.$route.query.id
      });
      console.log(res);
      let res2 = await getFloorPage({
        idList:res.data.data.activityIdList.toString()
      });
      console.log(res2);
      res2 = res2.data.data;
      this.activityList = res2;

      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
    },
    getSwiper() {
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: "auto",
        observer: true,
        observeParents: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    // 会员活动详情
    toMemberDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/member-detail",
        query: {
          id: id,
        },
      });
    },
    // 底部
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.activityList.length < this.total) {
          this.loading = false;
          this.getData();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}

.pcMenber {
  margin-bottom: 80px;
}
.member_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.big-img {
  position: relative;
  img {
    width: 100%;
    max-height: 580px;
  }
  .introduce {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    .title {
      display: block;
      font-size: 120px;
      font-weight: bold;
      letter-spacing: 15px;
    }
    .english {
      font-size: 20px;
      letter-spacing: 30px;
    }
  }
}

.member-top {
  .title {
    font-size: 36px;
    font-weight: 500;
    color: #111;
    display: flex;
    justify-content: center;
    margin-top: 80px !important;
  }
  .top-info {
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    .top-info-item {
      font-size: 14px;
      color: #111;
      display: flex;
      align-items: center;
      margin-right: 30px;
      .top-info-item-left {
        margin-right: 10px;
      }
      .clear {
        outline: none;
        background-color: #f5f5f5;
        height: 30px;
        padding-left: 15px;
        width: 250px;
      }
      input {
        border: 0;
      }
      ::-webkit-input-placeholder {
        color: #ccc;
      }
      select {
        border: 1px solid #111;
        border-radius: 4px;
      }
    }
    .search {
      border: 0;
      outline: none;
      background-color: #111;
      color: #fff;
      padding: 0 14px;
      border-radius: 4px;
      margin-left: auto;
    }
  }
}

.member-center {
  margin-top: 180px;
  .member-center-item {
    position: relative;
    background-color: #f8f8f8;
    margin-top: 150px !important;
    .member-center-item-left {
      .left {
        position: absolute;
        left: 60px;
        top: -60px;
      }
      .left-img {
        width: 100%;
        // height: 600px;
      }
      .left-icon {
        position: absolute;
        width: 100px;
        height: 50px;
        top: 24px;
        left: -10px;
      }
    }
    .member-center-item-right {
      font-size: 14px;
      color: #444;
      padding-right: 60px;
      padding-left: 100px;
      .one {
        font-size: 20px;
        font-weight: 500;
        color: #111;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 70px;
      }
      .two {
        margin-top: 30px;
        margin-bottom: 50px;
        button {
          outline: none;
          border: 1px solid #bba492;
          color: #bba492;
          background-color: transparent;
          padding: 2px 12px;
          margin-right: 15px;
        }
      }
      .three {
        display: block;
        margin-top: 20px;
      }
      .four {
        font-size: 20px;
        font-weight: 500;
        color: #111;
        display: block;
        margin-top: 50px;
      }
      .five {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #ccc;
        margin-top: 30px;
        cursor: pointer;
        margin-bottom: 60px;
      }
      .color-choose {
        background-color: #111;
      }
    }
    .bottom-line {
      display: flex;
      justify-content: center;
      .bottom-line-top {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .one {
          font-size: 20px;
          font-weight: 500;
          color: #111;
        }
        .two {
          font-size: 12px;
          color: #999;
          margin-right: auto;
          margin-left: 10px;
        }
      }
    }
    .line {
      width: 100%;
      border-bottom: 1px dotted #ccc;
    }
  }
}

.bottom-swiper {
  margin-top: 30px;
  margin-bottom: 60px;
  .swiper-slide {
    .swiper-slide-img {
      width: 100%;
      background-color: #fff;
    }
  }
}

.pointer {
  cursor: pointer;
}

.swiper-button-prev {
  left: 15px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
}
.swiper-button-next {
  right: 15px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
}

//////////////////////////////////
/deep/ .el-range-editor--mini .el-range-input {
  font-size: 14px;
  background-color: #f5f5f5;
}
.el-input__inner {
  background-color: #f5f5f5;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px !important;
}

/deep/ .el-date-editor .el-range__icon {
  line-height: 22px;
}

/deep/ .el-date-editor .el-range__close-icon {
  line-height: 22px;
}

/deep/ .el-date-editor .el-range-separator {
  font-size: 14px;
}

//////////////////////////////////////////////

.discount-bottom {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

/deep/ .el-tag {
  background-color: #111;
  color: #fff;
  margin-right: 20px;
}

/deep/ .el-tag .el-tag__close {
  color: #fff;
}

/deep/ .el-tag .el-tag__close:hover {
  background-color: #111;
}

/deep/ .clear-tag {
  background-color: #eee;
  color: #111;
  border: 0;
  cursor: pointer;
}

.mobile-member-top {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #111;
  font-weight: 500;
  position: relative;
  img {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 15px;
  }
}

.drawer-container {
  padding: 15px;
  height: 100%;
  position: relative;
  .title-name {
    font-size: 14px;
    color: #111;
    display: block;
  }
  .drawer-container-top {
    display: flex;
    justify-content: flex-end;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .drawer-container-time {
    margin-top: 30px;
  }
  .drawer-container-name {
    margin-top: 30px;
    input {
      margin-top: 20px;
      height: 40px;
      border: 0;
      outline: none;
      background-color: #f5f5f5;
      width: 100%;
      font-size: 14px;
      padding-left: 10px;
    }
    input::placeholder {
      color: #ccc;
    }
  }
  .drawer-container-status {
    margin-top: 30px;
    .status-item {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      span {
        display: block;
        width: 22%;
        height: 34px;
        background-color: #f5f5f5;
        font-size: 14px;
        color: #111;
        font-weight: 500;
        text-align: center;
        line-height: 34px;
      }
      .active {
        background-color: #111;
        color: #fff;
      }
    }
  }
  .drawer-bottom {
    position: absolute;
    bottom: 30px;
    left: 15px;
    right: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .drawer-bottom-one {
      width: 130px;
      height: 40px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .drawer-bottom-two {
      width: 130px;
      height: 40px;
      background-color: #111;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.time {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #111;
  .line {
    width: 6%;
    height: 1px;
    background-color: #111;
  }
}

/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 45%;
}
/deep/ .el-input__inner {
  border: 0;
}

/deep/ .picker-two .el-input__prefix {
  display: none;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding-left {
    padding-left: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 15px;
  }
  .member {
    .big-img {
      .introduce {
        .title {
          font-size: 40px;
        }
        .english {
          letter-spacing: 10px;
          font-size: 20px;
        }
      }
    }
  }
  .member-center {
    margin-top: 30px;
    padding: 0 15px;
    .member-center-item {
      margin-top: 20px !important;
      .member-center-item-left {
        position: static;
        .left {
          position: static;
          top: 80px !important;
        }
        .left-icon {
          position: absolute !important;
          left: 8px !important;
        }
      }
      .member-center-item-right {
        padding-left: 15px !important;
        padding-right: 15px !important;
        position: relative;
        margin-bottom: 20px;
        .one {
          margin-top: 20px;
        }
        .two {
          margin: 10px 0 !important;
        }
        .three {
          margin-top: 5px;
        }
        .four {
          margin-top: 20px;
        }
        .five {
          margin: 0;
          position: absolute;
          right: 15px;
          bottom: 0;
          width: 72px;
        }
      }
      .bottom-line {
        .bottom-line-top {
          margin-top: 20px;
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1499px) {
  .clear {
    width: 180px !important;
  }
  .member-center {
    margin-top: 20px;
    .member-center-item {
      margin-top: 100px !important;
      .member-center-item-left {
        .left {
          top: 80px !important;
        }
      }
      .member-center-item-right {
        padding-left: 30px !important;
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1200px) {
  .member-center {
    margin-top: 20px;
    .member-center-item {
      margin-top: 100px !important;
      .member-center-item-left {
        .left {
          top: 80px !important;
        }
      }
      .member-center-item-right {
        padding-left: 30px !important;
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 992px){
  .introduce{
    .english{
      font-size: 14px !important;
    }
  }

  .member-center-item{
    .one{
      font-size: 16px !important;
    }

    .three{
      font-size: 12px;
    }
    .four{
      font-size: 16px !important;
    }
  }
}
</style>