<template>
  <div :class="{'info':true, 'mine-top':true,classic:isMobile}">
    <div class="container">
      <div class="row">
        <div class="d-lg-none">
          <div class="top-sss">
            <div class="top-hhh">
              <div class="top-left">
                <img class="avatar" :src="baseObj.avatar" alt="" />
              </div>
              <div class="top-right">
                <div class="name">{{ baseObj.nickname }}</div>
                <div class="name-status">
                  <span class="status-one">NO.{{ baseObj.userId }}</span>
                  <span>注册时间{{ baseObj.createTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2 col-sm-12 info-left no-padding">
          <div class="info-left-total d-sm-none d-lg-block">
            <div
              class="info-left-item"
              v-for="(item, index) in navList"
              :key="index"
              @click="toChooseLeftNav(index)"
            >
              <span :class="{ 'info-left-active': chooseLeftNav == index }">{{
                item.text
              }}</span>
            </div>
          </div>
          <!-- /////////////////////////////////////////////////////// -->

          <div class="info-left-total d-lg-none">
            <div
              class="info-left-item"
              v-for="(item, index) in navList2"
              :key="index"
              @click="toChooseLeftNav2(index)"
            >
              <span :class="{ 'info-left-active': chooseLeftNav == index }">{{
                item.text
              }}</span>
              <img
                class="d-lg-none"
                src="../../assets/img/arrow_right.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- //////////////////////////////////////////////////////////// -->
        <router-view></router-view>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////// -->
    <div class="fixed-bottom d-lg-none" @click="quit">退出登入</div>
  </div>
</template>

<script>
import { getBasicInfoApi,loginOutApi } from "../../assets/api/api.js";
export default {
  created() {
    this.getInfo();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    if(sessionStorage.getItem('userMine')){
      this.chooseLeftNav = sessionStorage.getItem('userMine');
    }else{
      sessionStorage.setItem('userMine',0)
    }

    console.log(this.$route.path);
    if(this.$route.path=='/basic-info'){
      this.chooseLeftNav = 0;
    }
  },
  data() {
    return {
      navList: [
        {
          id: 0,
          text: "基本信息",
        },
        {
          id: 1,
          text: "我的订单",
        },
        {
          id: 2,
          text: "发票管理",
        },
        {
          id: 3,
          text: "我的收藏",
        },
        {
          id: 4,
          text: "我的关注",
        },
        {
          id: 5,
          text: "我的活动",
        },
      ],
      navList2: [
        {
          id: 0,
          text: "注册信息",
        },
        {
          id: 1,
          text: "我的地址",
        },
        {
          id: 2,
          text: "开票信息",
        },
        {
          id: 3,
          text: "我的订单",
        },
        {
          id: 4,
          text: "发票管理",
        },
        {
          id: 5,
          text: "我的收藏",
        },
        {
          id: 6,
          text: "我的关注",
        },
        {
          id: 7,
          text: "我的活动",
        },
      ],
      chooseLeftNav: 0,

      infoList: {
        name: "巨永家居de粉丝",
        tel: "15261987915",
        email: "123@163.com",
        password: "123456789",
        wechart: "未绑定",
      },
      baseObj: {},
      isMobile:false
    };
  },

  methods: {
    toChooseLeftNav(index) {
      this.chooseLeftNav = index;
      console.log(this.chooseLeftNav);
      sessionStorage.setItem('userMine',index);
      if (index == 0) {
        this.$router.push({
          path: "/basic-info",
        });
      } else if (index == 1) {
        this.$router.push("/my-order");
      } else if (index == 2) {
        this.$router.push("/invoice");
      } else if (index == 3) {
        this.$router.push("/collection");
      } else if (index == 4) {
        this.$router.push("/activity");
      } else {
        this.$router.push("/my-activity");
      }
    },
    toChooseLeftNav2(index) {
      if (index == 0) {
        this.$router.push("/mine/baseInfo");
      } else if (index == 1) {
        this.$router.push("/mine/address");
      } else if (index == 2) {
        this.$router.push("/mine/invoice");
      } else if (index == 3) {
        this.$router.push("/mine/myOrder");
      } else if (index == 5) {
        this.$router.push("/mine/collection");
      } else if (index == 6) {
        this.$router.push("/mine/attention");
      } else if (index == 7) {
        this.$router.push("/mine/activity");
      } else if (index == 4) {
        this.$router.push("/mine/invoice-manage");
      }
    },
    async getInfo() {
      let res = await getBasicInfoApi({
        id: 1,
      });
      res = res.data.data;
      this.baseObj = res;
      console.log(res);
    },
    // 退出登入
    async quit(){
      let res = await loginOutApi();
      if (res.data.result == 0) {
        // this.message = "退出成功";
        // this.showInfo();
        this.$emit("fatherSort");
        sessionStorage.setItem('address',this.$route.fullPath)
        this.$router.push("/login");
      }
    }
  },
};
</script>

<style lang="less" scoped>
.classic{
  margin-top: 63px;
}
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.info {
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: #f5f5f5;
}

.top-hhh {
  margin: 15px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  .top-left {
    margin-right: 10px;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .top-right {
    color: #0d0d0d;
    .name {
      font-size: 20px;
      font-weight: 500;
    }
    .name-status {
      font-size: 12px;
      .status-one {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

.info-left {
  .info-left-total {
    width: 180px;
    background-color: #fff;
    margin: 0 auto;
  }
  .info-left-item {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #666;
    font-size: 16px;
  }
  .info-left-active {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 4px solid #111;
    color: #111;
    font-weight: 500;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding {
    padding: 0;
  }
  .info-left-total {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    .info-left-item {
      font-size: 14px;
      font-weight: 500;
      color: #111;
      justify-content: space-between;
      height: 60px;
    }
    .info-left-active {
      border: 0;
      justify-content: space-between;
    }
  }
  .mine-top {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.fixed-bottom{
  position: sticky;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: #111;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
}
</style>