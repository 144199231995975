<template>
  <div :class="{ about: true, classic_mobile: isMobile }">
    <div class="big-img">
      <!-- <img class="img-fluid" src="../../assets/img/banner11.png" alt="" /> -->
      <img
        class="img-fluid"
        src="http://file.antiker.cn/8f225b339d9749eb868b9a9148e3fe7f.png"
        alt=""
      />
      <div class="introduce">
        <span class="title">关于我们</span>
        <div>
          <span class="english">ABOUT</span>
          <span class="english-right">US</span>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="company-think d-lg-none">
        <span class="one">企业经营理念</span>
        <span class="two">{{ idea }}</span>
      </div>
      <!-- 企业理念 -->
      <div class="row about-top">
        <div class="col-lg-6 col-sm-6">
          <div class="about-top-left">
            <img class="left-one-img" :src="leftOneImg" alt="" />
            <img class="left-two-img" :src="rightImage" alt="" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 no-padding-left about-top-right">
          <span class="one d-sm-none d-lg-block"
            >企业经营理念：<span class="two">{{ idea }}</span></span
          >
          <div class="line d-sm-none d-lg-block"></div>

          <span class="three">{{ ideaDescription }}</span>
        </div>
      </div>
    </div>

    <!-- 企业简介 -->
    <div class="company-introduce row">
      <div
        class="company-introduce-left col-lg-6 col-sm-12 d-sm-none d-lg-block"
      >
        <div class="company-introduce-left-item">
          <span class="one">企业简介</span>
          <!-- <span class="three">{{ introduction }}</span> -->
          <span class="three" v-html="introduction"></span>
        </div>
      </div>
      <div class="col-lg-6 d-sm-12 no-padding">
        <div class="company-introduce-right">
          <img class="img-fluid" :src="image" alt="" />
        </div>
      </div>
      <div class="company-introduce-left col-sm-12 d-lg-none">
        <div class="company-introduce-left-item">
          <span class="one">企业简介</span>
          <!-- <span class="three">{{ introduction }}</span> -->
          <span class="three" v-html="introduction"></span>
        </div>
      </div>
    </div>

    <!-- 经营范围 -->
    <div class="company-scope container">
      <span class="scope">经营范围</span>
      <div class="row scope-row">
        <div
          :class="{
            'col-lg-3': true,
            'col-sm-6': true,
            'no-padding-right': index % 2 == 0,
            'no-padding-left': index % 2 != 0,
          }"
          v-for="(item, index) in businessList"
          :key="index"
        >
          <div class="scope-item">
            <img class="img-fluid" :src="item.businessScopeImage" alt="" />
            <div class="bottom">
              <span>{{ item.businessScope }}</span>
              <div class="bottom-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact-img">
        <img class="img-fluid" src="../../assets/img/img2.png" alt="" />
        <div class="contact-info">
          <div class="contact-info-item">
            <span class="one">联系我们</span>
            <span class="two"
              >若您希望进一步了解我们的产品和服务或者您使用的产品有任何问题，请在下方留言，我们的顾问会第一时间给您提供专业的服务。</span
            >
            <img
              class="six-img img-fuild"
              src="../../assets/img/location.png"
              alt=""
            />
            <span class="three">{{ phone }}</span>
            <span class="four">邮件：{{ mail }}</span>
            <span class="five">地址：{{ address }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 个人信息 -->
    <div class="container">
      <div class="info row">
        <!-- 姓名 -->
        <div class="col-lg-6 info-item">
          <span class="name">姓名</span>
          <input
            class="info-input"
            type="text"
            placeholder="请输入"
            v-model.trim="iname"
          />
        </div>
        <!-- 性别 -->
        <div class="col-lg-6 info-item">
          <span class="name">性别</span>
          <select
            :class="{ 'info-input': true, 'select-default': isex == -1 }"
            name=""
            id=""
            v-model.trim="isex"
          >
            <option value="-1" disabled>请选择</option>
            <option value="1">男</option>
            <option value="0">女</option>
          </select>
        </div>
        <!-- 手机号 -->
        <div class="col-lg-6 info-item">
          <span class="name">手机号</span>
          <input
            class="info-input"
            type="text"
            placeholder="请输入"
            v-model.trim="iphone"
          />
        </div>
        <!-- 邮箱 -->
        <div class="col-lg-6 info-item">
          <span class="name">邮箱</span>
          <input
            class="info-input"
            type="email"
            placeholder="请输入"
            v-model.trim="imail"
          />
        </div>
        <!-- 留言 -->
        <div class="col-lg-12 info-item info-item-teatarea">
          <span class="name">留言内容</span>
          <textarea
            v-model.trim="icontent"
            class="textarea-default"
            placeholder="请输入"
          ></textarea>
        </div>
        <!-- 提交按钮 -->
        <div class="row btn">
          <button @click="toCommit">提交</button>
        </div>
      </div>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import { getAboutUs, contactApi } from "../../assets/api/api.js";
import { resultEmail, resultPhone } from "../../uitils/check.js";
export default {
  created() {
    this.getCompanyInfo();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      leftOneImg: "",
      rightImage: "",
      idea: "",
      ideaDescription: "",
      image: "",
      introduction: "",
      mail: "",
      address: "",
      phone: "",
      businessList: [],
      iname: "",
      isex: -1,
      iphone: "",
      imail: "",
      icontent: "",
      message: "",
      isMobile: false,
    };
  },
  methods: {
    async getCompanyInfo() {
      let res = await getAboutUs({ type: 1 });
      res = res.data.data;
      this.leftOneImg = res.leftImage;
      this.rightImage = res.rightImage;
      this.idea = res.idea;
      this.ideaDescription = res.ideaDescription;
      this.image = res.image;
      this.introduction = res.introduction;
      this.businessList = res.businessScopeVOList;
      this.address = res.address;
      this.mail = res.mail;
      this.phone = res.phone;
      console.log(res);
    },
    async toCommit() {
      console.log(this.iname);
      console.log(this.isex);
      console.log(this.iphone);
      console.log(this.imail);
      console.log(this.icontent);
      // if (
      //   this.iname != "" &&
      //   this.isex != -1 &&
      //   resultPhone(this.iphone) &&
      //   resultEmail(this.imail) &&
      //   this.icontent != ""
      // ) {
      //   let res = await contactApi({
      //     content: this.icontent,
      //     mail: this.imail,
      //     phone: this.iphone,
      //     sex: this.isex,
      //     username: this.iname,
      //   });
      //   console.log(res);
      //   this.message = res.data.msg;
      //   this.$("#message").css("display", "block");
      //   setTimeout(() => {
      //     this.$("#message").css("display", "none");
      //   }, 3000);
      // }
      if (this.iname == "") {
        this.message = "请输入姓名";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else if (this.isex == -1) {
        this.message = "请选择性别";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else if (!resultPhone(this.iphone)) {
        this.message = "请选择正确的手机号";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else if (!resultEmail(this.imail)) {
        this.message = "请选择正确的邮箱";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else if (this.icontent == "") {
        this.message = "请输入留言内容";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else {
        let res = await contactApi({
          content: this.icontent,
          mail: this.imail,
          phone: this.iphone,
          sex: this.isex,
          username: this.iname,
        });
        if (res.data.result == 0) {
          this.message = "提交成功";
          this.$("#message").css("display", "block");
          setTimeout(() => {
            this.$("#message").css("display", "none");
          }, 3000);
          this.iname = '';
          this.isex = -1;
          this.iphone = '';
          this.imail = '';
          this.icontent = '';
        }else{
          this.message = "提交失败";
          this.$("#message").css("display", "block");
          setTimeout(() => {
            this.$("#message").css("display", "none");
          }, 3000);
        } 
      }
    },
  },
};
</script>


<style lang="less" scoped>
.classic_mobile {
  margin-top: 63px;
}
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.no-padding {
  padding: 0;
}
.big-img {
  position: relative;
  img {
    width: 100%;
    max-height: 580px;
  }
  .introduce {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    .title {
      display: block;
      font-size: 120px;
      font-weight: bold;
      letter-spacing: 15px;
    }
    .english {
      font-size: 20px;
      letter-spacing: 30px;
    }
    .english-right {
      margin-left: 30px;
      font-size: 20px;
    }
  }
}

.about-top {
  .about-top-left {
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 130px;
    .left-one-img {
      width: 57%;
      object-fit: cover;
    }
    .left-two-img {
      width: 35%;
      object-fit: cover;
    }
  }

  .about-top-right {
    padding-left: 30px;
    .one {
      display: block;
      font-size: 36px;
      color: #111;
      font-weight: 500;
      margin-top: 70px;
      .two {
        display: block;
      }
    }
    .line {
      width: 100px;
      height: 2px;
      background-color: #111;
      margin-top: 40px;
    }
    .three {
      display: block;
      margin-top: 60px;
      color: #111;
      font-size: 16px;
    }
  }
}

.company-introduce {
  .company-introduce-left {
    width: 100%;
    background-color: #bba492;
    display: flex;
    align-items: center;
    .company-introduce-left-item {
      padding: 40px 80px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .one {
        font-size: 36px;
        font-weight: 500;
        color: #fff;
        display: block;
      }
      .two {
        font-weight: 500;
        color: #fff;
        display: block;
        margin-top: 8%;
      }
      /deep/ .three {
        font-size: 14px;
        color: #fff;
        display: block;
        margin-top: 20px;
        p {
          text-indent: 2em !important;
        }
      }
    }
  }
  .company-introduce-right {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.company-scope {
  color: #111;
  margin-top: 100px;
  .scope {
    display: block;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
  }
  .scope-row {
    margin-top: 80px !important;
    .scope-item {
      position: relative;
      padding: 0;
      transition: all 0.3s ease;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
      .bottom {
        font-size: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(17, 17, 17, 0.4);
        color: #fff;
        height: 0;
        opacity: 0;
        transition: all 0.3s ease;
        .bottom-line {
          width: 100px;
          height: 2px;
          background-color: #fff;
          margin-top: 25px;
        }
      }
    }
    .scope-item:hover {
      transform: translate(5px, -20px);
      box-shadow: 0px 10px 14px 0px rgba(17, 17, 17, 0.3);
    }

    .scope-item:hover .bottom {
      height: 30%;
      opacity: 1;
    }
  }
}

.contact {
  margin-top: 100px;
  .contact-img {
    position: relative;
    img {
      width: 100%;
    }
    .contact-info {
      color: #fff;
      position: absolute;
      background: rgba(17, 17, 17, 0.7);
      backdrop-filter: blur(5px);
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .contact-info-item {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .one {
          font-size: 36px;
          font-weight: 500;
        }
        .two {
          font-size: 14px;
        }
        .three {
          font-size: 70px;
          font-weight: bold;
        }
        .four {
          font-size: 14px;
        }
        .five {
          font-size: 14px;
        }
        .six-img {
          width: 20%;
        }
      }
    }
  }
}

.info {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
  .info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #111;
    margin-bottom: 24px;
    .name {
      display: inline-block;
      width: 104px;
      font-size: 20px;
    }
    .info-input {
      width: 78%;
      height: 40px;
      outline: none;
      border: 1px solid #cccdcd;
      padding-left: 24px;
      font-size: 18px;
    }
    ::-webkit-input-placeholder {
      font-size: 18px;
      color: #ccc;
    }
    textarea {
      font-size: 18px;
      width: 89%;
      outline: none;
      border: 1px solid #cccdcd;
      height: 140px;
    }
    .select-default {
      color: #ccc;
    }
    .textarea-default {
      padding: 10px 20px;
    }
  }
  .info-item-teatarea {
    display: flex;
    align-items: flex-start;
    .name {
      display: inline-block;
      width: 104px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-start;
    button {
      font-size: 16px;
      width: 200px;
      height: 50px;
      outline: none;
      border: 0;
      background-color: #111;
      color: #fff;
      margin-left: 116px;
    }
  }
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding-right {
    padding-right: 5px;
  }
  .no-padding-left {
    padding-left: 5px !important;
  }
  .company-think {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .one {
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .two {
      font-size: 14px;
    }
  }
  .introduce {
    .title {
      font-size: 30px !important;
    }
    .english {
      letter-spacing: 5px !important;
      font-size: 10px !important;
    }
    .english-right {
      margin-left: 15px !important;
      letter-spacing: 5px !important;
    }
  }
  .about-top {
    .about-top-left {
      position: static !important;
    }
  }
  .about-top {
    margin-top: 30px !important;
    .about-top-right {
      .three {
        margin-top: 0;
      }
    }
  }

  // 企业简介
  .company-introduce {
    margin-top: 30px !important;
    .company-introduce-left-item {
      padding: 20px 10px !important;
    }
  }
  .company-scope .scope-row .scope-item .bottom {
    opacity: 1;
    height: 70px;
  }
  .contact {
    height: 310px;
    .contact-img {
      height: 100%;
      img {
        // height: 100%;
        width: 110px;
        height: 110px;
      }
    }
    .contact-info {
      .six-img {
        width: 30% !important;
      }
      .contact-info-item {
        .one {
          font-size: 20px !important;
        }
        .two {
          font-size: 10px !important;
          padding: 0 12px;
          margin-top: 10px;
        }
        .three {
          font-size: 20px !important;
        }
        .four {
          margin-bottom: 10px;
        }
      }
    }
  }
  .textarea-default {
    width: 78% !important;
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .introduce .english {
    font-size: 14px !important;
  }

  .english-right {
    font-size: 14px !important;
  }

  .about-top .about-top-right .three {
    font-size: 12px;
  }

  .company-introduce .company-introduce-left .company-introduce-left-item .one {
    font-size: 30px;
  }

  .company-introduce
    .company-introduce-left
    .company-introduce-left-item
    .three {
    font-size: 12px;
  }

  .company-scope {
    margin-top: 60px !important;
  }

  .company-scope .scope-row {
    margin-top: 50px !important;
  }

  .company-scope .scope-row .scope-item .bottom {
    font-size: 14px;
  }

  .contact {
    margin-top: 60px;
  }

  .contact .contact-info .contact-info-item {
    .one {
      font-size: 16px !important;
    }
    .two {
      font-size: 12px !important;
      margin-bottom: 6px;
    }

    .three {
      font-size: 16px !important;
    }
  }

  .info .info-item {
    .name {
      font-size: 15px;
    }
    .info-input {
      height: 32px;
      padding-left: 16px;
      font-size: 15px;
    }
  }

  ::-webkit-input-placeholder {
    font-size: 15px !important;
  }

  .info .btn button {
    width: 140px;
    height: 40px;
    font-size: 15px;
  }
}
</style>