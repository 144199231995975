<template>
  <div class="info container" v-on:scroll.passive="onScroll" ref="box">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>我的关注</span>
    </div>
    <div class="col-sm-12">
      <div class="nav-collection">
        <span
          :class="{ active: navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
          @click="chooseNav(item.id)"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div class="row auction-domain">
      <template v-if="navIndex == 0">
        <div
          class="col-sm-12"
          v-for="(item, index) in auctionList"
          :key="index"
          @click="goAuctionDetail(item)"
        >
          <div class="auction-container">
            <img class="img-fuild big-image" :src="item.image" alt="" />
            <img
              class="title-img"
              src="../../assets/img/auction-pre.png"
              v-if="item.type == 0"
            />
            <img
              class="title-img"
              src="../../assets/img/auction-loading.png"
              v-if="item.type == 1"
            />
            <img
              class="title-img"
              src="../../assets/img/auction-end.png"
              v-if="item.type == 2"
            />
            <div class="auction-text">
              <div class="one">
                {{ item.name }}
              </div>
              <div class="two">拍品来源：{{ item.source }}</div>
              <div class="two">拍卖时间：{{ item.startTime }}</div>
              <div class="two">拍卖地点：{{ item.address }}</div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="auctionList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
      </template>
      <template v-if="navIndex == 1">
        <div
          class="col-sm-12"
          v-for="(item, index) in auctionList"
          :key="index"
          @click="goMemberDetail(item)"
        >
          <div class="auction-container">
            <img class="img-fuild big-image" :src="item.image" alt="" />
            <img
              class="title-img"
              src="../../assets/img/activity-pre.png"
              v-if="item.state == 0"
            />
            <img
              class="title-img"
              src="../../assets/img/activity-loading.png"
              v-if="item.state == 1"
            />
            <img
              class="title-img"
              src="../../assets/img/activity-end.png"
              v-if="item.state == 2"
            />
            <div class="auction-text">
              <div class="one">
                {{ item.name }}
              </div>
              <div class="two">
                活动时间：{{ item.activityStartTime }}至{{
                  item.activityEndTime
                }}
              </div>
              <div class="two">
                报名时间：{{ item.signUpStartTime }}至{{ item.signUpEndTime }}
              </div>
              <div class="two">活动地点：{{ item.address }}</div>
              <div class="two">主办方：{{ item.sponsor }}</div>
              <div class="two">
                活动名额：{{ item.signUpPeople }}/{{ item.quota }}
              </div>
              <div class="three">
                报名费：{{
                  item.signUpMoney == 0 ? "免费" : "￥" + item.signUpMoney
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="auctionList.length==0">
              <img src="../../assets/img/all-null.png" alt="">
              <span>这里空空的什么也没有~</span>
            </div>
      </template>
      <div class="loading" :class="{ 'loading-hidden': loading }">
        {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
      </div>
    </div>
  </div>
</template>


<script>
import {
  getAuctionAttentionPage,
  getActivePage,
} from "../../assets/api/api.js";
export default {
  created() {
    this.getInfo();
  },
  data() {
    return {
      navIndex: 0,
      navList: [
        {
          id: 0,
          text: "拍卖会",
        },
        {
          id: 1,
          text: "活动",
        },
      ],
      pageNo: 1,
      pageSize: 3,
      auctionList: [],
      total: 0,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
    };
  },
  methods: {
    toPreview() {
      this.$router.go(-1);
    },
    chooseNav(id) {
      this.navIndex = id;
      this.pageNo = 1;
      this.auctionList = [];
      this.getInfo();
    },
    // 获得信息
    async getInfo() {
      let res;
      if (this.navIndex == 0) {
        res = await getAuctionAttentionPage({
          userId: 1,
          type: -1,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });
      } else {
        res = await getActivePage({
          userId: 1,
          state: -1,
          pageNo: this.pageNo,
          type: 1,
          pageSize: this.pageSize,
        });
      }
      res = res.data;
      console.log(res);
      this.auctionList = this.auctionList.concat(res.data.items);
      this.total = res.data.total;
    },
    // 去拍卖会详情
    goAuctionDetail(item) {
      this.$router.push({
        path: "/auction-item-detail",
        query: {
          id: item.id,
        },
      });
    },
    // 会员活动详情
    goMemberDetail(item) {
      this.$router.push({
        path: "/member-detail",
        query: {
          id: item.id,
        },
      });
    },
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        console.log(this.auctionList.length);
        if (this.auctionList.length < this.total) {
          this.loading = false;
          this.getInfo();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  overflow: auto;
  .top {
    padding-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .nav-collection {
    font-size: 16px;
    margin-top: 25px;
    span {
      margin-right: 20px;
      color: #666666;
    }
    .active {
      font-size: 18px;
      color: #111;
    }
  }
}

.auction-domain {
  margin-top: 20px !important;
}
.auction-container {
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  .big-image {
    width: 100%;
  }
  .title-img {
    position: absolute;
    left: -7px;
    top: 10px;
    width: 70px;
  }
  .auction-text {
    padding: 15px;
    .one {
      font-size: 15px;
      color: #111;
      font-weight: 500;
      margin-bottom: 5px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .two {
      font-size: 12px;
      color: #444;
      margin-bottom: 3px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .three {
      margin-top: 10px;
      color: #111111;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.null-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img{
  width: 200px;
  height: 200px;
}
</style>