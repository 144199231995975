<template>
  <div :class="{'auction-detail':true,'classic':isMobile}">
    <div class="auction-detail-top container">
      <div class="row">
        <div class="col-md-5">
          <div class="swiper-container" id="swiper1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in shopDetailList.imageList"
                :key="index"
              >
                <div class="swiper-item swiper-img-item">
                  <div class="swiper-top-flex">
                    <img class="img-fluid swiper-top-img" :src="item" alt="" />
                    <img
                      class="swiper-img-status"
                      src="../../assets/img/auction-pre.png"
                      alt=""
                      v-if="shopDetailList.type == 0"
                    />
                    <img
                      class="swiper-img-status"
                      src="../../assets/img/auction-loading.png"
                      alt=""
                      v-if="shopDetailList.type == 1"
                    />
                    <img
                      class="swiper-img-status"
                      src="../../assets/img/auction-end.png"
                      alt=""
                      v-if="shopDetailList.type == 2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>

        <div class="auction-detail-top-left col-md-7">
          <span class="one">{{ shopDetailList.name }}</span>
          <span class="two">{{ shopDetailList.introduction }}</span>
          <div class="three">
            <span class="four"
              >起拍价<span class="fuhao" v-if="shopDetailList.startPrice"
                >￥<span class="price">{{
                  shopDetailList.startPrice
                }}</span></span
              >
              <span class="fuhao" v-else
                ><span class="price">暂无</span></span
              ></span
            >
            <span class="four five"
              >成交价<span class="fuhao" v-if="shopDetailList.finalPrice"
                >￥<span class="price">{{
                  shopDetailList.finalPrice
                }}</span></span
              >
              <span class="fuhao" v-else
                ><span class="price">暂无</span></span
              ></span
            >
            <span class="six">拍品来源：{{ shopDetailList.source }}</span>
            <span class="six">拍卖时间：{{ shopDetailList.startTime }}</span>
            <span class="six">拍卖地点：{{ shopDetailList.address }}</span>
          </div>
          <div class="auction-btn" @click="addAttention" v-if="shopDetailList.attention==0">
            <span style="font-size: 28px" class="add">+</span>
            <span>关注</span>
          </div>
          <div class="auction-btn" style="background-color:#111;color:#fff" @click="addAttention" v-if="shopDetailList.attention==1">
            <span>已关注</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 历史成交 -->
    <div class="center-swiper container">
      <span class="center-name col-sm-12">拍品详情</span>
      <div class="shopping-intro col-sm-12" v-html="shopDetailList.description"></div>
    </div>

    <!-- 历史成交 -->
    <div class="center-swiper container">
      <span class="center-name col-sm-12">历史成交</span>
      <div class="swiper-container row swiper-no-swiping" id="swiper2" v-if="historyList.length>0">
        <div class="swiper-wrapper">
          <div
            :class="{
              'swiper-slide': true,
              'col-lg-3': true,
              'col-sm-4': true,
              pointer: true,
              'no-padding-left': true,
              'no-padding-right': true,
            }"
            v-for="(item, index) in historyList"
            :key="index"
          >
            <div class="swiper-item">
              <div class="swiper-item-total">
                <img class="img-fluid" :src="item.image" alt="" />
                <div class="swiper-item-bottom">
                  <span class="name">{{ item.name }}</span>
                  <span class="price">成交价：￥{{ item.finalPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div v-else style="font-size:30px;font-weight:500;margin-left:50px">暂无</div>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getAuctionDetailList,
  getHistoryList,
  getAuctionAttention,
} from "../../assets/api/api.js";
export default {
  created() {
    this.id = this.$route.query.id;
    this.getList();
    this.getHistory();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      id: 0,
      shopDetailList: {},
      historyList: [],
      message:'',
      isMobile:false
    };
  },
  methods: {
    //展示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    getList() {
      this.getDetailList();
    },
    async getDetailList() {
      let res = await getAuctionDetailList({
        userId: 1,
        auctionMerchandiseId: this.id,
      });
      console.log(res);
      res = res.data.data;
      this.shopDetailList = res;
      let that = this;
      this.$nextTick(function () {
        that.getSwiper1();
      });
      console.log(res);
    },
    getSwiper1() {
      new this.Swiper("#swiper1", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    getSwiper2() {
      new this.Swiper("#swiper2", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 'auto',

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    //历史成交
    async getHistory() {
      let res = await getHistoryList({
        pageNo: 1,
        pageSize: 20,
      });
      res = res.data.data;
      this.historyList = res;
      console.log(res);
      let that = this;
      this.$nextTick(function () {
        that.getSwiper2();
      });
    },
    // 关注
    async addAttention() {
      let res = await getAuctionAttention({
        userId: 1,
        auctionMerchandiseId: this.id,
      });
      if(res.data.result==0){
        this.getList();
        this.message = res.data.msg;
        this.showInfo();
      }else{
        this.$router.push('/login')
      }
      console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.classic{
  margin-top: 63px;
}
.auction-detail-top {
  margin-top: 40px;
  padding-bottom: 60px !important;
  border-bottom: 1px dotted #cccccc;
  #swiper1 {
    .swiper-slide {
      .swiper-item {
        display: flex;
        justify-content: center;
        .swiper-top-flex {
          position: relative;
          width: 92%;
          .swiper-top-img {
            width: 100%;
            background-color: #eee;
          }
          .swiper-img-status {
            position: absolute;
            width: 100px;
            height: 50px;
            top: 24px;
            left: -8px;
          }
        }
      }
    }
  }
  .auction-detail-top-left {
    color: #111;
    font-size: 14px;
    .one {
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-top: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .two {
      color: #666;
      margin-top: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .three {
      padding: 14px 14px 20px;
      background-color: #f3f3f3;
      margin-top: 15px;
      .four {
        .fuhao {
          margin-left: 15px;
          font-size: 18px;
          font-weight: 500;
          .price {
            font-size: 28px;
          }
        }
      }
      .five {
        margin-left: 100px;
      }
      .six {
        display: block;
        margin-top: 14px;
      }
    }
    .auction-btn {
      margin-top: 10px;
      width: 200px;
      height: 50px;
      background: rgba(17, 17, 17, 0.04);
      border: 1px solid #111111;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      .add {
        margin-right: 5px;
        display: inline-block;
        margin-top: -3px;
      }
    }
  }
}

.swiper-container {
  padding-bottom: 50px;
}

.swiper-item {
  .swiper-item-total {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      max-height: 240px;
      min-height: 140px;
    }
    .swiper-item-bottom {
      background: rgba(17, 17, 17, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      position: absolute;
      bottom: -45px;
      left: 5%;
      .name {
        font-size: 20px;
        color: #fff;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 15px 0 0 15px;
      }
      .price {
        font-size: 16px;
        color: #999;
        display: block;
        margin-top: 5px;
        padding-bottom: 15px;
      }
    }
  }
}

#swiper2 {
  margin-top: 50px;
}

.center-swiper {
  margin-top: 60px;
  .center-name {
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
}

/deep/ .shopping-intro {
  margin-top: 50px;
  font-size: 16px;
  .span-intro {
    font-size: 14px;
    color: #111;
    display: block;
  }
  img {
    width: 100%;
    margin-top: 50px;
  }
}

#swiper1 .swiper-button-prev {
  left: 22px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 50% !important;
}
#swiper1 .swiper-button-next {
  right: 22px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 50% !important;
}

.swiper-button-prev {
  left: 15px;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 35%;
}
.swiper-button-next {
  right: 15px;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
  top: 35%;
}

@media (min-width: 300px) and (max-width: 992px) {
  .no-padding-left {
    padding-left: 5px;
  }
  .no-padding-right {
    padding-right: 5px;
  }

  .auction-detail {
    padding-bottom: 80px;
  }

  .swiper-top-flex {
    position: relative;
    width: 95%;
  }

  .swiper-top-img {
    width: 100% !important;
    height: 100% !important;
  }
  .swiper-img-status {
    left: -8px !important;
  }

  .auction-detail-top-left {
    .one {
      padding: 0 20px;
    }
    .two {
      padding: 0 20px;
    }
    .three {
      margin: 0 10px;
    }
    .auction-btn {
      margin: 0 10px;
    }
  }

  .swiper-button-prev {
    left: 5px;
    top: 45%;
  }
  .swiper-button-next {
    right: 5px;
    top: 45%;
  }
  .center-name {
    display: block;
    font-size: 18px !important;
    font-weight: 550 !important;
    margin-bottom: 10px !important;
  }
  .center-swiper {
    margin-top: 30px;
  }

  .swiper-item-bottom {
    bottom: -30px !important;
    .name {
      font-size: 12px !important;
      padding-top: 10px !important;
    }
    .price {
      padding-bottom: 10px !important;
      margin-top: 0 !important;
    }
  }

  .shopping-intro{
    margin-top: 30px !important;
  }
}
.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 992px){
  .swiper-img-status{
    width: 75px !important;
    height: 40px !important;
  }

  .swiper-container{
    padding-bottom: 20px;
  }

  .auction-detail-top-left{
    .four{
      .price{
        font-size: 20px !important;
      }
    }
    .five{
      margin-left: 40px !important;
    }

    .six{
      font-size: 12px !important;
    }
  }

  .shopping-intro{
    font-size: 12px !important;
  }

  .swiper-item-bottom{
    width: 100% !important;
    .name{
      font-size: 12px !important;
    }

    .price{
      font-size: 12px !important;
    }
  }
  .swiper-item .swiper-item-total img{
    min-height: 80px;
  }
}
</style>