<template>
  <div class="logistics">
    <div class="container">
      <div class="row bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/mine' }"
            >个人中心</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/invoice' }"
            >发票管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>物流详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="row logistics-container">
        <span style="font-weight: bold; font-size: 18px">{{ obj.state }}</span>
        <span class="two">运单号码： {{ obj.expressageId }} </span>
        <span>物流公司： {{ obj.expressageName }} </span>
        <div class="roadway">
          <div class="roadway-left">
            <div
              class="roadway-left-item"
              v-for="(item, index) in obj.jsonObject"
              :key="index"
            >
              <div
                :class="{
                  circle: true,
                  'circle-top': index == 0,
                  'circle-bottom': index == obj.jsonObject.length - 1,
                }"
              ></div>
              <div class="line" v-if="index != obj.jsonObject.length - 1"></div>
            </div>
          </div>
          <div class="roadway-right">
            <div
              class="roadway-right-item"
              v-for="(item, index) in obj.jsonObject"
              :key="index"
            >
              <span class="roadway-one">{{ item.date }}</span>
              <span class="roadway-two">{{ item.week }}</span>
              <span class="roadway-three">{{ item.time }}</span>
              <span class="roadway-four">{{ item.context }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { invoiceLogisitic } from "../../assets/api/api.js";
export default {
  created() {
    let id = this.$route.query.orderNumber;
    this.getLogistic(id);
  },
  data() {
    return {
      roadwayList: [
        {
          day: "2021-05-29",
          week: "周六",
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          day: "2021-05-29",
          week: "周六",
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单",
        },
        {
          time: "17:37:56",
          info: "商品已经下单1111111111",
        },
      ],
      obj: {},
    };
  },
  methods: {
    async getLogistic(id) {
      let res = await invoiceLogisitic({
        orderUuid: id,
      });
      console.log(res);
      res = res.data.data;
      this.obj = res;
      console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.logistics {
  background-color: #f5f5f5;
  padding-bottom: 80px;
  padding-top: 20px;
  .logistics-container {
    margin-top: 20px !important;
    padding: 40px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #111;
    .two {
      margin: 20px 0 15px 0;
    }
    .roadway {
      display: flex;
      justify-content: flex-start;
      background-color: #f8f8f8;
      margin-top: 30px;
      padding: 30px 40px;
      width: 100%;
      .roadway-left {
        width: 10px;
        flex-shrink: 0;
        .roadway-left-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #d8d8d8;
            margin: 3px 0;
          }
          .circle-top {
            margin-top: 6px;
          }
          .circle-bottom {
            background-color: #111;
          }
          .line {
            width: 1px;
            height: 26px;
            background-color: #d8d8d8;
          }
        }
      }
      .roadway-right {
        flex-grow: 1;
        font-size: 12px;
        color: #666;
        display: flex;
        align-items: flex-start;
        margin-left: 20px;
        flex-direction: column;
        overflow: hidden;
        .roadway-right-item {
          width: 100%;
          height: 38px;
          display: flex;
          .roadway-one {
            flex-shrink: 0;
            font-weight: 500;
            color: #111;
            display: inline-block;
            width: 100px;
          }
          .roadway-two {
            flex-shrink: 0;
            font-weight: 500;
            color: #111;
            display: inline-block;
            width: 40px;
          }
          .roadway-three {
            flex-shrink: 0;
            width: 80px;
            display: inline-block;
            margin-left: 20px;
          }
          .roadway-four {
            flex-grow: 1;
            display:block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>