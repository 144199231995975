<template>
  <div class="auction-detail">
    <div class="auction-detail-top container">
      <div class="d-sm-none d-lg-block">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/tasting-online' }"
            >品鉴在线</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/artist' }"
            >艺术工匠家</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="center-swiper container">
        <span class="center-name">基本信息</span>
      </div>

      <!-- 基本信息 -->
      <div class="row artist-detail-top">
        <div class="auction-detail-top-left col-lg-5">
          <img class="img-fluid" :src="artistObj.image" alt="" />
        </div>

        <div class="auction-detail-top-right col-lg-7">
          <div class="name">
            <span class="name-big">{{ artistObj.name }}</span>
            <span class="name-small">{{ artistObj.name }}</span>
          </div>
          <span class="one">国籍：{{ artistObj.nationality }}</span>
          <span class="artist-intro">{{ artistObj.introduction }}</span>
        </div>
      </div>
    </div>

    <!-- 个人作品 -->
    <div class="center-swiper container">
      <span class="center-name">艺术作品</span>

      <div class="artist-works" v-if="artistObj.simpleCommodityVOList.length>0">
        <div class="swiper-container" id="swiper1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide col-sm-4 col-lg-3 no-padding"
              v-for="(item, index) in artistObj.simpleCommodityVOList"
              :key="index"
            >
              <div class="artist-works-item" @click="toDetail(item)">
                <img class="img-fluid" :src="item.image" alt="" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
      <div v-else style="font-size:30px;font-weight:500;margin-left:50px;margin-top:30px">暂无</div>
    </div>

    <!-- 拍品详情 -->
    <div class="center-swiper container">
      <span class="center-name">关于{{ name }}</span>
      <div class="shopping-intro col-sm-12" v-html="artistObj.description" v-if="artistObj.isVideo==0"></div>
      <div class="shopping-intro col-sm-12" v-if="artistObj.isVideo==1">
        <video :src="artistObj.video" class="artistVideo" controls></video>
      </div>
    </div>
    <div class="bottom-margin d-lg-none"></div>
  </div>
</template>


<script>
import { getArtistListDetail } from "../../assets/api/api.js";
export default {
  created() {
    this.id = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getData();
  },
  data() {
    return {
      id: 0,
      name: "",
      artistObj: {},
    };
  },
  methods: {
    async getData() {
      let res = await getArtistListDetail({
        artistId: this.id,
      });
      res = res.data.data;
      this.artistObj = res;
      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
      console.log(res);
    },
    // 作品跳转
    toDetail(item) {
      let deviceWidth =
        document.documentElement.clientWidth || window.innerWidth;
      if (item.type == 0) {
        if (deviceWidth < 992) {
          this.$router.push({
            path: "/mobile/classic-detail",
            query: {
              id: item.id,
            },
          });
        } else {
          this.$router.push({
            path: "/classic-detail",
            query: {
              id: item.id,
            },
          });
        }
      } else if (item.type == 1) {
        if (deviceWidth < 992) {
          this.$router.push({
            path: "/mobile/luxery-detail",
            query: {
              id: item.id,
            },
          });
        } else {
          this.$router.push({
            path: "/luxery-detail",
            query: {
              id: item.id,
            },
          });
        }
      }
    },
    getSwiper() {
      new this.Swiper("#swiper1", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: "auto",

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.artist-detail-top {
  margin-top: 60px;
}

.artist-works {
  margin-top: 50px;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .artist-works-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
    }
    span {
      display: block;
      margin-top: 10px;
      font-size: 20px;
    }
  }
}

.auction-detail-top {
  margin-top: 40px;
  padding-bottom: 60px !important;
  border-bottom: 1px dotted #cccccc;
}

.auction-detail-top-left {
  img {
    width: 100%;
    box-shadow: 14px 14px 24px 0px rgba(17, 17, 17, 0.5);
  }
}

.auction-detail-top-right {
  padding: 0 25px;
  font-size: 14px;
  color: #111;
  .name {
    padding-top: 20px;
    position: relative;
    .name-big {
      font-size: 50px;
      font-weight: 500;
      color: #eee;
    }
    .name-small {
      font-size: 24px;
      color: #111;
      font-weight: 500;
      position: absolute;
      bottom: 10px;
      left: 0;
    }
  }

  .one {
    display: block;
    margin-bottom: 30px;
  }
}

.center-swiper {
  margin-top: 60px;
  margin-bottom: 20px;
  .center-name {
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
}

/deep/.shopping-intro {
  margin-top: 50px;
  margin-bottom: 60px;
  font-size: 14px;
  color: #111;
  .span-intro {
    font-size: 14px;
    color: #111;
    display: block;
  }
  img {
    width: 100%;
    margin-top: 50px;
  }
}

#swiper1 .swiper-button-prev {
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
  left: 15px;
  top: 40%;
  z-index: 999;
}
#swiper1 .swiper-button-next {
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
  right: 15px;
  top: 40%;
  z-index: 999;
}

.el-breadcrumb {
  margin-bottom: 60px;
}

@media (min-width: 300px) and (max-width: 992px) {
  .bottom-margin{
    height: 10px;
  }
  .no-padding {
    padding: 0 5px;
  }
  .center-swiper {
    margin-top: 30px !important;
    .center-name {
      font-size: 18px !important;
      padding-left: 15px !important;
    }
    .artist-works {
      margin-top: 20px;
      border-bottom: 1px dotted #ccc;
      padding-bottom: 40px;
      .artist-works-item {
        span {
          font-size: 12px;
        }
      }
    }
  }
  .auction-detail-top-right {
    .one {
      margin-bottom: 15px;
    }
  }
  #swiper1 .swiper-button-prev {
    top: 40%;
    left: 11px;
  }
  #swiper1 .swiper-button-next {
    top: 40%;
    right: 11px;
  }
  .auction-detail-top {
    padding-bottom: 40px !important;
  }

  .shopping-intro {
    margin-top: 30px;
    margin-bottom: 70px;
  }
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  pointer-events: painted;
}

.artistVideo{
  width: 100%;
}

@media (min-width: 300px) and (max-width: 992px){
  .name-big{
    font-size: 38px !important;
  }
  .artist-intro{
    font-size: 12px;
  }
}
</style>