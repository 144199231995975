<template>
  <div class="shop-detail-top">
    <div class="container">
      <div class="row">
        <!-- 图像放大镜 -->
        <div class="col-md-5 shop-detail-top-left">
          <div id="box">
            <img class="show-img" :src="chooseImg" alt="" id="smallImage" />
            <img
              src="http://file.antiker.cn/fba6388858bb4acb85ad07bd8a702f2d.png"
              class="bimg-icon"
              v-if="videoImage && selectImgId == -1"
              @click="openVideo"
            />
            <video
              :src="video"
              class="swiper-video"
              controls
              v-show="videoChoose"
              id="sVideo"
            ></video>
            <div id="move" v-if="!videoChoose"></div>
            <div id="bimg" v-if="!videoChoose">
              <img :src="chooseImg" alt="" id="b_bimg" />
            </div>
          </div>

          <div class="shop-detail-img-item">
            <div class="swiper-container" id="swiper1">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-if="videoImage">
                  <img
                    :src="videoImage"
                    alt=""
                    @click="imgChoose(-1)"
                    :class="{ selectedImg: selectImgId == -1 }"
                  />
                  <img
                    src="http://file.antiker.cn/81b9870b08fc43a9a8cdafef5c06ecac.png"
                    alt=""
                    class="video-icon"
                  />
                </div>
                <div
                  class="swiper-slide"
                  v-for="(item, index) in imgList"
                  :key="index"
                >
                  <img
                    :src="item"
                    alt=""
                    @click="imgChoose(index)"
                    :class="{ selectedImg: selectImgId == index }"
                  />
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="col-md-7 shop-detail-main">
          <span class="one">{{ name }}</span>
          <span class="two">{{ introduction }}</span>
          <!-- 价格 -->
          <div class="three">
            <div class="top" v-if="isSale == 1">
              <span class="top-top">价格</span>
              <span class="top-item">￥</span>
              <span class="top-price">{{ nowPrice }}</span>
            </div>
            <div class="top">
              <span class="top-top">分类</span>
              <span class="top-more-span-none">{{
                itemObj.classificationNameOne
              }}</span>
              <span class="top-more-span">{{
                itemObj.classificationNameTwo
              }}</span>
              <span class="top-more-span">{{
                itemObj.classificationNameThree
              }}</span>
            </div>
            <div class="top">
              <span class="top-top">年代</span>
              <span>{{ years }}</span>
            </div>
            <div class="top center bottom pointer">
              <span class="top-top">选择网点</span>
              <div class="three-select" id="wangdian" v-show="wangdianName!=0">
                <input
                  type="text"
                  placeholder="请输入网点"
                  class="pointer wangdianinput"
                  :value="wangdianName"
                  disabled
                />
                <img
                  src="../../assets/img/up_black.png"
                  alt=""
                  v-if="isClick"
                />
                <img src="../../assets/img/down_black.png" alt="" v-else />
                <div class="three-select-absolut" v-if="isClick == true"></div>
                <div
                  :class="{ 'select-absoulte': true, block: isClick }"
                  v-if="isClick == true"
                >
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 1,
                    }"
                    data-num="1"
                    @click="chooseSelected"
                  >
                    <span data-num="1" @click="chooseSelected">
                      {{ province }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 1 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 2,
                      none: province == '请选择',
                    }"
                    data-num="2"
                    @click="chooseSelected"
                  >
                    <span data-num="2" @click="chooseSelected">
                      {{ city }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 2 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 3,
                      none: city == '请选择',
                    }"
                    data-num="3"
                    @click="chooseSelected"
                  >
                    <span data-num="3" @click="chooseSelected">
                      {{ region }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 3 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 4,
                      none: region == '请选择',
                    }"
                    data-num="4"
                    @click="chooseSelected"
                  >
                    <span data-num="4" @click="chooseSelected">
                      {{ wangdian }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 4 }"
                    ></div>
                  </div>
                  <div class="absoulte-line"></div>
                  <!-- 省市区 -->
                  <div class="select-container">
                    <div
                      v-for="(item, index) in areaList"
                      :key="index"
                      class="select-loop"
                    >
                      <template v-if="chooseSelect == 1">
                        <span
                          @click="
                            toProvice(item.provinceName, item.provinceValue)
                          "
                          :class="{
                            cityClass: item.provinceValue == provinceIndex,
                          }"
                          >{{ item.provinceName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 2">
                        <span
                          @click="toCity(item.cityName, item.cityValue)"
                          :class="{ cityClass: item.cityValue == cityIndex }"
                          >{{ item.cityName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 3">
                        <span
                          @click="
                            toRegion(item.districtName, item.districtValue)
                          "
                          :class="{
                            cityClass: item.districtValue == regionIndex,
                          }"
                          >{{ item.districtName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 4">
                        <span
                          @click="
                            toWangdian(item.name, item.areaValue, item.id)
                          "
                          :class="{
                            cityClass: item.areaValue == wangdianIndex,
                            block: true,
                          }"
                          >{{ item.name }}</span
                        >
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <span style="margin-left:3px" v-show="wangdianName==0">暂无网点</span>
            </div>
          </div>

          <template v-if="isSale == 1">
            <div
              class="four"
              v-for="(item, index) in detailLeft"
              :key="index"
              :class="{ none: index > 1 && isshow }"
            >
              <div class="four-left constructer-left">
                <span>{{ item.name }}</span>
              </div>
              <div class="four-right">
                <template v-for="(item2, index2) in detailRight">
                  <div :key="index2">
                    <div
                      class="four-right-item"
                      @click="chooseConst(item, item2, index2)"
                      v-if="item.id == item2.specificationId&&(item2.image==null)"
                    >
                      <span
                        :class="{
                          'my-border': true,
                          'my-border-two': item2.flag,
                        }"
                        >{{ item2.value }}</span
                      >
                      <img
                        class="
                          four-right-item-position
                          five-right-item-position
                        "
                        src="../../assets/img/detail-choose.png"
                        alt=""
                        v-if="item2.flag"
                      />
                    </div>
                    <div class="four-right-item" @click="chooseConst(item, item2, index2)" v-if="item.id == item2.specificationId&&(item2.image!=null)">
                      <span class="four-img-block">
                        <img :src="item2.image" alt="" :class="{'img-color':true,'img-border':item2.flag}">
                        <img
                        class="
                          four-right-item-position
                          color-right-item-position
                        "
                        src="../../assets/img/detail-choose.png"
                        alt=""
                        v-if="item2.flag"
                      />
                      <span class="color-world">{{item2.value}}</span>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-if="isSale == 1">
            <div class="line-more" v-if="isshow">
              <img
                class="pointer"
                src="../../assets/img/arrow-down-three.png"
                @click="isshow = false"
                alt=""
              />
            </div>
          </template>

          <!-- 数量 -->
          <div class="four" v-if="isSale == 1">
            <div class="four-left constructer-left">
              <span>数量</span>
            </div>
            <div class="four-right">
              <el-input-number
                class="input-number"
                v-model="num"
                @change="handleChange"
                :min="1"
                :max="1"
                size="small"
              ></el-input-number>
            </div>
          </div>

          <!-- 按钮 -->
          <div class="five" v-if="isSale == 1">
            <div class="see-more see-more-one pointer" @click="toOrder">
              立即下单
            </div>
            <div class="see-more see-more-two pointer" @click="addSort">
              <img src="../../assets/img/cart.png" alt="" />
              <span class="addSort">加入购物车</span>
            </div>
            <div class="collect pointer" @click="toSaveTotal">
              <img src="../../assets/img/save.png" alt="" v-if="save == 0" />
              <img
                src="../../assets/img/save_black.png"
                alt=""
                v-if="save == 1"
              />
              <span v-if="save == 0">收藏</span>
              <span v-if="save == 1">已收藏</span>
            </div>
            <div class="see-more see-more-three pointer" @click="toConsult">
              立即咨询
            </div>
          </div>
          <div class="five" v-if="isSale == 0">
            <div class="see-more see-more-one pointer" @click="toConsult">
              立即咨询
            </div>
            <div class="collect pointer" @click="toSaveTotal">
              <img src="../../assets/img/save.png" alt="" v-if="save == 0" />
              <img
                src="../../assets/img/save_black.png"
                alt=""
                v-if="save == 1"
              />
              <span v-if="save == 0">收藏</span>
              <span v-if="save == 1">已收藏</span>
            </div>
          </div>
        </div>
      </div>
      <!--  艺术工匠家 -->
      <div class="row artist" v-if="itemObj.artistId">
        <div class="artist-left">
          <img :src="itemObj.artistImage" alt="" />
        </div>
        <div class="artist-right">
          <div class="artist-right-name">
            <span>艺术工匠家： {{ itemObj.artistName }}</span>
          </div>
          <div class="artist-right-intro">
            <span>{{ itemObj.artistIntroduction }}</span>
          </div>
          <div class="more">
            <div class="more-item" @click="toArtistPath">
              <img class="more-img" src="../../assets/img/sanjiao.png" alt="" />
              <span>了解更多</span>
            </div>
          </div>
        </div>
      </div>
      <!--  品鉴师 -->
      <div class="row artist" v-if="itemObj.tastingId">
        <div class="artist-left">
          <img :src="itemObj.tastingImage" alt="" />
        </div>
        <div class="artist-right">
          <div class="artist-right-name">
            <span>品鉴师： {{ itemObj.tastingName }}</span>
          </div>
          <div class="artist-right-intro">
            <span>{{ itemObj.tastingIntroduction }}</span>
          </div>
          <div class="more">
            <div class="more-item" @click="toTastingPath">
              <img class="more-img" src="../../assets/img/sanjiao.png" alt="" />
              <span>了解更多</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 艺术工匠家评价 -->
      <div class="estimate row" v-if="itemObj.tastingId">
        <span class="result"
          >品鉴结果: <span> {{ itemObj.result }}</span></span
        >
      </div>

      <!-- 商品介绍 -->
      <div class="row shop-introduce">
        <div class="shop-introduce-top">
          <div
            data-num="1"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 1,
              'shop-backgorund': chooseNum == 1,
            }"
            @click="toIntroduce"
          >
            商品介绍
          </div>
          <div
            data-num="2"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 2,
              'shop-backgorund': chooseNum == 2,
            }"
            @click="toIntroduce"
          >
            规格与包装
          </div>
          <div
            data-num="3"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 3,
              'shop-backgorund': chooseNum == 3,
            }"
            @click="toIntroduce"
          >
            其他说明
          </div>
          <div class="introduce-item"></div>
        </div>
      </div>
      <!-- 介绍详情 -->

      <div
        class="IntroductionText"
        v-html="itemObj.commodityIntroduction"
        v-if="chooseNum == 1"
      ></div>
      <div
        class="IntroductionText"
        v-html="itemObj.specificationPackage"
        v-if="chooseNum == 2"
      ></div>
      <div
        class="IntroductionText"
        v-html="itemObj.description"
        v-if="chooseNum == 3"
      ></div>
    </div>

    <!-- 绑定微信 -->
    <div class="item-one">
      <el-dialog title="立即咨询" :visible.sync="dialogVisible" center>
        <div class="domian-dialog">
          <div class="domian-dialog-img">
            <img :src="consultImg" alt="" />
          </div>
          <span class="one">扫描二维码，或者公众号搜索“巨永环境”</span>
        </div>
      </el-dialog>
    </div>

    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getClassicLuxDetail,
  addSortApi,
  getRegion,
  getWangdian,
  saveApi,
  pxoveSaveApi,
  getShoppingPrice,
  orderImmediately,
  getShoppingInventory,
  consultApi,
} from "../../assets/api/api.js";
export default {
  created() {
    this.areaList = this.addressList;
    this.getDetailList();
  },
  data() {
    return {
      id: 0,
      mid: 0,
      wid: 0,
      itemObj: {},
      imgList: [],
      areaList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      wangdianList: [],
      areaList: [],
      detailLeft: [],
      detailRight: [],
      commodityList: [],
      chooseImg: "",
      num: 1,
      chooseNum: 1,
      chooseSelect: 1,
      introduction: "",
      name: "",
      nowPrice: "",
      prePrice: "",
      colorChoose: -1,
      province: "请选择",
      city: "请选择",
      region: "请选择",
      wangdian: "请选择",
      provinceIndex: 0,
      cityIndex: 0,
      regionIndex: 0,
      wangdianIndex: 0,
      wangdianName: "",
      isClick: false,
      isshow: true,
      message: "",
      save: 0,
      // 判断是否能下单
      isOrder: false,
      dialogVisible: false,
      // 下单唯一id
      randomId: "",
      consultImg: "",
      isSale: 1,
      years: "",
      selectImgId: 0,
      isDisabled: false,

      video: "",
      videoImage: "",
      videoChoose: false,
    };
  },

  mounted() {
    this.getMove();
  },

  methods: {
    getMove() {
      if (!this.videoChoose) {
        this.$("#wangdian").hover(this.chooseArea);
        let that = this;
        var box = document.getElementById("box");
        var move = document.getElementById("move");
        var bimg = document.getElementById("bimg");
        var b_bimg = document.getElementById("b_bimg");
        var smallImage = document.getElementById("smallImage");
        //鼠标移入  移出
        box.onmouseover = function () {
          //遮挡层出现
          move.style = "display:block";
          bimg.style = "display:block";
        };
        box.onmouseout = function () {
          move.style = "display:none";
          bimg.style = "display:none";
        };
        //鼠标移动
        box.onmousemove = function (e) {
          //   var e = event || window.event;
          //事件发生时  相对于视窗 y轴的距离
          var y = e.clientY;
          var x = e.clientX;
          let t = that.$("#box").offset().left;
          let l = that.$("#box").offset().top;

          var left = x - t - move.offsetWidth / 2;
          var top = y - l - move.offsetHeight / 2;

          if (top <= 0) {
            top = 0;
          } else if (top >= box.offsetHeight - move.offsetHeight) {
            top = box.offsetHeight - move.offsetHeight;
          }
          if (left <= 0) {
            left = 0;
          } else if (left >= box.offsetWidth - move.offsetWidth) {
            left = box.offsetWidth - move.offsetWidth;
          }

          move.style.left = left + "px";
          move.style.top = top + "px";
          //
          // 计算比率
          // 鼠标移动的距离 占 可移动距离  的 比例
          // 3. 大图片的移动距离 = 遮挡层移动距离 * 大图片最大移动距离 / 遮挡层的最大移动距离

          var w = left / (box.offsetWidth - move.offsetWidth);
          var h = top / (box.offsetHeight - move.offsetHeight);
          //比率
          var b_left = w * (b_bimg.offsetWidth - bimg.offsetWidth);
          var b_top = h * (b_bimg.offsetHeight - bimg.offsetHeight);
          b_bimg.style.left = -b_left + "px";
          b_bimg.style.top = -b_top + "px";
        };
      }
    },
    openVideo() {
      console.log("...");
      let video = document.getElementById("sVideo");
      video.play();
      this.videoChoose = true;
    },
    getSwiper() {
      console.log(".........");
      var mySwiper = new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    //展示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getDetailList() {
      let id = this.$route.query.id;
      let res = await getClassicLuxDetail({ id: id });
      res = res.data.data;
      this.itemObj = res;
      console.log(res);
      this.isSale = res.isSell;
      this.nowPrice = res.priceMin;
      this.years = res.years;
      this.id = id;
      this.video = res.video;
      this.videoImage = res.videoImage;
      // 左侧图片
      this.imgList = res.imagesUrlList;
      // 选择图片
      if (this.videoImage) {
        this.chooseImg = this.videoImage;
        this.selectImgId = -1;
      } else {
        this.chooseImg = this.imgList[0];
      }

      // 左侧
      this.detailLeft = res.specificationVOList;
      // 右侧
      this.detailRight = res.specificationValueVOList;
      // 商品介绍
      this.introduction = res.introduction;
      // 商品名
      this.name = res.commodityName;
      // 商品类型
      this.commodityList = res.commodityTypeVOList;
      //是否收藏
      this.save = res.isFavorites;
      // 默认网点
      this.provinceIndex = res.outletsVO.provinceValue;
      this.province = res.outletsVO.provinceName;
      this.cityIndex = res.outletsVO.cityValue;
      this.city = res.outletsVO.cityName;
      this.regionIndex = res.outletsVO.districtValue;
      this.region = res.outletsVO.districtName;
      this.wangdianIndex = res.outletsVO.areaValue;
      this.wangdian = res.outletsVO.name;
      this.wangdianName =
        this.province + this.city + this.region + this.wangdian;
      this.wid = res.outletsVO.id;

      if (this.isSale == 1) {
        if (this.itemObj.specificationVOList.length > 2) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
        let arr = [];
        let arrSpecial = [];
        for (let i = 0; i < this.detailRight.length; i++) {
          if (arr.indexOf(this.detailRight[i].specificationId) == -1) {
            arr.push(this.detailRight[i].specificationId);
            arrSpecial.push(this.detailRight[i].id);
            this.detailRight[i].flag = true;
          }
        }
        for (let j = 0; j < this.commodityList.length; j++) {
          let specialTypeList = this.commodityList[j].specificationValueIdList;
          if (arrSpecial.join(",") == specialTypeList.join(",")) {
            this.mid = this.commodityList[j].id;
          }
        }
        let res1 = await getShoppingPrice({
          id: this.id,
          specificationValueIds: arrSpecial.join(","),
        });
        res1 = res1.data;
        if (res1.result == 0) {
          res1 = res1.data;
          this.nowPrice = res1.specialOffer;
          this.prePrice = res1.specialOffer;
          this.isOrder = true;
        } else {
          this.message = "库存不足";
          this.isOrder = false;
          this.showInfo();
        }

        console.log(res1);
        console.log(arrSpecial);
        console.log(res);
      }
      this.$nextTick(function () {
        this.getSwiper();
      });
    },
    // 图片选择
    imgChoose(index) {
      this.videoChoose = false;
      let video = document.getElementById("sVideo");
      video.pause();
      if (index == -1) {
        this.chooseImg = this.videoImage;
        this.selectImgId = index;
      } else {
        this.selectImgId = index;
        this.chooseImg = this.imgList[index];
      }
      this.$nextTick(function () {
        this.getMove();
      });
    },
    // 右侧选择
    async chooseConst(item, item2, index2) {
      for (let i = 0; i < this.detailRight.length; i++) {
        if (item.id == this.detailRight[i].specificationId) {
          this.detailRight[i].flag = false;
        }
      }
      this.detailRight[index2].flag = true;

      let arrSpecial = [];

      for (let i = 0; i < this.detailRight.length; i++) {
        if (this.detailRight[i].flag) {
          arrSpecial.push(this.detailRight[i].id);
        }
      }

      for (let j = 0; j < this.commodityList.length; j++) {
        let specialTypeList = this.commodityList[j].specificationValueIdList;
        if (arrSpecial.join(",") == specialTypeList.join(",")) {
          this.mid = this.commodityList[j].id;
        }
      }
      let res1 = await getShoppingPrice({
        id: this.id,
        specificationValueIds: arrSpecial.join(","),
      });
      res1 = res1.data;
      if (res1.result == 0) {
        res1 = res1.data;
        this.nowPrice = res1.specialOffer;
        this.prePrice = res1.specialOffer;
        this.isOrder = true;
      } else {
        this.message = res1.msg;
        this.showInfo();
        this.isOrder = false;
      }
      if(item2.image!=null){
        this.chooseImg = item2.image;
        this.selectImgId = -2;
        if(this.videoChoose){
          this.videoChoose = false;
          this.$nextTick(function(){
            this.getMove();
          })
        }
      }
      console.log(res1);
      console.log(arrSpecial);
    },
    // 加入购物车
    async addSort() {
      if (this.isOrder) {
        if (this.wangdianName == "") {
          this.message = "请选择网点";
          this.$("#message").css("display", "block");
          setTimeout(() => {
            this.$("#message").css("display", "none");
          }, 3000);
        } else {
          let res = await addSortApi({
            commodityId: Number(this.id),
            commodityTypeId: this.mid,
            outletsId: this.wid,
            quantity: this.num,
            userId: 1,
          });
          if (res.data.result == 0) {
            console.log(this.wangdianIndex);
            console.log(res);
            this.message = "加入购物车成功";
            this.$("#message").css("display", "block");
            setTimeout(() => {
              this.$("#message").css("display", "none");
            }, 1000);
            this.$emit("fatherSort");
          } else if (res.data.result == 3) {
            this.message = res.data.msg;
            this.$("#message").css("display", "block");
            setTimeout(() => {
              this.$("#message").css("display", "none");
            }, 3000);
          } else {
            sessionStorage.setItem("address", this.$route.fullPath);
            this.$router.push("/login");
          }
        }
      } else {
        this.message = "该商品库存不足";
        this.showInfo();
      }
    },
    handleChange(val) {
      this.num = val;
    },
    toIntroduce(e) {
      this.chooseNum = e.target.dataset.num;
    },
    // 地址选择
    async chooseSelected(e) {
      if (e != 0) {
        this.chooseSelect = e.target.dataset.num;
      }

      if (this.chooseSelect == 1) {
        let res = await getRegion({
          commodityId: this.$route.query.id,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 2) {
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          commodityId: this.$route.query.id,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 3) {
        // this.areaList = this.regionList;
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          cityValue: this.cityIndex,
          commodityId: this.$route.query.id,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 4) {
        // this.areaList = this.wangdianList;
        let res = await getWangdian({
          areaValue: this.regionIndex,
          commodityId: this.$route.query.id,
        });
        this.areaList = res.data.data;
        console.log(res);
      }
    },
    toProvice(name, id) {
      this.provinceIndex = id;
      this.province = name;
      this.city = "请选择";
      this.region = "请选择";
      this.wangdian = "请选择";
      this.chooseSelect = 2;
      this.chooseSelected(0);
    },
    toCity(name, id) {
      this.cityIndex = id;
      this.city = name;
      this.region = "请选择";
      this.wangdian = "请选择";
      this.chooseSelect = 3;
      this.chooseSelected(0);
    },
    toRegion(name, id) {
      this.regionIndex = id;
      this.region = name;
      this.wangdian = "请选择";
      this.chooseSelect = 4;
      this.chooseSelected(0);
    },
    toWangdian(name, id, wid) {
      console.log(id);
      console.log(wid);
      this.wangdianIndex = id;
      this.wid = wid;
      this.wangdian = name;
      this.wangdianName =
        this.province + this.city + this.region + this.wangdian;
      this.isClick = false;
    },
    async chooseArea() {
      console.log("...");
      this.isClick = !this.isClick;
      if (this.chooseSelect == 1) {
        let res = await getRegion({ commodityId: this.$route.query.id });
        this.areaList = res.data.data;
        console.log(res);
      }
    },
    // closeArea(){

    // },
    // 收藏
    async toSave() {
      if (this.save == 0) {
        let res = await saveApi({ userId: 1, commodityId: Number(this.id) });
        if (res.data.result == 0) {
          this.save = 1;
          this.message = "已收藏";
          this.$("#message").css("display", "block");
          setTimeout(() => {
            this.$("#message").css("display", "none");
          }, 3000);
        } else {
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }

        console.log(res);
      }
    },
    // 取消收藏
    async cancelSave() {
      let res = await pxoveSaveApi({
        userId: 1,
        commodityId: Number(this.id),
      });
      if (res.data.result == 0) {
        this.save = 0;
        this.message = "取消收藏";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else {
        sessionStorage.setItem("address", this.$route.fullPath);
        this.$router.push("/login");
      }

      console.log(res);
    },
    // 下单
    async toOrder() {
      let res1 = await getShoppingInventory({
        id: this.mid,
        quantity: this.num,
      });
      console.log(res1);
      if (this.wangdianName == "") {
        this.message = "请输入网点";
        this.$("#message").css("display", "block");
        setTimeout(() => {
          this.$("#message").css("display", "none");
        }, 3000);
      } else {
        if (this.isOrder && res1.data.result == 0) {
          console.log(this.mid);
          console.log(this.wid);
          console.log(this.num);
          let res = await orderImmediately({
            id: this.mid,
            quantity: this.num,
            outletsId: this.wid,
          });
          if (res.data.result == 0) {
            console.log(res);
            this.$router.push({
              path: "/order",
              query: {
                id: res.data.data,
                path: 0,
              },
            });
          } else {
            sessionStorage.setItem("address", this.$route.fullPath);
            this.$router.push("/login");
          }
        } else {
          this.message = "该商品库存不足";
          this.showInfo();
        }
      }
    },
    // 立即咨询
    async toConsult() {
      let res = await consultApi();
      res = res.data.data;
      this.consultImg = res;
      console.log(res);
      this.dialogVisible = true;
    },
    // 艺术工匠家详情
    toArtistPath() {
      this.$router.push({
        path: "/artist-detail",
        query: {
          id: this.itemObj.artistId,
          name: this.itemObj.artistName,
        },
      });
    },
    // 品鉴师详情
    toTastingPath() {
      this.$router.push({
        path: "/tasting-division-detail",
        query: {
          id: this.itemObj.tastingId,
          name: this.itemObj.tastingName,
        },
      });
    },
    toSaveTotal() {
      console.log(this.save);
      if (this.save == 0) {
        this.toSave();
      } else {
        this.cancelSave();
      }
    },
  },
};
</script>


 

<style lang="less" scoped>
#box {
  width: 100%;
  position: relative;
  border: 1px solid #cccdcd;
}

#move {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}

#bimg {
  width: 460px;
  height: 460px;
  position: absolute;
  left: 500px;
  top: 0px;
  overflow: hidden;
  display: none;
  z-index: 999;
}

.bimg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 99;
}

.swiper-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  // object-fit: fill;
}

#b_bimg {
  width: 880px;
  height: 880px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.three-select-absolut {
  width: 218px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 28px;
  z-index: 999;
  display: none;
}

.none {
  display: none !important;
}

.shop-detail-top {
  margin-top: 40px;
  margin-bottom: 140px;
}
.shop-detail-top-left {
  .show-img {
    width: 100%;
    max-width: 460px;
    height: 460px;
  }
}

.shop-detail-img-item {
  margin-top: 10px;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid #cccdcd;
  }
}

.swiper-wrapper {
  .swiper-slide {
    width: 80px !important;
    height: 80px;
    margin-right: 11px;
    position: relative;
    .video-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.shop-detail-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: #111;
  .one {
    font-size: 20px;
    font-weight: 700;
  }
  .two {
    color: #666;
    display: block;
    margin-top: 12px;
  }
  .three {
    margin-top: 25px;
    width: 100%;
    background-color: #f3f3f3;
    .top {
      font-size: 12px;
      margin-top: 20px;
      .top-top {
        display: inline-block;
        width: 68px;
        margin-left: 14px;
      }
      .top-item {
        font-size: 18px;
        font-weight: 500;
      }
      .top-price {
        font-size: 28px;
        font-weight: 500;
      }
      .top-more-span-none {
        padding-right: 10px;
        border-right: 1px solid #111;
      }
      .top-more-span {
        border-right: 1px solid #111;
        padding-right: 10px;
        padding-left: 10px;
      }
      .top-more-span:nth-last-child(1) {
        border-right: 0;
      }
      .top-item-pad {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
    .center {
      margin-top: 25px;
    }
    .top-two {
      font-size: 14px;
      text-decoration: line-through;
    }

    .bottom {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #f5f5f5;
    }

    .three-select {
      position: relative;
      width: 220px;
      height: 30px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #cccdcd;
      input {
        padding-left: 20px;
        width: 160px;
        height: 14px;
        border: 0;
        outline: none;
        background-color: #fff;
        ::placeholder {
          font-size: 14px;
          color: #999;
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .select-absoulte {
        overflow: hidden;
        background-color: #fff;
        width: 365px;
        // height: 500px;
        position: absolute;
        padding: 14px 20px 0;
        top: 29px;
        left: -1px;
        z-index: 998;
        border: 1px solid #cccdcd;
        display: none;
        .item-button {
          position: relative;
          display: inline-block;
          width: 70px;
          height: 24px;
          font-size: 14px;
          margin-right: 10px;
          border: 1px solid #cccdcd;
          text-align: center;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .absoulte-line {
          position: absolute;
          top: 36px;
          width: 100%;
          height: 2px;
          background-color: #111;
        }
      }
    }
  }

  .three-select:hover .select-absoulte {
    display: block;
  }

  .three-select:hover .three-select-absolut {
    display: block;
  }

  .item-button-bottom {
    position: absolute;
    width: 66px;
    height: 3px;
    background-color: #fff;
    top: 20px;
    left: 0;
    z-index: 999;
  }
  .item-button-click {
    border: 2px solid #111 !important;
    border-bottom: 0 !important;
  }
  .select-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 200px;
    overflow-y: scroll;
    .select-loop {
      display: inline-block;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      span {
        padding: 4px 5px;
        margin-right: 20px;
      }
    }
    // .select-loop-wang {
    //   display: block;
    //   width: 100%;
    //   span {
    //     padding: 4px 5px;
    //   }
    // }
  }

  .select-container::-webkit-scrollbar {
    width: 10px;
    height: 1px;
    // background-color: #f5f5f5;
  }

  .select-container::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/

    border-radius: 10px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    background: #bbbbbb;
  }

  .select-container::-webkit-scrollbar-track {
    /*滚动条里面轨道*/

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    background: #ededed;
  }

  //////////////////////////////////////
  .color-catagory {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border: 1px solid #cccdcd;
  }

  .four {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    margin-top: 20px;
    .four-left {
      width: 68px;
      margin-left: 14px;
      font-size: 12px;
      margin-top: 10px;
      flex-shrink: 0;
    }
    .four-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
    .four-right-item {
      position: relative;
      height: 50px;
    }
    .four-right-item-position {
      position: absolute;
      right: 12px;
      bottom: 0;
      bottom: 3px;
    }
  }
}

.five-right-item-position {
  right: 6px !important;
}

.my-border {
  display: inline-block;
  border: 1px solid #cccdcd;
  padding: 5px 9px;
  color: #333;
  margin-right: 6px;
  margin-top: 12px;
  cursor: pointer;
}

.constructer-left {
  display: block;
  margin-top: 20px !important;
}

.my-border-two {
  border: 2px solid #bba492;
}

.line-more {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #cccdcd;
  margin-top: 20px;
  img {
    width: 24px;
    height: 12px;
    position: absolute;
    left: 50px;
    top: 0;
    border-top: 1px solid transparent !important;
  }
}

.input-number {
  margin-top: 14px;
}

.five {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;

  .see-more {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-right: 20px;
  }

  .see-more-one {
    background-color: #111;
    color: #fff;
  }

  .see-more-two {
    background-color: rgba(17, 17, 17, 0.04);
    border: 1px solid #111;
  }

  .see-more-three {
    border: 1px solid #cccdcd;
  }

  .collect {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #cccdcd;
    margin-right: 20px;
  }

  .addSort {
    margin-left: 10px;
  }
}

.shop-introduce {
  margin-top: 60px !important;
  margin-bottom: 40px !important;
  font-size: 16px;
  .shop-introduce-top {
    width: 100%;
    height: 50px;
    background: #f8f8f8;
    // border: 1px solid #cccdcd;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .shop-introduce-top-item {
      width: 11%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-right: 1px solid #cccdcd;
      color: #666;
      border-top: 1px solid #cccdcd;
      border-left: 1px solid #cccdcd;
      border-bottom: 1px solid #cccdcd;
      cursor: pointer;
    }
    .introduce-item {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cccdcd;
    }
  }
  .shop-backgorund {
    background-color: #fff;
  }
}

.introduce-top {
  border-top: 4px solid #111 !important;
  color: #111 !important;
  border-bottom: 0 !important;
}

.introcude-detail {
  padding-bottom: 10px !important;
  border-bottom: 1px dotted #cccdcd;
  font-size: 14px;
  margin-bottom: 10px !important;
  .one {
    color: #111;
  }
  .two {
    color: #999;
  }
}

.shop-big-img {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

.block {
  display: block !important;
}

.pointer {
  cursor: pointer;
}
.cityClass {
  background-color: #111;
  color: #fff;
}
.none {
  display: none;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
}
/deep/ .el-dialog {
  width: 400px;
}
.domian-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 260px;
    height: 260px;
    border: 1px solid #cccdcd;
    padding: 20px;
  }
  .one {
    margin-top: 20px;
  }
}

.artist {
  display: flex;
  flex-wrap: nowrap;
  height: 220px;
  border: 1px solid #cccdcd;
  margin-top: 25px !important;
  .artist-left {
    width: 220px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .artist-right {
    padding: 30px;
    .artist-right-name {
      font-size: 20px;
      font-weight: 500;
      color: #111;
    }
    .artist-right-intro {
      font-size: 14px;
      color: #666;
      margin-top: 20px;
      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .more {
      margin-top: 40px;
      .more-item {
        cursor: pointer;
        padding-bottom: 10px;
        display: inline-block;
        border-bottom: 1px solid #111;
        .more-img {
          transform: rotate(180deg);
        }
        span {
          margin-left: 10px;
        }
      }
    }
  }
}

.estimate {
  padding: 24px 30px;
  background-color: #f8f8f8;
  font-size: 16px;
  .result {
    font-weight: bold;
    color: #111;
    padding-right: 8px;
    span {
      font-size: 14px;
      color: #666666;
      font-weight: 400;
    }
  }
}

/deep/.IntroductionText img {
  // display: block !important;
  // width: 100% !important;
  // text-align: center !important;
}

/deep/.IntroductionText p {
  font-size: 16px;
  // text-align: center;
}

.wangdianinput {
  width: 170px !important;
  overflow: hidden; /*溢出隐藏*/
  text-overflow: ellipsis; /*以省略号...显示*/
  white-space: nowrap; /*强制不换行*/
}

.selectedImg {
  border: 2px solid #111 !important;
}

#swiper1 .swiper-button-prev {
  left: 0;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #999;
}
#swiper1 .swiper-button-next {
  right: 0;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #999;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: painted;
}

.four-img-block{
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 12px;
  position: relative;
  .img-color{
    width: 100%;
    height: 100%;
  }
  .color-right-item-position{
    right: 0 !important;
    bottom: 0 !important;
  }
  .img-border{
    border: 2px solid #bba492;
  }
  .color-world{
    display: inline-block;
    white-space: nowrap;
    position: relative;
    bottom: -10px;
    background-color: #fff;
    border: 1px solid #CCCDCD;
    padding: 0 5px;
    opacity: 0;
    z-index: 10;
  }
}

.four-img-block:hover .color-world{
  opacity: 1;
}
</style>