<template>
  <div class="auction-detail">
    <div class="auction-detail-top container">
      <div class="d-sm-none d-lg-block">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/tasting-online' }"
            >品鉴在线</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/tasting-division' }"
            >品鉴师</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="center-swiper container">
        <span class="center-name">基本信息</span>
      </div>

      <!-- 基本信息 -->
      <div class="row artist-detail-top">
        <div class="auction-detail-top-left col-md-5">
          <img class="img-fluid" :src="tastingObj.image" alt="" />
        </div>

        <div class="auction-detail-top-right col-md-7">
          <div class="name">
            <span class="name-big">{{ tastingObj.name }}</span>
            <span class="name-small">{{ tastingObj.name }}</span>
          </div>
          <span class="artist-intro">{{ tastingObj.introduction }}</span>
        </div>
      </div>
    </div>

    <!-- 个人作品 -->
    <div
      class="center-swiper container"
      v-if="tastingObj.tastingWorkVOList.length > 0"
    >
      <span class="center-name">品鉴的作品</span>

      <div class="artist-works d-sm-none d-lg-block">
        <div class="swiper-container" id="swiper1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in tastingObj.tastingWorkVOList"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="artist-works-item">
                <img
                  class="img-fluid tasting-swiper-img"
                  :src="item.commodityImage"
                  alt=""
                />
                <div class="tasting-intro">
                  <div class="tasting-intro-top">
                    <span class="one">{{ item.commodityName }}</span>
                    <span class="two">{{ item.commodityIntroduction }}</span>
                  </div>
                  <div class="tasting-intro-bottom">
                    <span class="three">品鉴人：{{ item.artistName }}</span>
                    <span class="four">品鉴结果: {{ item.result }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="artist-works d-lg-none">
        <div class="row">
          <div
            class="artist-works-items"
            v-for="(item, index) in tastingObj.tastingWorkVOList"
            :key="index"
            @click="toMobileDetail(item)"
          >
            <div class="col-sm-6 artist-works-items-left">
              <img :src="item.commodityImage" alt="" />
            </div>
            <div class="col-sm-6 artist-works-items-right">
              <div class="one">
                <span>{{ item.commodityName }}</span>
              </div>
              <div class="two">
                <span>{{ item.commodityIntroduction }}</span>
              </div>
              <div class="three">
                <span>品鉴人：{{ item.artistName }}</span>
              </div>
              <div class="four">
                <span>{{ item.result }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-sm-none d-lg-block">
        <div class="arrow">
          <div class="arrow-left" id="left">
            <img
              class="img-fuild"
              src="../../assets/img/left_black.png"
              alt=""
            />
          </div>
          <div class="arrow-right" id="right">
            <img src="../../assets/img/right_black.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 拍品详情 -->
    <div class="center-swiper container">
      <span class="center-name">关于{{ name }}</span>
      <div
        v-if="tastingObj.isVideo == 0"
        class="shopping-intro col-sm-12"
        v-html="tastingObj.description"
      ></div>
      <div v-else>
        <video :src="tastingObj.video" class="artistVideo" controls></video>
      </div>
    </div>
    <div class="bottom-margin d-lg-none"></div>
  </div>
</template>


<script>
import { getArtistListDetail } from "../../assets/api/api.js";
export default {
  created() {
    this.name = this.$route.query.name;
    this.id = this.$route.query.id;
    this.getList();
  },
  data() {
    return {
      name: "",
      id: 0,
      tastingObj: {},
    };
  },
  methods: {
    async getList() {
      let res = await getArtistListDetail({
        artistId: this.id,
      });
      res = res.data.data;
      this.tastingObj = res;
      console.log(res);
      let that = this;
      this.$nextTick(function () {
        that.getSwiper();
      });
    },
    toDetail(item) {
      console.log(item);
      if (item.commodityType == 0) {
        this.$router.push({
          path: "/classic-detail",
          query: {
            id: item.commodityId,
          },
        });
      } else {
        this.$router.push({
          path: "/luxery-detail",
          query: {
            id: item.commodityId,
          },
        });
      }
    },
    toMobileDetail(item) {
      console.log(item);
      if (item.commodityType == 0) {
        this.$router.push({
          path: "/mobile/classic-detail",
          query: {
            id: item.commodityId,
          },
        });
      } else {
        this.$router.push({
          path: "/mobile/luxery-detail",
          query: {
            id: item.commodityId,
          },
        });
      }
    },
    getSwiper() {
      var swiper1 = new this.Swiper("#swiper1", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 3,
      });
      this.$("#left").click(function () {
        swiper1.slidePrev();
      });
      this.$("#right").click(function () {
        swiper1.slideNext();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.arrow {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 100px;
  cursor: pointer;
}
.artist-detail-top {
  margin-top: 60px;
}

.artist-works {
  margin-top: 50px;
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .artist-works-item {
    cursor: pointer;
    width: 95%;
    border: 1px solid #cccdcd;
    .tasting-swiper-img {
      width: 100%;
    }
    .tasting-intro {
      width: 100%;
      height: 280px;
      padding: 40px 30px;
      .tasting-intro-top {
        border-bottom: 1px dotted #ccc;
        padding-bottom: 18px;
        .one {
          display: block;
          color: #111;
          font-size: 20px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .two {
          font-size: 14px;
          color: #666;
          display: block;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      .tasting-intro-bottom {
        font-size: 14px;
        color: #666;
        .three {
          display: block;
          margin-top: 18px;
        }
        .four {
          margin-top: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
    }
  }
}

.auction-detail-top {
  margin-top: 40px;
  padding-bottom: 60px !important;
  border-bottom: 1px dotted #cccccc;
}

.auction-detail-top-left {
  img {
    width: 100%;
    box-shadow: 14px 14px 24px 0px rgba(17, 17, 17, 0.5);
  }
}

.auction-detail-top-right {
  padding: 0 25px;
  font-size: 14px;
  color: #111;
  .name {
    padding-top: 20px;
    position: relative;
    .name-big {
      font-size: 50px;
      font-weight: 500;
      color: #eee;
    }
    .name-small {
      font-size: 24px;
      color: #111;
      font-weight: 500;
      position: absolute;
      bottom: 10px;
      left: 0;
    }
  }

  .one {
    display: block;
    margin-bottom: 30px;
  }
}

.center-swiper {
  margin-top: 60px;
  margin-bottom: 30px;
  .center-name {
    font-size: 36px;
    font-weight: 500;
    color: #111;
  }
}

.el-breadcrumb {
  margin-bottom: 60px;
}

@media (min-width: 300px) and (max-width: 992px) {
  .center-swiper {
    padding-left: 15px !important;
    margin-top: 30px;
    .center-name {
      font-size: 18px !important;
    }
  }
  .auction-detail-top-right {
    .one {
      margin-bottom: 15px;
    }
  }
  .artist-works {
    margin-top: 30px;
    .artist-works-items {
      display: flex;
      margin-bottom: 20px;
      .artist-works-items-left {
        img {
          width: 100%;
        }
      }
      .artist-works-items-right {
        color: #666;
        .one {
          font-size: 14px;
          font-weight: 500;
          color: #111;
        }
        .two {
          margin-top: 10px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .four {
          margin-top: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
}

.artistVideo{
  width: 100%;
}

@media (min-width: 300px) and (max-width: 992px){
  .auction-detail{
    padding-bottom: 80px;
  }
  .name-big{
    font-size: 38px !important;
  }
  .artist-intro{
    font-size: 12px;
  }
}
</style>