<template>
  <div class="info container">
    <div class="top col-sm-12" @click="toPreview">
      <img src="../../assets/img/to-right-arrow.png" alt="" />
      <span>开票信息</span>
    </div>
    <div class="col-sm-12">
      <div class="add-new">
        <div class="add-new-add">
          <span>发票抬头<span class="redStar" v-if="flag">*</span></span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.invoiceHeader"
            :disabled="isDisabled"
          />
        </div>
        <div class="add-new-add">
          <span>税号<span class="redStar" v-if="flag">*</span></span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.taxNumber"
            :disabled="isDisabled"
          />
        </div>
        <div class="add-new-add">
          <span>开户银行</span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.bankName"
            :disabled="isDisabled"
          />
        </div>
        <div class="add-new-add">
          <span>银行账号</span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.bankAccount"
            :disabled="isDisabled"
          />
        </div>
        <div class="add-new-add">
          <span>企业地址</span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.companyAddress"
            :disabled="isDisabled"
          />
        </div>
        <div class="add-new-add">
          <span>企业电话</span>
          <input
            type="text"
            placeholder="暂未填写"
            v-model.trim="invoiceObj.companyPhone"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="info-bottom" @click="saveInvoice">
        <img v-if="flag == false" src="../../assets/img/edit.png" alt="" />
        <span v-if="flag == false">编辑</span>
        <span v-else>保存</span>
      </div>
    </div>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>

<script>
import { getInvoiceApi, editInvoice } from "../../assets/api/api.js";
export default {
  created() {
    this.getInvoinceInfo();
  },
  data() {
    return {
      invoiceObj: {
        invoiceHeader:'',
        taxNumber:'',
        bankName:'',
        bankAccount:'',
        companyAddress:'',
        companyPhone:'',
        id:''
      },
      isDisabled: true,
      flag: false,
      message: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    toPreview() {
      this.$router.push("/mine");
    },
    // 获得开票信息
    async getInvoinceInfo() {
      let res = await getInvoiceApi({
        userId: 1,
      });
      console.log(res);
      if (res.data.data) {
        res = res.data.data;
        this.invoiceObj = res;
        console.log(res);
      }
    },
    // 编辑
    async saveInvoice() {
      if (this.flag == false) {
        this.isDisabled = false;
        this.flag = true;
      } else {
        if (this.invoiceObj.invoiceHeader == "") {
          this.message = "发票抬头不能为空";
          this.showInfo();
        } else if (this.invoiceObj.taxNumber == "") {
          this.message = "税号不能为空";
          this.showInfo();
        } else {
          let res = await editInvoice({
            invoiceHeader: this.invoiceObj.invoiceHeader,
            taxNumber: this.invoiceObj.taxNumber,
            bankName: this.invoiceObj.bankName,
            bankAccount: this.invoiceObj.bankAccount,
            companyAddress: this.invoiceObj.companyAddress,
            companyPhone: this.invoiceObj.companyPhone,
            userId: 1,
            id: this.invoiceObj.id,
          });
          res = res.data;
          this.message = "编辑成功";
          this.showInfo();
          this.flag = false;
          this.isDisabled = true;
          console.log(res);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding-bottom: 80px !important;
  .top {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .add-new {
    font-size: 15px;
    font-weight: 500;
    color: #111;
    .add-new-add {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    input {
      color: #666;
      border: 0;
      outline: none;
      flex-grow: 1;
      text-align: right;
    }
    input::placeholder {
      color: #ccc;
    }
    input:disabled {
      background-color: #fff;
    }
    textarea {
      //   height: 24px;
      color: #666;
      border: 0;
      outline: none;
      flex-grow: 1;
      text-align: right;
    }
    textarea::placeholder {
      color: #ccc;
    }
  }
  .info-bottom {
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #111;
    position: fixed;
    bottom: 34px;
    left: 15px;
    right: 15px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .redStar {
    color: red;
  }
}

.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>