<template>
  <div class="col-md-10 order">
    <div class="order-fff">
      <div class="top-nav">
        <div class="top-nav-left">
          <div
            :class="{ 'left-item': true, 'select-nav': chooseNav == index }"
            v-for="(item, index) in navList"
            :key="index"
            @click="toChooseNav(index)"
          >
            <span
              :class="{ 'left-item-span': true, 'border-none': index == 4 }"
              >{{ item.text }}</span
            >
          </div>
        </div>
        <div class="top-nav-right">
          <span>状态类型</span>
          <select v-model="statusType" v-if="chooseNav == 0">
            <option value="-1">全部</option>
            <option value="0">待拍卖</option>
            <option value="1">拍卖中</option>
            <option value="2">已结束</option>
          </select>
          <select v-model="status2Type" v-if="chooseNav == 1">
            <option value="-1">全部</option>
            <option value="0">待开始</option>
            <option value="1">进行中</option>
            <option value="2">已结束</option>
          </select>
          <button @click="toSearch">查询</button>
        </div>
      </div>
      <!-- 拍卖会主体主体 -->
      <div class="discount-body" v-show="chooseNav == 0">
        <!-- <div class="row"> -->
        <div
          class="discount-item col-md-4"
          v-for="(item, index) in shopList"
          :key="index"
          @click="goAuction(item)"
        >
          <img
            class="search"
            src="../../assets/img/ic.png"
            @click="removeOne(item.id, $event)"
            alt=""
          />
          <img
            class="auction-status"
            src="../../assets/img/auction-pre.png"
            alt=""
            v-if="item.type == 0"
          />
          <img
            class="auction-status"
            src="../../assets/img/auction-loading.png"
            alt=""
            v-if="item.type == 1"
          />
          <img
            class="auction-status"
            src="../../assets/img/auction-end.png"
            alt=""
            v-if="item.type == 2"
          />
          <img class="discount-item-img img-fluid" :src="item.image" alt="" />
          <span class="one">{{ item.name }}</span>
          <span class="two three">拍品来源：{{ item.source }}</span>
          <span class="three">拍卖时间：{{ item.startTime }}</span>
          <span class="three">拍卖地点：{{ item.address }}</span>
        </div>
        <div class="null-info" v-if="shopList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
        <!-- </div> -->
      </div>
      <!-- 活动主体 -->
      <div class="activity-body" v-show="chooseNav == 1">
        <div
          class="activity-item row"
          v-for="(item, index) in activityList"
          :key="index"
          @click="toActivityDetail(item.id)"
        >
          <div class="activity-body-left col-md-6">
            <img class="img-fluid left-img" :src="item.image" alt="" />
            <img
              class="small-icon"
              v-if="item.state == 0"
              src="../../assets/img/activity-pre.png"
              alt=""
            />
            <img
              class="small-icon"
              v-if="item.state == 1"
              src="../../assets/img/activity-loading.png"
              alt=""
            />
            <img
              class="small-icon"
              v-if="item.state == 2"
              src="../../assets/img/activity-end.png"
              alt=""
            />
          </div>
          <div class="activity-body-right col-md-6">
            <span class="activity-one">{{ item.name }}</span>
            <div class="label-btn">
              <button
                v-for="(item2, index2) in item.activityLabelVOList"
                :key="index2"
              >
                {{ item2.name }}
              </button>
            </div>
            <span class="activity-two"
              >活动时间：{{ item.activityStartTime }}至{{
                item.activityEndTime
              }}</span
            >
            <span class="activity-two"
              >报名时间：{{ item.signUpStartTime }}至{{
                item.signUpEndTime
              }}</span
            >
            <span class="activity-two">活动地点：{{ item.address }}</span>
            <span class="activity-two"
              >活动名额：{{ item.signUpPeople }} / {{ item.quota }}</span
            >
            <span class="activity-two">主办方：{{ item.sponsor }}</span>
            <span class="activity-three">报名费：￥{{ item.signUpMoney }}</span>
            <button class="activity-four" v-if="item.signUpState == 0">
              立即报名
            </button>
            <button class="activity-four" v-if="item.signUpState == 1">
              报名截止
            </button>
            <button class="activity-four" v-if="item.signUpState == 2">
              已结束
            </button>
            <button class="activity-four" v-if="item.signUpState == 3">
              待开始
            </button>
            <button class="activity-four" v-if="item.signUpState == 4">
              已报名
            </button>
            <div class="line"></div>
            <div class="activity-info">
              <span class="activity-five">活动瞬间</span>
              <span v-if="item.imagesVOList">{{
                item.imagesVOList[0].createTime
              }}</span>
              <span v-else>暂无</span>
            </div>
            <div class="activity-message">
              <!-- swiper -->
              <div class="swiper-container" v-if="item.imagesVOList">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide col-md-3"
                    v-for="(item3, index3) in item.imagesVOList"
                    :key="index3"
                  >
                    <img class="item" :src="item3.url" />
                  </div>
                </div>
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
              <div v-else>
                <div class="item item-one">巨勇家居</div>
              </div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="activityList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
      </div>

      <div
        class="discount-bottom container"
        v-if="shopList.length > 0 || activityList.length > 0"
      >
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getAuctionAttentionPage,
  getActivePage,
  getAuctionAttention,
} from "../../assets/api/api.js";
export default {
  // name:'shopList',
  created() {
    this.getData();
  },
  data() {
    return {
      navList: [
        {
          text: "拍卖会",
        },
        {
          text: "活动",
        },
      ],
      shopList: [],
      activityList: [],
      chooseNav: 0,
      num: 130,
      total: 0,
      pageSize: 6,
      currentPage: 1,
      type: -1,
      statusType: -1,
      status2Type: -1,
      state: -1,
      message: "",
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    // 换页
    async handleCurrentChange(val) {
      this.currentPage = val;
      if (this.chooseNav == 0) {
        let res = await getAuctionAttentionPage({
          userId: 1,
          type: this.type,
          pageNo: val,
          pageSize: this.pageSize,
        });
        res = res.data.data;
        this.shopList = res.items;
        this.total = res.total;
      } else {
        let res = await getActivePage({
          userId: 1,
          state: this.state,
          pageNo: val,
          type: 1,
          pageSize: this.pageSize,
        });
        res = res.data.data;
        this.total = res.total;
        this.activityList = res.items;
        console.log(res);
        let that = this;
        this.$nextTick(function () {
          that.swiperChange();
        });
      }
    },
    // 获得拍卖会数据
    async getData() {
      this.currentPage = 1;
      this.pageSize = 6;
      let res = await getAuctionAttentionPage({
        userId: 1,
        type: this.type,
        pageNo: 1,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.shopList = res.items;
      this.total = res.total;
      console.log(res);
    },
    // 获得活动数据
    async getData2() {
      this.currentPage = 1;
      this.pageSize = 3;
      let res = await getActivePage({
        userId: 1,
        state: this.state,
        pageNo: 1,
        type: 1,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.total = res.total;
      this.activityList = res.items;
      console.log(res);
      let that = this;
      this.$nextTick(function () {
        that.swiperChange();
      });
    },
    toChooseNav(index) {
      this.chooseNav = index;
      console.log(index);
      if (index == 0) {
        this.getData();
      }
      if (index == 1) {
        this.getData2();
      }
    },
    swiperChange() {
      new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 4,
        observer: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    // 查询
    toSearch() {
      if (this.chooseNav == 0) {
        this.type = this.statusType;
        this.getData();
      } else {
        this.state = this.status2Type;
        this.getData2();
      }
    },
    // 删除
    async removeOne(id, e) {
      e.stopPropagation();
      let res = await getAuctionAttention({
        userId: 1,
        auctionMerchandiseId: id,
      });
      if (res.data.result == 0) {
        this.message = "删除成功";
        this.showInfo();
      }
      this.getData();
    },
    // 拍卖会详情
    goAuction(item) {
      console.log(item);
      this.$router.push({
        path: "/auction-item-detail",
        query: {
          id: item.id,
        },
      });
    },
    toActivityDetail(id) {
      this.$router.push({
        path: "/member-detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.order-fff {
  padding: 15px;
  background-color: #fff;
}
.order {
  background-color: #fff;
  padding: 0 15px 0 15px;
  background-color: #f5f5f5;
  .top-nav {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-nav-left {
      display: flex;
      align-items: center;
      height: 100%;
      .left-item {
        width: 110px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .left-item-span {
          font-size: 16px;
          text-align: center;
          border-right: 1px solid #eee;
          display: inline-block;
          width: 100%;
          color: #666;
        }
        .border-none {
          border-right: 0;
        }
      }
      .select-nav {
        font-size: 18px;
        font-weight: 500;
        color: #111;
        border-bottom: 2px solid #111;
      }
    }
    .top-nav-right {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #111;
      font-weight: bold;
      select {
        width: 200px;
        height: 30px;
        border: 2px solid #111;
        outline: none;
        padding-left: 15px;
        margin-left: 15px;
        font-weight: bold;
      }
      button {
        border: 0;
        outline: none;
        background-color: #111;
        color: #fff;
        width: 50px;
        height: 30px;
      }
    }
  }
}

.discount-body {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.discount-item:hover .search {
  opacity: 1;
}

.discount-item:hover {
  box-shadow: 0px 4px 14px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.discount-item {
  color: #111;
  font-size: 14px;
  position: relative;
  padding-bottom: 30px;
  width: 31% !important;
  margin-bottom: 40px;
  transition: all 0.3s ease;
  .search {
    position: absolute;
    top: 0;
    right: 15px;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  .discount-item-img {
    width: 100%;
    margin-bottom: 20px;
  }
  .auction-status {
    position: absolute;
    top: 24px;
    left: 7px;
    width: 100px;
    width: 80px;
  }
  .one {
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    border-bottom: 2px solid #111;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
    text-overflow: ellipsis;
  }
  .two {
    margin-top: 20px !important;
  }
  .three {
    margin-left: 20px;
    font-size: 12px;
    color: #999;
    display: block;
    margin-top: 10px;
  }
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

///////////////////////////////////////////////////////////////////
.activity-body {
  .activity-item {
    margin-top: 30px !important;
    background-color: #f8f8f8;
    .activity-body-left {
      position: relative;
      .left-img {
        width: 100%;
        height: 530px;
      }
      .small-icon {
        position: absolute;
        width: 100px;
        top: 24px;
        left: 5px;
      }
    }
    .activity-body-right {
      font-size: 12px;
      color: #444;
      padding-top: 20px;
      .activity-one {
        border-bottom: 2px solid #111;
        font-size: 14px;
        font-weight: bold;
        color: #111;
      }
      .label-btn {
        margin-top: 13px;
        margin-bottom: 20px;
        button {
          outline: none;
          border: 1px solid #bba492;
          color: #bba492;
          margin-right: 10px;
          background-color: #fff;
        }
      }
      .activity-two {
        display: block;
        margin-bottom: 10px;
      }
      .activity-three {
        font-size: 14px;
        font-weight: bold;
        color: #111;
        margin-top: 20px;
        display: block;
      }
      .activity-four {
        outline: none;
        background-color: #cccccc;
        width: 90px;
        height: 30px;
        border: 0;
        margin-top: 10px;
      }
      .line {
        width: 100%;
        border-bottom: 1px dotted #cccccc;
        margin-top: 20px;
      }
      .activity-info {
        margin-top: 10px;
        .activity-five {
          font-size: 14px;
          color: #111;
          font-weight: bold;
          margin-right: 10px;
        }
      }
      .activity-message {
        margin-top: 20px;
        .item {
          height: 127px;
          width: 127px;
          // width: 100%;
          background-color: #fff;
        }
        .item-one {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: #fff;
          background-color: #eee;
        }
      }
    }
  }
}

.col-md-3 {
  padding-left: 0;
}

.swiper-button-prev {
  left: 0;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #111;
}
.swiper-button-next {
  right: 0;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #111;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

.null-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img {
  width: 200px;
  height: 200px;
}
</style>
