import axios from 'axios'
import qs from 'qs'

// let base = 'http://192.168.0.117:8081'
let base = 'http://api.antiker.cn';

const instance = axios.create({
    withCredentials: true,
}); //重写axios

axios.defaults.withCredentials = true;

//首页
export const indexApi = () => {
    return axios.get(`${base}/index/index`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        },
    })
}

//首页
export const pageKeyWord = (params) => {
    return axios.post(`${base}/keyword/page`, qs.stringify(params),{
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        },
        
    })
}

// 登入协议
export const loginApi = (params) => {
    return axios.post(`${base}/login/getProtocol`,qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

export const basicGetApi = () => {
    return axios.post(`${base}/basic-setting/get`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


export const indexActivityApi = (params) => {
    return axios.post(`${base}/index/page/activity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


// 获得广告详情
export const getAdvertising = (params) => {
    return axios.post(`${base}/advertising/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 获得banner图
export const getBannerApi = (params) => {
    return axios.post(`${base}/advertising/get/banner`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 特价优惠
export const specialPrice = (params) => {
    return axios.post(`${base}/floor-goods/index/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//特价优惠详情
export const specialDetail = (params) => {
    return axios.post(`${base}/index/get/commodity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//限时抢购模块
export const getLimitPage = (params) => {
    return axios.post(`${base}/panic-buy/index/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//限时抢购详情
export const getLimitDetail = (params) => {
    return axios.post(`${base}/panic-buy/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//限时抢购价格
export const getLimitPrice = (params) => {
    return axios.post(`${base}/panic-buy/get/price`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//加入购物车
export const addSortApi = (params) => {
    return axios.post(`${base}/shopping-cart/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获取购物车
export const getSortApi = (params) => {
    return axios.post(`${base}/shopping-cart/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 全部购物车列表
export const getAllSortApi = (params) => {
    return axios.post(`${base}/shopping-cart/list`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 删除一个购物车
export const removeSortApi = (params) => {
    return axios.post(`${base}/shopping-cart/delete`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得商品类型
export const getShopping = (params) => {
    return axios.post(`${base}/commodity/get/value`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得商品几个
export const getShoppingPrice = (params) => {
    return axios.post(`${base}/commodity/get/price`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//判断库存是否充足
export const getShoppingInventory = (params) => {
    return axios.post(`${base}/commodity/get/inventory`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得唯一编码数据
export const getOrderUuid = (params) => {
    return axios.post(`${base}/commodity/get/uuid`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得唯一编码数据
export const getClassicLuxDetail = (params) => {
    return axios.post(`${base}/commodity/get/commodity-details`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//商品类型修改
export const editShopping = (params) => {
    return axios.post(`${base}/shopping-cart/edit/commodity-type`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//移入收藏夹
export const removeSaveShopping = (params) => {
    return axios.post(`${base}/shopping-cart/remove`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//购物车批量删除
export const removeManyShopping = (params) => {
    return axios.post(`${base}/shopping-cart/delete/list`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//购物车清空失效商品
export const removeOverShopping = (params) => {
    return axios.post(`${base}/shopping-cart/delete/commodity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//下单显示
export const getOrderThing = (params) => {
    return axios.post(`${base}/shopping-cart/ready-order`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


//获得省，市，区
export const getRegion = (params) => {
    return axios.post(`${base}/area/list/outlets`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获取网点
export const getWangdian = (params) => {
    return axios.post(`${base}/outlets/list`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 修改网点
export const editWangdian = (params) => {
    return axios.post(`${base}/shopping-cart/edit/outlets`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 修改购物车数量
export const editSortNum = (params) => {
    return axios.post(`${base}/shopping-cart/edit/quantity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 获取订单地址
export const getOrderAddress = (params) => {
    return axios.post(`${base}/user-address/list`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 绑定微信
export const bindWX = (params) => {
    return axios.post(`${base}/user/bind-account`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 绑定微信2
export const bindWX2 = (params) => {
    return axios.post(`${base}/user/weChat`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 绑定微信头像
export const bindWX3 = (params) => {
    return axios.post(`${base}/user/avatar`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得个人省市区
export const getAddress = (params) => {
    return axios.post(`${base}/area/list`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//添加订单地址
export const addAddress = (params) => {
    return axios.post(`${base}/user-address/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//编辑用户地址
export const editAddressApi = (params) => {
    return axios.post(`${base}/user-address/edit`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//删除用户地址
export const removeAddressApi = (params) => {
    return axios.post(`${base}/user-address/delete`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//解绑微信
export const removeBindApi = (params) => {
    return axios.post(`${base}/user/delete-wx`,qs.stringify(params) ,{
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//收藏
export const saveApi = (params) => {
    return axios.post(`${base}/favorites/add`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//取消收藏
export const removeSaveApi = (params) => {
    return axios.post(`${base}/favorites/delete`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//收藏列表
export const savePageApi = (params) => {
    return axios.post(`${base}/favorites/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//古典精选，现在轻奢分类
export const getClassicClassification = (params) => {
    return axios.post(`${base}/classification/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//古典精选，现在轻奢列表
export const getClassicPage = (params) => {
    return axios.post(`${base}/commodity/index/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//欧洲品牌，商品列表
export const getcBrandLifePage = (params) => {
    return axios.post(`${base}/brand-life/index/page`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//欧洲品牌，商品列表详情
export const getDetailBrand = (params) => {
    return axios.post(`${base}/brand-life/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//品鉴在线列表
export const getTastingOnline = () => {
    return axios.get(`${base}/tasting-online/get`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


//拍卖品内容列表
export const getAuctionList = (params) => {
    return axios.post(`${base}/tasting-online/page/auction-merchandise`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//拍卖详情
export const getAuctionDetailList = (params) => {
    return axios.post(`${base}/tasting-online/get/auction-merchandise`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//历史成交
export const getHistoryList = (params) => {
    return axios.post(`${base}/tasting-online/page/history`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//拍卖关注
export const getAuctionAttention = (params) => {
    return axios.post(`${base}/tasting-online/update/attention`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//拍卖关注活动
export const getAuctionAttentionPage = (params) => {
    return axios.post(`${base}/tasting-online/page/user`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//艺术家列表
export const getArtistList = (params) => {
    return axios.post(`${base}/tasting-online/page/artist`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//艺术家详情
export const getArtistListDetail = (params) => {
    return axios.post(`${base}/tasting-online/get/artist`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//会员活动列表
export const getActivityPage = (params) => {
    return axios.post(`${base}/activity/page/activity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//楼层活动列表
export const getFloorPage = (params) => {
    return axios.post(`${base}/activity/page/advertising`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获取单个会员活动
export const getActivityOne = (params) => {
    return axios.post(`${base}/activity/get/activity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//活动关注
export const getActivePage = (params) => {
    return axios.post(`${base}/activity/user/activity`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//活动关注
export const getActiveAttention = (params) => {
    return axios.post(`${base}/activity/update/attention`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//活动报名
export const getActiveSignUp = (params) => {
    return axios.post(`${base}/sign-up-activity/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//关于我们
export const getAboutUs = (params) => {
    return axios.post(`${base}/about-us/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得基本信息
export const getBasicInfoApi = (params) => {
    return axios.post(`${base}/user/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//修改昵称
export const editNameApi = (params) => {
    return axios.post(`${base}/user/nickname`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//修改邮箱
export const editMail = (params) => {
    return axios.post(`${base}/user/mail`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得开票信息
export const getInvoiceApi = (params) => {
    return axios.post(`${base}/user-invoice/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


//修改开票信息
export const editInvoice = (params) => {
    return axios.post(`${base}/user-invoice/edit`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//申请开票
export const invoiceAdd = (params) => {
    return axios.post(`${base}/invoice/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//我的发票列表
export const getMineInvoicePage = (params) => {
    return axios.post(`${base}/invoice/page/user`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//发票价格
export const getInvoicePrice = (params) => {
    return axios.post(`${base}/invoice/sum`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得验证码
export const getCode = (params) => {
    return axios.post(`${base}/login/sendMessage`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获得验证码
export const getIsLogin = (params) => {
    return axios.post(`${base}/login/getUserInfo`,qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//更换手机号
export const changePhone = (params) => {
    return axios.post(`${base}/user/phone`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//更换密码
export const changePassword = (params) => {
    return axios.post(`${base}/user/password`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//立即下单
export const orderImmediately = (params) => {
    return axios.post(`${base}/commodity/ready-order`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


//留言提交
export const contactApi = (params) => {
    return axios.post(`${base}/leave-message/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 购物须知
export const shoppingNotes = () => {
    return axios.get(`${base}/basic-setting/shopping-notes`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 售后服务
export const servicesNotes = () => {
    return axios.get(`${base}/basic-setting/after-sales-service`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 立即咨询
export const consultApi = () => {
    return axios.get(`${base}/basic-setting/image`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 去付款
export const payApi = (params) => {
    return axios.post(`${base}/order/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 限时抢购报名
export const panicAdd = (params) => {
    return axios.post(`${base}/user-panic/add`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 我的订单列表
export const myOrderPage = (params) => {
    return axios.post(`${base}/order/page/user`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 删除订单
export const deleteOrder = (params) => {
    return axios.post(`${base}/order/delete`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}


// 订单付款
export const payOrder = (params) => {
    return axios.post(`${base}/order/payOrder`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 订单详情
export const orderDetailApi = (params) => {
    return axios.post(`${base}/order/get`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 取消订单
export const removeOrder = (params) => {
    return axios.post(`${base}/order/remove`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 订单物流
export const orderLogisitic = (params) => {
    return axios.post(`${base}/logistics/order`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 发票涡流
export const invoiceLogisitic = (params) => {
    return axios.post(`${base}/logistics/invoice`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// pc付款页面
export const payAli = (params) => {
    return axios.get(`${base}/toPagePay`, {
        params: params,
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 移动付款页面
export const payMobile = (params) => {
    return axios.get(`${base}/payAli`, {
        params: params,
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//登陆模块
//用户登录
export const loginLoginApi = (params) => {
    return axios.post(`${base}/login/login`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        },
        withCredentials:true
    })
}

//用户退出
export const loginOutApi = () => {
    return axios.get(`${base}/login/loginOut`, {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获取验证码
export const loginSendMessageApi = (params) => {
    return axios.post(`${base}/login/sendMessage`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//用户注册
export const loginUserRegisterApi = (params) => {
    return axios.post(`${base}/login/userRegister`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//忘记密码身份验证
export const loginTestCodeApi = (params) => {
    return axios.post(`${base}/login/test-code`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//修改密码
export const loginEditPwdApi = (params) => {
    return axios.post(`${base}/login/editPwd`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//获取微信登陆信息
export const loginWeChatApi = (params) => {
    return axios.post(`${base}/login/weChat`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//微信扫码登录验证信息
export const callbackPcApi = (params) => {
    return axios.post(`${base}/callback/pc`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//绑定微信
export const bindAccountApi = (params) => {
    return axios.post(`${base}/callback/bind-account`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
//激活账户
export const activationApi = (params) => {
    return axios.post(`${base}/login/activation`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

// 微信pc支付
//激活账户
export const payPcWX = (params) => {
    return axios.post(`${base}/payall`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//查看微信结果
export const wxResult = (params) => {
    return axios.post(`${base}/selectOrder`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//上传文件
export const fileUpload = (params) => {
    return axios.post(`${base}/upload/file`, (params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//更改头像
export const changeAvatarInfo = (params) => {
    return axios.post(`${base}/user/updateAvatar`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}

//模块简介
export const baseIntroductionApi = (params) => {
    return axios.post(`${base}/basic-setting/introduction`, qs.stringify(params), {
        headers: {
            'x-access-token': sessionStorage.getItem("token")
        }
    })
}
