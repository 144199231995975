import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/index/index.vue'
import Advertist from '../components/index/advertist.vue'
import AdvertistShopping from '../components/index/adverShopping.vue'
import AdverActivity from '../components/index/adverActivity.vue'
import Discount from '../components/index/discount.vue'
import ShopDetail from '../components/index/shopDetail.vue'
import Sort from '../components/index/sort.vue'
import Order from '../components/index/order.vue'
import BuyLimit from '../components/index/buyLimit.vue'
import BuyLimitDetail from '../components/index/buyLimitDetail.vue'
import NewProduct from '../components/index/newProduct.vue'
import NewProductDetail from '../components/index/newProductDetail.vue'
import ClassicCollection from '../components/classic/classicCollection.vue'
import ClassicDetail from '../components/classic/classicDetail.vue'
import LuxeryCollection from '../components/luxery/luxeryCollection.vue'
import LuxeryDetail from '../components/luxery/luxeryDetail.vue'
import EuropeanFamousBrand from '../components/European/EuropeanFamousBrand.vue'
import EuropeanLife from '../components/European/EuropeanLife.vue'
import BrandDetail from '../components/European/BrandDetail.vue'
import TastingOnline from '../components/testing/tastingOnline.vue'
import Auction from '../components/testing/auction.vue'
import AuctionItem from '../components/testing/auctionItem.vue'
import AuctionItemTwo from '../components/testing/auctionItemTwo.vue'
import AuctionItemDetail from '../components/testing/auctionDetail.vue'
import Artist from '../components/testing/artist.vue'
import ArtistItem from '../components/testing/artistItem.vue'
import ArtistDetail from '../components/testing/artistDetail.vue'
import TastingDivision from '../components/testing/tastingDivision.vue'
import TastingDivisionDetail from '../components/testing/tastingDivisionDetail.vue'
import Member from '../components/member/member.vue'
import MemberDetail from '../components/member/memberDetail.vue'
import AboutUs from '../components/about/aboutUs.vue'
import MyOrder from '../components/mine/myOrder.vue'
import BasicInfo from '../components/mine/basicInfo.vue'
import Mine from '../components/mine/mine.vue'
import Invoice from '../components/mine/invoice.vue'
import Logistics from '../components/mine/logistics.vue'
import InvoiceLogistic from '../components/mine/invoiceLogistic.vue'
import Collection from '../components/mine/collection.vue'
import Activity from '../components/mine/activity.vue'
import MyActivity from '../components/mine/myActivity.vue'
import Login from '../components/login/login'
import LoginSuccess from '../components/login/loginSuccess'
import LoginSure from '../components/login/loginSure'
import OrderDetail from '../components/mine/orderDetail.vue'
import OrderInvoice from '../components/mine/orderInvoice.vue'
import MBaseInfo from '../components/mobile_mine/baseInfo.vue'
import MAddress from '../components/mobile_mine/myAddress.vue'
import MInvoiceInfo from '../components/mobile_mine/invoiceInfo.vue'
import MOrder from '../components/mobile_mine/myOrder.vue'
import MOrderDetail from '../components/mobile_mine/orderDetail.vue'
import MLogistic from '../components/mobile_mine/logistic.vue'
import MobileInvoice from '../components/mobile_mine/mobileInvoice.vue'
import MInvoice from '../components/mobile_mine/invoice.vue'
import MCollection from '../components/mobile_mine/collection.vue'
import MAttention from '../components/mobile_mine/attention.vue'
import MActivity from '../components/mobile_mine/activity.vue'
import MInvoiceManager from '../components/mobile_mine/invoiceManager.vue'
import MShopDetail from '../components/mobile_detail/shopDetail.vue'
import MSort from '../components/mobile_detail/sort.vue'
import MOrderBig from '../components/mobile_detail/order.vue'
import MClassicDetail from '../components/mobile_detail/classicDetail.vue'
import MLuxeryDetail from '../components/mobile_detail/luxeryDetail.vue'
import MLimitDetail from '../components/mobile_detail/limitDetail.vue'
import MNewProductDetail from '../components/mobile_detail/newProductDetail.vue'
import MWx from '../components/login/loginWx.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    redirect: '/index'
  },
  {
    path: '/mWx',
    component: MWx
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/loginSuccess',
    name: 'LoginSuccess',
    component: LoginSuccess
  },
  {
    path: '/login/activation',
    component: LoginSure
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/advertist',
    name: 'Advertist',
    component: Advertist,
    props: true
  },
  {
    path: '/advertistShopping',
    component: AdvertistShopping
  },
  {
    path: '/adverActivity',
    component: AdverActivity
  },
  {
    path: '/index/discount',
    name: 'Discount',
    component: Discount
  },
  {
    path: '/shop-detail',
    name: 'ShopDetail',
    component: ShopDetail
  },
  {
    path: '/sort',
    name: 'Sort',
    component: Sort
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/index/buy-limit',
    name: 'BuyLimit',
    component: BuyLimit
  },
  {
    path: '/index/buy-limit/detail',
    name: 'BuyLimitDetail',
    component: BuyLimitDetail
  },
  {
    path: '/index/new-product',
    name: 'NewProduct',
    component: NewProduct
  },
  {
    path: '/index/new-product/detail',
    name: 'NewProductDetail',
    component: NewProductDetail
  },
  {
    path: '/classic-collection',
    name: 'ClassicCollection',
    component: ClassicCollection
  },
  {
    path: '/classic-detail',
    name: 'ClassicDetail',
    component: ClassicDetail
  },
  {
    path: '/luxery-collection',
    name: 'LuxeryCollection',
    component: LuxeryCollection
  },
  {
    path: '/luxery-detail',
    component: LuxeryDetail,
    name: 'LuxeryDetail'
  },
  {
    path: '/European-famous-brand',
    component: EuropeanFamousBrand,
    name: 'EuropeanBrand'
  },
  {
    path: '/European-life',
    component: EuropeanLife,
    name: 'EuropeanLife'
  },
  {
    path: '/tasting-online',
    component: TastingOnline,
    name: 'TastingOnline'
  },
  {
    path: '/auction',
    component: Auction
  },
  {
    path: '/auction-item',
    component: AuctionItem
  },
  {
    path: '/auction-item-two',
    component: AuctionItemTwo
  },
  {
    path: '/auction-item-detail',
    component: AuctionItemDetail
  },
  {
    path: '/artist',
    component: Artist
  },
  {
    path: '/artist-item',
    component: ArtistItem
  },
  {
    path: '/artist-detail',
    component: ArtistDetail
  },
  {
    path: '/tasting-division',
    component: TastingDivision
  },
  {
    path: '/tasting-division-detail',
    component: TastingDivisionDetail
  },
  {
    path: '/member',
    component: Member,
    name: "Member"
  },
  {
    path: '/member-detail',
    component: MemberDetail
  },
  {
    path: '/about-us',
    component: AboutUs,
    name: 'AboutUs'
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine,
    children: [{
        path: '/my-order',
        component: MyOrder
      },
      {
        path: '/basic-info',
        component: BasicInfo
      },
      {
        path: '/invoice',
        component: Invoice
      },
      {
        path: '/collection',
        component: Collection
      },
      {
        path: '/activity',
        component: Activity
      },
      {
        path: '/my-activity',
        component: MyActivity
      },
      {
        path: '/order-detail',
        component: OrderDetail
      },
      {
        path: '/order-invoice',
        component: OrderInvoice
      }
    ],
    redirect: '/basic-info'
  },
  {
    path: '/logistics',
    name: 'Logistics',
    component: Logistics
  },
  {
    path: '/invoiceLogistic',
    component: InvoiceLogistic
  },
  {
    path: '/mine/baseInfo',
    component: MBaseInfo
  },
  {
    path: '/mine/address',
    component: MAddress
  },
  {
    path: '/mine/invoice',
    component: MInvoiceInfo
  },
  {
    path: '/mine/minvoice',
    component: MobileInvoice
  },
  {
    path: '/mine/myOrder',
    component: MOrder
  },
  {
    path: '/mine/order-detail',
    component: MOrderDetail
  },
  {
    path: '/mine/logistic',
    component: MLogistic
  },
  {
    path: '/mine/add-invoice',
    component: MInvoice
  },
  {
    path: '/mine/collection',
    component: MCollection
  },
  {
    path: '/mine/attention',
    component: MAttention
  },
  {
    path: '/mine/activity',
    component: MActivity
  },
  {
    path: '/mine/invoice-manage',
    component: MInvoiceManager
  },
  {
    path: '/mobile/shopDetail',
    component: MShopDetail
  },
  {
    path: '/mobile/sort',
    component: MSort
  },
  {
    path: '/mobile/order',
    component: MOrderBig
  },
  {
    path: '/mobile/classic-detail',
    component: MClassicDetail
  },
  {
    path: '/mobile/luxery-detail',
    component: MLuxeryDetail
  },
  {
    path: '/mobile/limit-detail',
    component: MLimitDetail
  },
  {
    path: '/mobile/newProduct-detail',
    component: MNewProductDetail
  },
  {
    path: '/brandDetail',
    component: BrandDetail
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next();
});

export default router