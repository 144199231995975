<template>
  <div class="info container">
    <div class="row">
      <div class="top col-sm-12" @click="toPreview">
        <img src="../../assets/img/to-right-arrow.png" alt="" />
        <span>注册信息</span>
      </div>
      <div class="m_container col-sm-12">
        <div class="m_container-item">
          <span>用户昵称</span>
          <input type="text" :value="baseObj.nickname" disabled  />
          <span class="option" @click="editName">修改</span>
        </div>
        <div class="m_container-item">
          <span>绑定邮箱</span>
          <input type="text" :value="baseObj.mail" disabled />
          <span class="option" @click="editEmail">修改</span>
        </div>
        <div class="m_container-item">
          <span>绑定手机</span>
          <input type="text" :value="baseObj.phone" disabled />
          <span class="option" @click="editPhone">修改</span>
        </div>
        <div class="m_container-item">
          <span>密码</span>
          <input type="password" :value="baseObj.password" disabled />
          <span class="option" @click="editPassword">修改</span>
        </div>
        <div class="m_container-item">
          <span>微信</span>
          <!-- <input
            v-if="baseObj.openid == null"
            type="text"
            value="未绑定"
            disabled
          /> -->
          <div class="already-bind" v-if="baseObj.openid != null">
            <img :src="baseObj.avatar" alt="" />
            <span>{{ baseObj.nickname }}</span>
          </div>
          <input v-else type="text" value="未绑定" disabled />
          <span
            class="option"
            v-if="baseObj.openid != null"
            @click="torevolveBind"
            >解绑</span
          >
          <span class="option" v-else @click="wxLogin">修改</span>
        </div>
      </div>
    </div>
    <!-- 弹出框1 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer1"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="drawer-top">
          <span>修改用户昵称</span>
          <img
            src="../../assets/img/login_close.png"
            @click="drawer1 = false"
            alt=""
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>用户昵称</span>
          <input
            type="text"
            v-model.trim="newName"
            :placeholder="baseObj.nickname"
          />
        </div>
        <div class="col-sm-12 drawer-sure">
          <div class="sure" @click="sureName">确定</div>
        </div>
      </div>
    </el-drawer>

    <!-- 弹出框2 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer2"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="drawer-top">
          <span>修改邮箱</span>
          <img
            src="../../assets/img/login_close.png"
            @click="drawer2 = false"
            alt=""
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>登录密码</span>
          <input
            type="password"
            v-model.trim="password1"
            placeholder="请输入登录密码"
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>新邮箱</span>
          <input
            type="text"
            v-model.trim="newEmail"
            placeholder="请输入新邮箱"
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>确认新邮箱</span>
          <input
            type="text"
            v-model.trim="sureNewEmail"
            placeholder="请再次输入新邮箱"
          />
        </div>
        <div class="col-sm-12 drawer-sure">
          <div class="sure" @click="sureEmail">确定</div>
        </div>
      </div>
    </el-drawer>

    <!-- 弹出框3 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer3"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="drawer-top">
          <span>更换手机号</span>
          <img
            src="../../assets/img/login_close.png"
            @click="drawer3 = false"
            alt=""
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>原手机号</span>
          <input type="password" disabled :placeholder="baseObj.phone" />
        </div>
        <div class="drawer-container col-sm-12">
          <span>新手机号</span>
          <input
            type="text"
            v-model.trim="newPhone"
            placeholder="请输入新手机号"
          />
          <span class="code_one" v-if="codeFlag">{{ codeTime }}</span>
          <span class="code" v-else @click="getCheckCode">获取验证码</span>
        </div>
        <div class="drawer-container col-sm-12">
          <span>验证码</span>
          <input type="text" v-model.trim="code" placeholder="请输入验证码" />
        </div>
        <div class="col-sm-12 drawer-sure">
          <div class="sure" @click="surePhone">确定</div>
        </div>
      </div>
    </el-drawer>

    <!-- 弹出框4 -->
    <el-drawer
      size="380px"
      :visible.sync="drawer4"
      :with-header="false"
      direction="btt"
    >
      <div class="drawer-domain">
        <div class="drawer-top">
          <span>重置密码</span>
          <img
            src="../../assets/img/login_close.png"
            @click="drawer4 = false"
            alt=""
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>登入密码</span>
          <input
            type="password"
            v-model.trim="password"
            placeholder="请输入登入密码"
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>新密码</span>
          <input
            type="password"
            v-model.trim="newPassword"
            placeholder="请输入新密码"
          />
        </div>
        <div class="drawer-container col-sm-12">
          <span>确认新密码</span>
          <input
            type="password"
            v-model.trim="newPassword2"
            placeholder="请再次输入新密码"
          />
        </div>
        <div class="col-sm-12 drawer-sure">
          <div class="sure" @click="surePassword">确定</div>
        </div>
      </div>
    </el-drawer>

    <!-- 弹出框5 -->
    <el-drawer
      size="480px"
      :visible.sync="drawer5"
      :with-header="false"
      direction="btt"
    >
      <div id="login_container"></div>
    </el-drawer>
    <!-- 提示 -->
    <div class="message" id="message">{{ message }}</div>

    <!-- <div class="showBox">
      <div class="showBox-one">是否改用微信名称和头像</div>
      <div class="showBox-two">
        <button>取消</button>
        <button>确定</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  getBasicInfoApi,
  editNameApi,
  editMail,
  getCode,
  changePhone,
  changePassword,
  removeBindApi,
  bindWX2,
  bindWX3,
} from "../../assets/api/api.js";
import {
  resultEmail,
  resultPhone,
} from "../../uitils/check.js";
export default {
  created() {
    if(this.$route.query.type == 1){
      this.$confirm('是否改用微信名称和头像', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass:'message-logout'
        }).then(() => {
          bindWX3().then((res)=>{
            if(res.data.result == 0){
              this.message = '已成功绑定';
              this.showInfo();
              this.$router.push("/mine/baseInfo");
              this.getInfo();
            }
          })
        }).catch(() => {  
        });
    }
    this.getInfo();
  },
  data() {
    return {
      baseObj: {},
      message: "",
      drawer1: false,
      drawer2: false,
      drawer3: false,
      drawer4: false,
      drawer5: false,
      newName: "",
      password1: "",
      newEmail: "",
      sureNewEmail: "",
      newPhone: "",
      code: "",
      password: "",
      newPassword: "",
      newPassword2: "",
      codeFlag: false,
      codeTime: 180,
    };
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    // 获取信息
    async getInfo() {
      let res = await getBasicInfoApi({
        id: 1,
      });
      res = res.data.data;
      this.baseObj = res;
      console.log(this.baseObj);
    },
    // 编辑昵称
    editName() {
      this.drawer1 = true;
    },
    // 提交昵称
    async sureName() {
      if (this.newName != "") {
        let res = await editNameApi({
          id: 1,
          nickname: this.newName,
        });
        res = res.data;
        this.message = res.msg;
        this.showInfo();
        this.drawer1 = false;
        this.getInfo();
      } else {
        this.message = "昵称不能为空";
        this.showInfo();
      }
    },
    // 编辑昵称
    editEmail() {
      this.drawer2 = true;
    },
    // 提交邮箱
    async sureEmail() {
      if (this.password1 == "") {
        this.message = "请输入登入密码";
        this.showInfo();
      } else if (this.newEmail == "" || this.sureNewEmail == "") {
        this.message = "请输入新邮箱";
        this.showInfo();
      } else if (
        (resultEmail(this.newEmail) && resultEmail(this.sureNewEmail)) == false
      ) {
        this.message = "邮箱格式错误";
        this.showInfo();
      } else if (this.newEmail != this.sureNewEmail) {
        this.message = "两次输入不一致";
        this.showInfo();
      } else {
        let res = await editMail({
          id: 1,
          password: this.password1,
          newMail: this.newEmail,
          twoMail: this.sureNewEmail,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = res.msg;
          this.showInfo();
        } else {
          this.message = "邮箱修改成功";
          this.showInfo();
          this.drawer2 = false;
          this.getInfo();
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
        console.log(res);
      }
    },
    // 编辑手机号
    editPhone() {
      this.drawer3 = true;
    },
    // 提交手机号
    async surePhone() {
      if (resultPhone(this.newPhone) == false) {
        this.message = "请输入手机号";
        this.showInfo();
      } else if (this.code == "") {
        this.message = "请输入验证码";
        this.showInfo();
      } else {
        let res = await changePhone({
          id: 1,
          phone: this.newPhone,
          code: this.code,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = "设置失败";
          this.showInfo();
        }
        if (res.result == 0) {
          this.message = "设置成功";
          this.showInfo();
          this.drawer3 = false;
          this.getInfo();
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
        console.log(res);
      }
    },
    // 获得验证码
    async getCheckCode() {
      if (resultPhone(this.newPhone)) {
        this.codeFlag = true;
        let that = this;
        let tid = setInterval(() => {
          that.codeTime--;
          if (that.codeTime == 0) {
            that.codeFlag = true;
            that.codeTime = 180;
            clearInterval(tid);
          }
        }, 1000);
        let res = await getCode({
          phone: this.newPhone,
        });
        console.log(res);
      } else {
        this.message = "请输入正确的手机号";
        this.showInfo();
      }
    },
    // 编辑密码
    editPassword() {
      this.drawer4 = true;
    },
    // 重置密码
    async surePassword() {
      if (this.password == "") {
        this.message = "请输入登入密码";
        this.showInfo();
      } else if (this.newPassword == "" || this.newPassword2 == "") {
        this.message = "请输入新密码";
        this.showInfo();
      } else if (this.newPassword != this.newPassword2) {
        this.message = "两次密码输入不一致";
        this.showInfo();
      } else {
        let res = await changePassword({
          id: 1,
          password: this.password,
          newPassword: this.newPassword,
          twoPassword: this.newPassword2,
        });
        res = res.data;
        if (res.result == 1) {
          this.message = res.msg;
          this.showInfo();
        }
        if (res.result == 0) {
          this.message = res.msg;
          this.showInfo();
          this.drawer4 = false;
          this.getInfo();
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
      }
    },
    // 返回上一级
    toPreview() {
      this.$router.push("/mine");
    },
    // 微信登入
    wxLogin() {
      if (!this.baseObj.openid) {
        this.drawer5 = true;
        bindWX2().then((res) => {
          if (res.data.result == 0) {
            console.log("...");
            let info = res.data.data;
            //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
            var obj = new WxLogin({
              self_redirect: false, //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
              id: "login_container", //需要承载二维码的容器id
              appid: info.appid,
              scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
              redirect_uri: 'http://www.antiker.cn/mWx', //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
              state: info.state,
              style: "black",
              href: "",
            });
          } else {
            Message.error(res.data.msg);
          }
        });
      }
    },
    // 解绑
    torevolveBind() {
      removeBindApi().then((res) => {
        console.log(res);
        if (res.data.result == 0) {
          this.message = "解绑成功";
          this.showInfo();
          this.getInfo();
        } else {
          this.message = "解绑失败";
          this.showInfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  .top {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
      transform: rotate(180deg);
    }
    span {
      font-size: 16px;
      color: #111;
      font-weight: 500;
    }
  }
  .m_container {
    font-size: 15px;
    .m_container-item {
      margin: 40px 0;
      display: flex;
      align-items: center;
      .option {
        border-bottom: 1px solid #111;
        margin-left: 5px;
        font-size: 12px;
        height: 17px;
      }
      input{
        color: #666;
      }
    }
  }
  input {
    border: 0;
    outline: none;
    flex-grow: 1;
    text-align: right;
    color: #ccc;
  }
}

input::placeholder {
  color: #ccc;
}

////////////////////////////////////
.message {
  font-size: 16px;
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}

/deep/ .el-drawer {
  border-radius: 20px 20px 0 0;
}

.domain {
  position: relative;
}

.drawer-top {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #111;
  position: relative;
  img {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 20px;
  }
}
.drawer-container {
  display: flex;
  margin: 40px 0;
  font-size: 14px;
  color: #111;
  span {
    display: inline-block;
    width: 110px;
  }
  input {
    flex-grow: 1;
    text-align: left;
    color: #111;
  }
}
.drawer-sure {
  position: absolute;
  bottom: 40px;
}
.sure {
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  background-color: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code {
  display: inline !important;
  width: 65px !important;
  font-size: 12px;
  border-bottom: 1px solid #111;
}

.code_one {
  width: 30px !important;
}
#login_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.already-bind {
  margin-left: auto;
  margin-right: 10px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.showBox{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  height: 100px;
  background-color: #eee;
  font-size: 16px;
  border-radius: 10px;
  z-index: 9999;
  .showBox-one{
    text-align: center;
    margin-top: 20px;
  }
  .showBox-two{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button{
      border: 0;
      outline: none;
      margin-right: 20px;
      border: 1px solid #111;
      background-color: #111;
      color: #fff;
    }
  }
}

</style>

<style>
  .message-logout{
    width: 300px !important;
  }
</style>