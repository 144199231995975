<template>
  <div>111</div>
</template>

<script>
import { bindWX } from "../../assets/api/api.js";
export default {
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      var size = document.documentElement.clientWidth || window.innerWidth;
      bindWX({
        code: this.$route.query.code,
        state: this.$route.query.state,
      }).then((res) => {
        if (res.data.result == 0) {
          if (size > 992) {
            this.$router.push({
              path: "/basic-info",
              query: {
                type: 1,
              },
            });
          }else{
            this.$router.push({
              path: "/mine/baseInfo",
              query: {
                type: 1,
              },
            });
          }
        }
      });
    },
  },
};
</script>