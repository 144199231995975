<template>
  <!-- 待付款 -->
  <div class="order-container-two col-md-10">
    <!-- ///////////////发票////////////////// -->
    <!-- 申请发票 -->
    <div class="order">
      <div class="address">
        <div class="top">
          <span class="get-info">申请发票</span>
        </div>
        <div class="has-address">
          <div class="has-invoice has-address-left">
            <div class="has-invoice-left invoice-item">
              <label for="">订单编号：</label>
              <input type="text" :value="invoiceNumber" disabled />
            </div>
            <div class="has-invoice-left invoice-item">
              <label for="">发票种类：</label>
              <select v-model="invoiceCatalog">
                <option value="0">纸质发票</option>
                <option value="1">电子发票</option>
              </select>
            </div>
            <div class="has-invoice-left invoice-item">
              <label for="">发票类型：</label>
              <select v-model="invoiceType">
                <option value="0">普通发票</option>
                <option value="1">增值税专用发票</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 开票信息 企业-->
    <div class="order">
      <div class="address">
        <div class="top">
          <span class="get-info">开票信息</span>
        </div>
        <div class="has-address">
          <div class="has-invoice has-address-left">
            <div class="has-invoice-left invoice-item">
              <label for=""><span>*</span>抬头类型：</label>
              <div class="radio-choose">
                <div class="item-choose-item radio-choose-item">
                  <input
                    class="radioType"
                    type="radio"
                    name="invoiceType"
                    value="0"
                    v-model="radioType"
                  />
                  <span>企业</span>
                </div>
                <div class="item-choose-item radio-choose-item" v-if="this.invoiceType !=1">
                  <input
                    class="radioType"
                    type="radio"
                    name="invoiceType"
                    value="1"
                    v-model="radioType"
                  />
                  <span>个人</span>
                </div>
              </div>
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for=""><span>*</span>发票抬头：</label>
              <input
                type="text"
                placeholder="请输入发票抬头"
                v-model="invoiceInfo.invoice"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for=""><span>*</span>税号：</label>
              <input
                type="text"
                placeholder="请输入税号"
                v-model="invoiceInfo.tax"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for="">开户银行：</label>
              <input
                type="text"
                placeholder="请输入开户银行"
                v-model="invoiceInfo.bank"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for="">银行账号：</label>
              <input
                type="text"
                placeholder="请输入银行账号"
                v-model="invoiceInfo.bankCount"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for="">企业地址：</label>
              <input
                type="text"
                placeholder="请输入企业地址"
                v-model="invoiceInfo.comAddress"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 0">
              <label for="">企业电话：</label>
              <input
                type="text"
                placeholder="请输入企业电话"
                v-model="invoiceInfo.comTel"
              />
            </div>
            <div class="has-invoice-left invoice-item" v-show="radioType == 1">
              <label for=""><span>*</span>姓名：</label>
              <input
                type="text"
                placeholder="请输入姓名"
                v-model="invoicePerson"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 地址信息 -->
    <div class="invoice order">
      <div class="address">
        <div class="top">
          <span class="get-info">收票人信息</span>
        </div>
        <div class="no-address" v-if="addressList.length == 0 || isAddAdress">
          <div class="no-address-left">
            <!-- 地区 -->
            <div class="one">
              <span>所在地区：</span>
              <!-- 省 -->
              <select
                :class="{ 'order-select': true, nine: proviceIndex == -1 }"
                @click="chooseProvince"
                v-model="proviceIndex"
              >
                <option value="-1" disabled>请选择</option>
                <option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :value="item.provinceValue"
                >
                  {{ item.provinceName }}
                </option>
              </select>
              <!-- 市 -->
              <select
                :class="{ 'order-select': true, nine: this.cityIndex == -1 }"
                @click="chooseCity"
                v-model="cityIndex"
              >
                <option value="-1" disabled>请选择</option>
                <option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :value="item.cityValue"
                >
                  {{ item.cityName }}
                </option>
              </select>
              <!-- 区 -->
              <select
                :class="{
                  'order-select': true,
                  nine: this.regionIndex == -1,
                }"
                @click="chooseRegion"
                v-model="regionIndex"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in regionList"
                  :key="index"
                  :value="item.districtValue"
                >
                  {{ item.districtName }}
                </option>
              </select>
            </div>
            <!-- 详细地址 -->
            <div class="two">
              <span>详细地址：</span>
              <textarea
                class="detail_address"
                type="text"
                placeholder="详细地址，街道、门牌号"
                v-model="detailAddress"
              />
            </div>
            <!-- 详细地址 -->
            <div class="three">
              <div class="three-left">
                <span>收货人：</span>
                <input
                  type="text"
                  placeholder="输入收货人姓名"
                  v-model="humanName"
                />
              </div>
              <div class="three-right">
                <span>手机号码：</span>
                <input
                  type="text"
                  placeholder="输入收货人手机号码"
                  v-model="phone"
                />
              </div>
            </div>
          </div>

          <div class="no-address-right">
            <div class="top">
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_s.png"
                alt=""
                v-if="totalChoose"
                @click="totalChoose = false"
              />
              <img
                class="checkbox-img"
                src="../../assets/img/icon_sel_n.png"
                alt=""
                @click="totalChoose = true"
                v-else
              />
              <span style="margin-left: 10px">设为默认</span>
            </div>
            <div class="bottom">
              <div class="save" @click="saveOneAddress">保存地址</div>
              <div class="save cancel" @click="isAddAdress = false">取消</div>
            </div>
          </div>
        </div>
        <!-- 有地址 -->
        <div
          class="has-address"
          v-if="addressList.length > 0 && isAddAdress == false"
        >
          <div class="has-address-left">
            <div class="top-address">
              <img src="../../assets/img/default_address.png" alt="" />
              <span class="deafult-address" v-if="addressOneObj.isDefault == 1"
                >默认地址</span
              >
              <button class="modift-btn" @click="editOneAddress">修改</button>
            </div>
            <div class="center">
              <span class="one">收货人:</span>
              <span>{{ addressOneObj.username }}</span>
            </div>
            <div class="center">
              <span class="one">联系方式:</span>
              <span>{{ addressOneObj.phone }}</span>
            </div>
            <div class="center">
              <span class="one">收货地址：</span>
              <span>{{
                addressOneObj.provinceName +
                addressOneObj.cityName +
                addressOneObj.districtName
              }}</span>
            </div>
          </div>
          <div class="has-address-right">
            <span @click="centerDialogVisible = true">地址切换</span>
            <button @click="addOneAddress">新建地址</button>
          </div>
          <!-- 选择地址对话框 -->
          <div class="has-address-dialog">
            <el-dialog
              title="选择地址"
              :visible.sync="centerDialogVisible"
              center
            >
              <!-- <span>需要注意的是内容是默认不居中的</span> -->
              <div
                v-for="(item, index) in addressList"
                :key="index"
                @click="chooseOneAddress(index)"
                :class="{
                  'address-item': true,
                  'active-address-item': chooseAddress == index,
                }"
              >
                <div class="address-item-item">
                  <span class="address-item-item-one">收货人：</span>
                  <span>{{ item.username }}</span>
                </div>
                <div class="address-item-item">
                  <span class="address-item-item-one">联系方式：</span>
                  <span>{{ item.phone }}</span>
                </div>
                <div class="address-item-item">
                  <span class="address-item-item-one">收货地址：</span>
                  <span>{{
                    item.provinceName + item.cityName + item.districtName
                  }}</span>
                </div>
                <div class="icon-true" v-if="chooseAddress == index">
                  <img src="../../assets/img/address_choose.png" alt="" />
                </div>
                <div class="default-address-span" v-if="item.isDefault == 1">
                  默认地址
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <button class="sure" @click="centerDialogVisible = false">
                  确 定
                </button>
                <button class="cancel" @click="centerDialogVisible = false">
                  取 消
                </button>
              </span>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>

    <button class="immediately-btn" @click="toAddInvoice">立即申请</button>
    <div class="message" id="message">{{ message }}</div>
  </div>
</template>


<script>
import {
  getAddress,
  getOrderAddress,
  addAddress,
  editAddressApi,
  invoiceAdd,
  getInvoiceApi
} from "../../assets/api/api.js";
import { resultPhone } from "../../uitils/check.js";
export default {
  created() {
    let arr = this.$route.query.arr;
    this.invoiceNumber = arr;
    this.getInfo()
    this.getTotalAddress();
  },
  data() {
    return {
      message:'',
      invoiceNumber: "",
      invoiceCatalog: 1,
      invoiceType: 0,
      radioType: 0,
      invoiceInfo: {
        invoice: "",
        tax: "",
        bank: "",
        bankCount: "",
        comAddress: "",
        comTel: "",
      },
      invoicePerson: "",
      addressList: [],
      isAddAdress: false,
      provinceList: [],
      cityList: [],
      regionList: [],
      proviceIndex: -1,
      cityIndex: -1,
      regionIndex: -1,
      detailAddress: "",
      humanName: "",
      phone: "",
      totalChoose: false,
      chooseAddress: 0,
      addressOneObj: {},
      isEdit: false,
      centerDialogVisible: false,
    };
  },
  watch: {
    proviceIndex: function () {
      this.cityIndex = -1;
      this.regionIndex = -1;
    },
    cityIndex: function () {
      this.regionIndex = -1;
    },
  },
  methods: {
    // 显示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    getInfo(){
      getInvoiceApi().then((res)=>{
        console.log(res);
        if(res.data.result == 0){
          res = res.data.data;
          this.invoiceInfo.invoice = res.invoiceHeader;
          this.invoiceInfo.tax = res.taxNumber;
          this.invoiceInfo.bank = res.bankName;
          this.invoiceInfo.bankCount = res.bankAccount;
          this.invoiceInfo.comAddress = res.companyAddress;
          this.invoiceInfo.comTel = res.companyPhone
        }
        
      })
    },
    // 获得所有地址
    async getTotalAddress() {
      let res = await getOrderAddress({ userId: 1 });
      res = res.data.data;
      this.addressList = res;
      if (!this.isEdit) {
        this.addressOneObj = this.addressList[0];
      }
      console.log(res);
    },
    // 选择省
    async chooseProvince() {
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
    },

    // 选择市
    async chooseCity() {
      console.log(this.proviceIndex);
      let res = await getAddress({ provinceValue: this.proviceIndex });
      res = res.data.data;
      this.cityList = res;
    },

    // 选择区
    async chooseRegion() {
      let res = await getAddress({
        provinceValue: this.proviceIndex,
        cityValue: this.cityIndex,
      });
      res = res.data.data;
      this.regionList = res;
    },
    // 选中一个地址
    chooseOneAddress(index) {
      this.chooseAddress = index;
      this.addressOneObj = this.addressList[index];
    },
    // 新增地址
    addOneAddress() {
      this.isAddAdress = true;
      this.isEdit = false;
      this.proviceIndex = -1;
      this.humanName = "";
      this.phone = "";
      this.detailAddress = "";
      this.totalChoose = false;
    },
    // 新增地址
    async saveOneAddress() {
      if (this.isEdit) {
        if (
          this.regionIndex != -1 &&
          this.detailAddress != "" &&
          resultPhone(this.phone) &&
          this.humanName != ""
        ) {
          let obj = {
            addressDetailed: this.detailAddress,
            areaValue: this.regionIndex,
            isDefault: this.totalChoose ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.humanName,
            id: this.addressOneObj.id,
          };
          let res = await editAddressApi(obj);
          console.log(res);
        }
      } else {
        if (
          this.regionIndex != -1 &&
          this.detailAddress != "" &&
          resultPhone(this.phone) &&
          this.humanName != ""
        ) {
          let obj = {
            addressDetailed: this.detailAddress,
            areaValue: this.regionIndex,
            isDefault: this.totalChoose ? 1 : 0,
            phone: this.phone,
            userId: 1,
            username: this.humanName,
          };
          let res = await addAddress(obj);
          console.log(res);
        }
      }
      this.isAddAdress = false;
      this.getTotalAddress();
      // this.isEdit = false;
    },
    // 修改一个地址
    async editOneAddress() {
      this.isAddAdress = true;
      this.isEdit = true;
      this.detailAddress = this.addressOneObj.addressDetailed;
      this.totalChoose = this.addressOneObj.isDefault == 1 ? true : false;
      this.humanName = this.addressOneObj.username;
      this.phone = this.addressOneObj.phone;
      this.proviceIndex = this.addressOneObj.provinceValue;
      let res = await getAddress();
      res = res.data.data;
      this.provinceList = res;
      this.cityIndex = this.addressOneObj.cityValue;
      let res1 = await getAddress({ provinceValue: this.proviceIndex });
      res1 = res1.data.data;
      this.cityList = res1;
      this.regionIndex = this.addressOneObj.districtValue;
      let res2 = await getAddress({
        provinceValue: this.proviceIndex,
        cityValue: this.cityIndex,
      });
      res2 = res2.data.data;
      this.regionList = res2;
    },
    // 立即申请
    toAddInvoice() {
      if (this.radioType == 0) {
        if (this.invoiceInfo.invoice == "" || this.invoiceInfo.tax == "") {
          this.message = '请输入必填信息';
          this.showInfo();
        } else if (this.addressOneObj == undefined) {
          this.message = '请输入地址';
          this.showInfo();
        } else {
          this.addInvoice();
        }
      } else {
        if (this.invoicePerson == "") {
          this.message = '请输入必填信息';
          this.showInfo();
        } else if (this.addressOneObj == undefined) {
          this.message = '请输入地址';
          this.showInfo();
        } else {
          this.addInvoice();
        }
      }
    },
    async addInvoice() {
      let res = await invoiceAdd({
        isType: this.invoiceCatalog,
        type: this.invoiceType,
        invoiceHeader:
          this.radioType == 0 ? this.invoiceInfo.invoice : this.invoicePerson,
        taxNumber: this.radioType == 0 ? this.invoiceInfo.tax : "",
        bankName: this.radioType == 0 ? this.invoiceInfo.bank : "",
        bankAccount: this.radioType == 0 ? this.invoiceInfo.bankCount : "",
        companyAddress: this.radioType == 0 ? this.invoiceInfo.comAddress : "",
        companyPhone: this.radioType == 0 ? this.invoiceInfo.comTel : "",
        numberList: this.invoiceNumber.split(","),
        userAddressId: this.addressOneObj.id,
        userId: 1,
      });
      console.log(res);
      if(res.data.result == 0){
        this.message = "申请成功";
        this.showInfo();
        this.$router.push('/invoice');
      }else{
        this.message = "申请失败";
      }
    },
  },
};
</script>


<style lang="less" scoped>
.order-container-two {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  .item {
    border: 1px solid #cccdcd;
    .top {
      height: 45px;
      background-color: #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .top-center {
        margin-right: auto;
        margin-left: 40px;
      }
    }
    .center {
      font-size: 14px;
      color: #111;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      .center-left {
        .center-left-item {
          .one {
            display: inline-block;
            width: 70px;
            margin-bottom: 10px;
            color: #999;
          }
        }
      }
      .center-right {
        .center-right-item {
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-end;
          .one {
            display: inline-block;
            width: 70px;
            text-align: right;
            color: #999;
          }
          .two {
            display: inline-block;
            width: 100px;
            text-align: right;
          }
          .three {
            color: #ce2829;
            font-weight: bold;
          }
        }
        .center-right-line {
          width: 255px;
          border-bottom: 1px dotted #cccdcd;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        .right-btn {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          button {
            border: 0;
            outline: none;
            width: 120px;
            height: 40px;
          }
          .cancel {
            background-color: #fff;
            border: 1px solid #111;
          }
          .sure {
            background-color: #111;
            color: #fff;
          }
        }
      }
    }
    .bottom {
      font-size: 12px;
      color: #111;
      .bottom-title {
        height: 40px;
        background-color: #f3f3f3;
        display: flex;
        // justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          text-align: center;
        }
        .one {
          width: 29%;
        }
        .two {
          width: 17%;
        }
        .three {
          width: 17%;
        }
        .four {
          width: 17%;
        }
        .five {
          width: 20%;
        }
      }
      .bottom-container {
        .bottom-container-item {
          height: 150px;
          display: flex;
          align-items: center;
          border-bottom: 1px dotted #cccdcd;
          .one {
            display: flex;
            align-items: center;
            width: 29%;
            img {
              width: 100px;
              height: 100px;
              border: 1px solid #cccdcd;
              margin-left: 20px;
            }
            .one-right {
              margin-left: 15px;
              .one-item {
                font-size: 14px;
                font-weight: bold;
              }
              .two-item {
                color: #666;
                display: block;
                margin-top: 10px;
              }
            }
          }
          .two {
            width: 17%;
            display: flex;
            justify-content: center;
            color: #666;
          }
          .three {
            width: 20%;
            display: flex;
            justify-content: center;
            color: #666;
          }
        }
        .bottom-container-item:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
}

///////////////////////////////////////////////
.order {
  font-size: 14px;
  color: #111;
  .address {
    border: 1px solid #cccdcd;
    .top {
      padding: 14px 20px;
      background-color: #f3f3f3;
      border-bottom: 1px solid #cccdcd;
    }
    .no-address {
      padding: 30px 30px;
      display: flex;
    }
  }
}
.has-invoice {
  border-right: 0 !important;
}
.has-address {
  padding: 30px 30px;
  display: flex;
  .top-address {
    margin-bottom: 20px;
    img {
      width: 24px;
      height: 25px;
    }
    .deafult-address {
      margin-left: 10px;
    }
    .modift-btn {
      display: inline-block;
      width: 60px;
      height: 28px;
      background: #ffffff;
      border: 1px solid #bba492;
      outline: none;
      margin-left: 20px;
      color: #bba492;
      font-size: 12px;
    }
  }
  .has-address-left {
    width: 80%;
    border-right: 1px dotted #cccdcd;
    .has-invoice-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .radio-choose {
        display: flex;
        .radio-choose-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          span {
            margin-left: 10px;
          }
        }
      }
    }
    .has-invoice-left:nth-last-child(1) {
      margin-bottom: 0;
    }
    label {
      margin-bottom: 0;
      width: 80px;
      text-align: right;
      span {
        color: #ce2829;
      }
    }
    input {
      outline: none;
      border: 1px solid #cccdcd;
      height: 30px;
      margin-left: 10px;
      flex-grow: 1;
      padding: 0 10px;
    }
    input:focus {
      outline: 1px solid #111;
    }
    select {
      outline: none;
      border: 1px solid #cccdcd;
      height: 30px;
      margin-left: 10px;
      flex-grow: 1;
      padding-left: 10px;
    }
    .radioType {
      width: 24px;
      height: 24px;
      border: 0 !important;
    }
    .radioType:focus {
      outline: none !important;
    }
    // .radioType:checked{
    //   background-color: #111 !important;
    // }
  }

  .has-address-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin-top: 20px;
      width: 144px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #111111;
      outline: none;
    }
    span {
      font-size: 12px;
    }
  }

  .center {
    margin-top: 20px;
    .one {
      display: inline-block;
      width: 70px;
    }
  }
}

.order-select {
  width: 190px;
  margin-left: 15px;
  outline: none;
  color: #999;
  padding: 2px 5px;
}

.two {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
}

.detail_address {
  width: 600px;
  margin-left: 15px;
  height: 70px;
  outline: none;
  resize: none;
  padding-left: 7px;
}

.detail_address::placeholder {
  color: #999;
}

.three {
  margin-top: 20px;
  display: flex;
  .three-left {
    margin-left: 13px;
    input {
      margin-left: 15px;
      outline: none;
      width: 240px;
      padding-left: 5px;
    }
  }
  .three-right {
    margin-left: 42px;
    span {
      margin-right: 10px;
    }
    input {
      width: 240px;
      padding-left: 5px;
      outline: none;
    }
  }
}

.no-address-right {
  margin-left: 40px;
  border-left: 1px dotted #cccdcd;
  padding-left: 40px;
  .top {
    margin-top: 30px;
    background-color: #fff !important;
    border: 0 !important;
    padding: 0 !important;
    .get-info {
      margin-left: 10px;
    }
  }
  .bottom {
    display: flex;
    margin-top: 40px;
    background-color: #fff;
    .save {
      width: 120px;
      height: 42px;
      color: #fff;
      background-color: #111;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      color: #111;
      margin-left: 20px;
      background-color: #fff;
      border: 1px solid #111111;
    }
  }
}

.checkbox-img {
  width: 18px;
  height: 18px;
}

//////////////////////////////////
//dialog部分
.address-item {
  padding: 15px 24px;
  border: 1px solid #cccdcd;
  color: #111;
  font-size: 14px;
  position: relative;
  margin-top: 15px;
  .address-item-item {
    display: flex;
    margin-top: 5px;
    .address-item-item-one {
      display: inline-block;
      width: 70px;
      flex-shrink: 0;
    }
    .address-item-item-one:first-child {
      margin: 0;
    }
  }
}
.address-item:nth-child(1) {
  margin: 0;
}
.active-address-item {
  border: 2px solid #111 !important;
}

.icon-true {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dialog-footer {
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 14px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

.default-address-span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #bba492;
}

/////////////////////////////////
.immediately-btn {
  width: 170px;
  height: 50px;
  background-color: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.nine {
  color: #999;
}

/deep/ .el-dialog {
  width: 600px;
}

.discount-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}
.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 9999;
}
</style>