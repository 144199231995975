<template>
  <div
    :class="{
      'discount-container': true,
      discount_mobile: isMobile,
      pcDiscount: !isMobile,
    }"
    v-on:scroll.passive="onScroll"
    ref="box"
  >
    <div class="discount-poster">
      <img class="img-fluid" :src="bannerImg" alt="" />
    </div>
    <div class="container discount-body pointer">
      <div class="row">
        <div
          class="col-lg-4 col-xl-3 col-sm-6"
          v-for="(item, index) in specialPriceList"
          :key="index"
        >
          <div class="discount-item" @click="toDetail(item.cid)">
            <div class="sanjiao d-sm-none d-lg-block"></div>
            <img
              class="search d-sm-none d-lg-block"
              src="../../assets/img/search.png"
            />
            <img class="discount-item-img img-fuild" :src="item.image" alt="" />
            <span class="one">{{ item.name }}</span>
            <span class="two">￥{{ item.specialOfferMin }}</span>
            <div class="d-sm-none d-lg-block">
              <div class="see-more">查看详情</div>
            </div>
          </div>
        </div>
        <div class="null-info" v-if="specialPriceList.length == 0">
          <img src="../../assets/img/all-null.png" alt="" />
          <span>这里空空的什么也没有~</span>
        </div>
      </div>
    </div>
    <div class="d-sm-none d-lg-block">
      <div class="discount-bottom container" v-if="specialPriceList.length > 0">
        <el-pagination
          @current-change="handleCurrentChange"
          class="discount-bottom-pagation"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
        >
        </el-pagination>
        <span class="page-span">共{{ total }}个</span>
      </div>
    </div>
    <div class="loading" :class="{ 'loading-hidden': loading }">
      {{ addMore ? "加载中" : "没有啦 o(╥﹏╥)o" }}
    </div>
  </div>
</template>

<script>
import { specialPrice,getBannerApi } from "../../assets/api/api.js";
export default {
  name:'shopList',
  created() {
    this.getSpecialPriceList();
    this.getBanner();
    if ((document.documentElement.clientWidth || window.innerWidth) < 993) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      pageSize: 12,
      specialPriceList: [],
      total: 0,
      isMobile: false,
      // 添加更多标记
      addMore: true,
      // loading显示标记
      loading: true,
      pageNo: 1,
      bannerImg:''
    };
  },
  methods: {
    getBanner(){
      getBannerApi({advertisingId:this.$route.query.id}).then((res)=>{
        console.log(res);
        this.bannerImg = res.data.data;
      })
    },
    async getSpecialPriceList() {
      let res = await specialPrice({
        type: 0,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      console.log(res);
      this.specialPriceList = this.specialPriceList.concat(res.items);
      this.total = res.total;
    },
    async handleCurrentChange(val) {
      let res = await specialPrice({
        type: 0,
        pageNo: val,
        pageSize: this.pageSize,
      });
      res = res.data.data;
      this.specialPriceList = res.items;
      console.log(res);
    },
    // 去详情
    toDetail(id) {
      if (this.isMobile) {
        this.$router.push({
          path: "/mobile/shopDetail",
          query: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          path: "/shop-detail",
          query: {
            id: id,
          },
        });
      }
    },
    // 滚动
    onScroll() {
      if (
        this.$refs.box.scrollTop + window.innerHeight - 63 >
        this.$refs.box.scrollHeight - 30
      ) {
        this.pageNo++;
        if (this.specialPriceList.length < this.total) {
          this.loading = false;
          this.getSpecialPriceList();
          console.log("...");
        } else {
          this.loading = false;
          this.addMore = false;
        }
        console.log("...");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.discount-container {
}
.discount_mobile {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.discount-poster {
  width: 100%;
  img {
    width: 100%;
    max-height: 580px;
  }
}
.pcDiscount {
  padding-bottom: 90px;
}
.row {
  margin: 0;
}

.discount-body {
  margin-top: 80px;
}

.discount-item:hover .sanjiao {
  opacity: 1;
}

.discount-item:hover .search {
  opacity: 1;
}

.discount-item:hover .see-more {
  opacity: 1;
}

.discount-item:hover {
  box-shadow: 0px 4px 14px 0px rgba(153, 153, 153, 0.4);
  transform: translate(1px, 1px);
}

.discount-item {
  color: #111;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px;
  margin-bottom: 30px;
  .sanjiao {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    border: 45px solid transparent;
    border-top-color: #bba492;
    border-left-color: #bba492;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .search {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 24px;
    height: 24px;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .discount-item-img {
    max-width: 294px;
    // height: 294px;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .one {
    font-size: 16px;
    font-weight: 500;
    width: 90%;
    display: block;
    margin: 10px auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
  }
  .two {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    width: 90%;
    margin: 0 auto;
    .three {
      text-decoration: line-through;
      font-size: 14px;
      color: #999;
      margin-left: 10px;
    }
  }
}

@media (min-width: 300px) and (max-width: 992px) {
  .discount-body {
    margin-top: 30px;
  }
  .discount-item {
    padding: 0;
    .discount-item-img {
      width: 100%;
      margin-top: 0;
    }
    .one {
      width: 90%;
      text-align: center;
      height: 18px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .two {
      width: 100%;
    }
  }
}

.see-more {
  width: 100%;
  height: 60px;
  color: #fff;
  background-color: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin: 30px auto;
  opacity: 0;
  transition: all 0.5s ease;
}
.discount-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  .discount-bottom-pagation {
    display: flex;
    justify-content: center;
    .active {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.page-span {
  font-size: 14px;
  color: #111;
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

/deep/ .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  text-align: center;
}

/deep/ .el-pager li.active {
  background-color: #111;
  border-radius: 50%;
  color: #fff;
}

/deep/ .el-pagination .btn-next .el-icon {
  font-size: 16px;
}

/deep/ .el-pagination .btn-prev .el-icon {
  font-size: 16px;
}

.loading {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: #cccccc;
  margin-bottom: 5px;
}

.loading-hidden {
  display: none;
}

.null-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
  margin-bottom: 20px;
}

.null-info img {
  width: 200px;
  height: 200px;
}

@media (min-width: 300px) and (max-width: 992px){
  .discount-item{
    .one{
      font-size: 14px;
    }
    .two{
      font-size: 14px;
    }
  }
}
</style>