<template>
  <div class="shop-detail-top">
    <div class="container">
      <div class="row">
        <!-- 图像放大镜 -->
        <div class="col-md-5 shop-detail-top-left">
          <div id="box">
            <img class="show-img" :src="chooseImg" alt="" id="smallImage" />
            <img
              src="http://file.antiker.cn/fba6388858bb4acb85ad07bd8a702f2d.png"
              class="bimg-icon"
              v-if="videoImage && selectImgId == -1"
              @click="openVideo"
            />
            <video
              :src="video"
              class="swiper-video"
              controls
              v-show="videoChoose"
              id="sVideo"
            ></video>
            <div id="move" v-if="!videoChoose"></div>
            <div id="bimg" v-if="!videoChoose">
              <img :src="chooseImg" alt="" id="b_bimg" />
            </div>
          </div>

          <div class="shop-detail-img-item">
            <div class="swiper-container" id="swiper1">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-if="videoImage">
                  <img
                    :src="videoImage"
                    alt=""
                    @click="imgChoose(-1)"
                    :class="{ selectedImg: selectImgId == -1 }"
                  />
                  <img
                    src="http://file.antiker.cn/81b9870b08fc43a9a8cdafef5c06ecac.png"
                    alt=""
                    class="video-icon"
                  />
                </div>
                <div
                  class="swiper-slide"
                  v-for="(item, index) in imgList"
                  :key="index"
                >
                  <img
                    :src="item"
                    alt=""
                    @click="imgChoose(index)"
                    :class="{ selectedImg: selectImgId == index }"
                  />
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="col-md-7 shop-detail-main">
          <span class="one">{{ name }}</span>
          <span class="two">{{ introduction }}</span>
          <!-- 价格 -->
          <div class="three">
            <div class="top">
              <span class="top-top">价格</span>
              <span class="top-item">￥</span>
              <span class="top-price">{{ nowPrice }}</span>
            </div>
            <div class="top center">
              <span class="top-top">原价</span>
              <span class="top-two">￥{{ prePrice }}</span>
            </div>
            <div class="top center">
              <span class="top-top">开奖日期</span>
              <span class="top-three">{{ paincTime }}</span>
            </div>
            <div class="top center">
              <span class="top-top">中奖名额</span>
              <span class="top-three">{{ paincNum }}</span>
            </div>
            <div class="top center">
              <span class="top-top">已经报名</span>
              <span class="top-three">{{ alreadyNum }}</span>
            </div>
            <div class="top center bottom pointer">
              <span class="top-top">选择网点</span>
              <div class="three-select" id="wangdian" v-show="wangdianName!=0">
                <input
                  type="text"
                  placeholder="请输入网点"
                  @click="chooseArea"
                  class="pointer wangdianinput"
                  :value="wangdianName"
                />
                <img
                  src="../../assets/img/up_black.png"
                  alt=""
                  v-if="isClick"
                  @click="chooseArea"
                />
                <img
                  src="../../assets/img/down_black.png"
                  alt=""
                  v-else
                  @click="chooseArea"
                />
                <div class="three-select-absolut" v-if="isClick"></div>
                <div :class="{ 'select-absoulte': true, block: isClick }" v-if="isClick">
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 1,
                    }"
                    data-num="1"
                    @click="chooseSelected"
                  >
                    <span data-num="1" @click="chooseSelected">
                      {{ province }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 1 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 2,
                      none: province == '请选择',
                    }"
                    data-num="2"
                    @click="chooseSelected"
                  >
                    <span data-num="2" @click="chooseSelected">
                      {{ city }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 2 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 3,
                      none: city == '请选择',
                    }"
                    data-num="3"
                    @click="chooseSelected"
                  >
                    <span data-num="3" @click="chooseSelected">
                      {{ region }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 3 }"
                    ></div>
                  </div>
                  <div
                    :class="{
                      'item-button': true,
                      'item-button-click': chooseSelect == 4,
                      none: region == '请选择',
                    }"
                    data-num="4"
                    @click="chooseSelected"
                  >
                    <span data-num="4" @click="chooseSelected">
                      {{ wangdian }}
                    </span>
                    <div
                      :class="{ 'item-button-bottom': chooseSelect == 4 }"
                    ></div>
                  </div>
                  <div class="absoulte-line"></div>
                  <!-- 省市区 -->
                  <div class="select-container">
                    <div
                      v-for="(item, index) in areaList"
                      :key="index"
                      class="select-loop"
                    >
                      <template v-if="chooseSelect == 1">
                        <span
                          @click="
                            toProvice(item.provinceName, item.provinceValue)
                          "
                          :class="{
                            cityClass: item.provinceValue == provinceIndex,
                          }"
                          >{{ item.provinceName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 2">
                        <span
                          @click="toCity(item.cityName, item.cityValue)"
                          :class="{ cityClass: item.cityValue == cityIndex }"
                          >{{ item.cityName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 3">
                        <span
                          @click="
                            toRegion(item.districtName, item.districtValue)
                          "
                          :class="{
                            cityClass: item.districtValue == regionIndex,
                          }"
                          >{{ item.districtName }}</span
                        >
                      </template>
                      <template v-if="chooseSelect == 4">
                        <span
                          @click="
                            toWangdian(item.name, item.areaValue, item.id)
                          "
                          :class="{
                            cityClass: item.areaValue == wangdianIndex,
                            block: true,
                          }"
                          >{{ item.name }}</span
                        >
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <span style="margin-left:3px" v-show="wangdianName==0">暂无网点</span>
            </div>
          </div>
          <!-- 结构 -->
          <div
            class="four"
            v-for="(item, index) in detailLeft"
            :key="index"
            :class="{ none: index > 1 && isshow }"
          >
            <div class="four-left constructer-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="four-right">
              <template v-for="(item2, index2) in detailRight">
                <div :key="index2">
                    <div
                      class="four-right-item"
                      @click="chooseConst(item, item2, index2)"
                      v-if="item.id == item2.specificationId&&(item2.image==null)"
                    >
                      <span
                        :class="{
                          'my-border': true,
                          'my-border-two': item2.flag,
                        }"
                        >{{ item2.value }}</span
                      >
                      <img
                        class="
                          four-right-item-position
                          five-right-item-position
                        "
                        src="../../assets/img/detail-choose.png"
                        alt=""
                        v-if="item2.flag"
                      />
                    </div>
                    <div class="four-right-item" @click="chooseConst(item, item2, index2)" v-if="item.id == item2.specificationId&&(item2.image!=null)">
                      <span class="four-img-block">
                        <img :src="item2.image" alt="" :class="{'img-color':true,'img-border':item2.flag}">
                        <img
                        class="
                          four-right-item-position
                          color-right-item-position
                        "
                        src="../../assets/img/detail-choose.png"
                        alt=""
                        v-if="item2.flag"
                      />
                      <span class="color-world">{{item2.value}}</span>
                      </span>
                    </div>
                  </div>
              </template>
            </div>
          </div>

          <div class="line-more" v-if="isshow">
            <img
              class="pointer"
              src="../../assets/img/arrow-down-three.png"
              @click="isshow = false"
              alt=""
            />
          </div>

          <!-- 按钮 -->
          <div class="five">
            <div
              class="see-more see-more-one pointer"
              @click="toOrder"
              v-if="state == 2"
            >
              <span>我要报名</span>
              <span v-if="cautionMoney > 0">保证金￥{{ cautionMoney }}</span>
            </div>
            <div
              class="see-more see-more-one pointer"
              @click="toGetAward"
              v-if="
                state == 1 &&
                itemObj.userPanicVO &&
                itemObj.userPanicVO.isJackpot == 1
              "
            >
              <span>去领奖</span>
            </div>
            <div class="see-more see-more-three pointer" @click="toConsult">
              立即咨询
            </div>
          </div>
        </div>
      </div>
      <!-- 商品介绍 -->
      <div class="row shop-introduce">
        <div class="shop-introduce-top">
          <div
            data-num="1"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 1,
              'shop-backgorund':chooseNum == 1
            }"
            @click="toIntroduce"
          >
            商品介绍
          </div>
          <div
            data-num="2"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 2,
              'shop-backgorund':chooseNum == 2
            }"
            @click="toIntroduce"
          >
            规格与包装
          </div>
          <div
            data-num="3"
            :class="{
              'shop-introduce-top-item': true,
              'introduce-top': chooseNum == 3,
              'shop-backgorund':chooseNum == 3
            }"
            @click="toIntroduce"
          >
            其他说明
          </div>
          <div class="introduce-item"></div>
        </div>
      </div>
      <!-- 介绍详情 -->
      <div
        class="IntroductionText"
        v-html="itemObj.commodityIntroduction"
        v-if="chooseNum == 1"
      ></div>
      <div
        class="IntroductionText"
        v-html="itemObj.specificationPackage"
        v-if="chooseNum == 2"
      ></div>
      <div
        class="IntroductionText"
        v-html="itemObj.description"
        v-if="chooseNum == 3"
      ></div>
    </div>
    <div id="myForm"></div>

    <!-- 绑定微信 -->
    <div class="item-one bind-wechart">
      <el-dialog title="立即咨询" :visible.sync="dialogVisible" center>
        <div class="domian-dialog">
          <div class="domian-dialog-img">
            <img :src="consultImg" alt="" />
          </div>
          <span class="one">扫描二维码，或者公众号搜索“巨永环境”</span>
        </div>
      </el-dialog>
    </div>

    <!-- 选择地址 -->
    <div class="has-address-dialog">
      <el-dialog title="选择地址" :visible.sync="centerDialogVisible" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <div
          v-for="(item, index) in totalAddress"
          :key="index"
          :class="{
            'address-item': true,
            'active-address-item': chooseAddress == index,
          }"
          @click="tochooseOneAddress(index)"
        >
          <div class="address-item-item">
            <span class="address-item-item-one">收货人：</span>
            <span>{{ item.username }}</span>
          </div>
          <div class="address-item-item">
            <span class="address-item-item-one">联系方式：</span>
            <span>{{ item.phone }}</span>
          </div>
          <div class="address-item-item">
            <span class="address-item-item-one">收货地址：</span>
            <span>{{
              oneAddressObj.provinceName +
              oneAddressObj.cityName +
              oneAddressObj.districtName +
              oneAddressObj.addressDetailed
            }}</span>
          </div>
          <div class="icon-true">
            <img
              v-if="chooseAddress == index"
              src="../../assets/img/address_choose.png"
              alt=""
            />
          </div>
          <div v-if="item.isDefault == 1" class="default-address-span">
            默认地址
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="toSaveOneAddress">确 定</button>
          <button class="cancel" @click="centerDialogVisible = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>

    <!-- 无地址 -->
    <div class="no-address-dialog">
      <el-dialog title="报名表单" :visible.sync="centerDialogVisible2" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <div class="dialog-container">
          <span>接收中奖信息的手机号码</span>
          <el-form ref="form" label-width="80px" label-position="left">
            <el-form-item label="手机号码">
              <el-input
                v-model="phone"
                placeholder="输入接收中奖信息的手机号"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dottat-line"></div>
        <span class="getInfo">收货信息</span>
        <el-form ref="form" label-width="80px" label-position="left">
          <el-form-item class="locationItem" label="所在地区">
            <div class="select-area">
              <select
                :class="{ ccccolor: province1Index == -1 }"
                v-model="province1Index"
                @click="choooseProvince"
              >
                <option value="-1" disabled>请选择</option>
                <option
                  v-for="(item, index) in province1List"
                  :key="index"
                  :value="item.provinceValue"
                >
                  {{ item.provinceName }}
                </option>
              </select>
              <select
                :class="{ ccccolor: city1Index == -1 }"
                v-model="city1Index"
                @click="chooseCity"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in city1List"
                  :key="index"
                  :value="item.cityValue"
                >
                  {{ item.cityName }}
                </option>
              </select>
              <select
                :class="{ ccccolor: region1Index == -1 }"
                v-model="region1Index"
                @click="chooseRegion"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in region1List"
                  :key="index"
                  :value="item.districtValue"
                >
                  {{ item.districtName }}
                </option>
              </select>
            </div>
          </el-form-item>

          <el-form-item>
            <textarea
              v-model.trim="newdetailAddress"
              class="my-textarea"
              placeholder="详细地址，街道、门牌号"
            ></textarea>
          </el-form-item>

          <el-form-item label="收货人">
            <el-input
              placeholder="输入接收中奖信息的姓名"
              v-model.trim="newUserName"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              placeholder="输入接收中奖信息的手机号"
              v-model.trim="newPhone"
            ></el-input>
          </el-form-item>
          <div class="pay-way" v-if="cautionMoney > 0">
            <div class="pay-way-item">
              <el-form ref="form" label-width="80px" label-position="left">
                <el-form-item label="保证金">
                  <el-input :value="'￥' + cautionMoney" disabled></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="pay-way-item">
              <el-form ref="form" label-width="80px" label-position="left">
                <el-form-item label="支付方式">
                  <div class="circle-total">
                    <div class="circle-item">
                      <img src="../../assets/img/circle_y.png" v-if="payAli" />
                      <img
                        src="../../assets/img/circle_n.png"
                        v-else
                        @click="
                          payAli = true;
                          payWeChart = false;
                        "
                      />
                      <span>支付宝</span>
                    </div>
                    <div class="circle-item circle-total-domain">
                      <img
                        src="../../assets/img/circle_y.png"
                        v-if="payWeChart"
                      />
                      <img
                        src="../../assets/img/circle_n.png"
                        v-else
                        @click="
                          payAli = false;
                          payWeChart = true;
                        "
                      />
                      <span>微信</span>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="provideOne">提 交</button>
          <button class="cancel" @click="centerDialogVisible2 = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>

    <!-- 有地址选择 -->
    <div class="no-address-dialog">
      <el-dialog title="报名表单" :visible.sync="centerDialogVisible3" center>
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <div class="dialog-container">
          <span>接收中奖信息的手机号码</span>
          <el-form ref="form" label-width="80px" label-position="left">
            <el-form-item label="手机号码">
              <el-input
                v-model="phone"
                placeholder="输入接收中奖信息的手机号"
              ></el-input>
            </el-form-item>
          </el-form>

          <div class="dottat-line"></div>

          <div class="detail-info">
            <div class="detail-info-top">
              <span class="detail-info-top-left">收货信息</span>
              <div class="detail-info-top-right" @click="addOneAddress">
                <span style="margin-right: 8px">+</span>
                <span>新建地址</span>
              </div>
            </div>
            <div class="detail-info-bottom">
              <div class="detail-info-bottom-left">
                <div class="item">
                  <span class="one">收货人</span>
                  <span class="two">{{ oneAddressObj.username }}</span>
                </div>
                <div class="item">
                  <span class="one">联系方式</span>
                  <span class="two">{{ oneAddressObj.phone }}</span>
                </div>
                <div class="item">
                  <span class="one">收货地址</span>
                  <span class="two">{{
                    oneAddressObj.provinceName +
                    oneAddressObj.cityName +
                    oneAddressObj.districtName +
                    oneAddressObj.addressDetailed
                  }}</span>
                </div>
              </div>
              <div
                class="detail-info-bottom-right"
                @click="centerDialogVisible = true"
              >
                <img src="../../assets/img/to-right-arrow.png" alt="" />
              </div>
            </div>
          </div>

          <div class="dottat-line" v-if="cautionMoney > 0"></div>
          <div class="pay-way" v-if="cautionMoney > 0">
            <div class="pay-way-item">
              <el-form ref="form" label-width="80px" label-position="left">
                <el-form-item label="保证金">
                  <el-input :value="'￥' + cautionMoney" disabled></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="pay-way-item">
              <el-form ref="form" label-width="80px" label-position="left">
                <el-form-item label="支付方式">
                  <div class="circle-total">
                    <div class="circle-item">
                      <img src="../../assets/img/circle_y.png" v-if="payAli" />
                      <img
                        src="../../assets/img/circle_n.png"
                        v-else
                        @click="
                          payAli = true;
                          payWeChart = false;
                        "
                      />
                      <span>支付宝</span>
                    </div>
                    <div class="circle-item circle-total-domain">
                      <img
                        src="../../assets/img/circle_y.png"
                        v-if="payWeChart"
                      />
                      <img
                        src="../../assets/img/circle_n.png"
                        v-else
                        @click="
                          payAli = false;
                          payWeChart = true;
                        "
                      />
                      <span>微信</span>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="provideTwo">提 交</button>
          <button class="cancel" @click="centerDialogVisible3 = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>

    <!-- 新建地址 -->
    <div class="no-address-dialog">
      <el-dialog
        title="新建地址"
        :visible.sync="centerDialogVisible4"
        center
        @close="closeDialog4"
      >
        <!-- <span>需要注意的是内容是默认不居中的</span> -->
        <el-form ref="form" label-width="80px" label-position="left">
          <el-form-item class="locationItem" label="所在地区">
            <div class="select-area">
              <select
                :class="{ ccccolor: province1Index == -1 }"
                v-model="province1Index"
                @click="choooseProvince"
              >
                <option value="-1" disabled>请选择</option>
                <option
                  v-for="(item, index) in province1List"
                  :key="index"
                  :value="item.provinceValue"
                >
                  {{ item.provinceName }}
                </option>
              </select>
              <select
                :class="{ ccccolor: city1Index == -1 }"
                v-model="city1Index"
                @click="chooseCity"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in city1List"
                  :key="index"
                  :value="item.cityValue"
                >
                  {{ item.cityName }}
                </option>
              </select>
              <select
                :class="{ ccccolor: region1Index == -1 }"
                v-model="region1Index"
                @click="chooseRegion"
              >
                <option value="-1">请选择</option>
                <option
                  v-for="(item, index) in region1List"
                  :key="index"
                  :value="item.districtValue"
                >
                  {{ item.districtName }}
                </option>
              </select>
            </div>
          </el-form-item>

          <el-form-item>
            <textarea
              class="my-textarea"
              v-model="addDetailAddress"
              placeholder="详细地址，街道、门牌号"
            ></textarea>
          </el-form-item>

          <el-form-item label="收货人">
            <el-input
              v-model="addUserName"
              placeholder="输入接收中奖信息的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              v-model="addPhone"
              placeholder="输入接收中奖信息的手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="defaultAddress">
              <img
                src="../../assets/img/icon_sel_s.png"
                alt=""
                v-if="totalChoose"
                @click="totalChoose = false"
              />
              <img
                src="../../assets/img/icon_sel_n.png"
                alt=""
                v-else
                @click="totalChoose = true"
              />
              <span>设为默认地址</span>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <button class="sure" @click="sureAddAddress">提 交</button>
          <button class="cancel" @click="centerDialogVisible4 = false">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>

    <div class="message" id="message">{{ message }}</div>

    <div class="scanPay" v-show="isshowScan">
      <span>扫码付款</span>
      <img class="closeIMg" src="../../assets/img/ic_menu_close.png" alt="" @click="isshowScan = false">
      <div id="qrcode" ref="qrcode"></div>
      <div class="isPay" @click="seeStatus">如果已付款，请点击</div>
    </div>
  </div>
</template>


<script>
import {
  consultApi,
  getRegion,
  getWangdian,
  getShoppingPrice,
  getLimitDetail,
  getAddress,
  getOrderAddress,
  addAddress,
  panicAdd,
  getLimitPrice,
  payPcWX,
  wxResult
} from "../../assets/api/api.js";
import { resultPhone } from "../../uitils/check.js";
import QRCode from "qrcodejs2";
export default {
  created() {
    this.getDetailList();
  },
  data() {
    return {
      qid: 0,
      id: 0,
      mid: "",
      wid: 0,
      imgList: [],
      areaList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      wangdianList: [],
      areaList: [],
      detailLeft: [],
      detailRight: [],
      commodityList: [],
      chooseImg: "",
      num: 1,
      chooseNum: 1,
      chooseSelect: 1,
      introduction: "",
      name: "",
      nowPrice: "",
      prePrice: "",
      colorChoose: -1,
      province: "省份",
      city: "城市",
      region: "区域",
      wangdian: "网点",
      provinceIndex: 0,
      cityIndex: 0,
      regionIndex: 0,
      wangdianIndex: 0,
      wangdianName: "",
      isClick: false,
      isshow: true,
      message: "",
      // 判断是否能下单
      isOrder: false,
      dialogVisible: false,
      // 下单唯一id
      randomId: "",
      cautionMoney: 0,
      // 咨询图片
      consultImg: "",
      // 开奖日期
      paincTime: "",
      // 中奖名额
      paincNum: 0,
      // 已经报名
      alreadyNum: 0,
      // dialog
      centerDialogVisible: false,
      centerDialogVisible2: false,
      centerDialogVisible3: false,
      centerDialogVisible4: false,
      totalAddress: [],
      province1List: [],
      province1Index: -1,
      city1List: [],
      city1Index: -1,
      region1List: [],
      region1Index: -1,
      oneAddressObj: {},
      phone: "",
      chooseAddress: 0,
      addDetailAddress: "",
      addUserName: "",
      addPhone: "",
      totalChoose: false,
      newdetailAddress: "",
      newUserName: "",
      newPhone: "",
      payAli: true,
      payWeChart: false,
      state: 0,
      itemObj: {},
      isshowScan: false,
      orderNUmber:'',
      selectImgId:0,

      video: "",
      videoImage: "",
      videoChoose: false,
    };
  },

  mounted() {
    this.getMove();
  },

  methods: {
    getMove() {
      if (!this.videoChoose) {
        this.$("#wangdian").hover(this.chooseArea);
        let that = this;
        var box = document.getElementById("box");
        var move = document.getElementById("move");
        var bimg = document.getElementById("bimg");
        var b_bimg = document.getElementById("b_bimg");
        var smallImage = document.getElementById("smallImage");
        //鼠标移入  移出
        box.onmouseover = function () {
          //遮挡层出现
          move.style = "display:block";
          bimg.style = "display:block";
        };
        box.onmouseout = function () {
          move.style = "display:none";
          bimg.style = "display:none";
        };
        //鼠标移动
        box.onmousemove = function (e) {
          //   var e = event || window.event;
          //事件发生时  相对于视窗 y轴的距离
          var y = e.clientY;
          var x = e.clientX;
          let t = that.$("#box").offset().left;
          let l = that.$("#box").offset().top;

          var left = x - t - move.offsetWidth / 2;
          var top = y - l - move.offsetHeight / 2;

          if (top <= 0) {
            top = 0;
          } else if (top >= box.offsetHeight - move.offsetHeight) {
            top = box.offsetHeight - move.offsetHeight;
          }
          if (left <= 0) {
            left = 0;
          } else if (left >= box.offsetWidth - move.offsetWidth) {
            left = box.offsetWidth - move.offsetWidth;
          }

          move.style.left = left + "px";
          move.style.top = top + "px";
          //
          // 计算比率
          // 鼠标移动的距离 占 可移动距离  的 比例
          // 3. 大图片的移动距离 = 遮挡层移动距离 * 大图片最大移动距离 / 遮挡层的最大移动距离

          var w = left / (box.offsetWidth - move.offsetWidth);
          var h = top / (box.offsetHeight - move.offsetHeight);
          //比率
          var b_left = w * (b_bimg.offsetWidth - bimg.offsetWidth);
          var b_top = h * (b_bimg.offsetHeight - bimg.offsetHeight);
          b_bimg.style.left = -b_left + "px";
          b_bimg.style.top = -b_top + "px";
        };
      }
    },
    openVideo() {
      console.log("...");
      let video = document.getElementById("sVideo");
      video.play();
      this.videoChoose = true;
    },
    getSwiper() {
      var mySwiper = new this.Swiper(".swiper-container", {
        direction: "horizontal", // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    //展示信息
    showInfo() {
      this.$("#message").css("display", "block");
      setTimeout(() => {
        this.$("#message").css("display", "none");
      }, 1000);
    },
    async getDetailList() {
      this.qid = this.$route.query.id;
      let res = await getLimitDetail({ id: this.qid, userId: 1 });
      res = res.data.data;
      console.log(res);
      this.itemObj = res;
      // 价格
      this.nowPrice = res.priceMin;
      this.prePrice = res.priceMax;
      this.id = res.commodityId;
      this.cautionMoney = res.cautionMoney;
      this.paincTime = res.panicTime;
      this.paincNum = res.quota;
      this.alreadyNum = res.peopleSize;
       this.video = res.video;
      this.videoImage = res.videoImage;
      // 左侧图片
      this.imgList = res.imagesUrlList;
      // 选择图片
      if (this.videoImage) {
        this.chooseImg = this.videoImage;
        this.selectImgId = -1;
      } else {
        this.chooseImg = this.imgList[0];
      }
      // 左侧
      this.detailLeft = res.specificationVOList;
      // 右侧
      this.detailRight = res.specificationValueVOList;
      // 商品介绍
      this.introduction = res.introduction;
      // 商品名
      this.name = res.commodityName;
      // 商品类型
      this.commodityList = res.commodityTypeVOList;
      //是否收藏
      this.save = res.isFavorites;
      // 状态
      this.state = res.state;
      // 默认网点
      this.provinceIndex = res.outletsVO.provinceValue;
      this.province = res.outletsVO.provinceName;
      this.cityIndex = res.outletsVO.cityValue;
      this.city = res.outletsVO.cityName;
      this.regionIndex = res.outletsVO.districtValue;
      this.region = res.outletsVO.districtName;
      this.wangdianIndex = res.outletsVO.areaValue;
      this.wangdian = res.outletsVO.name;
      this.wangdianName =
        this.province + this.city + this.region + this.wangdian;
      this.wid = res.outletsVO.id;
      if (this.itemObj.specificationVOList.length > 2) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
      let arr = [];
      let arrSpecial = [];
      for (let i = 0; i < this.detailRight.length; i++) {
        if (arr.indexOf(this.detailRight[i].specificationId) == -1) {
          arr.push(this.detailRight[i].specificationId);
          arrSpecial.push(this.detailRight[i].id);
          this.detailRight[i].flag = true;
        }
      }
      console.log("...");
      for (let j = 0; j < this.commodityList.length; j++) {
        let specialTypeList = this.commodityList[j].specificationValueIdList;
        if (arrSpecial.join(",") == specialTypeList.join(",")) {
          this.mid = this.commodityList[j].id;
        }
      }
      console.log(this.mid);
      let res1 = await getLimitPrice({
        id: this.$route.query.id,
        specificationValueIds: arrSpecial.join(","),
      });
      res1 = res1.data;
      console.log(res1);
      if (res1.result == 0) {
        res1 = res1.data;
        this.nowPrice = res1.specialOffer;
        this.prePrice = res1.price;
        this.isOrder = true;
      } else {
        this.message = res1.msg;
        this.isOrder = false;
        this.showInfo();
      }

      console.log(res1);
      console.log(res);
      this.$nextTick(function () {
          this.getSwiper();
        });
    },
    // 图片选择
    imgChoose(index) {
      this.videoChoose = false;
      let video = document.getElementById("sVideo");
      video.pause();
      if (index == -1) {
        this.chooseImg = this.videoImage;
        this.selectImgId = index;
      } else {
        this.selectImgId = index;
        this.chooseImg = this.imgList[index];
      }
      this.$nextTick(function () {
        this.getMove();
      });
    },
    // 右侧选择
    async chooseConst(item, item2, index2) {
      for (let i = 0; i < this.detailRight.length; i++) {
        if (item.id == this.detailRight[i].specificationId) {
          this.detailRight[i].flag = false;
        }
      }
      this.detailRight[index2].flag = true;

      let arrSpecial = [];

      for (let i = 0; i < this.detailRight.length; i++) {
        if (this.detailRight[i].flag) {
          arrSpecial.push(this.detailRight[i].id);
        }
      }

      for (let j = 0; j < this.commodityList.length; j++) {
        let specialTypeList = this.commodityList[j].specificationValueIdList;
        if (arrSpecial.join(",") == specialTypeList.join(",")) {
          this.mid = this.commodityList[j].id;
        }
      }

      let res1 = await getLimitPrice({
        id: this.$route.query.id,
        specificationValueIds: arrSpecial.join(","),
      });
      res1 = res1.data;
      if (res1.result == 0) {
        res1 = res1.data;
        this.nowPrice = res1.specialOffer;
        this.prePrice = res1.price;
        this.isOrder = true;
      } else {
        this.message = res1.msg;
        this.showInfo();
        this.isOrder = false;
      }
      if(item2.image!=null){
        this.chooseImg = item2.image;
        this.selectImgId = -2;
        if(this.videoChoose){
          this.videoChoose = false;
          this.$nextTick(function(){
            this.getMove();
          })
        }
      }
      console.log(res1);
      console.log(arrSpecial);
    },
    handleChange(val) {
      this.num = val;
    },
    // 商品介绍
    toIntroduce(e) {
      this.chooseNum = e.target.dataset.num;
    },
    // 地址选择
    async chooseSelected(e) {
      if(e!=0){
        this.chooseSelect = e.target.dataset.num;
      }
      if (this.chooseSelect == 1) {
        let res = await getRegion({
          commodityId: this.itemObj.commodityId,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 2) {
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          commodityId: this.itemObj.commodityId,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 3) {
        // this.areaList = this.regionList;
        let res = await getRegion({
          provinceValue: this.provinceIndex,
          cityValue: this.cityIndex,
          commodityId: this.itemObj.commodityId,
        });
        this.areaList = res.data.data;
      }
      if (this.chooseSelect == 4) {
        // this.areaList = this.wangdianList;
        let res = await getWangdian({
          areaValue: this.regionIndex,
          commodityId: this.itemObj.commodityId,
        });
        this.areaList = res.data.data;
        console.log(res);
      }
    },
    toProvice(name, id) {
      this.provinceIndex = id;
      this.province = name;
      this.city = "请选择";
      this.region = "请选择";
      this.wangdian = "请选择";
      this.chooseSelect = 2;
      this.chooseSelected(0);
    },
    toCity(name, id) {
      this.cityIndex = id;
      this.city = name;
      this.region = "请选择";
      this.wangdian = "请选择";
      this.chooseSelect = 3;
      this.chooseSelected(0);
    },
    toRegion(name, id) {
      this.regionIndex = id;
      this.region = name;
      this.wangdian = "请选择";
      this.chooseSelect = 4;
      this.chooseSelected(0);
    },
    toWangdian(name, id, wid) {
      this.wangdianIndex = id;
      this.wid = wid;
      this.wangdian = name;
      this.wangdianName =
        this.province + this.city + this.region + this.wangdian;
      this.isClick = false
    },
    async chooseArea() {
      this.isClick = !this.isClick;
      if (this.chooseSelect == 1) {
        let res = await getRegion({ commodityId: this.itemObj.commodityId });
        this.areaList = res.data.data;
        console.log(res);
      }
    },
    // 下单
    async toOrder() {
      if (this.wangdianIndex == 0) {
        this.message = "请选择网点";
        this.showInfo();
      } else if (!this.isOrder) {
        this.message = "该商品库存不足";
        this.showInfo();
      } else {
        let res = await getOrderAddress({ userId: 1 });
        console.log(res);
        if (res.data.result == 0) {
          res = res.data.data;
          this.totalAddress = res;
          if (this.totalAddress.length > 0) {
            this.centerDialogVisible3 = true;
            this.oneAddressObj = this.totalAddress[0];
            if (this.totalChoose == false) {
              this.oneAddressObj =
                this.totalAddress[this.totalAddress.length - 1];
            }
          } else {
            this.centerDialogVisible2 = true;
          }
        } else {
          sessionStorage.setItem("address", this.$route.fullPath);
          this.$router.push("/login");
        }
      }

      console.log(this.oneAddressObj);
    },
    // 选择一个地址
    tochooseOneAddress(index) {
      this.chooseAddress = index;
    },
    // 一个地址确定
    toSaveOneAddress() {
      this.oneAddressObj = this.totalAddress[this.chooseAddress];
      this.centerDialogVisible = false;
    },
    // 新增地址
    addOneAddress() {
      this.centerDialogVisible4 = true;
      this.phone = "";
      this.province1Index = -1;
    },

    // 选择省
    async choooseProvince() {
      let res = await getAddress();
      res = res.data.data;
      this.province1List = res;
      console.log(res);
    },

    // 选择市
    async chooseCity() {
      let res = await getAddress({
        provinceValue: this.province1Index,
      });
      res = res.data.data;
      console.log(res);
      this.city1List = res;
    },

    // 选择区
    async chooseRegion() {
      let res = await getAddress({
        provinceValue: this.province1Index,
        cityValue: this.city1Index,
      });
      res = res.data.data;
      this.region1List = res;
      console.log(res);
    },
    // 确定新增地址
    async sureAddAddress() {
      if (
        this.region1Index != -1 &&
        this.addDetailAddress != "" &&
        this.addUserName != "" &&
        resultPhone(this.addPhone)
      ) {
        let res = await addAddress({
          addressDetailed: this.addDetailAddress,
          areaValue: this.region1Index,
          isDefault: this.totalChoose ? 1 : 0,
          phone: this.addPhone,
          userId: 1,
          username: this.addUserName,
        });
        this.toOrder();
        this.centerDialogVisible4 = false;
      }
    },
    // 新增关闭
    closeDialog4() {
      this.province1Index = -1;
      this.addDetailAddress = "";
      this.addUserName = "";
      this.addPhone = "";
      this.totalChoose = false;
    },

    // 提交
    async provideOne() {
      console.log(resultPhone(this.phone));
      if (!resultPhone(this.phone)) {
        console.log("aaa");
        this.message = "请输入正确的手机号";
        this.showInfo();
      } else if (this.region1Index == -1) {
        this.message = "请选择正确的地址";
        this.showInfo();
      } else if (this.newdetailAddress == "") {
        this.message = "请输入详细地址";
        this.showInfo();
      } else if (this.newUserName == "") {
        this.message = "请输入收货人地址";
        this.showInfo();
      } else if (!resultPhone(this.newPhone)) {
        this.message = "请输入收货人手机号码";
        this.showInfo();
      } else {
        let res = await addAddress({
          addressDetailed: this.newdetailAddress,
          areaValue: this.region1Index,
          isDefault: 0,
          phone: this.newPhone,
          userId: 1,
          username: this.newUserName,
        });
        if (res.data.result == 0) {
          console.log("...");
          let res2 = await getOrderAddress({
            userId: 1,
          });
          res2 = res2.data.data;
          let res3 = await panicAdd({
            commodityId: this.id,
            commodityTypeId: this.mid,
            outletsId: this.wid,
            panicBuyId: this.qid,
            phone: this.phone,
            userAddressId: res2[0].id,
            userId: 1,
            type: this.payAli ? 0 : 1,
            isType: 0,
          });
          this.orderNUmber = res3.data.order_number
          if (this.payAli) {
            if (this.cautionMoney > 0) {
              this.$("#myForm").html(res3.data);
            } else {
              this.message = "报名成功";
              this.showInfo();
              this.$router.push("/index/buy-limit");
            }
            this.centerDialogVisible2 = false;
          } else {
            this.isshowScan = true;
            this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
            let qrcode = new QRCode("qrcode", {
              width: 200, // 设置宽度，单位像素
              height: 200, // 设置高度，单位像素
              text: res3.data.code_url, // 设置二维码内容或跳转地址(完整链接)
            });
          }
        }
      }
    },

    async provideTwo() {
      if (resultPhone(this.phone)) {
        let res = await panicAdd({
          commodityId: this.id,
          commodityTypeId: this.mid,
          outletsId: this.wid,
          panicBuyId: this.qid,
          phone: this.phone,
          userAddressId: this.oneAddressObj.id,
          type: this.payAli ? 0 : 1,
          userId: 1,
          isType: 0,
        });
        console.log(res);
        this.orderNUmber = res.data.order_number
        if (this.payAli) {
          if (this.cautionMoney > 0) {
            this.$("#myForm").html(res.data);
          } else {
            this.message = "报名成功";
            this.showInfo();
            this.$router.push("/index/buy-limit");
          }
          this.centerDialogVisible3 = false;
        } else {
          this.isshowScan = true;
          this.$refs.qrcode.innerHTML = ""; // 清空之前生成的二维码内容
          let qrcode = new QRCode("qrcode", {
            width: 200, // 设置宽度，单位像素
            height: 200, // 设置高度，单位像素
            text: res.data.code_url, // 设置二维码内容或跳转地址(完整链接)
          });
        }
      } else {
        this.message = "请填写正确手机号码";
        this.showInfo();
      }
    },

    // 立即咨询
    async toConsult() {
      let res = await consultApi();
      res = res.data.data;
      this.consultImg = res;
      this.dialogVisible = true;
    },
    // 去领奖
    toGetAward() {
      this.$router.push("/my-order");
    },
    seeStatus(){
      wxResult({
        orderId:this.orderNUmber
      }).then((res)=>{
        console.log(res);
        if(res.data.result == 0){
          this.message = res.data.msg;
          this.showInfo();
          this.$router.push('/my-order')
        }else{
          this.message = res.data.msg;
          this.showInfo();
        }
      })
    }
  },
  watch: {
    province1Index: function () {
      this.city1Index = -1;
      this.region1Index = -1;
    },
    city1Index: function () {
      this.region1Index = -1;
    },
  },
};
</script>


 

<style lang="less" scoped>
#box {
  width: 100%;
  position: relative;
  border: 1px solid #cccdcd;
}

#move {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}

#bimg {
  width: 460px;
  height: 460px;
  position: absolute;
  left: 500px;
  top: 0px;
  overflow: hidden;
  display: none;
  z-index: 999;
}

.bimg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 99;
}

.swiper-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  // object-fit: fill;
}

#b_bimg {
  width: 880px;
  height: 880px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.three-select-absolut {
  width: 218px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 28px;
  z-index: 999;
  display: none;
}

.none {
  display: none !important;
}

.shop-detail-top {
  margin-top: 40px;
  margin-bottom: 140px;
}
.shop-detail-top-left {
  .show-img {
    width: 100%;
    max-width: 460px;
    height: 460px;
  }
}

.shop-detail-img-item {
  margin-top: 10px;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid #cccdcd;
  }
}

.swiper-wrapper {
  .swiper-slide {
    width: 80px !important;
    height: 80px;
    margin-right: 11px;
    position: relative;
    .video-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.shop-detail-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: #111;
  .one {
    font-size: 20px;
    font-weight: 700;
  }
  .two {
    color: #666;
    display: block;
    margin-top: 12px;
  }
  .three {
    margin-top: 25px;
    width: 100%;
    background-color: #f3f3f3;
    .top {
      font-size: 12px;
      margin-top: 10px;
      .top-top {
        display: inline-block;
        width: 68px;
        margin-left: 14px;
      }
      .top-item {
        font-size: 18px;
        font-weight: 500;
      }
      .top-price {
        font-size: 28px;
        font-weight: 500;
      }
    }
    .center {
      margin-top: 15px;
    }
    .top-two {
      font-size: 14px;
      text-decoration: line-through;
    }
    .top-three {
      font-size: 14px;
    }

    .bottom {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #f3f3f3 !important;
    }

    .three-select {
      position: relative;
      width: 220px;
      height: 30px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #cccdcd;
      input {
        padding-left: 20px;
        width: 160px;
        height: 14px;
        border: 0;
        outline: none;
        background-color: #fff;
        ::placeholder {
          font-size: 14px;
          color: #999;
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .select-absoulte {
        overflow: hidden;
        background-color: #fff;
        width: 365px;
        // height: 500px;
        position: absolute;
        padding: 14px 20px 0;
        top: 29px;
        left: -1px;
        z-index: 998;
        border: 1px solid #cccdcd;
        display: none;
        .item-button {
          position: relative;
          display: inline-block;
          width: 70px;
          height: 24px;
          font-size: 14px;
          margin-right: 10px;
          border: 1px solid #cccdcd;
          text-align: center;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .absoulte-line {
          position: absolute;
          top: 36px;
          width: 100%;
          height: 2px;
          background-color: #111;
        }
      }
    }
  }
  .item-button-bottom {
    position: absolute;
    width: 66px;
    height: 3px;
    background-color: #fff;
    top: 20px;
    left: 0;
    z-index: 999;
  }
  .item-button-click {
    border: 2px solid #111 !important;
    border-bottom: 0 !important;
  }
  .select-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 200px;
    overflow-y: scroll;
    .select-loop {
      display: inline-block;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      span {
        padding: 4px 5px;
        margin-right: 20px;
      }
    }
  }

  .three-select:hover .select-absoulte{
    display: block;
  }

  .three-select:hover .three-select-absolut{
    display: block;
  }

  .select-container::-webkit-scrollbar {
    width: 10px;
    height: 1px;
    // background-color: #f5f5f5;
  }

  .select-container::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/

    border-radius: 10px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    background: #bbbbbb;
  }

  .select-container::-webkit-scrollbar-track {
    /*滚动条里面轨道*/

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    background: #ededed;
  }

  //////////////////////////////////////
  .color-catagory {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border: 1px solid #cccdcd;
  }

  .four {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    margin-top: 20px;
    .four-left {
      width: 68px;
      margin-left: 14px;
      font-size: 12px;
      margin-top: 10px;
      flex-shrink: 0;
    }
    .four-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
    .four-right-item {
      position: relative;
      height: 50px;
    }
    .four-right-item-position {
      position: absolute;
      right: 12px;
      bottom: 3px;
    }
  }
}

.five-right-item-position {
  right: 6px !important;
}

.my-border {
  display: inline-block;
  border: 1px solid #cccdcd;
  padding: 5px 9px;
  color: #333;
  margin-right: 6px;
  margin-top: 12px;
  cursor: pointer;
}

.constructer-left {
  display: block;
  margin-top: 20px !important;
}

.my-border-two {
  border: 2px solid #bba492;
}

.line-more {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #cccdcd;
  margin-top: 20px;
  img {
    width: 24px;
    height: 12px;
    position: absolute;
    left: 50px;
    top: 0;
    border-top: 1px solid transparent !important;
  }
}

.input-number {
  margin-top: 14px;
}

.five {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;

  .see-more {
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-right: 20px;
  }

  .see-more-one {
    background-color: #111;
    color: #fff;
  }

  .see-more-two {
    background-color: rgba(17, 17, 17, 0.04);
    border: 1px solid #ccc;
  }

  .see-more-three {
    border: 1px solid #cccdcd;
  }

  .collect {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #cccdcd;
  }

  .addSort {
    margin-left: 10px;
  }
}

.shop-introduce {
  margin-top: 60px !important;
  margin-bottom: 40px !important;
  font-size: 16px;
  .shop-introduce-top {
    width: 100%;
    height: 50px;
    background: #f8f8f8;
    // border: 1px solid #cccdcd;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .shop-introduce-top-item {
      width: 11%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-right: 1px solid #cccdcd;
      color: #666;
      border-top: 1px solid #cccdcd;
      border-left: 1px solid #cccdcd;
      border-bottom: 1px solid #cccdcd;
      cursor: pointer;
    }
    .introduce-item {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cccdcd;
    }
  }
  .shop-backgorund{
    background-color: #fff;
  }
}

.introduce-top {
  border-top: 4px solid #111 !important;
  color: #111 !important;
  border-bottom: 0 !important;
}

.introcude-detail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #cccdcd;
  font-size: 14px;
  margin-bottom: 10px !important;
  .one {
    color: #111;
  }
  .two {
    color: #999;
  }
}

.shop-big-img {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

.block {
  display: block !important;
}

.pointer {
  cursor: pointer;
}
.cityClass {
  background-color: #111;
  color: #fff;
}
.none {
  display: none;
}

.message {
  width: 300px;
  height: 56px;
  background: rgba(17, 17, 17, 0.7);
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;
  line-height: 56px;
  text-align: center;
  z-index: 999999;
}
/deep/ .el-dialog {
  width: 400px;
}
.domian-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 260px;
    height: 260px;
    border: 1px solid #cccdcd;
    padding: 20px;
  }
  .one {
    margin-top: 20px;
  }
}

//////////////////////////////////
//dialog部分
.address-item {
  padding: 15px 24px;
  border: 1px solid #cccdcd;
  color: #111;
  font-size: 14px;
  position: relative;
  margin-top: 15px;
  .address-item-item {
    display: flex;
    margin-top: 5px;
    .address-item-item-one {
      display: inline-block;
      width: 70px;
      flex-shrink: 0;
    }
    .address-item-item-one:first-child {
      margin: 0;
    }
  }
}
.address-item:nth-child(1) {
  margin: 0;
}
.active-address-item {
  border: 2px solid #111 !important;
}

.icon-true {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dialog-footer {
  font-size: 16px;
  .sure {
    background-color: #111;
    color: #fff;
    width: 144px;
    height: 42px;
    outline: none;
    margin-right: 14px;
    border: 0;
  }
  .cancel {
    width: 144px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #111111;
    color: #111;
    outline: none;
  }
}

.default-address-span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: #bba492;
}

////////////////////////////////////////////////////////////////////////////////////////

/deep/.el-dialog {
  color: #111 !important;
  width: 610px;
}

.el-form {
  margin-top: 20px;
}

.dottat-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #cccdcd;
}

.getInfo {
  display: block;
  margin-top: 20px;
}

.select-area {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  select {
    width: 30%;
    height: 30px;
    outline: none;
    border: 1px solid #cccdcd;
    padding-left: 10px;
    background-color: #fff;
  }
  .my-area {
    display: block;
  }
  .ccccolor {
    color: #cccdcd;
  }
}

.my-textarea {
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #cccdcd;
  padding-left: 10px;
  margin-top: -20px;
}
.my-textarea::placeholder {
  color: #cccdcd;
}

.detail-info {
  margin-top: 20px;
}

.detail-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-info-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    margin-bottom: 10px;
    .one {
      display: inline-block;
      width: 80px;
      color: #999;
    }
    .two {
      color: #111;
    }
  }
}

.defaultAddress {
  margin-top: -15px;
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
  }
}

.circle-total {
  display: flex;
  .circle-item {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .circle-total-domain {
    margin-left: 30px;
  }
}

/deep/.IntroductionText img {
  display: block !important;
  width: 100% !important;
}

/deep/.IntroductionText p {
  font-size: 16px;
}

.scanPay {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9999;
  font-size: 20px;
  #qrcode {
    margin-bottom: 30px;
  }
  span {
    margin-top: 10px;
  }
  .isPay {
    margin-bottom: 20px;
    cursor: pointer;
    color: red;
  }
  .closeIMg{
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}

.wangdianinput {
  width: 170px !important;
  overflow: hidden; /*溢出隐藏*/
  text-overflow: ellipsis; /*以省略号...显示*/
  white-space: nowrap; /*强制不换行*/
}

.selectedImg{
  border: 2px solid #111 !important;
}

#swiper1 .swiper-button-prev {
  left: 0;
  background-image: url("../../assets/img/small-arrow-left.png");
  background-size: 24px 24px;
  background-color: #999;
}
#swiper1 .swiper-button-next {
  right: 0;
  background-image: url("../../assets/img/arrow-small-right.png");
  background-size: 24px 24px;
  background-color: #999;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: painted;
}

.four-img-block{
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 12px;
  position: relative;
  .img-color{
    width: 100%;
    height: 100%;
  }
  .color-right-item-position{
    right: 0 !important;
    bottom: 0 !important;
  }
  .img-border{
    border: 2px solid #bba492;
  }
  .color-world{
    display: inline-block;
    white-space: nowrap;
    position: relative;
    bottom: -10px;
    background-color: #fff;
    border: 1px solid #CCCDCD;
    padding: 0 5px;
    opacity: 0;
    z-index: 10;
  }
}

.four-img-block:hover .color-world{
  opacity: 1;
}
</style>