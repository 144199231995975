<template></template>

<script>
import { Message } from "element-ui";
import { callbackPcApi } from "../../assets/api/api";
export default {
  name: "loginSuccess",
  data() {
    return {};
  },
  created() {
    this.getCallPc();
  },
  methods: {
    getCallPc() {
      let that = this;
      let para = {
        code: this.$route.query.code,
        state: this.$route.query.state,
      };
      callbackPcApi(para).then((res) => {
        if (res.data.result == 0) {
          //0注册/1未注册
          if (res.data.data.isHave == 0) {
            //0被禁用/1未被禁用
            if (res.data.data.isState == 1) {
              //0未激活/1已激活
              if (res.data.data.isActivation == 1) {
                sessionStorage.setItem("token", res.data.data.token);
                sessionStorage.setItem("avatar", res.data.data.avatar);
                sessionStorage.setItem("nickname", res.data.data.nickname);
                this.$router.push({ path: sessionStorage.getItem("address") });
              } else {
                Message.error("请先至邮箱中激活账号后登录");
                setTimeout(function () {
                  that.$router.replace({ path: "/login" });
                }, 2000);
              }
            } else {
              Message.error("账号异常");
              setTimeout(function () {
                that.$router.replace({ path: "/login" });
              }, 2000);
            }
          } else {
            Message.warning("请关联账号");
            setTimeout(function () {
              that.$router.replace({
                path: "/login",
                query: { state: that.$route.query.state },
              });
            }, 2000);
          }
        } else {
          Message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>