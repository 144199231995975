<template>
  <div class="o-detail">
    <div class="o-top">
      <div class="o-top-one">
        <span class="status" v-if="detailObj.state == 0">已取消</span>
        <span class="status" v-if="detailObj.state == 1">待付款</span>
        <span class="status" v-if="detailObj.state == 2">待发货</span>
        <span class="status" v-if="detailObj.state == 3">已发货</span>
        <span class="status" v-if="detailObj.state == 4">已完成</span>
        <span class="status-word" v-if="detailObj.state == 1"
          >请在 {{ detailObj.now }} 前付款</span
        >
      </div>
      <div class="o-top-two">
        <div class="o-top-two-left">
          <div class="o-top-two-left-item-one">
            <span class="one">{{ detailObj.username }}</span>
            <span>{{ detailObj.phone }}</span>
          </div>
          <div class="o-top-two-left-item-two">
            <div class="btn">
              <span class="one status-word">默认</span>
            </div>
            <span class="two">{{ detailObj.address }}</span>
          </div>
        </div>
        <!-- <div class="o-top-two-right">
          <img src="../../assets/img/arrow_right.png" alt="" />
        </div> -->
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="o-info">
      <div class="o-info-top">
        <span>商品信息</span>
      </div>
      <div class="o-info-domain">
        <div
          class="o-info-domain-item"
          v-for="(item, index) in detailObj.orderCommodityVOList"
          :key="index"
        >
          <div class="left-img">
            <img class="img-fuild" :src="item.commodityImage" alt="" />
          </div>
          <div class="center-container">
            <div class="one">{{ item.commodityName }}</div>
            <div class="two">{{ item.specificationValueName }}</div>
            <div class="two">
              {{ detailObj.outletsArea + detailObj.outletsName }}
            </div>
            <div class="three">
              ￥{{ item.commodityTypePrice * item.quantity }}
            </div>
          </div>
          <div class="right-qunatity">
            <span>x{{ item.quantity }}</span>
          </div>
        </div>
        <div class="o-info-domain-bottom">
          <div class="o-info-domain-bottom-item">
            <span>邮费</span>
            <span>￥0.00</span>
          </div>
          <div class="o-info-domain-bottom-item">
            <span>商品合计</span>
            <span>￥{{ detailObj.orderMoney }}</span>
          </div>
          <div class="o-info-domain-bottom-item">
            <span>应付总额</span>
            <span>￥{{ detailObj.orderMoney }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="o-info o-info-bottom">
      <div class="o-info-top">
        <span>订单信息</span>
      </div>
      <div class="order-information">
        <div class="o-info-domain-bottom-item">
          <span>订单编号</span>
          <span>{{ detailObj.orderNumber }}</span>
        </div>
        <div class="o-info-domain-bottom-item">
          <span>下单时间</span>
          <span>{{ detailObj.orderTime }}</span>
        </div>
      </div>
    </div>
    <div id="myForm"></div>
    <div class="fix-bottom">
      <div class="fix-bottom-domain">
        <div class="fix-bottom-left">
          <span>订单金额 :</span>
          <span class="price">￥{{ detailObj.orderMoney }}</span>
        </div>
        <div class="fix-bottom-right">
          <div class="btn" v-if="detailObj.state == 1" @click="cancelOneOrder">
            取消订单
          </div>
          <div
            class="btn btn-time"
            v-if="detailObj.state == 1"
            @click="payOrderMoney"
          >
            付款 {{ detailObj.now }}
          </div>
          <!-- <div class="btn" v-if="detailObj.state == 4" @click="tokaipiao">申请开票</div>
          <div class="btn" v-if="detailObj.state == 3 || detailObj.state == 4">
            查看物流
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      title="支付方式"
      :visible.sync="typePay"
      custom-class="mobile-pay-dialog"
      center
    >
      <div class="payTypeMobile">
        <div class="payTypeMobile-item">
          <img src="../../assets/img/zhifubao.png" alt="" />
          <span>支付宝</span>
          <img
            class="to-right"
            src="../../assets/img/ic_gou_s.png"
            alt=""
            v-if="payAli"
          />
          <img
            class="to-right"
            src="../../assets/img/ic_gou_n.png"
            alt=""
            v-else
            @click="payAli = true"
          />
        </div>
        <div class="payTypeMobile-item">
          <img src="../../assets/img/wechart.png" alt="" />
          <span>微信</span>
          <img
            class="to-right"
            src="../../assets/img/ic_gou_s.png"
            alt=""
            v-if="!payAli"
          />
          <img
            class="to-right"
            src="../../assets/img/ic_gou_n.png"
            alt=""
            v-else
            @click="payAli = false"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typePay = false">取 消</el-button>
        <el-button type="primary" @click="paySure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { orderDetailApi, payOrder, removeOrder } from "../../assets/api/api.js";
import { loopDate } from "../../uitils/check.js";
export default {
  created() {
    let id = this.$route.query.id;
    this.getInfo(id);
  },
  data() {
    return {
      detailObj: {},
      sid: 0,
      typePay:false,
      payAli:true,
      infoItem:''
    };
  },
  methods: {
    async getInfo(id) {
      let res = await orderDetailApi({
        id: id,
      });
      res = res.data.data;
      this.detailObj = res;
      console.log(res);
      if (res.state == 1) {
        this.getLoop();
      }
    },
    getLoop() {
      this.detailObj.time--;
      this.detailObj.now = loopDate(this.detailObj.time);
      if (this.detailObj.time == 0) {
        clearTimeout(this.sid);
        this.getInfo(this.detailObj.id);
      }
      clearTimeout(this.sid);
      this.getLoopDetail();
    },
    getLoopDetail() {
      this.sid = setTimeout(this.getLoop, 1000);
    },
    payOrderMoney() {
      // console.log(this.detailObj);
      // payOrder({
      //   orderNumber: this.detailObj.orderNumber,
      //   type: 1,
      // }).then((res) => {
      //   console.log(res);
      //   if (this.detailObj.payType == 0) {
      //     if (res.status == 200) {
      //       this.$("#myForm").html(res.data);
      //     }
      //   } else {
      //     location.href = res.data.h5_url;
      //   }
      // });
      this.payAli = true;
      this.typePay = true;
    },
    paySure(){
      this.typePay = false;
      payOrder({
        orderNumber: this.detailObj.orderNumber,
        type: 1,
        payType:this.payAli?0:1
      }).then((res) => {
        console.log(res);
        console.log(this.payAli);
        if (this.payAli) {
          if (res.status == 200) {
            this.$("#myForm").html(res.data);
          }
        } else {
          location.href = res.data.h5_url;
        }
      });
    },
    cancelOneOrder() {
      console.log(this.detailObj);
      removeOrder({
        id: this.detailObj.id,
      }).then((res) => {
        if (res.data.result == 0) {
          this.getInfo(this.detailObj.id);
        }
      });
    },
    tokaipiao() {},
  },
};
</script>

<style lang="less" scoped>
.status-word {
  font-size: 16px;
}
.o-detail {
  width: 100%;
  background-color: #f3f3f3;
  position: absolute;
  top: 63px;
  left: 0;
  bottom: 0;
  overflow: auto;
  .o-top {
    .o-top-one {
      width: 100%;
      background-color: #111;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 20px 15px;
      .status {
        font-size: 20px;
        font-weight: 500;
        margin-right: 10px;
      }
    }
    .o-top-two {
      padding: 0 15px;
      height: 77px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .o-top-two-left {
        .o-top-two-left-item-one {
          height: 25px;
          font-size: 15px;
          color: #111;
          font-weight: 500;
          .one {
            display: inline-block;
            width: 70px;
          }
        }
        .o-top-two-left-item-two {
          font-size: 16px;
          .btn {
            width: 80px;
            text-align: left;
            font-size: 16px;
            .one {
              color: #bba492;
              border: 1px solid #bba492;
              padding: 0 2px 2px 5px;
              letter-spacing: 2px;
            }
          }
          .two {
            color: #999;
          }
        }
      }
      .o-top-two-right {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .o-info {
    padding: 20px 15px;
    background-color: #fff;
    margin-top: 10px;
    .o-info-top {
      font-size: 15px;
      margin-bottom: 24px;
    }
    .o-info-domain {
      width: 100%;
      .o-info-domain-item {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-img {
          width: 32%;
          img {
            width: 100%;
            border: 1px solid #cccdcd;
          }
        }
        .center-container {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .one {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #111;
          }
          .two {
            font-size: 12px;
            color: #999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .three {
            font-size: 15px;
            color: #111;
            font-weight: 500;
          }
        }
        .right-qunatity {
          width: 10%;
        }
      }
      .o-info-domain-bottom {
        margin-top: 35px;
        .o-info-domain-bottom-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #666;
          padding: 5px 0;
        }
      }
    }
    .order-information {
      .o-info-domain-bottom-item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
        padding: 5px 0;
      }
    }
  }
  .o-info-bottom {
    margin-bottom: 123px;
  }
}

.fix-bottom {
  padding: 0 15px;
  height: 74px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .fix-bottom-domain {
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .fix-bottom-left {
    font-size: 14px;
    color: #999;
    .price {
      color: #111;
    }
  }
  .fix-bottom-right {
    display: flex;
    font-size: 16px;
    .btn {
      width: 80px;
      height: 30px;
      border: 1px solid #111;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      font-size: 16px;
    }
    .btn-time {
      background-color: #111;
      color: #fff;
    }
  }
}

.payTypeMobile-item{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.payTypeMobile-item .to-right{
  margin-left: auto;
}
</style>